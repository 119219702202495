import React from 'react';
import { TextField } from '@mui/material';

export const Input = (props) => {
  const { label, value, onChange, type, required, helperText, error } = props;
  return (
    <TextField
      required={required}
      helperText={helperText}
      error={error}
      sx={{ marginTop: 3 }}
      type={type}
      label={label}
      value={value}
      onChange={onChange}
      variant="outlined"
    />
  );
};
