import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material';

export const StatusSelect = ({ label, name, value, onChange, error }) => {
  return (
    <FormControl sx={{ marginTop: 3 }} fullWidth error={error}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        label={label}
      >
        <MenuItem value="Активний">Активний</MenuItem>
        <MenuItem value="Заблокований">Заблокований</MenuItem>
        <MenuItem value="Обмежений">Обмежений</MenuItem>
      </Select>
      {error && <FormHelperText>Це поле обов'язкове</FormHelperText>}
    </FormControl>
  );
};
