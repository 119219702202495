import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const driverApi = createApi({
  reducerPath: 'driverApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  tagTypes: ['Drivers'],
  endpoints: (builder) => ({
    getDrivers: builder.query({
      query: () => ({
        url: '/drivers/get-all',
        method: 'GET',
      }),
      providesTags: () => ['Drivers'],
    }),
    getDriver: builder.query({
      query: (id) => ({
        url: `/drivers/get-driver/${id}`,
        method: 'GET',
      }),
      providesTags: () => ['Drivers'],
    }),
    registerDriver: builder.mutation({
      query: (body) => ({
        url: '/drivers/register',
        method: 'POST',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['Drivers'],
    }),
    updateDriver: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/drivers/update/${id}`,
        method: 'PATCH',
        credentials: 'include',
        body: formData,
      }),
      invalidatesTags: ['Drivers'],
    }),
    deleteDriver: builder.mutation({
      query: (id) => ({
        url: `/drivers/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['Drivers'],
    }),
  }),
});

export const {
  useGetDriversQuery,
  useGetDriverQuery,
  useRegisterDriverMutation,
  useUpdateDriverMutation,
  useDeleteDriverMutation,
} = driverApi;
