import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSocket } from '../../../utils/websoketContext';
import { useGetOrderQuery, useLazyGetRateQuery } from '../../../store/api';
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Modal from 'react-modal';

import { Map } from './components/Map';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useLoadScript } from '@react-google-maps/api';
import { formula } from '../../../utils/formula';
import { ChangeZoneVisible } from './components/ChangeZoneVisible';
import { ChangeCityZone } from './components/ChangeCityZone';
import { ItemPoints } from './components/Points/Point';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { OrderTypesSelect } from './components/OrderTypesSelect';
import { CustomInput } from '../../../components/CustomInput';

const libraries = ['places', 'geometry'];
export const OrderPage = () => {
  const { id } = useParams();

  const { data } = useGetOrderQuery({ id });

  const [distance, setDistance] = useState(0);
  const [time, setTime] = useState(0);
  const [originPlace, setOriginPlace] = useState('');
  const [destinationPlace, setDestinationPlace] = useState('');
  const [wayPoints, setWayPoints] = useState([]);
  const [waypointsDirection, setWaypointsDirection] = useState([]);
  const [value, setValue] = useState(dayjs());
  const [allPrice, setAllPrice] = useState('');
  const [distanceInCity, setDistanceInCity] = useState(0);
  const [selectedType, setSelectedType] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedSubType, setSelectedSubType] = useState({});
  const [isRoadCreated, setIsRoadCreated] = useState(false);
  const [priceKilometers, setPriceKilometers] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const socketRef = useSocket();
  const center = useSelector((state) => state.order.center);

  const handleRefuseOrder = () => {
    socketRef.current.emit('refuse_order', {
      orderId: id,
      driverId: data?.driverId,
    });
  };

  const handleCancelOrder = () => {
    socketRef.current.emit('cancel_order', {
      orderId: id,
      driverId: data?.driverId,
    });
  };

  const routePoints = useSelector((state) => state.order.routePoints);

  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
    language: 'ua',
  });

  const onRouteOrigin = useCallback((distance, time, distanceCity) => {
    setDistance(distance);
    setTime(time);
    setDistanceInCity(distanceCity);
  }, []);

  const onSelectedOrderType = (type) => {
    setSelectedType(type);
  };

  const onSelectedOrderCategory = (category) => {
    setSelectedCategory(category);
  };

  const onSelectedOrderOption = (option) => {
    setSelectedOption(option);
  };

  const onSelectedOrderSubType = (subType) => {
    setSelectedSubType(subType);
  };
  const [trigger, result] = useLazyGetRateQuery();
  const createRoad = useCallback(() => {
    if (selectedType) {
      setOriginPlace(routePoints[0].location);
      setDestinationPlace(routePoints[routePoints.length - 1].location);
      setWayPoints(
        routePoints.slice(1, -1).map(({ id, description, ...rest }) => rest),
      );
      setWaypointsDirection(
        routePoints.slice(1, -1).map(({ id, description, ...rest }) => ({
          name: description,
          ...rest,
        })),
      );
      trigger({ hour: value.$d.getHours() });
      setIsRoadCreated(false);
    } else {
      setIsRoadCreated(true);
    }
  }, [wayPoints, routePoints, value, selectedType]);

  const checkRoutes = (arr) => {
    const aaa = arr.length >= 2 && arr.every((obj) => obj.description !== '');
    return aaa;
  };

  useEffect(() => {
    const sumOfPrices = selectedOption.reduce(
      (sum, option) => sum + option.price,
      0,
    );

    const distanceOutCity =
      distanceInCity <= 0
        ? distance
        : distance - distanceInCity <= 0
          ? 0
          : distanceInCity <= 0
            ? distance
            : distance - distanceInCity;
    const price = formula(
      selectedType.minTariff,
      selectedType.submissionKilometers,
      selectedType.priceInCity,
      selectedType.priceOutCity,
      selectedType.price,
      selectedType.submissionMinutes,
      distanceInCity,
      distanceOutCity,
      time,
      distance,
      result?.data?.rate,
      sumOfPrices,
    );
    setAllPrice(price === undefined ? 0 : price.toFixed(0));
    setPriceKilometers((price / distance).toFixed(2));
  }, [time, distanceInCity, selectedType, result, distance, selectedOption]);

  useEffect(() => {
    if (distance) {
      setPriceKilometers((allPrice / distance).toFixed(2));
    }
  }, [allPrice]);

  useEffect(() => {
    socketRef.current.on(
      'order_updated',
      async ({ orderId, updatedFields }) => {
        setIsModalOpen(true);
        setModalMessage('Заказ обновлен!');
      },
    );

    // Cleanup when component unmounts
    return () => {
      socketRef.current.off('update_order');
    };
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    setModalMessage(''); // Clear the message if required
  };

  const sendDataToServer = async () => {
    const dataToSend = {
      orderTypeId: selectedType._id,
      price: allPrice,
      initialPrice: allPrice,
      typeOrder: selectedType.name,
      categoryOrder: selectedCategory.name,
      subTypeOrder: selectedSubType.name,
      additionalOptions: selectedOption,
      distance: distance,
      pickupLocation: {
        name: routePoints[0].description,
        coordinates: {
          lat: originPlace.lat,
          lng: originPlace.lng,
        },
      },
      destinationLocation: {
        name: routePoints[routePoints.length - 1].description,
        coordinates: {
          lat: destinationPlace.lat,
          lng: destinationPlace.lng,
        },
      },
      waypoints: waypointsDirection,
      coordinatesRoute: [
        [originPlace.lng, originPlace.lat],
        ...waypointsDirection.map((point) => [
          point.location.lng,
          point.location.lat,
        ]),
        [destinationPlace.lng, destinationPlace.lat],
      ],
      locationRoute: [
        { name: routePoints[0].description },
        ...waypointsDirection.map((point) => ({ name: point.name })),
        { name: routePoints[routePoints.length - 1].description },
      ],
      priceKilometers: priceKilometers,
      commission: selectedType?.commission,
      freeWaiting: selectedType?.freeWaiting,
      priceWaiting: selectedType?.priceWaiting,
    };
    socketRef.current.emit('update_order', {
      orderId: data?._id,
      updatedFields: dataToSend,
    });
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Grid container spacing={2} direction={isMobile ? 'column' : 'row'}>
      <Grid item xs={12} sm={7} order={isMobile ? 2 : 1}>
        <Box sx={{ margin: 5 }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography variant="h4">Деталі замовлення</Typography>
            <Box>
              <Button
                sx={{ marginLeft: 5 }}
                variant="contained"
                color="secondary"
                onClick={handleRefuseOrder}
              >
                Зняти замовлення
              </Button>
              <Button
                sx={{ marginLeft: 5 }}
                variant="contained"
                color="primary"
                onClick={handleCancelOrder}
              >
                Відмінити замовлення
              </Button>
            </Box>
          </Box>

          <Box sx={{ marginTop: 10 }}>
            Точки маршрута:
            {data?.locationRoute.map((i, index) => (
              <Typography key={index}>
                {index + 1} {i.name}
              </Typography>
            ))}
          </Box>
          <Box sx={{ marginTop: 2 }}>Тип замовлення: {data?.typeOrder}</Box>
          <Box sx={{ marginTop: 2 }}>
            Категория заказа: {data?.categoryOrder}
          </Box>
          <Box sx={{ marginTop: 2 }}>
            Під тип замовлення: {data?.subTypeOrder}
          </Box>
          <Box sx={{ marginTop: 2 }}>
            Початкова ціна замовлення: {data?.initialPrice}
          </Box>
          <Box sx={{ marginTop: 2 }}>Ціна замовлення: {data?.price}</Box>

          {data?.additionalOptions.length > 0 && (
            <Box sx={{ marginTop: 2 }}>
              Додаткові опції:
              {data?.additionalOptions.map((i, index) => (
                <Typography>
                  {index + 1} {i.name}
                </Typography>
              ))}
            </Box>
          )}

          {data ? (
            <Box sx={{ margin: 5 }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <ChangeZoneVisible />
                <ChangeCityZone />
              </Box>
              <ItemPoints isLoaded={isLoaded} />
              <FormControl fullWidth sx={{ marginTop: 5 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    disablePast={true}
                    minDateTime={dayjs()}
                    ampm={false}
                    renderInput={(props) => <TextField {...props} />}
                    label="Час"
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
              <OrderTypesSelect
                onSelectedOrderType={onSelectedOrderType}
                onSelectedOrderCategory={onSelectedOrderCategory}
                onSelectedOrderOption={onSelectedOrderOption}
                onSelectedOrderSubType={onSelectedOrderSubType}
                isRoadCreated={isRoadCreated}
              />
              <Button
                variant="contained"
                fullWidth
                disabled={
                  selectedType?.name === undefined || !checkRoutes(routePoints)
                }
                onClick={createRoad}
              >
                Створити маршрут
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 2,
                }}
              >
                <Box sx={{ marginLeft: 2, width: '50%' }}>
                  <Typography>
                    Довжина маршрута - {distance.toFixed(2)} км
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    Довжина маршрута в місті - {distanceInCity.toFixed(2)} км
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    Довжина маршрута за містом -{' '}
                    {(distanceInCity <= 0
                      ? distance
                      : distance - distanceInCity <= 0
                        ? 0
                        : distanceInCity <= 0
                          ? distance
                          : distance - distanceInCity
                    ).toFixed(2)}{' '}
                    км
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    Час маршрута - {time} хвилин
                  </Typography>
                  {distance ? (
                    <Typography sx={{ mt: 2 }}>
                      Вартість км - {priceKilometers} грн / км
                    </Typography>
                  ) : null}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 2,
                }}
              ></Box>
              <CustomInput
                type={'number'}
                sx={{ mt: 2 }}
                fullWidth
                onChange={(event) => setAllPrice(event.target.value)}
                value={allPrice}
                name={'Вартість поїздки'}
                label={'Вартість поїздки'}
                autoComplete={'Вартість поїздки'}
              />
              <Button
                sx={{ mt: 2 }}
                variant="contained"
                fullWidth
                onClick={sendDataToServer}
              >
                Вартість замовлення
              </Button>
            </Box>
          ) : (
            <Typography variant="h5">Loading...</Typography>
          )}
          {data?.acceptOrder && (
            <Typography variant="h6">
              Прийняв замовлення: {new Date(data.acceptOrder).toLocaleString()}
            </Typography>
          )}
          {data?.onPlace && (
            <Typography variant="h6">
              На місці: {new Date(data.onPlace).toLocaleString()}
            </Typography>
          )}
          {data?.startTheTrip && (
            <Typography variant="h6">
              Почав замовлення: {new Date(data.startTheTrip).toLocaleString()}
            </Typography>
          )}
          {data?.completeTheTrip && (
            <Typography variant="h6">
              Завершив замовлення:{' '}
              {new Date(data.completeTheTrip).toLocaleString()}
            </Typography>
          )}
          {data?.startWaiting && (
            <Typography variant="h6">
              Початок очікування: {new Date(data.startWaiting).toLocaleString()}
            </Typography>
          )}
          {data?.endWaiting && (
            <Typography variant="h6">
              Кінець очікування: {new Date(data.endWaiting).toLocaleString()}
            </Typography>
          )}
          {data?.refuseOrder && (
            <Typography variant="h6">
              Дата завершення замовлення:{' '}
              {new Date(data.refuseOrder).toLocaleString()}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={5} order={isMobile ? 1 : 2}>
        <Map
          center={center}
          isLoaded={isLoaded}
          onRoute={onRouteOrigin}
          originPlace={originPlace}
          destinationPlace={destinationPlace}
          waypoints={data?.waypoints}
        />
      </Grid>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Модальне вікно"
        style={{
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
          },
          content: {
            position: 'relative',
            top: 'auto',
            right: 'auto',
            bottom: 'auto',
            left: 'auto',
            width: '400px',
            height: '250px',
            background: 'white',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '10px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <h2>Сообщение</h2>
        <p>{modalMessage}</p>
        <button onClick={closeModal}>Закрити</button>
      </Modal>
    </Grid>
  );
};
