import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const container = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const body = {
  marginTop: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const CustomModal = ({ title, open, handleClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby={'modal-modal-title'}
      aria-describedby={'modal-modal-description'}
    >
      <Box sx={container}>
        <Typography id={'modal-modal-title'} variant={'h6'} component={'h2'}>
          {title}
        </Typography>
        <Box id={'modal-modal-description'} sx={body}>
          {children}
        </Box>
      </Box>
    </Modal>
  );
};
