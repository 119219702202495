import { createSlice } from '@reduxjs/toolkit';
import { orderApi } from '../api';

const initialState = {
  orders: [],
  internalRouts: [],
  distanceInCity: 0,
  distanceOutCity: 0,
  routePoints: [{ id: 'start_point', description: '', location: {} }],
  center: {
    lat: 50.448546,
    lng: 30.527281,
  },
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setInternalRouts: (state, action) => {
      if (
        !state.internalRouts.some((i) => i.distance === action.payload.distance)
      ) {
        state.internalRouts.push(action.payload);
        if (action.payload.status) {
          state.distanceInCity += action.payload.distance;
        } else {
          state.distanceOutCity += action.payload.distance;
        }
      }
    },
    setRoutePoints: (state, action) => {
      state.routePoints = action.payload;
    },
    setCenter: (state, action) => {
      state.center = action.payload;
    },
    addPoint(state, action) {
      if (action.payload.isClick) {
        const emptyPointIndex = state.routePoints.findIndex(
          (point) =>
            point.description === '' &&
            Object.keys(point.location).length === 0,
        );

        if (emptyPointIndex !== -1) {
          state.routePoints[emptyPointIndex] = action.payload;
        } else {
          state.routePoints.push(action.payload);
        }
      } else {
        state.routePoints.push(action.payload);
      }
    },
    removePoint(state, action) {
      state.routePoints = state.routePoints.filter(
        (point) => point.id !== action.payload,
      );
    },
    updatePoint(state, action) {
      const { id, description, location } = action.payload;
      const point = state.routePoints.find((p) => p.id === id);
      if (point) {
        point.description = description;
        point.location = location;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      orderApi.endpoints.getOrder.matchFulfilled,
      (state, { payload }) => {
        state.orders = payload;
      },
    );
  },
});

export const { setRoutePoints, setCenter, addPoint, updatePoint, removePoint } =
  orderSlice.actions;

export default orderSlice.reducer;
