import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const rateApi = createApi({
  reducerPath: 'rateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getRates: builder.query({
      query: () => ({
        url: '/rate/get-all',
        method: 'GET',
      }),
      providesTags: () => ['Rate'],
    }),
    getRate: builder.query({
      query: (body) => ({
        url: `/rate/get-rate`,
        method: 'GET',
        params: { ...body },
      }),
    }),
    createRate: builder.mutation({
      query: (body) => ({
        url: '/rate/create',
        method: 'POST',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['Rate'],
    }),
    updateRate: builder.mutation({
      query: (body) => ({
        url: '/rate/update',
        method: 'PATCH',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['Rate'],
    }),
  }),
});

export const {
  useGetRatesQuery,
  useCreateRateMutation,
  useUpdateRateMutation,
  useLazyGetRateQuery,
} = rateApi;
