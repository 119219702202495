import { createSlice } from '@reduxjs/toolkit';
import { cityApi } from '../api';

const initialState = {
  city: {},
  cities: [],
};

export const citySlice = createSlice({
  name: 'city',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      cityApi.endpoints.getCities.matchFulfilled,
      (state, { payload }) => {
        state.cities = payload;
      },
    );
    builder.addMatcher(
      cityApi.endpoints.getCity.matchFulfilled,
      (state, { payload }) => {
        state.city = payload;
      },
    );
  },
});

export default citySlice.reducer;
