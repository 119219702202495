export const statusOrder = {
  pending: 'в очікування',
  accepted: 'прийнятий',
  completed: 'закінчений',
  canceled: 'відхилений',
};

export const stageOrder = {
  notTaken: 'не взятий',
  onPlace: 'на місці',
  startTheTrip: 'замовлення почате',
  completeTheTrip: 'замовлення закінчене',
  waiting: 'очікування',
  goToClient: 'їду до клієнта',
};

export const paymentOrder = {
  cash: 'наличка',
  card: 'карта',
};

export const getColorForStatus = (status) => {
  switch (status) {
    case 'pending':
      return 'orange';
    case 'accepted':
      return 'blue';
    case 'completed':
      return 'green';
    case 'canceled':
      return 'red';
    default:
      return 'black';
  }
};
