import React from 'react';
import { CircularProgress } from '@mui/material';
import { Route, Routes } from 'react-router-dom';

import { RequireAuth } from './utils/RequireAuth';
import { RequireDispatcher } from './utils/RequireDispatcher';
import { RequireAdmin } from './utils/RequireAdmin';
import { DispatcherLayout } from './components/DispatcherLayout';
import { AdminLayout } from './components/AdminLayout';
import {
  DispatcherPage,
  OrderPage,
  OrderListPage,
  DriverSettingsPage,
} from './pages/Dispatcher';
import { LoginPage } from './pages/LoginPage';
import {
  AdminPage,
  CitySettingsPage,
  ClientCreatePage,
  ClientEditPage,
  ClientSettingsPage,
  OrderTypeListPage,
  OrderTypePage,
  RateSettingsPage,
  ZoneSettingsPage,
} from './pages/Admin';
import { useCheckAuthQuery } from './store/api';
import Box from '@mui/material/Box';

import './App.css';
import { PrivateRoute } from './navigation/PrivateRoute';
import { DriverListPage } from './pages/Admin/DriverListPage';
import { SocketProvider } from './utils/websoketContext';
import { DriverPage } from './pages/Admin/DriverPage';

const App = () => {
  const { isLoading } = useCheckAuthQuery();
  return (
    <SocketProvider>
      <Box>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Routes>
            <Route index element={<PrivateRoute />} />
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dispatcher" element={<DispatcherLayout />}>
              <Route
                index
                element={
                  <RequireAuth>
                    <RequireAdmin>
                      <DispatcherPage />
                    </RequireAdmin>
                  </RequireAuth>
                }
              />
              <Route
                path="driver-settings"
                element={
                  <RequireAuth>
                    <RequireAdmin>
                      <DriverSettingsPage />
                    </RequireAdmin>
                  </RequireAuth>
                }
              />
              <Route
                path="order-list"
                element={
                  <RequireAuth>
                    <RequireAdmin>
                      <OrderListPage />
                    </RequireAdmin>
                  </RequireAuth>
                }
              />
              <Route
                path="order/:id"
                element={
                  <RequireAuth>
                    <RequireAdmin>
                      <OrderPage />
                    </RequireAdmin>
                  </RequireAuth>
                }
              />
              {/*<Route path="client-settings" element={*/}
              {/*    <RequireAuth>*/}
              {/*        <RequireAdmin>*/}
              {/*            <ClientPage/>*/}
              {/*        </RequireAdmin>*/}
              {/*    </RequireAuth>*/}
              {/*}/>*/}
            </Route>
            <Route path="/admin" element={<AdminLayout />}>
              <Route
                index
                element={
                  <RequireAuth>
                    <RequireDispatcher>
                      <AdminPage />
                    </RequireDispatcher>
                  </RequireAuth>
                }
              />
              <Route
                path="drivers"
                element={
                  <RequireAuth>
                    <RequireDispatcher>
                      {/*<DriverSettingsPage/>*/}
                      <DriverListPage />
                    </RequireDispatcher>
                  </RequireAuth>
                }
              />
              <Route
                path="orders"
                element={
                  <RequireAuth>
                    <RequireDispatcher>
                      <OrderPage />
                    </RequireDispatcher>
                  </RequireAuth>
                }
              />
              <Route
                path="create-order"
                element={
                  <RequireAuth>
                    <RequireDispatcher>
                      <OrderTypePage />
                    </RequireDispatcher>
                  </RequireAuth>
                }
              />
              <Route
                path="create-order/:id"
                element={
                  <RequireAuth>
                    <RequireDispatcher>
                      <OrderTypePage />
                    </RequireDispatcher>
                  </RequireAuth>
                }
              />
              <Route
                path="clients"
                element={
                  <RequireAuth>
                    <RequireDispatcher>
                      <ClientSettingsPage />
                    </RequireDispatcher>
                  </RequireAuth>
                }
              />
              <Route
                path="tariffs"
                element={
                  <RequireAuth>
                    <RequireDispatcher>
                      <OrderTypeListPage />
                    </RequireDispatcher>
                  </RequireAuth>
                }
              />
              <Route
                path="zones"
                element={
                  <RequireAuth>
                    <RequireDispatcher>
                      <ZoneSettingsPage />
                    </RequireDispatcher>
                  </RequireAuth>
                }
              />
              <Route
                path="cities"
                element={
                  <RequireAuth>
                    <RequireDispatcher>
                      <CitySettingsPage />
                    </RequireDispatcher>
                  </RequireAuth>
                }
              />
              <Route
                path="rates"
                element={
                  <RequireAuth>
                    <RequireDispatcher>
                      <RateSettingsPage />
                    </RequireDispatcher>
                  </RequireAuth>
                }
              />
              <Route
                path="driver-create/:id"
                element={
                  <RequireAuth>
                    <RequireDispatcher>
                      <DriverPage />
                    </RequireDispatcher>
                  </RequireAuth>
                }
              />
              <Route
                path="driver-create"
                element={
                  <RequireAuth>
                    <RequireDispatcher>
                      <DriverPage />
                    </RequireDispatcher>
                  </RequireAuth>
                }
              />
              <Route
                path="client-edit/:id"
                element={
                  <RequireAuth>
                    <RequireDispatcher>
                      <ClientEditPage />
                    </RequireDispatcher>
                  </RequireAuth>
                }
              />
              <Route
                path="client-create"
                element={
                  <RequireAuth>
                    <RequireDispatcher>
                      <ClientCreatePage />
                    </RequireDispatcher>
                  </RequireAuth>
                }
              />
            </Route>
          </Routes>
        )}
      </Box>
    </SocketProvider>
  );
};

export default App;
