import React, { useState } from 'react';
import { useGetRatesQuery } from '../../../store/api';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import TableContainer from '@mui/material/TableContainer';
import { RateModal } from './components/RateModal';

export const RateSettingsPage = () => {
  const rates = useSelector((state) => state.rate.rates);

  const [visibleModal, setVisibleModal] = useState(false);
  const [typeModal, setTypeModal] = useState('');
  const [rate, setRate] = useState();

  const openModal = (data) => {
    setRate(data);
    setVisibleModal(true);
  };

  const closeModal = () => {
    setVisibleModal(false);
    setTypeModal('');
  };

  useGetRatesQuery();

  return (
    <Box>
      <TableContainer>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <Typography sx={{ margin: 2 }}>
            Налаштування глобальної надбавки
          </Typography>
        </Box>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width={300}>Час</TableCell>
              <TableCell width={300} align="center">
                Назва
              </TableCell>
              <TableCell width={300} align="center">
                Надбавка
              </TableCell>
              <TableCell align="center">Дії</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rates?.map((row) => (
              <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.hour}:00
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.rate}</TableCell>
                <TableCell align="center">
                  <Tooltip title="Редагувати">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setTypeModal('edit');
                        openModal({
                          name: row?.name,
                          rate: row?.rate,
                          id: row?._id,
                        });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {visibleModal && (
        <RateModal
          visible={visibleModal}
          setVisible={closeModal}
          data={rate}
          typeModal={typeModal}
        />
      )}
    </Box>
  );
};
