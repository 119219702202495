import React, { useEffect, useState } from 'react';
import { Edit, Delete, AddBox } from '@mui/icons-material';
import {
  TableContainer,
  IconButton,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Table,
  Button,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  useDeleteOrderTypeMutation,
  useGetOrderTypesQuery,
} from '../../../store/api';

import { DeleteConfirmationModal } from '../../../components/DeleteConfirmModal';

export const OrderTypeListPage = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { data, isLoading } = useGetOrderTypesQuery();
  const [deleteOrderType, { isLoading: deleteLoading }] =
    useDeleteOrderTypeMutation();

  const handleDeleteClick = (index) => {
    setSelectedRowIndex(index);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedRowIndex !== null) {
      const row = value[selectedRowIndex];
      try {
        await deleteOrderType(row._id).unwrap();
        setValue(value.filter((_, index) => index !== selectedRowIndex));
      } catch (error) {
        console.error('Failed to delete row:', error);
      }
    }
    setDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    if (!isLoading && data) {
      setValue(data);
    }
  }, [data, isLoading]);

  return (
    <Box>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ margin: 2 }}>Тип маршрута</Typography>
              <Button
                sx={{ margin: 2 }}
                color="primary"
                onClick={() => {
                  navigate(`/admin/create-order`);
                }}
                variant="contained"
                endIcon={<AddBox />}
              >
                Додати тип
              </Button>
            </Box>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Назва</TableCell>
                  <TableCell>Ціна хвилини</TableCell>
                  <TableCell>Подача кілометри</TableCell>
                  <TableCell>Подача хвилини</TableCell>
                  <TableCell>Ціна в місті</TableCell>
                  <TableCell>Ціна за містом</TableCell>
                  <TableCell>Ціна простоя</TableCell>
                  <TableCell>Безкоштовне очікування</TableCell>
                  <TableCell>Мінімальний тариф</TableCell>
                  <TableCell>Комісія</TableCell>
                  <TableCell>Редагувати</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {value.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={11} align="center">
                      Немає створених типів замовлення
                    </TableCell>
                  </TableRow>
                ) : (
                  value.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.price}</TableCell>
                      <TableCell>{row.submissionKilometers}</TableCell>
                      <TableCell>{row.submissionMinutes}</TableCell>
                      <TableCell>{row.priceInCity}</TableCell>
                      <TableCell>{row.priceOutCity}</TableCell>
                      <TableCell>{row.priceWaiting}</TableCell>
                      <TableCell>{row.freeWaiting}</TableCell>
                      <TableCell>{row.minTariff}</TableCell>
                      <TableCell>{row.commission}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            navigate(`/admin/create-order/${row._id}`);
                          }}
                          color="primary"
                          aria-label="edit row"
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            handleDeleteClick(index);
                          }}
                          color="error"
                          aria-label="delete row"
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <DeleteConfirmationModal
            open={deleteModalOpen}
            onClose={handleDeleteCancel}
            onConfirm={handleDeleteConfirm}
            isLoading={deleteLoading}
          />
        </>
      )}
    </Box>
  );
};
