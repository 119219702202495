import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const orderTypesApi = createApi({
  reducerPath: 'orderTypesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  tagTypes: ['OrderType'],
  endpoints: (builder) => ({
    getOrderTypes: builder.query({
      query: () => ({
        url: `/order-types`,
        method: 'GET',
      }),
      providesTags: () => ['OrderType'],
    }),
    getOrderType: builder.query({
      query: (id) => ({
        url: `/order-types/${id}`,
        method: 'GET',
      }),
      providesTags: () => ['OrderType'],
    }),
    createOrderType: builder.mutation({
      query: (body) => ({
        url: `/order-types`,
        method: 'POST',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['OrderType'],
    }),
    updateOrderType: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/order-types/${id}`,
        method: 'PUT',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['OrderType'],
    }),
    deleteOrderType: builder.mutation({
      query: (id) => ({
        url: `/order-types/${id}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['OrderType'],
    }),
  }),
});

export const {
  useGetOrderTypesQuery,
  useGetOrderTypeQuery,
  useCreateOrderTypeMutation,
  useUpdateOrderTypeMutation,
  useDeleteOrderTypeMutation,
} = orderTypesApi;
