import { createSlice } from '@reduxjs/toolkit';
import { authApi, dispatcherApi } from '../api';

const initialState = {
  dispatcherData: {},
  isAllCities: false,
};

export const dispatcherSlice = createSlice({
  name: 'dispatcher',
  initialState,
  reducers: {
    setIsAllCities: (state, action) => {
      state.isAllCities = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.checkAuth.matchFulfilled,
      (state, { payload }) => {
        state.dispatcherData = payload.user;
      },
    );
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.dispatcherData = payload.user;
      },
    );
    builder.addMatcher(
      dispatcherApi.endpoints.updateVisibleZone.matchFulfilled,
      (state, { payload }) => {
        state.dispatcherData = payload;
      },
    );
    builder.addMatcher(
      dispatcherApi.endpoints.updateDispatcherCity.matchFulfilled,
      (state, { payload }) => {
        state.dispatcherData = payload;
      },
    );
  },
});

export const { setIsAllCities } = dispatcherSlice.actions;

export default dispatcherSlice.reducer;
