import React, { useCallback, useEffect, useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import dayjs from 'dayjs';
import {
  Grid,
  TextField,
  Box,
  FormControl,
  Button,
  Typography,
  Autocomplete,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector } from 'react-redux';
import { MuiTelInput } from 'mui-tel-input';

import { useGetDriversQuery, useLazyGetRateQuery } from '../../../store/api';

import { formula } from '../../../utils/formula';
import { Map } from './components/Map';
import { ItemPoints } from './components/Points/Point';
import { ChangeZoneVisible } from './components/ChangeZoneVisible';
import { ChangeCityZone } from './components/ChangeCityZone';
import { OrderTypesSelect } from './components/OrderTypesSelect';
import { CustomInput } from '../../../components/CustomInput';
import { useSocket } from '../../../utils/websoketContext';
import { CustomModal } from '../../../components/Modal';

const libraries = ['places', 'geometry'];

export const DispatcherPage = () => {
  const [markers, setMarkers] = useState([]);
  const [distance, setDistance] = useState(0);
  const [time, setTime] = useState(0);
  const [originPlace, setOriginPlace] = useState('');
  const [destinationPlace, setDestinationPlace] = useState('');
  const [wayPoints, setWayPoints] = useState([]);
  const [waypointsDirection, setWaypointsDirection] = useState([]);
  const [value, setValue] = useState(dayjs());
  const [allPrice, setAllPrice] = useState('');
  const [orderDescription, setOrderDescription] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [distanceInCity, setDistanceInCity] = useState(0);
  const [selectedType, setSelectedType] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedSubType, setSelectedSubType] = useState({});
  const [isRoadCreated, setIsRoadCreated] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState('');
  const [priceKilometers, setPriceKilometers] = useState('');
  const [open, setOpen] = React.useState(false);

  const routePoints = useSelector((state) => state.order.routePoints);
  const center = useSelector((state) => state.order.center);
  console.log('routePoints', routePoints);
  const { data, isLoading } = useGetDriversQuery();
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
    language: 'ua',
  });

  const onRouteOrigin = useCallback((distance, time, distanceCity) => {
    setDistance(distance);
    setTime(time);
    setDistanceInCity(distanceCity);
  }, []);

  const onSelectedOrderType = (type) => {
    setSelectedType(type);
  };

  const onSelectedOrderCategory = (category) => {
    setSelectedCategory(category);
  };

  const onSelectedOrderOption = (option) => {
    setSelectedOption(option);
  };

  const onSelectedOrderSubType = (subType) => {
    setSelectedSubType(subType);
  };
  const [trigger, result] = useLazyGetRateQuery();
  const createRoad = useCallback(() => {
    if (selectedType) {
      setOriginPlace(routePoints[0].location);
      setDestinationPlace(routePoints[routePoints.length - 1].location);
      setWayPoints(
        routePoints.slice(1, -1).map(({ id, description, ...rest }) => rest),
      );
      setWaypointsDirection(
        routePoints.slice(1, -1).map(({ id, description, ...rest }) => ({
          name: description,
          ...rest,
        })),
      );
      trigger({ hour: value.$d.getHours() });
      setIsRoadCreated(false);
    } else {
      setIsRoadCreated(true);
    }
  }, [wayPoints, routePoints, value, selectedType]);

  const onMarkerAdd = useCallback(
    (coordinates) => {
      setMarkers([...markers, coordinates]);
    },
    [markers],
  );

  const checkRoutes = (arr) => {
    const aaa = arr.length >= 2 && arr.every((obj) => obj.description !== '');
    return aaa;
  };

  useEffect(() => {
    const sumOfPrices = selectedOption.reduce(
      (sum, option) => sum + option.price,
      0,
    );

    const distanceOutCity =
      distanceInCity <= 0
        ? distance
        : distance - distanceInCity <= 0
          ? 0
          : distanceInCity <= 0
            ? distance
            : distance - distanceInCity;
    const price = formula(
      selectedType.minTariff,
      selectedType.submissionKilometers,
      selectedType.priceInCity,
      selectedType.priceOutCity,
      selectedType.price,
      selectedType.submissionMinutes,
      distanceInCity,
      distanceOutCity,
      time,
      distance,
      result?.data?.rate,
      sumOfPrices,
    );
    setAllPrice(price === undefined ? 0 : price.toFixed(0));
    setPriceKilometers((price / distance).toFixed(2));
  }, [time, distanceInCity, selectedType, result, distance, selectedOption]);

  useEffect(() => {
    if (distance) {
      setPriceKilometers((allPrice / distance).toFixed(2));
    }
  }, [allPrice]);

  useEffect(() => {
    socketRef.current.on('new_order', async (newOrder) => {
      handleOpen();
    });

    return () => {
      socketRef.current.off('create_order');
    };
  }, []);

  const socketRef = useSocket();
  const sendDataToServer = async () => {
    const dataToSend = {
      driverId: selectedDriver._id,
      orderTypeId: selectedType._id,
      clientName: name,
      clientPhoneNumber: phoneNumber,
      status: 'pending',
      price: allPrice,
      initialPrice: allPrice,
      typeOrder: selectedType.name,
      categoryOrder: selectedCategory.name,
      subTypeOrder: selectedSubType.name,
      additionalOptions: selectedOption,
      comments: orderDescription,
      distance: distance,
      stage: 'notTaken',
      pickupLocation: {
        name: routePoints[0].description,
        coordinates: {
          lat: originPlace.lat,
          lng: originPlace.lng,
        },
      },
      destinationLocation: {
        name: routePoints[routePoints.length - 1].description,
        coordinates: {
          lat: destinationPlace.lat,
          lng: destinationPlace.lng,
        },
      },
      waypoints: waypointsDirection,
      coordinatesRoute: [
        [originPlace.lng, originPlace.lat],
        ...waypointsDirection.map((point) => [
          point.location.lng,
          point.location.lat,
        ]),
        [destinationPlace.lng, destinationPlace.lat],
      ],
      locationRoute: [
        { name: routePoints[0].description },
        ...waypointsDirection.map((point) => ({ name: point.name })),
        { name: routePoints[routePoints.length - 1].description },
      ],
      paymentMethod: 'cash',
      priceKilometers: priceKilometers,
      commission: selectedType?.commission,
      freeWaiting: selectedType?.freeWaiting,
      priceWaiting: selectedType?.priceWaiting,
      startWaiting: [],
      endWaiting: [],
    };
    socketRef.current.emit('create_order', dataToSend);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (newValue) => {
    setPhoneNumber(newValue);
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Grid container spacing={2} direction={isMobile ? 'column' : 'row'}>
      <Grid item xs={12} sm={7} order={isMobile ? 2 : 1}>
        <Box sx={{ margin: 5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <ChangeZoneVisible />
            <ChangeCityZone />
          </Box>
          <ItemPoints isLoaded={isLoaded} />
          <FormControl fullWidth sx={{ marginTop: 5 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                disablePast={true}
                minDateTime={dayjs()}
                ampm={false}
                renderInput={(props) => <TextField {...props} />}
                label="Час"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
              />
            </LocalizationProvider>
          </FormControl>
          <OrderTypesSelect
            onSelectedOrderType={onSelectedOrderType}
            onSelectedOrderCategory={onSelectedOrderCategory}
            onSelectedOrderOption={onSelectedOrderOption}
            onSelectedOrderSubType={onSelectedOrderSubType}
            isRoadCreated={isRoadCreated}
          />
          <Button
            variant="contained"
            fullWidth
            disabled={
              selectedType?.name === undefined || !checkRoutes(routePoints)
            }
            onClick={createRoad}
          >
            Створити маршрут
          </Button>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
              justifyContent: 'space-between',
              marginTop: 2,
            }}
          >
            <CustomInput
              sx={{ width: `${isMobile ? '100%' : '50%'}` }}
              onChange={(event) => setOrderDescription(event.target.value)}
              value={orderDescription}
              name={'Коментар до замовлення'}
              label={'Коментар до замовлення'}
              autoComplete={'Коментар до замовлення'}
              multiline={true}
              rows={4}
            />
            <Box sx={{ width: `${isMobile ? '100%' : '50%'}` }}>
              <Typography>
                Довжина маршрута - {distance.toFixed(2)} км
              </Typography>
              <Typography sx={{ mt: 2 }}>
                Довжина маршрута в місті - {distanceInCity.toFixed(2)} км
              </Typography>
              <Typography sx={{ mt: 2 }}>
                Довжина маршрута за містом -{' '}
                {(distanceInCity <= 0
                  ? distance
                  : distance - distanceInCity <= 0
                    ? 0
                    : distanceInCity <= 0
                      ? distance
                      : distance - distanceInCity
                ).toFixed(2)}{' '}
                км
              </Typography>
              <Typography sx={{ mt: 2 }}>
                Час маршрута - {time} хвилин
              </Typography>
              {distance ? (
                <Typography sx={{ mt: 2 }}>
                  Вартість км - {priceKilometers} грн / км
                </Typography>
              ) : null}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
              justifyContent: 'space-between',
              marginTop: 2,
            }}
          >
            <CustomInput
              sx={{ width: `${isMobile ? '100%' : '50%'}` }}
              onChange={(event) => setName(event.target.value)}
              value={name}
              name={'Імя клієнта'}
              label={'Імя клієнта'}
              autoComplete={'Імя клієнта'}
            />
            <MuiTelInput
              sx={{ width: `${isMobile ? '100%' : '50%'}` }}
              defaultCountry={'UA'}
              onlyCountries={['UA']}
              value={phoneNumber}
              onChange={handleChange}
              disableDropdown={true}
            />
          </Box>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 2,
              }}
            >
              <FormControl variant={'outlined'} fullWidth>
                <Autocomplete
                  onChange={(event, newValue) => {
                    setSelectedDriver(newValue);
                  }}
                  options={data}
                  getOptionLabel={(option) => option.nickname}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Водій'}
                      variant={'outlined'}
                    />
                  )}
                />
              </FormControl>
            </Box>
          )}
          <CustomInput
            type={'number'}
            sx={{ mt: 2 }}
            fullWidth
            onChange={(event) => setAllPrice(event.target.value)}
            value={allPrice}
            name={'Вартість поїздки'}
            label={'Вартість поїздки'}
            autoComplete={'Вартість поїздки'}
          />
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            fullWidth
            disabled={phoneNumber.length !== 16}
            onClick={sendDataToServer}
          >
            Створити замовлення
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} sm={5} order={isMobile ? 1 : 2}>
        <Map
          center={center}
          isLoaded={isLoaded}
          markers={markers}
          onMarkerAdd={onMarkerAdd}
          onRoute={onRouteOrigin}
          originPlace={originPlace}
          destinationPlace={destinationPlace}
          waypoints={wayPoints}
        />
      </Grid>
      <CustomModal
        title={'Замовлення створено!'}
        open={open}
        handleClose={handleClose}
      >
        Замовлення було відправлено в ефір
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleClose}
        >
          Гаразд
        </Button>
      </CustomModal>
    </Grid>
  );
};
