import React, { memo, useCallback, useRef } from 'react';

import { GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { pathKyiv } from '../../../paths';
import { useSelector } from 'react-redux';
import { useGetPriceZoneQuery } from '../../../store/api';

export const AdminPage = memo(() => {
  const zones = useSelector((state) => state.zone.zones);
  const containerStyle = {
    width: '100%',
    height: '93vh',
  };

  const center = {
    lat: 50.448546,
    lng: 30.527281,
  };

  const defaultOptions = {
    panControl: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    clickableIcons: false,
    keyboardShortcuts: false,
    scrollwheel: false,
    disableDoubleClickZoom: false,
    fullscreenControl: false,
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    language: 'ua',
  });

  const mapRef = useRef(0);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = 0;
  }, []);

  useGetPriceZoneQuery();

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={9}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={defaultOptions}
    >
      {zones.map((i) => (
        <Polygon
          key={i._id}
          paths={i.coordinates}
          options={{
            fillColor: 'transparent',
            fillOpacity: 1,
            strokeColor: 'red',
            strokeOpacity: 1,
            strokeWeight: 2,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1,
          }}
        />
      ))}

      <Polygon
        paths={pathKyiv}
        options={{
          fillColor: 'transparent',
          fillOpacity: 1,
          strokeColor: 'green',
          strokeOpacity: 1,
          strokeWeight: 6,
          clickable: true,
          draggable: false,
          editable: false,
          geodesic: false,
          zIndex: 1,
        }}
      />
    </GoogleMap>
  ) : (
    <></>
  );
});
