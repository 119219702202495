import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useCreateOrderTypeMutation,
  useGetOrderTypeQuery,
  useUpdateOrderTypeMutation,
} from '../../../store/api';

import { CustomInput } from '../../../components/CustomInput';
import { ArrayInput } from '../../../components/ArrayInput';
import { ErrorModal } from '../../../components/ErrorModal';
import { inputLabels } from './mock';

export const OrderTypePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [errors, setErrors] = useState({});
  const [errorModal, setErrorModal] = useState({ open: false, message: '' });
  const [values, setValues] = useState({
    name: '',
    price: 0,
    submissionKilometers: 0,
    submissionMinutes: 0,
    priceInCity: 0,
    priceOutCity: 0,
    priceWaiting: 0,
    freeWaiting: 0,
    minTariff: 0,
    commission: 0,
    subTypeOrder: [],
    categoryOrder: [],
    optionOrder: [],
  });

  const [createOrderType] = useCreateOrderTypeMutation();
  const [updateOrderType] = useUpdateOrderTypeMutation();
  const { data, isLoading } = useGetOrderTypeQuery(id, { skip: !id });

  const displayValues = { ...values };
  delete displayValues.subTypeOrder;
  delete displayValues.categoryOrder;
  delete displayValues.optionOrder;
  delete displayValues._id;
  delete displayValues.__v;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleCloseErrorModal = () => {
    setErrorModal({ open: false, message: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    for (const key in displayValues) {
      if (displayValues[key] === '') {
        newErrors[key] = true;
      }
    }

    if (Object.keys(newErrors).length === 0) {
      try {
        if (id) {
          await updateOrderType({ id, ...values })
            .unwrap()
            .then(() => {
              navigate('/admin/tariffs');
            })
            .catch((error) => {
              setErrorModal({ open: true, message: error.data.message });
            });
        } else {
          await createOrderType(values)
            .unwrap()
            .then(() => {
              navigate('/admin/tariffs');
            })
            .catch((error) => {
              setErrorModal({ open: true, message: error.data.message });
            });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setErrors(newErrors);
    }
  };

  useEffect(() => {
    if (!isLoading && data) {
      setValues(data);
    }
  }, [data, isLoading]);

  return (
    <Grid container spacing={2} sx={{ padding: 5 }}>
      <Grid item xs={12} md={12}>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          color="primary"
          size="large"
        >
          {id ? 'Оновити тип замовлення' : 'Створити тип замовлення'}
        </Button>
      </Grid>
      <Grid item xs={5} md={5}>
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}
        >
          {Object.entries(displayValues).map(([key, value]) => (
            <CustomInput
              key={key}
              sx={{ marginTop: 3 }}
              name={key}
              type={key !== 'name' ? 'number' : 'text'}
              value={value}
              onChange={handleChange}
              label={inputLabels[key]}
              error={errors[key]}
              required={true}
              helperText="Это поле обязательное"
            />
          ))}
        </Box>
      </Grid>
      <Grid item xs={7} md={7}>
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column', maxWidth: 600 }}
        >
          <ArrayInput
            items={values.subTypeOrder}
            label="Під тип замовлення"
            onChange={(newItems) =>
              setValues({ ...values, subTypeOrder: newItems })
            }
          />
          <ArrayInput
            items={values.categoryOrder}
            label="Види замовлення"
            onChange={(newItems) =>
              setValues({ ...values, categoryOrder: newItems })
            }
          />
          <ArrayInput
            items={values.optionOrder}
            label="Побажання"
            onChange={(newItems) =>
              setValues({ ...values, optionOrder: newItems })
            }
          />
        </Box>
      </Grid>
      <ErrorModal
        open={errorModal.open}
        message={errorModal.message}
        onClose={handleCloseErrorModal}
      />
    </Grid>
  );
};
