import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const zoneApi = createApi({
  reducerPath: 'zoneApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getPriceZone: builder.query({
      query: () => ({
        url: '/zone/get/price-zone',
        method: 'GET',
      }),
      providesTags: () => ['Zone'],
    }),
    updatePriceZone: builder.mutation({
      query: (body) => ({
        url: '/zone/update/price-zone',
        method: 'PATCH',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['Zone'],
    }),
  }),
});

export const { useGetPriceZoneQuery, useUpdatePriceZoneMutation } = zoneApi;
