export const inputLabels = {
  name: 'Назва',
  price: 'Ціна хвилини',
  submissionKilometers: 'Подача кілометри',
  submissionMinutes: 'Подача хвилини',
  priceInCity: 'Ціна в місті',
  priceOutCity: 'Ціна за містом',
  priceWaiting: 'Ціна простоя',
  freeWaiting: 'Безкоштовне очікування',
  minTariff: 'Мінімальний тариф',
  commission: 'Комісія',
};
