import { createSlice } from '@reduxjs/toolkit';
import { authApi } from '../api';

const initialState = {
  userDate: {},
  isLogin: false,
};

export const authSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        localStorage.setItem('token', payload.accessToken);
        state.isLogin = true;
        state.userDate = payload;
      },
    );
    builder.addMatcher(
      authApi.endpoints.checkAuth.matchFulfilled,
      (state, { payload }) => {
        localStorage.setItem('token', payload.accessToken);
        state.userDate = payload;
        state.isLogin = true;
      },
    );
    builder.addMatcher(
      authApi.endpoints.checkAuth.matchRejected,
      (state, { payload }) => {
        localStorage.setItem('token', payload.accessToken);
        state.userDate = payload;
        state.isLogin = false;
      },
    );
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      localStorage.removeItem('token');
      state.isLogin = false;
    });
  },
});

export default authSlice.reducer;
