export const pathKyiv = [
  { lat: 50.4544852, lng: 30.3530126 },
  { lat: 50.4525663, lng: 30.3569361 },
  { lat: 50.4333257, lng: 30.3596826 },
  { lat: 50.4222054, lng: 30.3668066 },
  { lat: 50.4102211, lng: 30.385861 },
  { lat: 50.4054347, lng: 30.3930279 },
  { lat: 50.3997451, lng: 30.4121681 },
  { lat: 50.3971736, lng: 30.4176613 },
  { lat: 50.3957784, lng: 30.4195925 },
  { lat: 50.3959426, lng: 30.4222532 },
  { lat: 50.3951765, lng: 30.4239698 },
  { lat: 50.3767063, lng: 30.4484316 },
  { lat: 50.3689123, lng: 30.4545158 },
  { lat: 50.3644298, lng: 30.4644604 },
  { lat: 50.3594335, lng: 30.4713698 },
  { lat: 50.3567777, lng: 30.4734941 },
  { lat: 50.3549295, lng: 30.4809185 },
  { lat: 50.3540122, lng: 30.4863258 },
  { lat: 50.3518959, lng: 30.4925128 },
  { lat: 50.3415988, lng: 30.5021258 },
  { lat: 50.3381228, lng: 30.5173089 },
  { lat: 50.3409713, lng: 30.5264928 },
  { lat: 50.3417793, lng: 30.5365994 },
  { lat: 50.3404348, lng: 30.5423783 },
  { lat: 50.3463958, lng: 30.5808016 },
  { lat: 50.3635912, lng: 30.5732485 },
  { lat: 50.3814099, lng: 30.5895564 },
  { lat: 50.3835717, lng: 30.628266 },
  { lat: 50.3846116, lng: 30.6515261 },
  { lat: 50.3873205, lng: 30.6613537 },
  { lat: 50.394268, lng: 30.6753397 },
  { lat: 50.4010387, lng: 30.6862831 },
  { lat: 50.4028988, lng: 30.6869054 },
  { lat: 50.40881, lng: 30.6992771 },
  { lat: 50.4166862, lng: 30.7109501 },
  { lat: 50.4425481, lng: 30.7043411 },
  { lat: 50.4543808, lng: 30.6910374 },
  { lat: 50.4582333, lng: 30.6818106 },
  { lat: 50.4645442, lng: 30.6587221 },
  { lat: 50.4676037, lng: 30.6568339 },
  { lat: 50.4703899, lng: 30.6543447 },
  { lat: 50.4862297, lng: 30.64559 },
  { lat: 50.494639, lng: 30.6245615 },
  { lat: 50.5097066, lng: 30.6277372 },
  { lat: 50.5274975, lng: 30.6316854 },
  { lat: 50.5349721, lng: 30.6225874 },
  { lat: 50.5361178, lng: 30.5995848 },
  { lat: 50.5310986, lng: 30.5859377 },
  { lat: 50.5217135, lng: 30.5375292 },
  { lat: 50.5297953, lng: 30.5177702 },
  { lat: 50.5320254, lng: 30.4854121 },
  { lat: 50.529352, lng: 30.4704239 },
  { lat: 50.5267602, lng: 30.4641153 },
  { lat: 50.5279334, lng: 30.4563047 },
  { lat: 50.5258054, lng: 30.4341604 },
  { lat: 50.5215491, lng: 30.4252769 },
  { lat: 50.5173948, lng: 30.3717615 },
  { lat: 50.5169377, lng: 30.369326 },
  { lat: 50.5158189, lng: 30.3659143 },
  { lat: 50.5157438, lng: 30.3635003 },
  { lat: 50.5161668, lng: 30.361376 },
  { lat: 50.4957235, lng: 30.3652169 },
  { lat: 50.492229, lng: 30.3650882 },
  { lat: 50.4832348, lng: 30.3614884 },
  { lat: 50.4770902, lng: 30.3588599 },
  { lat: 50.4709996, lng: 30.3563064 },
  { lat: 50.46835, lng: 30.3555125 },
  { lat: 50.4660964, lng: 30.3553193 },
  { lat: 50.464109, lng: 30.3554266 },
  { lat: 50.4544852, lng: 30.3530126 },
];

export const zoneCity = [
  {
    name: 'Kyiv',
    points: {
      north: 50.602631,
      south: 50.272689,
      east: 30.825567,
      west: 30.2599,
    },
  },
  {
    name: 'Dnipro',
    points: {
      north: 50.602631,
      south: 50.272689,
      east: 30.825567,
      west: 30.2599,
    },
  },
  {
    name: 'Kharkiv',
    points: {
      north: 50.602631,
      south: 50.272689,
      east: 30.825567,
      west: 30.2599,
    },
  },
  {
    name: 'Odesa',
    points: {
      north: 50.602631,
      south: 50.272689,
      east: 30.825567,
      west: 30.2599,
    },
  },
  {
    name: 'Lviv',
    points: {
      north: 50.602631,
      south: 50.272689,
      east: 30.825567,
      west: 30.2599,
    },
  },
];

export const paths = [
  {
    name: 'ИРПЕНЬ 1',
    price: 1,
    points: [
      { lat: 50.5152822, lng: 30.1921463 },
      { lat: 50.509715, lng: 30.2038193 },
      { lat: 50.5089508, lng: 30.2087975 },
      { lat: 50.5084049, lng: 30.2178955 },
      { lat: 50.5073132, lng: 30.2201271 },
      { lat: 50.5063307, lng: 30.2278519 },
      { lat: 50.5073405, lng: 30.2278519 },
      { lat: 50.5057848, lng: 30.2323151 },
      { lat: 50.5011991, lng: 30.2415848 },
      { lat: 50.4942106, lng: 30.23592 },
      { lat: 50.493883, lng: 30.2331734 },
      { lat: 50.4919173, lng: 30.2326584 },
      { lat: 50.4929002, lng: 30.2295685 },
      { lat: 50.4894054, lng: 30.2249336 },
      { lat: 50.4860197, lng: 30.2252769 },
      { lat: 50.4817598, lng: 30.2180672 },
      { lat: 50.4756424, lng: 30.2196121 },
      { lat: 50.4760794, lng: 30.224762 },
      { lat: 50.4792474, lng: 30.2240753 },
      { lat: 50.4789197, lng: 30.2299118 },
      { lat: 50.4839444, lng: 30.2309418 },
      { lat: 50.4850366, lng: 30.235405 },
      { lat: 50.4821967, lng: 30.2472496 },
      { lat: 50.4853097, lng: 30.2487516 },
      { lat: 50.4875487, lng: 30.2523994 },
      { lat: 50.4897604, lng: 30.2526569 },
      { lat: 50.4916989, lng: 30.2554465 },
      { lat: 50.4925452, lng: 30.2567768 },
      { lat: 50.4941014, lng: 30.2548027 },
      { lat: 50.495521, lng: 30.2517986 },
      { lat: 50.5019357, lng: 30.2528286 },
      { lat: 50.5084049, lng: 30.2515411 },
      { lat: 50.51037, lng: 30.251627 },
      { lat: 50.5119119, lng: 30.2551138 },
      { lat: 50.5126367, lng: 30.2528021 },
      { lat: 50.5139754, lng: 30.2505117 },
      { lat: 50.5149609, lng: 30.2496108 },
      { lat: 50.5161404, lng: 30.2490105 },
      { lat: 50.5241755, lng: 30.2467364 },
      { lat: 50.5319572, lng: 30.2446765 },
      { lat: 50.5361139, lng: 30.2435498 },
      { lat: 50.5376248, lng: 30.2429594 },
      { lat: 50.5395083, lng: 30.2419281 },
      { lat: 50.5392901, lng: 30.2352333 },
      { lat: 50.5374354, lng: 30.2309418 },
      { lat: 50.5362352, lng: 30.2304268 },
      { lat: 50.5350351, lng: 30.2268219 },
      { lat: 50.5319799, lng: 30.2220154 },
      { lat: 50.5271786, lng: 30.2156639 },
      { lat: 50.5247777, lng: 30.2148056 },
      { lat: 50.5231407, lng: 30.2105141 },
      { lat: 50.5201939, lng: 30.1991844 },
      { lat: 50.5152822, lng: 30.1921463 },
    ],
  },
  {
    name: 'БУЧА 1',
    price: 2,
    points: [
      { lat: 50.5429448, lng: 30.1844215 },
      { lat: 50.5378446, lng: 30.1816547 },
      { lat: 50.536474, lng: 30.1815146 },
      { lat: 50.5351033, lng: 30.1818895 },
      { lat: 50.533094, lng: 30.1889592 },
      { lat: 50.5326075, lng: 30.191142 },
      { lat: 50.532446, lng: 30.1928343 },
      { lat: 50.5325153, lng: 30.1945816 },
      { lat: 50.5327482, lng: 30.1960285 },
      { lat: 50.535041, lng: 30.2043428 },
      { lat: 50.5357781, lng: 30.2062903 },
      { lat: 50.5370468, lng: 30.2081654 },
      { lat: 50.5417521, lng: 30.2109849 },
      { lat: 50.5511924, lng: 30.2241656 },
      { lat: 50.5521779, lng: 30.2264469 },
      { lat: 50.5527797, lng: 30.2288751 },
      { lat: 50.5529169, lng: 30.2311192 },
      { lat: 50.5525633, lng: 30.2335349 },
      { lat: 50.5517512, lng: 30.2359926 },
      { lat: 50.5496817, lng: 30.2387233 },
      { lat: 50.5481017, lng: 30.2395736 },
      { lat: 50.543821, lng: 30.2398699 },
      { lat: 50.5419129, lng: 30.2404236 },
      { lat: 50.5427812, lng: 30.2466488 },
      { lat: 50.5444721, lng: 30.2499962 },
      { lat: 50.5499261, lng: 30.2597809 },
      { lat: 50.5500352, lng: 30.2584076 },
      { lat: 50.5509623, lng: 30.2571201 },
      { lat: 50.5547251, lng: 30.2579784 },
      { lat: 50.5548342, lng: 30.2606392 },
      { lat: 50.5581605, lng: 30.2605534 },
      { lat: 50.5612685, lng: 30.2614975 },
      { lat: 50.5629587, lng: 30.2635574 },
      { lat: 50.5640491, lng: 30.2619267 },
      { lat: 50.5654121, lng: 30.2602959 },
      { lat: 50.5671022, lng: 30.2584076 },
      { lat: 50.5681653, lng: 30.2584505 },
      { lat: 50.5703732, lng: 30.2587509 },
      { lat: 50.5692829, lng: 30.2548027 },
      { lat: 50.5685197, lng: 30.2505112 },
      { lat: 50.5690103, lng: 30.2493954 },
      { lat: 50.5683561, lng: 30.2457047 },
      { lat: 50.5681926, lng: 30.2435589 },
      { lat: 50.5686287, lng: 30.2415848 },
      { lat: 50.5688467, lng: 30.2373791 },
      { lat: 50.5695009, lng: 30.2366924 },
      { lat: 50.5691738, lng: 30.2342892 },
      { lat: 50.5692284, lng: 30.2250195 },
      { lat: 50.5686832, lng: 30.2161789 },
      { lat: 50.5689558, lng: 30.2072525 },
      { lat: 50.5679745, lng: 30.2015877 },
      { lat: 50.5684114, lng: 30.184808 },
      { lat: 50.569828, lng: 30.174036 },
      { lat: 50.5639668, lng: 30.1694428 },
      { lat: 50.5597276, lng: 30.1636278 },
      { lat: 50.5585895, lng: 30.1645832 },
      { lat: 50.5539481, lng: 30.1610541 },
      { lat: 50.5525438, lng: 30.1600456 },
      { lat: 50.5507442, lng: 30.1597881 },
      { lat: 50.5429448, lng: 30.1844215 },
    ],
  },
  {
    name: 'ГОСТОМЕЛЬ',
    price: 3,
    points: [
      { lat: 50.5867244, lng: 30.1986694 },
      { lat: 50.5855255, lng: 30.2026176 },
      { lat: 50.5825826, lng: 30.2005577 },
      { lat: 50.5838906, lng: 30.1962662 },
      { lat: 50.5824736, lng: 30.1938629 },
      { lat: 50.5818196, lng: 30.1966095 },
      { lat: 50.5782225, lng: 30.1955795 },
      { lat: 50.5768053, lng: 30.1969528 },
      { lat: 50.5753881, lng: 30.1996994 },
      { lat: 50.5730988, lng: 30.1976395 },
      { lat: 50.5749521, lng: 30.1869965 },
      { lat: 50.5717905, lng: 30.1859665 },
      { lat: 50.5708093, lng: 30.1931763 },
      { lat: 50.5695009, lng: 30.1936913 },
      { lat: 50.5695009, lng: 30.2129173 },
      { lat: 50.5720085, lng: 30.2206421 },
      { lat: 50.5720085, lng: 30.2252769 },
      { lat: 50.5737529, lng: 30.2458763 },
      { lat: 50.5734395, lng: 30.2570986 },
      { lat: 50.5703732, lng: 30.2587509 },
      { lat: 50.5681653, lng: 30.2584505 },
      { lat: 50.5671022, lng: 30.2584076 },
      { lat: 50.5654121, lng: 30.2602959 },
      { lat: 50.5640491, lng: 30.2619267 },
      { lat: 50.5629587, lng: 30.2635574 },
      { lat: 50.5612685, lng: 30.2614975 },
      { lat: 50.5581605, lng: 30.2605534 },
      { lat: 50.5548342, lng: 30.2606392 },
      { lat: 50.5547251, lng: 30.2579784 },
      { lat: 50.5509623, lng: 30.2571201 },
      { lat: 50.5500352, lng: 30.2584076 },
      { lat: 50.5499261, lng: 30.2597809 },
      { lat: 50.5480991, lng: 30.2624846 },
      { lat: 50.5475809, lng: 30.2728271 },
      { lat: 50.5483445, lng: 30.2752304 },
      { lat: 50.5496534, lng: 30.2803802 },
      { lat: 50.5516167, lng: 30.2831268 },
      { lat: 50.552053, lng: 30.2923965 },
      { lat: 50.541363, lng: 30.299263 },
      { lat: 50.5392901, lng: 30.297718 },
      { lat: 50.5340531, lng: 30.2913666 },
      { lat: 50.5321981, lng: 30.2929115 },
      { lat: 50.5328528, lng: 30.2978897 },
      { lat: 50.5323073, lng: 30.3032112 },
      { lat: 50.5440902, lng: 30.3052711 },
      { lat: 50.5468992, lng: 30.3029108 },
      { lat: 50.5474719, lng: 30.3040695 },
      { lat: 50.5516167, lng: 30.298233 },
      { lat: 50.5539344, lng: 30.2987051 },
      { lat: 50.5549978, lng: 30.2980614 },
      { lat: 50.5559725, lng: 30.2993917 },
      { lat: 50.5575334, lng: 30.2969134 },
      { lat: 50.557806, lng: 30.2951861 },
      { lat: 50.5587058, lng: 30.2937698 },
      { lat: 50.5592511, lng: 30.2899933 },
      { lat: 50.5544525, lng: 30.2905083 },
      { lat: 50.5540162, lng: 30.2834702 },
      { lat: 50.555434, lng: 30.279007 },
      { lat: 50.5566337, lng: 30.2802086 },
      { lat: 50.5590329, lng: 30.2793503 },
      { lat: 50.5662299, lng: 30.2793503 },
      { lat: 50.5688467, lng: 30.2776337 },
      { lat: 50.5735348, lng: 30.2717972 },
      { lat: 50.5765873, lng: 30.2707672 },
      { lat: 50.5816016, lng: 30.266304 },
      { lat: 50.5859615, lng: 30.2645874 },
      { lat: 50.5922825, lng: 30.2647591 },
      { lat: 50.5974042, lng: 30.2649307 },
      { lat: 50.5989296, lng: 30.2654457 },
      { lat: 50.60514, lng: 30.2700806 },
      { lat: 50.607101, lng: 30.2712822 },
      { lat: 50.6068831, lng: 30.2498245 },
      { lat: 50.6036147, lng: 30.2481079 },
      { lat: 50.6017625, lng: 30.2494812 },
      { lat: 50.6003461, lng: 30.2549744 },
      { lat: 50.5980579, lng: 30.2505112 },
      { lat: 50.5988207, lng: 30.2462196 },
      { lat: 50.5951158, lng: 30.2446747 },
      { lat: 50.5952248, lng: 30.2357483 },
      { lat: 50.5923915, lng: 30.2347183 },
      { lat: 50.5935902, lng: 30.2264786 },
      { lat: 50.5982759, lng: 30.2208138 },
      { lat: 50.5948979, lng: 30.2189255 },
      { lat: 50.5971862, lng: 30.2043343 },
      { lat: 50.6020894, lng: 30.2067375 },
      { lat: 50.6055758, lng: 30.2033043 },
      { lat: 50.6054669, lng: 30.2017593 },
      { lat: 50.6014357, lng: 30.1996994 },
      { lat: 50.6018715, lng: 30.1981544 },
      { lat: 50.6198455, lng: 30.1844215 },
      { lat: 50.6210436, lng: 30.1794434 },
      { lat: 50.6201723, lng: 30.1775551 },
      { lat: 50.6182118, lng: 30.1763535 },
      { lat: 50.5867244, lng: 30.1986694 },
    ],
  },
  {
    name: 'ГОРЕНКА',
    price: 4,
    points: [
      { lat: 50.5692284, lng: 30.2947998 },
      { lat: 50.5683561, lng: 30.298233 },
      { lat: 50.566557, lng: 30.2980614 },
      { lat: 50.5654667, lng: 30.2945423 },
      { lat: 50.5608868, lng: 30.2911949 },
      { lat: 50.5592511, lng: 30.2899933 },
      { lat: 50.5587058, lng: 30.2937698 },
      { lat: 50.557806, lng: 30.2951861 },
      { lat: 50.5575334, lng: 30.2969134 },
      { lat: 50.5559725, lng: 30.2993917 },
      { lat: 50.5549978, lng: 30.2980614 },
      { lat: 50.5539344, lng: 30.2987051 },
      { lat: 50.5516167, lng: 30.298233 },
      { lat: 50.5474719, lng: 30.3040695 },
      { lat: 50.5498716, lng: 30.3082752 },
      { lat: 50.5477446, lng: 30.3141975 },
      { lat: 50.5454538, lng: 30.3160858 },
      { lat: 50.5479082, lng: 30.3226948 },
      { lat: 50.5457265, lng: 30.3254414 },
      { lat: 50.5457265, lng: 30.3275013 },
      { lat: 50.5494898, lng: 30.3268147 },
      { lat: 50.5490535, lng: 30.3299904 },
      { lat: 50.5506351, lng: 30.3325653 },
      { lat: 50.5514941, lng: 30.3328443 },
      { lat: 50.5530892, lng: 30.3305912 },
      { lat: 50.553539, lng: 30.33175 },
      { lat: 50.5543979, lng: 30.3309345 },
      { lat: 50.5549978, lng: 30.3318787 },
      { lat: 50.5563065, lng: 30.3321362 },
      { lat: 50.5566882, lng: 30.333252 },
      { lat: 50.5559248, lng: 30.3359985 },
      { lat: 50.5564701, lng: 30.3369427 },
      { lat: 50.5563065, lng: 30.3399467 },
      { lat: 50.5560339, lng: 30.3406334 },
      { lat: 50.5575607, lng: 30.3426933 },
      { lat: 50.5588694, lng: 30.3411484 },
      { lat: 50.5602325, lng: 30.3315353 },
      { lat: 50.5629587, lng: 30.3272438 },
      { lat: 50.5651396, lng: 30.3281879 },
      { lat: 50.5722266, lng: 30.3038979 },
      { lat: 50.5707002, lng: 30.3023529 },
      { lat: 50.5690103, lng: 30.3050995 },
      { lat: 50.5684651, lng: 30.3047562 },
      { lat: 50.5709728, lng: 30.2958298 },
      { lat: 50.5692284, lng: 30.2947998 },
    ],
  },
  {
    name: 'ПУЩА-ВОДИЦА',
    price: 5,
    points: [
      { lat: 50.5416357, lng: 30.3599453 },
      { lat: 50.5470901, lng: 30.3659534 },
      { lat: 50.5497625, lng: 30.3663826 },
      { lat: 50.5509078, lng: 30.363121 },
      { lat: 50.5526529, lng: 30.3556538 },
      { lat: 50.5584877, lng: 30.3553104 },
      { lat: 50.5585422, lng: 30.349474 },
      { lat: 50.5575607, lng: 30.3493881 },
      { lat: 50.5575607, lng: 30.3426933 },
      { lat: 50.5560339, lng: 30.3406334 },
      { lat: 50.5563065, lng: 30.3399467 },
      { lat: 50.5564701, lng: 30.3369427 },
      { lat: 50.5559248, lng: 30.3359985 },
      { lat: 50.5566882, lng: 30.333252 },
      { lat: 50.5563065, lng: 30.3321362 },
      { lat: 50.5549978, lng: 30.3318787 },
      { lat: 50.5543979, lng: 30.3309345 },
      { lat: 50.553539, lng: 30.33175 },
      { lat: 50.5530892, lng: 30.3305912 },
      { lat: 50.5514941, lng: 30.3328443 },
      { lat: 50.5465992, lng: 30.3359985 },
      { lat: 50.545672, lng: 30.3340244 },
      { lat: 50.5438721, lng: 30.3341961 },
      { lat: 50.5427812, lng: 30.34132 },
      { lat: 50.5342713, lng: 30.3481007 },
      { lat: 50.5335621, lng: 30.3462124 },
      { lat: 50.5292519, lng: 30.3497314 },
      { lat: 50.5294702, lng: 30.3517914 },
      { lat: 50.5224859, lng: 30.3611469 },
      { lat: 50.5226496, lng: 30.3629494 },
      { lat: 50.5245049, lng: 30.3659534 },
      { lat: 50.5230315, lng: 30.3679276 },
      { lat: 50.5240683, lng: 30.3701591 },
      { lat: 50.5257053, lng: 30.3680992 },
      { lat: 50.5296339, lng: 30.3748798 },
      { lat: 50.5416357, lng: 30.3599453 },
    ],
  },
  {
    name: 'КП ГОСТОМЕЛ',
    price: 6,
    points: [
      { lat: 50.5305614, lng: 30.331192 },
      { lat: 50.5170832, lng: 30.3531647 },
      { lat: 50.5142998, lng: 30.3612328 },
      { lat: 50.5144635, lng: 30.3626919 },
      { lat: 50.5162646, lng: 30.3644085 },
      { lat: 50.5191025, lng: 30.3550529 },
      { lat: 50.5323073, lng: 30.3334236 },
      { lat: 50.5305614, lng: 30.331192 },
    ],
  },
  {
    name: 'ПЕТРОВЦЫ НОВЫЕ',
    price: 7,
    points: [
      { lat: 50.6290478, lng: 30.4041481 },
      { lat: 50.617994, lng: 30.4167652 },
      { lat: 50.6181029, lng: 30.4186535 },
      { lat: 50.6196277, lng: 30.4232883 },
      { lat: 50.60721, lng: 30.4347038 },
      { lat: 50.6068831, lng: 30.4436302 },
      { lat: 50.5998558, lng: 30.4516125 },
      { lat: 50.6023618, lng: 30.4528999 },
      { lat: 50.6057937, lng: 30.4647446 },
      { lat: 50.6074278, lng: 30.4698086 },
      { lat: 50.6069512, lng: 30.4723406 },
      { lat: 50.6065495, lng: 30.4734778 },
      { lat: 50.6067742, lng: 30.4755592 },
      { lat: 50.6078091, lng: 30.4784775 },
      { lat: 50.6094432, lng: 30.4826832 },
      { lat: 50.6140183, lng: 30.4876614 },
      { lat: 50.6357986, lng: 30.4528999 },
      { lat: 50.6420041, lng: 30.4371929 },
      { lat: 50.6399357, lng: 30.429554 },
      { lat: 50.6381938, lng: 30.4256058 },
      { lat: 50.6290478, lng: 30.4041481 },
    ],
  },
  {
    name: 'ВОДОГОН',
    price: 8,
    points: [
      { lat: 50.5691738, lng: 30.4561615 },
      { lat: 50.5677019, lng: 30.4556465 },
      { lat: 50.5659573, lng: 30.4559898 },
      { lat: 50.5650305, lng: 30.460453 },
      { lat: 50.5654667, lng: 30.4662037 },
      { lat: 50.5681926, lng: 30.4661179 },
      { lat: 50.5695554, lng: 30.4649162 },
      { lat: 50.569828, lng: 30.4619122 },
      { lat: 50.5691738, lng: 30.4561615 },
    ],
  },
  {
    name: 'ВЫШГОРОД',
    price: 9,
    points: [
      { lat: 50.5934268, lng: 30.449295 },
      { lat: 50.5917921, lng: 30.4516983 },
      { lat: 50.5874328, lng: 30.4504108 },
      { lat: 50.5868334, lng: 30.4543591 },
      { lat: 50.589994, lng: 30.455389 },
      { lat: 50.5808386, lng: 30.4699802 },
      { lat: 50.5765873, lng: 30.4734135 },
      { lat: 50.5674293, lng: 30.4722118 },
      { lat: 50.5647034, lng: 30.4718685 },
      { lat: 50.5610504, lng: 30.4720402 },
      { lat: 50.5600144, lng: 30.4753876 },
      { lat: 50.5594692, lng: 30.4785633 },
      { lat: 50.559142, lng: 30.4821682 },
      { lat: 50.5615411, lng: 30.4842281 },
      { lat: 50.5667751, lng: 30.4886913 },
      { lat: 50.5762603, lng: 30.498476 },
      { lat: 50.5904844, lng: 30.5065441 },
      { lat: 50.6022528, lng: 30.5019093 },
      { lat: 50.6049793, lng: 30.4901909 },
      { lat: 50.6056876, lng: 30.4814134 },
      { lat: 50.6067742, lng: 30.4755592 },
      { lat: 50.6065495, lng: 30.4734778 },
      { lat: 50.6069512, lng: 30.4723406 },
      { lat: 50.6074278, lng: 30.4698086 },
      { lat: 50.6057937, lng: 30.4647446 },
      { lat: 50.6023618, lng: 30.4528999 },
      { lat: 50.5998558, lng: 30.4516125 },
      { lat: 50.5934268, lng: 30.449295 },
    ],
  },
  {
    name: 'БОГАТЫРСКАЯ ДАЧИ',
    price: 10,
    points: [
      { lat: 50.5548887, lng: 30.466032 },
      { lat: 50.5525705, lng: 30.4655816 },
      { lat: 50.5449622, lng: 30.4666331 },
      { lat: 50.5453993, lng: 30.4819965 },
      { lat: 50.5463265, lng: 30.482254 },
      { lat: 50.5464868, lng: 30.4867333 },
      { lat: 50.546456, lng: 30.4912985 },
      { lat: 50.5442539, lng: 30.4913521 },
      { lat: 50.5442879, lng: 30.4949355 },
      { lat: 50.5413084, lng: 30.4947853 },
      { lat: 50.5413834, lng: 30.4970384 },
      { lat: 50.5416016, lng: 30.5017698 },
      { lat: 50.5428937, lng: 30.5054872 },
      { lat: 50.5444311, lng: 30.5086684 },
      { lat: 50.5461083, lng: 30.5042267 },
      { lat: 50.5478945, lng: 30.5010939 },
      { lat: 50.5506078, lng: 30.4977894 },
      { lat: 50.5520258, lng: 30.4962445 },
      { lat: 50.5543434, lng: 30.4941845 },
      { lat: 50.5593601, lng: 30.4921246 },
      { lat: 50.561214, lng: 30.4874897 },
      { lat: 50.5619228, lng: 30.4870605 },
      { lat: 50.5615411, lng: 30.4842281 },
      { lat: 50.559142, lng: 30.4821682 },
      { lat: 50.5572881, lng: 30.4774475 },
      { lat: 50.5571245, lng: 30.4698944 },
      { lat: 50.5548887, lng: 30.466032 },
    ],
  },
  {
    name: 'КП ВЫШГОРОД',
    price: 11,
    points: [
      { lat: 50.5287609, lng: 30.4813957 },
      { lat: 50.5287472, lng: 30.4842388 },
      { lat: 50.5331802, lng: 30.491395 },
      { lat: 50.5381173, lng: 30.4968882 },
      { lat: 50.5413834, lng: 30.4970384 },
      { lat: 50.5413084, lng: 30.4947853 },
      { lat: 50.5442879, lng: 30.4949355 },
      { lat: 50.5442539, lng: 30.4913521 },
      { lat: 50.546456, lng: 30.4912985 },
      { lat: 50.5464868, lng: 30.4867333 },
      { lat: 50.5463265, lng: 30.482254 },
      { lat: 50.5453993, lng: 30.4819965 },
      { lat: 50.53809, lng: 30.4819965 },
      { lat: 50.5287609, lng: 30.4813957 },
    ],
  },
  {
    name: 'ЗАЗИМЬЕ',
    price: 12,
    points: [
      { lat: 50.5545615, lng: 30.6988907 },
      { lat: 50.5557067, lng: 30.7141685 },
      { lat: 50.5654121, lng: 30.7118511 },
      { lat: 50.5675929, lng: 30.7055855 },
      { lat: 50.5671022, lng: 30.6929684 },
      { lat: 50.5675384, lng: 30.6890202 },
      { lat: 50.5692284, lng: 30.6885052 },
      { lat: 50.5706457, lng: 30.6916809 },
      { lat: 50.5729352, lng: 30.6952 },
      { lat: 50.5802391, lng: 30.7060146 },
      { lat: 50.5822011, lng: 30.7054996 },
      { lat: 50.5822011, lng: 30.7028389 },
      { lat: 50.5832911, lng: 30.6995773 },
      { lat: 50.5815471, lng: 30.6994057 },
      { lat: 50.5799121, lng: 30.6978607 },
      { lat: 50.5782225, lng: 30.6935692 },
      { lat: 50.5778954, lng: 30.6903934 },
      { lat: 50.5785495, lng: 30.6861019 },
      { lat: 50.5815471, lng: 30.6800079 },
      { lat: 50.5810566, lng: 30.678978 },
      { lat: 50.578713, lng: 30.6799221 },
      { lat: 50.5770779, lng: 30.6799221 },
      { lat: 50.5762603, lng: 30.6772614 },
      { lat: 50.5768871, lng: 30.6765532 },
      { lat: 50.5768326, lng: 30.6724548 },
      { lat: 50.5769961, lng: 30.6709528 },
      { lat: 50.5779363, lng: 30.670352 },
      { lat: 50.5792376, lng: 30.6680346 },
      { lat: 50.5806206, lng: 30.6670475 },
      { lat: 50.5811111, lng: 30.66576 },
      { lat: 50.5798031, lng: 30.6600094 },
      { lat: 50.578822, lng: 30.6593227 },
      { lat: 50.5766418, lng: 30.6618118 },
      { lat: 50.5772959, lng: 30.6640434 },
      { lat: 50.5743525, lng: 30.6677341 },
      { lat: 50.5730442, lng: 30.6680775 },
      { lat: 50.5714634, lng: 30.6669617 },
      { lat: 50.5711908, lng: 30.6647301 },
      { lat: 50.5716815, lng: 30.6607819 },
      { lat: 50.5696645, lng: 30.6593227 },
      { lat: 50.5678655, lng: 30.6564903 },
      { lat: 50.566339, lng: 30.6550312 },
      { lat: 50.5657938, lng: 30.6534863 },
      { lat: 50.5638856, lng: 30.6526279 },
      { lat: 50.5632858, lng: 30.6513405 },
      { lat: 50.563613, lng: 30.6467056 },
      { lat: 50.5630678, lng: 30.644989 },
      { lat: 50.5618683, lng: 30.6468773 },
      { lat: 50.5609959, lng: 30.6491947 },
      { lat: 50.5597963, lng: 30.6543446 },
      { lat: 50.5590329, lng: 30.6572628 },
      { lat: 50.5588694, lng: 30.6585503 },
      { lat: 50.5594487, lng: 30.6605673 },
      { lat: 50.5586513, lng: 30.6646442 },
      { lat: 50.557438, lng: 30.6651378 },
      { lat: 50.5563065, lng: 30.6661892 },
      { lat: 50.5554886, lng: 30.6674767 },
      { lat: 50.5536345, lng: 30.6643009 },
      { lat: 50.5523938, lng: 30.6659961 },
      { lat: 50.5526529, lng: 30.6675625 },
      { lat: 50.5545615, lng: 30.6777763 },
      { lat: 50.5575607, lng: 30.6867886 },
      { lat: 50.5596327, lng: 30.6923676 },
      { lat: 50.5615956, lng: 30.6973457 },
      { lat: 50.5564156, lng: 30.6980324 },
      { lat: 50.5545615, lng: 30.6988907 },
    ],
  },
  {
    name: 'ПОГРЕБЫ',
    price: 13,
    points: [
      { lat: 50.5559794, lng: 30.6194973 },
      { lat: 50.5546161, lng: 30.6212139 },
      { lat: 50.5526529, lng: 30.6223297 },
      { lat: 50.5507442, lng: 30.6240463 },
      { lat: 50.5514531, lng: 30.6261063 },
      { lat: 50.5494898, lng: 30.6265354 },
      { lat: 50.5472537, lng: 30.6254196 },
      { lat: 50.5452902, lng: 30.6236172 },
      { lat: 50.5451266, lng: 30.6221581 },
      { lat: 50.5441993, lng: 30.6210423 },
      { lat: 50.5438721, lng: 30.6192398 },
      { lat: 50.5414721, lng: 30.6165791 },
      { lat: 50.5371626, lng: 30.6171799 },
      { lat: 50.5373809, lng: 30.6187248 },
      { lat: 50.536508, lng: 30.6187248 },
      { lat: 50.5367808, lng: 30.625248 },
      { lat: 50.5362898, lng: 30.6293678 },
      { lat: 50.5355261, lng: 30.630827 },
      { lat: 50.5354169, lng: 30.6357193 },
      { lat: 50.5351442, lng: 30.6416416 },
      { lat: 50.5363444, lng: 30.6485081 },
      { lat: 50.5349805, lng: 30.6504822 },
      { lat: 50.5351442, lng: 30.6538296 },
      { lat: 50.5377082, lng: 30.6550312 },
      { lat: 50.5410357, lng: 30.6542587 },
      { lat: 50.5422357, lng: 30.6548595 },
      { lat: 50.5452902, lng: 30.6555462 },
      { lat: 50.5467083, lng: 30.6548595 },
      { lat: 50.54769, lng: 30.655632 },
      { lat: 50.5483445, lng: 30.6577778 },
      { lat: 50.5463265, lng: 30.6586361 },
      { lat: 50.5462174, lng: 30.6596661 },
      { lat: 50.5503624, lng: 30.6633568 },
      { lat: 50.5523938, lng: 30.6659961 },
      { lat: 50.5536345, lng: 30.6643009 },
      { lat: 50.5554886, lng: 30.6674767 },
      { lat: 50.5563065, lng: 30.6661892 },
      { lat: 50.557438, lng: 30.6651378 },
      { lat: 50.5586513, lng: 30.6646442 },
      { lat: 50.5594487, lng: 30.6605673 },
      { lat: 50.5588694, lng: 30.6585503 },
      { lat: 50.5590329, lng: 30.6572628 },
      { lat: 50.5597963, lng: 30.6543446 },
      { lat: 50.5609959, lng: 30.6491947 },
      { lat: 50.5618683, lng: 30.6468773 },
      { lat: 50.5630678, lng: 30.644989 },
      { lat: 50.5635585, lng: 30.6442165 },
      { lat: 50.5625225, lng: 30.6413841 },
      { lat: 50.5600689, lng: 30.6397533 },
      { lat: 50.5596327, lng: 30.6357193 },
      { lat: 50.5598508, lng: 30.6330585 },
      { lat: 50.5590329, lng: 30.6305695 },
      { lat: 50.5594146, lng: 30.6270504 },
      { lat: 50.5615411, lng: 30.6212139 },
      { lat: 50.5617047, lng: 30.6188107 },
      { lat: 50.5605051, lng: 30.6174374 },
      { lat: 50.5587058, lng: 30.6178665 },
      { lat: 50.5559794, lng: 30.6194973 },
    ],
  },
  {
    name: 'БРОВАРЫ',
    price: 14,
    points: [
      { lat: 50.4896239, lng: 30.7062721 },
      { lat: 50.4808859, lng: 30.7047272 },
      { lat: 50.4904976, lng: 30.7395744 },
      { lat: 50.4913713, lng: 30.7479858 },
      { lat: 50.4877126, lng: 30.7489729 },
      { lat: 50.4879856, lng: 30.755024 },
      { lat: 50.4828521, lng: 30.7567406 },
      { lat: 50.4827429, lng: 30.7634354 },
      { lat: 50.4901699, lng: 30.775795 },
      { lat: 50.4875487, lng: 30.7806015 },
      { lat: 50.4858012, lng: 30.7823181 },
      { lat: 50.4873303, lng: 30.7881546 },
      { lat: 50.4889686, lng: 30.7915878 },
      { lat: 50.4901699, lng: 30.8051491 },
      { lat: 50.4897331, lng: 30.8319283 },
      { lat: 50.4954119, lng: 30.8324432 },
      { lat: 50.4986878, lng: 30.8360481 },
      { lat: 50.503492, lng: 30.841713 },
      { lat: 50.4978142, lng: 30.8521843 },
      { lat: 50.4996705, lng: 30.8547592 },
      { lat: 50.4966403, lng: 30.8599519 },
      { lat: 50.5006532, lng: 30.8648872 },
      { lat: 50.5091691, lng: 30.8521843 },
      { lat: 50.51037, lng: 30.8530426 },
      { lat: 50.5125532, lng: 30.850296 },
      { lat: 50.5174652, lng: 30.8599091 },
      { lat: 50.5290337, lng: 30.8398247 },
      { lat: 50.5309979, lng: 30.8405113 },
      { lat: 50.5341622, lng: 30.8348465 },
      { lat: 50.5384173, lng: 30.8410263 },
      { lat: 50.5422357, lng: 30.8357048 },
      { lat: 50.5356897, lng: 30.8194399 },
      { lat: 50.5345986, lng: 30.8104706 },
      { lat: 50.5300158, lng: 30.7962227 },
      { lat: 50.5280516, lng: 30.7850647 },
      { lat: 50.5316526, lng: 30.7842064 },
      { lat: 50.5314343, lng: 30.7771683 },
      { lat: 50.5276151, lng: 30.7764816 },
      { lat: 50.5276151, lng: 30.7800865 },
      { lat: 50.5261964, lng: 30.7811165 },
      { lat: 50.521831, lng: 30.765667 },
      { lat: 50.5197573, lng: 30.7682419 },
      { lat: 50.5164829, lng: 30.7673836 },
      { lat: 50.516592, lng: 30.7654953 },
      { lat: 50.5185567, lng: 30.7648087 },
      { lat: 50.5205213, lng: 30.7610321 },
      { lat: 50.519539, lng: 30.7527924 },
      { lat: 50.5171378, lng: 30.7543373 },
      { lat: 50.5121166, lng: 30.7615471 },
      { lat: 50.509715, lng: 30.7579422 },
      { lat: 50.5019634, lng: 30.7419777 },
      { lat: 50.4993429, lng: 30.7337379 },
      { lat: 50.4896239, lng: 30.7062721 },
    ],
  },
  {
    name: 'БОРИСПОЛЬ',
    price: 15,
    points: [
      { lat: 50.3189427, lng: 30.9198189 },
      { lat: 50.3143389, lng: 30.9414482 },
      { lat: 50.3252995, lng: 30.9479713 },
      { lat: 50.3246419, lng: 30.9575844 },
      { lat: 50.3291351, lng: 30.9579277 },
      { lat: 50.3289159, lng: 30.9682274 },
      { lat: 50.3274913, lng: 30.9778404 },
      { lat: 50.3250803, lng: 30.9788704 },
      { lat: 50.3259571, lng: 30.9867668 },
      { lat: 50.3322034, lng: 30.9857368 },
      { lat: 50.3334087, lng: 30.9876251 },
      { lat: 50.3305597, lng: 30.9951782 },
      { lat: 50.3357097, lng: 30.9994698 },
      { lat: 50.3377915, lng: 30.9967232 },
      { lat: 50.3377915, lng: 30.9948349 },
      { lat: 50.3408592, lng: 30.9943199 },
      { lat: 50.3441458, lng: 30.9972382 },
      { lat: 50.3451318, lng: 31.003418 },
      { lat: 50.3496367, lng: 31.0020017 },
      { lat: 50.3496778, lng: 31.0046625 },
      { lat: 50.3519232, lng: 31.003933 },
      { lat: 50.3519232, lng: 31.0010147 },
      { lat: 50.3563043, lng: 30.9996414 },
      { lat: 50.3587137, lng: 30.9955215 },
      { lat: 50.3665983, lng: 30.9847069 },
      { lat: 50.3718539, lng: 30.9799004 },
      { lat: 50.3829107, lng: 30.9771538 },
      { lat: 50.388958, lng: 30.9759522 },
      { lat: 50.3839921, lng: 30.9655877 },
      { lat: 50.3834588, lng: 30.9648797 },
      { lat: 50.3871795, lng: 30.9484863 },
      { lat: 50.3836769, lng: 30.9474564 },
      { lat: 50.3849904, lng: 30.9404182 },
      { lat: 50.3881645, lng: 30.9395599 },
      { lat: 50.3888212, lng: 30.9311485 },
      { lat: 50.385866, lng: 30.9328651 },
      { lat: 50.3865227, lng: 30.9237671 },
      { lat: 50.3867691, lng: 30.9188748 },
      { lat: 50.3864133, lng: 30.9144974 },
      { lat: 50.3786415, lng: 30.9196472 },
      { lat: 50.3786415, lng: 30.917244 },
      { lat: 50.3738246, lng: 30.916729 },
      { lat: 50.3754668, lng: 30.906601 },
      { lat: 50.3779847, lng: 30.9067726 },
      { lat: 50.3783131, lng: 30.903511 },
      { lat: 50.3739341, lng: 30.9023094 },
      { lat: 50.3724013, lng: 30.9071159 },
      { lat: 50.3688977, lng: 30.9069443 },
      { lat: 50.3679122, lng: 30.9120941 },
      { lat: 50.3644082, lng: 30.9122658 },
      { lat: 50.3642987, lng: 30.9163857 },
      { lat: 50.3619991, lng: 30.9186172 },
      { lat: 50.3599184, lng: 30.9193039 },
      { lat: 50.3576186, lng: 30.9165573 },
      { lat: 50.3568519, lng: 30.9181023 },
      { lat: 50.3509374, lng: 30.9169006 },
      { lat: 50.3483085, lng: 30.9169006 },
      { lat: 50.3359289, lng: 30.9213638 },
      { lat: 50.3278201, lng: 30.9222221 },
      { lat: 50.3189427, lng: 30.9198189 },
    ],
  },
  {
    name: 'АЭРОПОРТ БОРИСПОЛЬ',
    price: 15,
    points: [
      { lat: 50.3520328, lng: 30.8813667 },
      { lat: 50.3294639, lng: 30.8801651 },
      { lat: 50.3290255, lng: 30.906601 },
      { lat: 50.3531281, lng: 30.9069443 },
      { lat: 50.3530185, lng: 30.9002495 },
      { lat: 50.3659413, lng: 30.9002495 },
      { lat: 50.3659961, lng: 30.8900356 },
      { lat: 50.3519232, lng: 30.8897781 },
      { lat: 50.3520328, lng: 30.8813667 },
    ],
  },
  {
    name: 'ОБУХОВ',
    price: 16,
    points: [
      { lat: 50.1133683, lng: 30.6020737 },
      { lat: 50.1125427, lng: 30.6037903 },
      { lat: 50.1042857, lng: 30.5935764 },
      { lat: 50.1034049, lng: 30.5931473 },
      { lat: 50.1005972, lng: 30.5900574 },
      { lat: 50.0977342, lng: 30.5953789 },
      { lat: 50.0939901, lng: 30.5891132 },
      { lat: 50.0929439, lng: 30.5893707 },
      { lat: 50.0925584, lng: 30.5916882 },
      { lat: 50.0924483, lng: 30.5939198 },
      { lat: 50.0869415, lng: 30.5928898 },
      { lat: 50.0851792, lng: 30.5956364 },
      { lat: 50.0833067, lng: 30.6002712 },
      { lat: 50.0815993, lng: 30.6065369 },
      { lat: 50.0816544, lng: 30.6121159 },
      { lat: 50.0841879, lng: 30.61306 },
      { lat: 50.0833067, lng: 30.6158924 },
      { lat: 50.0811587, lng: 30.6279087 },
      { lat: 50.0906862, lng: 30.6312561 },
      { lat: 50.0950362, lng: 30.6381226 },
      { lat: 50.0993859, lng: 30.6398392 },
      { lat: 50.103515, lng: 30.6452465 },
      { lat: 50.104561, lng: 30.6438732 },
      { lat: 50.104561, lng: 30.6416416 },
      { lat: 50.1064877, lng: 30.6384659 },
      { lat: 50.106818, lng: 30.6400108 },
      { lat: 50.1073685, lng: 30.6420708 },
      { lat: 50.1081392, lng: 30.6436157 },
      { lat: 50.110341, lng: 30.646019 },
      { lat: 50.1096116, lng: 30.6472421 },
      { lat: 50.1078089, lng: 30.6473923 },
      { lat: 50.10624, lng: 30.6490231 },
      { lat: 50.1078089, lng: 30.6516838 },
      { lat: 50.1094053, lng: 30.6522203 },
      { lat: 50.1084832, lng: 30.6550097 },
      { lat: 50.1098043, lng: 30.6552028 },
      { lat: 50.1109465, lng: 30.6553745 },
      { lat: 50.1132032, lng: 30.6509972 },
      { lat: 50.1153497, lng: 30.6475639 },
      { lat: 50.115735, lng: 30.6446457 },
      { lat: 50.1173861, lng: 30.6443024 },
      { lat: 50.1195875, lng: 30.6475639 },
      { lat: 50.1220639, lng: 30.6530571 },
      { lat: 50.1210734, lng: 30.6539154 },
      { lat: 50.1207982, lng: 30.6565762 },
      { lat: 50.1192023, lng: 30.6588936 },
      { lat: 50.1200278, lng: 30.6618977 },
      { lat: 50.1286122, lng: 30.6637001 },
      { lat: 50.1312532, lng: 30.666275 },
      { lat: 50.130648, lng: 30.6679916 },
      { lat: 50.1294925, lng: 30.667305 },
      { lat: 50.1291349, lng: 30.6687426 },
      { lat: 50.1304829, lng: 30.6695366 },
      { lat: 50.1290523, lng: 30.678978 },
      { lat: 50.1287222, lng: 30.6921101 },
      { lat: 50.1275667, lng: 30.6970024 },
      { lat: 50.1274567, lng: 30.7014656 },
      { lat: 50.1359297, lng: 30.7067871 },
      { lat: 50.1365899, lng: 30.6841278 },
      { lat: 50.1411008, lng: 30.6838703 },
      { lat: 50.1475914, lng: 30.6717682 },
      { lat: 50.1463813, lng: 30.6695366 },
      { lat: 50.1464363, lng: 30.6633568 },
      { lat: 50.1379652, lng: 30.6618977 },
      { lat: 50.1358747, lng: 30.6598377 },
      { lat: 50.1450063, lng: 30.6441307 },
      { lat: 50.142751, lng: 30.6405258 },
      { lat: 50.1391204, lng: 30.6382084 },
      { lat: 50.13736, lng: 30.634346 },
      { lat: 50.1353245, lng: 30.633316 },
      { lat: 50.1348844, lng: 30.6314278 },
      { lat: 50.1368649, lng: 30.6249905 },
      { lat: 50.1357646, lng: 30.6239605 },
      { lat: 50.1330138, lng: 30.6140041 },
      { lat: 50.1325186, lng: 30.6146908 },
      { lat: 50.130758, lng: 30.6102276 },
      { lat: 50.1250355, lng: 30.6094551 },
      { lat: 50.1236048, lng: 30.6082535 },
      { lat: 50.1243202, lng: 30.6018162 },
      { lat: 50.1271265, lng: 30.6014729 },
      { lat: 50.1271815, lng: 30.6001854 },
      { lat: 50.1293275, lng: 30.6001854 },
      { lat: 50.1294925, lng: 30.5945206 },
      { lat: 50.1292724, lng: 30.5920315 },
      { lat: 50.1268514, lng: 30.5922031 },
      { lat: 50.1205231, lng: 30.5900574 },
      { lat: 50.1150745, lng: 30.6037045 },
      { lat: 50.1133683, lng: 30.6020737 },
    ],
  },
  {
    name: 'УКРАИНКА',
    price: 17,
    points: [
      { lat: 50.1525413, lng: 30.7333088 },
      { lat: 50.1521013, lng: 30.7319355 },
      { lat: 50.1504514, lng: 30.7304764 },
      { lat: 50.1505064, lng: 30.7297897 },
      { lat: 50.1515513, lng: 30.7290173 },
      { lat: 50.1521563, lng: 30.7273006 },
      { lat: 50.1533662, lng: 30.7258415 },
      { lat: 50.155016, lng: 30.72052 },
      { lat: 50.1558409, lng: 30.7194901 },
      { lat: 50.1565008, lng: 30.7182026 },
      { lat: 50.155071, lng: 30.7167435 },
      { lat: 50.1532012, lng: 30.7214642 },
      { lat: 50.1511113, lng: 30.7249832 },
      { lat: 50.1491314, lng: 30.727644 },
      { lat: 50.1481414, lng: 30.7264423 },
      { lat: 50.1484164, lng: 30.7258415 },
      { lat: 50.1491864, lng: 30.7196617 },
      { lat: 50.1488564, lng: 30.7176876 },
      { lat: 50.1516063, lng: 30.7124519 },
      { lat: 50.1506714, lng: 30.7112503 },
      { lat: 50.1498464, lng: 30.7124519 },
      { lat: 50.1491314, lng: 30.7120228 },
      { lat: 50.1482514, lng: 30.7128811 },
      { lat: 50.1484714, lng: 30.7143402 },
      { lat: 50.1478664, lng: 30.7164001 },
      { lat: 50.1483064, lng: 30.7204342 },
      { lat: 50.1474814, lng: 30.7237816 },
      { lat: 50.1466564, lng: 30.7243824 },
      { lat: 50.1462713, lng: 30.7213783 },
      { lat: 50.1463813, lng: 30.7180309 },
      { lat: 50.1468214, lng: 30.7147694 },
      { lat: 50.1459413, lng: 30.7151127 },
      { lat: 50.1445112, lng: 30.7217216 },
      { lat: 50.1439612, lng: 30.7243824 },
      { lat: 50.1430811, lng: 30.7237816 },
      { lat: 50.142256, lng: 30.7228374 },
      { lat: 50.1419809, lng: 30.7212925 },
      { lat: 50.142256, lng: 30.7182026 },
      { lat: 50.142641, lng: 30.7161427 },
      { lat: 50.1437961, lng: 30.7139111 },
      { lat: 50.1446212, lng: 30.7128811 },
      { lat: 50.1453913, lng: 30.7110786 },
      { lat: 50.1458863, lng: 30.7080746 },
      { lat: 50.1468214, lng: 30.705843 },
      { lat: 50.1480314, lng: 30.7028389 },
      { lat: 50.1480314, lng: 30.7012081 },
      { lat: 50.1473164, lng: 30.7012939 },
      { lat: 50.1461063, lng: 30.703783 },
      { lat: 50.1442912, lng: 30.7073879 },
      { lat: 50.1414858, lng: 30.7062721 },
      { lat: 50.1403307, lng: 30.7086754 },
      { lat: 50.1387353, lng: 30.7076454 },
      { lat: 50.1381302, lng: 30.7077312 },
      { lat: 50.1382402, lng: 30.709362 },
      { lat: 50.1375251, lng: 30.709362 },
      { lat: 50.1378001, lng: 30.7120228 },
      { lat: 50.1375801, lng: 30.7137394 },
      { lat: 50.1375801, lng: 30.7153702 },
      { lat: 50.1379927, lng: 30.7161426 },
      { lat: 50.1383503, lng: 30.7173443 },
      { lat: 50.1381852, lng: 30.7184601 },
      { lat: 50.1374701, lng: 30.7194042 },
      { lat: 50.1364798, lng: 30.7194901 },
      { lat: 50.1356546, lng: 30.7186317 },
      { lat: 50.1351044, lng: 30.717001 },
      { lat: 50.1346093, lng: 30.716486 },
      { lat: 50.133454, lng: 30.7180309 },
      { lat: 50.1329038, lng: 30.7233524 },
      { lat: 50.1326837, lng: 30.7254124 },
      { lat: 50.1328488, lng: 30.7285881 },
      { lat: 50.1286672, lng: 30.7282448 },
      { lat: 50.128172, lng: 30.7262707 },
      { lat: 50.1277318, lng: 30.7244682 },
      { lat: 50.1268514, lng: 30.7230949 },
      { lat: 50.1267964, lng: 30.7217216 },
      { lat: 50.1262461, lng: 30.7218075 },
      { lat: 50.1264662, lng: 30.7278156 },
      { lat: 50.1265212, lng: 30.7294464 },
      { lat: 50.1264112, lng: 30.7331371 },
      { lat: 50.1264662, lng: 30.7351971 },
      { lat: 50.1262461, lng: 30.7369995 },
      { lat: 50.1264662, lng: 30.7393169 },
      { lat: 50.1267413, lng: 30.7430077 },
      { lat: 50.1275117, lng: 30.7462692 },
      { lat: 50.128227, lng: 30.7503891 },
      { lat: 50.1285021, lng: 30.7523632 },
      { lat: 50.1284471, lng: 30.754509 },
      { lat: 50.1278968, lng: 30.7573414 },
      { lat: 50.1293275, lng: 30.7578564 },
      { lat: 50.1303178, lng: 30.7593155 },
      { lat: 50.1352695, lng: 30.7572556 },
      { lat: 50.1384053, lng: 30.7572556 },
      { lat: 50.1389004, lng: 30.7544231 },
      { lat: 50.1393955, lng: 30.7542943 },
      { lat: 50.1403857, lng: 30.7563972 },
      { lat: 50.1438511, lng: 30.7548523 },
      { lat: 50.1470414, lng: 30.7548523 },
      { lat: 50.1483614, lng: 30.7546806 },
      { lat: 50.1508914, lng: 30.7537365 },
      { lat: 50.1543011, lng: 30.7518482 },
      { lat: 50.1558959, lng: 30.7510757 },
      { lat: 50.1572706, lng: 30.7499599 },
      { lat: 50.1583704, lng: 30.7487583 },
      { lat: 50.1589203, lng: 30.7460117 },
      { lat: 50.1589203, lng: 30.7446384 },
      { lat: 50.1588653, lng: 30.7430935 },
      { lat: 50.1583704, lng: 30.7426643 },
      { lat: 50.1577106, lng: 30.7416344 },
      { lat: 50.1577656, lng: 30.7400036 },
      { lat: 50.1582605, lng: 30.736742 },
      { lat: 50.1579855, lng: 30.7351112 },
      { lat: 50.1557309, lng: 30.7333946 },
      { lat: 50.1525413, lng: 30.7333088 },
    ],
  },
  {
    name: 'КОЗИН 2',
    price: 18,
    points: [
      { lat: 50.253242, lng: 30.623703 },
      { lat: 50.2287594, lng: 30.6403542 },
      { lat: 50.1944845, lng: 30.6655884 },
      { lat: 50.1940449, lng: 30.6734848 },
      { lat: 50.1920668, lng: 30.6882477 },
      { lat: 50.1887697, lng: 30.7023239 },
      { lat: 50.1892093, lng: 30.7164001 },
      { lat: 50.1984406, lng: 30.7366562 },
      { lat: 50.2098673, lng: 30.7256699 },
      { lat: 50.2111856, lng: 30.72052 },
      { lat: 50.2129433, lng: 30.710907 },
      { lat: 50.2160191, lng: 30.7040405 },
      { lat: 50.2197536, lng: 30.7033539 },
      { lat: 50.2267827, lng: 30.7043839 },
      { lat: 50.230736, lng: 30.7019806 },
      { lat: 50.2386418, lng: 30.6937408 },
      { lat: 50.2425942, lng: 30.6872177 },
      { lat: 50.2463267, lng: 30.6786346 },
      { lat: 50.2520347, lng: 30.6724548 },
      { lat: 50.2553274, lng: 30.6686783 },
      { lat: 50.2597174, lng: 30.6590652 },
      { lat: 50.2551628, lng: 30.6520272 },
      { lat: 50.2534615, lng: 30.639925 },
      { lat: 50.2508823, lng: 30.6386376 },
      { lat: 50.250498, lng: 30.636835 },
      { lat: 50.251925, lng: 30.6331444 },
      { lat: 50.2551079, lng: 30.628252 },
      { lat: 50.253242, lng: 30.623703 },
    ],
  },
  {
    name: 'ВАСИЛЬКОВ',
    price: 18,
    points: [
      { lat: 50.2134376, lng: 30.3353977 },
      { lat: 50.2172273, lng: 30.3368568 },
      { lat: 50.21882, lng: 30.3389168 },
      { lat: 50.222829, lng: 30.3353119 },
      { lat: 50.2240371, lng: 30.3234673 },
      { lat: 50.2327126, lng: 30.3244114 },
      { lat: 50.2323283, lng: 30.332222 },
      { lat: 50.2360616, lng: 30.3323936 },
      { lat: 50.2362812, lng: 30.3241539 },
      { lat: 50.2361165, lng: 30.3214931 },
      { lat: 50.231889, lng: 30.3214073 },
      { lat: 50.2264532, lng: 30.3212357 },
      { lat: 50.2263983, lng: 30.3180599 },
      { lat: 50.2238723, lng: 30.319519 },
      { lat: 50.2231035, lng: 30.3194332 },
      { lat: 50.2222249, lng: 30.31497 },
      { lat: 50.2230486, lng: 30.3121376 },
      { lat: 50.2224995, lng: 30.3083611 },
      { lat: 50.2223897, lng: 30.3032112 },
      { lat: 50.2270572, lng: 30.2960873 },
      { lat: 50.2261237, lng: 30.292654 },
      { lat: 50.2270023, lng: 30.2906799 },
      { lat: 50.2271121, lng: 30.2816677 },
      { lat: 50.2254099, lng: 30.2812386 },
      { lat: 50.2254648, lng: 30.2876759 },
      { lat: 50.2256295, lng: 30.2905083 },
      { lat: 50.2243116, lng: 30.2937698 },
      { lat: 50.2233781, lng: 30.2928257 },
      { lat: 50.2226642, lng: 30.2938557 },
      { lat: 50.220852, lng: 30.299263 },
      { lat: 50.2194791, lng: 30.3012371 },
      { lat: 50.2186003, lng: 30.3057003 },
      { lat: 50.2168978, lng: 30.3064728 },
      { lat: 50.2156346, lng: 30.3101635 },
      { lat: 50.2101969, lng: 30.3075886 },
      { lat: 50.2087138, lng: 30.3080177 },
      { lat: 50.2079104, lng: 30.3075456 },
      { lat: 50.2071208, lng: 30.3066444 },
      { lat: 50.2044084, lng: 30.3058398 },
      { lat: 50.2024512, lng: 30.3052711 },
      { lat: 50.2005833, lng: 30.3054428 },
      { lat: 50.1994295, lng: 30.3050137 },
      { lat: 50.1992098, lng: 30.3041553 },
      { lat: 50.2015722, lng: 30.2959156 },
      { lat: 50.2013868, lng: 30.294038 },
      { lat: 50.198887, lng: 30.2931475 },
      { lat: 50.1974722, lng: 30.2925253 },
      { lat: 50.1976164, lng: 30.2917957 },
      { lat: 50.1976301, lng: 30.2905083 },
      { lat: 50.1964694, lng: 30.2896928 },
      { lat: 50.1962841, lng: 30.2893174 },
      { lat: 50.1956384, lng: 30.2888775 },
      { lat: 50.1927811, lng: 30.2871609 },
      { lat: 50.1914074, lng: 30.2901649 },
      { lat: 50.1892093, lng: 30.2877617 },
      { lat: 50.1876156, lng: 30.2871609 },
      { lat: 50.187176, lng: 30.2864742 },
      { lat: 50.1880003, lng: 30.2848434 },
      { lat: 50.1860768, lng: 30.2822685 },
      { lat: 50.1850876, lng: 30.2838993 },
      { lat: 50.1834938, lng: 30.2841568 },
      { lat: 50.183219, lng: 30.2817535 },
      { lat: 50.182587, lng: 30.2801443 },
      { lat: 50.1808762, lng: 30.2800584 },
      { lat: 50.1810755, lng: 30.2953148 },
      { lat: 50.1801754, lng: 30.2941024 },
      { lat: 50.1793166, lng: 30.2933407 },
      { lat: 50.1780868, lng: 30.2933514 },
      { lat: 50.177118, lng: 30.2940273 },
      { lat: 50.1761286, lng: 30.2916241 },
      { lat: 50.1747269, lng: 30.2898591 },
      { lat: 50.1730262, lng: 30.2900898 },
      { lat: 50.1731774, lng: 30.2873218 },
      { lat: 50.1724112, lng: 30.2870106 },
      { lat: 50.1710712, lng: 30.2893066 },
      { lat: 50.1690371, lng: 30.2892208 },
      { lat: 50.1689271, lng: 30.2925682 },
      { lat: 50.1696968, lng: 30.2943707 },
      { lat: 50.1708513, lng: 30.2948856 },
      { lat: 50.1705764, lng: 30.2963448 },
      { lat: 50.1696968, lng: 30.2954006 },
      { lat: 50.1690921, lng: 30.2973747 },
      { lat: 50.166838, lng: 30.2990913 },
      { lat: 50.166783, lng: 30.3040695 },
      { lat: 50.1659033, lng: 30.3040695 },
      { lat: 50.1655734, lng: 30.3060436 },
      { lat: 50.1641989, lng: 30.3051853 },
      { lat: 50.1635941, lng: 30.3012371 },
      { lat: 50.163814, lng: 30.294199 },
      { lat: 50.1623845, lng: 30.2940273 },
      { lat: 50.1584254, lng: 30.2960873 },
      { lat: 50.1580405, lng: 30.2932549 },
      { lat: 50.1558409, lng: 30.2935982 },
      { lat: 50.154088, lng: 30.3250766 },
      { lat: 50.1506852, lng: 30.3244328 },
      { lat: 50.1501833, lng: 30.3288639 },
      { lat: 50.1533662, lng: 30.3324795 },
      { lat: 50.1533662, lng: 30.3352261 },
      { lat: 50.1513313, lng: 30.3364277 },
      { lat: 50.1487464, lng: 30.3475857 },
      { lat: 50.1516613, lng: 30.349474 },
      { lat: 50.1538611, lng: 30.3517914 },
      { lat: 50.1522113, lng: 30.3530788 },
      { lat: 50.1518263, lng: 30.3557396 },
      { lat: 50.1506714, lng: 30.3576279 },
      { lat: 50.1512213, lng: 30.3617477 },
      { lat: 50.1503964, lng: 30.3625202 },
      { lat: 50.1485814, lng: 30.362606 },
      { lat: 50.1486639, lng: 30.3691077 },
      { lat: 50.1474814, lng: 30.3767681 },
      { lat: 50.1452813, lng: 30.3771114 },
      { lat: 50.1439062, lng: 30.3836346 },
      { lat: 50.142311, lng: 30.3838062 },
      { lat: 50.1387216, lng: 30.381124 },
      { lat: 50.1380202, lng: 30.3826904 },
      { lat: 50.1421459, lng: 30.3880978 },
      { lat: 50.1450613, lng: 30.3881836 },
      { lat: 50.1442912, lng: 30.3918743 },
      { lat: 50.1456663, lng: 30.3916168 },
      { lat: 50.1466564, lng: 30.3860378 },
      { lat: 50.1504514, lng: 30.3826904 },
      { lat: 50.1499841, lng: 30.3865099 },
      { lat: 50.1505064, lng: 30.3941917 },
      { lat: 50.1536961, lng: 30.3942776 },
      { lat: 50.1541911, lng: 30.3826046 },
      { lat: 50.1563908, lng: 30.3739357 },
      { lat: 50.1618346, lng: 30.3747082 },
      { lat: 50.1670029, lng: 30.3731632 },
      { lat: 50.1688172, lng: 30.3751373 },
      { lat: 50.1696968, lng: 30.3744507 },
      { lat: 50.1690371, lng: 30.3701591 },
      { lat: 50.1707413, lng: 30.3674126 },
      { lat: 50.1711811, lng: 30.3644085 },
      { lat: 50.1729953, lng: 30.3648376 },
      { lat: 50.173545, lng: 30.3620911 },
      { lat: 50.1750842, lng: 30.3620052 },
      { lat: 50.175359, lng: 30.3552139 },
      { lat: 50.1699786, lng: 30.3553534 },
      { lat: 50.1671129, lng: 30.3655243 },
      { lat: 50.1660683, lng: 30.3647518 },
      { lat: 50.1643089, lng: 30.3622627 },
      { lat: 50.164034, lng: 30.3593445 },
      { lat: 50.1652985, lng: 30.3558254 },
      { lat: 50.1679925, lng: 30.3530788 },
      { lat: 50.1690921, lng: 30.3438091 },
      { lat: 50.1707963, lng: 30.3446674 },
      { lat: 50.171401, lng: 30.3432941 },
      { lat: 50.1759087, lng: 30.3437233 },
      { lat: 50.1764034, lng: 30.3399467 },
      { lat: 50.1790418, lng: 30.3391743 },
      { lat: 50.1798663, lng: 30.3370285 },
      { lat: 50.1806358, lng: 30.3397751 },
      { lat: 50.1839884, lng: 30.3387451 },
      { lat: 50.1837549, lng: 30.3342926 },
      { lat: 50.1848609, lng: 30.3352421 },
      { lat: 50.1854173, lng: 30.3349686 },
      { lat: 50.187176, lng: 30.3342819 },
      { lat: 50.1870111, lng: 30.3307629 },
      { lat: 50.1898687, lng: 30.3326511 },
      { lat: 50.1909128, lng: 30.3352261 },
      { lat: 50.1919569, lng: 30.3398609 },
      { lat: 50.1954736, lng: 30.3404617 },
      { lat: 50.1966549, lng: 30.3383803 },
      { lat: 50.1965725, lng: 30.332222 },
      { lat: 50.2078898, lng: 30.3337669 },
      { lat: 50.2134376, lng: 30.3353977 },
    ],
  },
  {
    name: 'БОЯРКА НОВАЯ',
    price: 19,
    points: [
      { lat: 50.3372985, lng: 30.3000355 },
      { lat: 50.3431599, lng: 30.3018379 },
      { lat: 50.348199, lng: 30.3047562 },
      { lat: 50.3540591, lng: 30.2950573 },
      { lat: 50.3537305, lng: 30.2900791 },
      { lat: 50.3550996, lng: 30.2874184 },
      { lat: 50.3518685, lng: 30.2809811 },
      { lat: 50.3505541, lng: 30.2808094 },
      { lat: 50.348199, lng: 30.2843285 },
      { lat: 50.3449127, lng: 30.2790928 },
      { lat: 50.3483085, lng: 30.2699089 },
      { lat: 50.3433242, lng: 30.2662182 },
      { lat: 50.3416261, lng: 30.2684498 },
      { lat: 50.3371889, lng: 30.2574635 },
      { lat: 50.3383393, lng: 30.2554035 },
      { lat: 50.3378599, lng: 30.2536655 },
      { lat: 50.3390515, lng: 30.2525711 },
      { lat: 50.3387776, lng: 30.2485371 },
      { lat: 50.3375724, lng: 30.2479362 },
      { lat: 50.3347784, lng: 30.2506828 },
      { lat: 50.3336279, lng: 30.2573776 },
      { lat: 50.3303405, lng: 30.268364 },
      { lat: 50.3285872, lng: 30.2675915 },
      { lat: 50.3284228, lng: 30.2691364 },
      { lat: 50.3300118, lng: 30.2698231 },
      { lat: 50.3256283, lng: 30.284071 },
      { lat: 50.3243131, lng: 30.2864742 },
      { lat: 50.3186686, lng: 30.2841568 },
      { lat: 50.3173533, lng: 30.2913666 },
      { lat: 50.3146677, lng: 30.2905083 },
      { lat: 50.3144622, lng: 30.2919889 },
      { lat: 50.3124753, lng: 30.2918816 },
      { lat: 50.3122561, lng: 30.2932549 },
      { lat: 50.3081998, lng: 30.2919674 },
      { lat: 50.3073776, lng: 30.2919674 },
      { lat: 50.3067746, lng: 30.2943707 },
      { lat: 50.3052397, lng: 30.2948856 },
      { lat: 50.3042529, lng: 30.2966881 },
      { lat: 50.3035402, lng: 30.299263 },
      { lat: 50.3086932, lng: 30.2984905 },
      { lat: 50.317415, lng: 30.2986837 },
      { lat: 50.3216828, lng: 30.2991772 },
      { lat: 50.326505, lng: 30.2995205 },
      { lat: 50.3372985, lng: 30.3000355 },
    ],
  },
  {
    name: 'БЕЛОГОРОДКА',
    price: 20,
    points: [
      { lat: 50.4078064, lng: 30.2511978 },
      { lat: 50.4101038, lng: 30.2505326 },
      { lat: 50.4124558, lng: 30.2454042 },
      { lat: 50.4162297, lng: 30.2395248 },
      { lat: 50.4208783, lng: 30.2418423 },
      { lat: 50.4197709, lng: 30.2433873 },
      { lat: 50.4218079, lng: 30.2466488 },
      { lat: 50.4218354, lng: 30.2483225 },
      { lat: 50.4307208, lng: 30.2624417 },
      { lat: 50.4317187, lng: 30.2605319 },
      { lat: 50.4313847, lng: 30.2543167 },
      { lat: 50.4247105, lng: 30.2429699 },
      { lat: 50.4271692, lng: 30.238929 },
      { lat: 50.4159562, lng: 30.222187 },
      { lat: 50.413495, lng: 30.2221012 },
      { lat: 50.4114713, lng: 30.219698 },
      { lat: 50.404743, lng: 30.2190971 },
      { lat: 50.4005306, lng: 30.2201271 },
      { lat: 50.3965913, lng: 30.2203846 },
      { lat: 50.3878909, lng: 30.2172947 },
      { lat: 50.3818708, lng: 30.2151489 },
      { lat: 50.382035, lng: 30.2128315 },
      { lat: 50.378751, lng: 30.2105141 },
      { lat: 50.3802288, lng: 30.2087975 },
      { lat: 50.3763426, lng: 30.1881123 },
      { lat: 50.3740983, lng: 30.1889706 },
      { lat: 50.3686787, lng: 30.1975536 },
      { lat: 50.3680217, lng: 30.2009869 },
      { lat: 50.3695546, lng: 30.2015877 },
      { lat: 50.3678027, lng: 30.2100849 },
      { lat: 50.3727298, lng: 30.2148056 },
      { lat: 50.3680217, lng: 30.2225304 },
      { lat: 50.3661055, lng: 30.2193546 },
      { lat: 50.3645725, lng: 30.2210712 },
      { lat: 50.3664888, lng: 30.2258778 },
      { lat: 50.3660508, lng: 30.2278519 },
      { lat: 50.3639155, lng: 30.2283669 },
      { lat: 50.3630394, lng: 30.2345467 },
      { lat: 50.3639155, lng: 30.2351475 },
      { lat: 50.360685, lng: 30.2414989 },
      { lat: 50.3616706, lng: 30.2426147 },
      { lat: 50.3634227, lng: 30.2442455 },
      { lat: 50.3659413, lng: 30.2452755 },
      { lat: 50.3674195, lng: 30.2529144 },
      { lat: 50.3694451, lng: 30.2558327 },
      { lat: 50.3771637, lng: 30.2601242 },
      { lat: 50.3801741, lng: 30.2395248 },
      { lat: 50.3880003, lng: 30.2427006 },
      { lat: 50.3862491, lng: 30.251112 },
      { lat: 50.3869058, lng: 30.2590942 },
      { lat: 50.4029924, lng: 30.2580643 },
      { lat: 50.4078064, lng: 30.2511978 },
    ],
  },
  {
    name: 'БОРТНИЧИ',
    price: 21,
    points: [
      { lat: 50.374591, lng: 30.6587219 },
      { lat: 50.37054, lng: 30.6609535 },
      { lat: 50.3591518, lng: 30.6679916 },
      { lat: 50.3536757, lng: 30.6691933 },
      { lat: 50.3471036, lng: 30.6745148 },
      { lat: 50.3472132, lng: 30.6968308 },
      { lat: 50.3534567, lng: 30.7139969 },
      { lat: 50.3814877, lng: 30.7184601 },
      { lat: 50.3810498, lng: 30.7218933 },
      { lat: 50.3833485, lng: 30.7227516 },
      { lat: 50.3837521, lng: 30.7193184 },
      { lat: 50.3926517, lng: 30.7105637 },
      { lat: 50.395497, lng: 30.7052422 },
      { lat: 50.39945, lng: 30.6928825 },
      { lat: 50.4021239, lng: 30.685131 },
      { lat: 50.4027873, lng: 30.6786775 },
      { lat: 50.4033481, lng: 30.6731415 },
      { lat: 50.4034506, lng: 30.6691504 },
      { lat: 50.391229, lng: 30.6552887 },
      { lat: 50.3878088, lng: 30.6558037 },
      { lat: 50.374591, lng: 30.6587219 },
    ],
  },
  {
    name: 'КП БОРИСПОЛЬ',
    price: 22,
    points: [
      { lat: 50.4042507, lng: 30.6888056 },
      { lat: 50.4021239, lng: 30.685131 },
      { lat: 50.39945, lng: 30.6928825 },
      { lat: 50.4000929, lng: 30.6958008 },
      { lat: 50.4023633, lng: 30.696938 },
      { lat: 50.4031292, lng: 30.6952429 },
      { lat: 50.4042507, lng: 30.6888056 },
    ],
  },
  {
    name: 'ОСОКОРКИ РУДЕНКО',
    price: 23,
    points: [
      { lat: 50.40157, lng: 30.6543016 },
      { lat: 50.4011871, lng: 30.6519413 },
      { lat: 50.3984242, lng: 30.634346 },
      { lat: 50.3963177, lng: 30.6349897 },
      { lat: 50.3920224, lng: 30.636363 },
      { lat: 50.3860849, lng: 30.6382513 },
      { lat: 50.3878088, lng: 30.6558037 },
      { lat: 50.391229, lng: 30.6552887 },
      { lat: 50.40157, lng: 30.6543016 },
    ],
  },
  {
    name: 'ОСОКОРКИ ГМЫРИ',
    price: 24,
    points: [
      { lat: 50.3849767, lng: 30.6283808 },
      { lat: 50.3860849, lng: 30.6382513 },
      { lat: 50.3920224, lng: 30.636363 },
      { lat: 50.3963177, lng: 30.6349897 },
      { lat: 50.3984242, lng: 30.634346 },
      { lat: 50.3971111, lng: 30.6258488 },
      { lat: 50.3959347, lng: 30.6179953 },
      { lat: 50.3945121, lng: 30.6178236 },
      { lat: 50.3940743, lng: 30.6182528 },
      { lat: 50.394642, lng: 30.6219864 },
      { lat: 50.3939375, lng: 30.6222439 },
      { lat: 50.3941017, lng: 30.6233168 },
      { lat: 50.3930073, lng: 30.6251192 },
      { lat: 50.3918582, lng: 30.6257629 },
      { lat: 50.3912837, lng: 30.6265783 },
      { lat: 50.3902166, lng: 30.6272221 },
      { lat: 50.3875625, lng: 30.6284237 },
      { lat: 50.3859755, lng: 30.6279087 },
      { lat: 50.3849767, lng: 30.6283808 },
    ],
  },
  {
    name: 'ОСОКОРКИ ЧАВДАР',
    price: 25,
    points: [
      { lat: 50.3948609, lng: 30.6113541 },
      { lat: 50.3945668, lng: 30.6098843 },
      { lat: 50.3934724, lng: 30.6098843 },
      { lat: 50.3931647, lng: 30.6108821 },
      { lat: 50.3922686, lng: 30.6117296 },
      { lat: 50.3923234, lng: 30.6134892 },
      { lat: 50.3924328, lng: 30.6146049 },
      { lat: 50.3934451, lng: 30.616107 },
      { lat: 50.3930621, lng: 30.6167507 },
      { lat: 50.3930073, lng: 30.6179953 },
      { lat: 50.393363, lng: 30.6209993 },
      { lat: 50.3909006, lng: 30.6218576 },
      { lat: 50.3893958, lng: 30.6228018 },
      { lat: 50.3865501, lng: 30.6237459 },
      { lat: 50.3847988, lng: 30.6244326 },
      { lat: 50.3849767, lng: 30.6283808 },
      { lat: 50.3859755, lng: 30.6279087 },
      { lat: 50.3875625, lng: 30.6284237 },
      { lat: 50.3902166, lng: 30.6272221 },
      { lat: 50.3912837, lng: 30.6265783 },
      { lat: 50.3918582, lng: 30.6257629 },
      { lat: 50.3930073, lng: 30.6251192 },
      { lat: 50.3941017, lng: 30.6233168 },
      { lat: 50.3939375, lng: 30.6222439 },
      { lat: 50.394642, lng: 30.6219864 },
      { lat: 50.3940743, lng: 30.6182528 },
      { lat: 50.3945121, lng: 30.6178236 },
      { lat: 50.3959347, lng: 30.6179953 },
      { lat: 50.3948609, lng: 30.6113541 },
    ],
  },
  {
    name: 'ОСОКОРКИ ДАЧИ',
    price: 26,
    points: [
      { lat: 50.3736056, lng: 30.5871391 },
      { lat: 50.3687882, lng: 30.5876541 },
      { lat: 50.3591518, lng: 30.5919456 },
      { lat: 50.3522518, lng: 30.5979538 },
      { lat: 50.3400923, lng: 30.6128883 },
      { lat: 50.3387776, lng: 30.614605 },
      { lat: 50.3371341, lng: 30.6188965 },
      { lat: 50.3426121, lng: 30.6393242 },
      { lat: 50.3849767, lng: 30.6283808 },
      { lat: 50.3847988, lng: 30.6244326 },
      { lat: 50.3865501, lng: 30.6237459 },
      { lat: 50.3893958, lng: 30.6228018 },
      { lat: 50.3909006, lng: 30.6218576 },
      { lat: 50.393363, lng: 30.6209993 },
      { lat: 50.3930073, lng: 30.6179953 },
      { lat: 50.3930621, lng: 30.6167507 },
      { lat: 50.3934451, lng: 30.616107 },
      { lat: 50.3924328, lng: 30.6146049 },
      { lat: 50.3923234, lng: 30.6134892 },
      { lat: 50.3922686, lng: 30.6117296 },
      { lat: 50.3920498, lng: 30.6079531 },
      { lat: 50.3913931, lng: 30.6067085 },
      { lat: 50.3906817, lng: 30.6055498 },
      { lat: 50.3903534, lng: 30.6061077 },
      { lat: 50.3900524, lng: 30.605979 },
      { lat: 50.3898062, lng: 30.603919 },
      { lat: 50.3896968, lng: 30.6021166 },
      { lat: 50.3901072, lng: 30.6017303 },
      { lat: 50.389642, lng: 30.6001425 },
      { lat: 50.3893411, lng: 30.5990267 },
      { lat: 50.3895599, lng: 30.5976963 },
      { lat: 50.3899156, lng: 30.5964518 },
      { lat: 50.3900798, lng: 30.595293 },
      { lat: 50.3902713, lng: 30.5950356 },
      { lat: 50.3902987, lng: 30.5940056 },
      { lat: 50.3867143, lng: 30.5919456 },
      { lat: 50.3811319, lng: 30.5899286 },
      { lat: 50.3736056, lng: 30.5871391 },
    ],
  },
  {
    name: 'ОСОКОРКИ ДАЧИ (НАЧ.)',
    price: 27,
    points: [
      { lat: 50.395114, lng: 30.5962372 },
      { lat: 50.3937187, lng: 30.5950785 },
      { lat: 50.3902987, lng: 30.5940056 },
      { lat: 50.3902713, lng: 30.5950356 },
      { lat: 50.3900798, lng: 30.595293 },
      { lat: 50.3899156, lng: 30.5964518 },
      { lat: 50.3895599, lng: 30.5976963 },
      { lat: 50.3893411, lng: 30.5990267 },
      { lat: 50.389642, lng: 30.6001425 },
      { lat: 50.3901072, lng: 30.6017303 },
      { lat: 50.3896968, lng: 30.6021166 },
      { lat: 50.3898062, lng: 30.603919 },
      { lat: 50.3900524, lng: 30.605979 },
      { lat: 50.3903534, lng: 30.6061077 },
      { lat: 50.3906817, lng: 30.6055498 },
      { lat: 50.3913931, lng: 30.6067085 },
      { lat: 50.3920498, lng: 30.6079531 },
      { lat: 50.3922686, lng: 30.6117296 },
      { lat: 50.3931647, lng: 30.6108821 },
      { lat: 50.3934724, lng: 30.6098843 },
      { lat: 50.3945668, lng: 30.6098843 },
      { lat: 50.3948609, lng: 30.6113541 },
      { lat: 50.3982053, lng: 30.6116867 },
      { lat: 50.4042507, lng: 30.6096268 },
      { lat: 50.4048798, lng: 30.6061077 },
      { lat: 50.4061106, lng: 30.5993271 },
      { lat: 50.4057824, lng: 30.5977392 },
      { lat: 50.4014606, lng: 30.5979538 },
      { lat: 50.39826, lng: 30.5966234 },
      { lat: 50.395114, lng: 30.5962372 },
    ],
  },
  {
    name: 'ПОЗНЯКИ',
    price: 28,
    points: [
      { lat: 50.414124, lng: 30.625205 },
      { lat: 50.4158742, lng: 30.6237888 },
      { lat: 50.4176517, lng: 30.6224585 },
      { lat: 50.4168587, lng: 30.6198835 },
      { lat: 50.416093, lng: 30.6174374 },
      { lat: 50.4148077, lng: 30.6134033 },
      { lat: 50.414042, lng: 30.6107426 },
      { lat: 50.4127977, lng: 30.6055284 },
      { lat: 50.411526, lng: 30.6004 },
      { lat: 50.4108696, lng: 30.5990267 },
      { lat: 50.4094474, lng: 30.5999279 },
      { lat: 50.4083808, lng: 30.6031036 },
      { lat: 50.4048798, lng: 30.6061077 },
      { lat: 50.4042507, lng: 30.6096268 },
      { lat: 50.3982053, lng: 30.6116867 },
      { lat: 50.3948609, lng: 30.6113541 },
      { lat: 50.3959347, lng: 30.6179953 },
      { lat: 50.3971111, lng: 30.6258488 },
      { lat: 50.3984242, lng: 30.634346 },
      { lat: 50.4018162, lng: 30.6329298 },
      { lat: 50.4086269, lng: 30.6285524 },
      { lat: 50.4130848, lng: 30.62572 },
      { lat: 50.414124, lng: 30.625205 },
    ],
  },
  {
    name: 'ПОЗНЯКИ СТАРЫЕ',
    price: 29,
    points: [
      { lat: 50.4178157, lng: 30.5928469 },
      { lat: 50.4133856, lng: 30.5952501 },
      { lat: 50.4117448, lng: 30.5971813 },
      { lat: 50.4108696, lng: 30.5990267 },
      { lat: 50.411526, lng: 30.6 },
      { lat: 50.4127977, lng: 30.6055284 },
      { lat: 50.414042, lng: 30.6107426 },
      { lat: 50.4148077, lng: 30.6134033 },
      { lat: 50.416093, lng: 30.6174374 },
      { lat: 50.4168587, lng: 30.6198835 },
      { lat: 50.4176517, lng: 30.6224585 },
      { lat: 50.4205228, lng: 30.633831 },
      { lat: 50.4207689, lng: 30.6346893 },
      { lat: 50.4211517, lng: 30.635376 },
      { lat: 50.4242961, lng: 30.6383371 },
      { lat: 50.4258818, lng: 30.6397104 },
      { lat: 50.4262919, lng: 30.6385088 },
      { lat: 50.4270028, lng: 30.6372643 },
      { lat: 50.4275496, lng: 30.6365776 },
      { lat: 50.4289438, lng: 30.6356335 },
      { lat: 50.4274949, lng: 30.6300116 },
      { lat: 50.4250069, lng: 30.6199694 },
      { lat: 50.4241594, lng: 30.6165361 },
      { lat: 50.4233391, lng: 30.6130171 },
      { lat: 50.4221907, lng: 30.6088114 },
      { lat: 50.4178157, lng: 30.5928469 },
    ],
  },
  {
    name: 'ПОЗНЯКИ ДРАГОМАНОВА',
    price: 30,
    points: [
      { lat: 50.4018162, lng: 30.6329298 },
      { lat: 50.3984242, lng: 30.634346 },
      { lat: 50.4011871, lng: 30.6519413 },
      { lat: 50.4037583, lng: 30.6509972 },
      { lat: 50.4083261, lng: 30.6480789 },
      { lat: 50.4125105, lng: 30.6455469 },
      { lat: 50.4131669, lng: 30.6452036 },
      { lat: 50.4242961, lng: 30.6383371 },
      { lat: 50.4211517, lng: 30.635376 },
      { lat: 50.4207689, lng: 30.6346893 },
      { lat: 50.4205228, lng: 30.633831 },
      { lat: 50.4176517, lng: 30.6224585 },
      { lat: 50.4158742, lng: 30.6237888 },
      { lat: 50.414124, lng: 30.625205 },
      { lat: 50.4130848, lng: 30.62572 },
      { lat: 50.4086269, lng: 30.6285524 },
      { lat: 50.4018162, lng: 30.6329298 },
    ],
  },
  {
    name: 'ХАРЬКОВСКИЙ',
    price: 31,
    points: [
      { lat: 50.4214525, lng: 30.6509972 },
      { lat: 50.4226282, lng: 30.6491089 },
      { lat: 50.4236399, lng: 30.6467056 },
      { lat: 50.4250343, lng: 30.6417704 },
      { lat: 50.4258818, lng: 30.6397104 },
      { lat: 50.4242961, lng: 30.6383371 },
      { lat: 50.4131669, lng: 30.6452036 },
      { lat: 50.4125105, lng: 30.6455469 },
      { lat: 50.4083261, lng: 30.6480789 },
      { lat: 50.4037583, lng: 30.6509972 },
      { lat: 50.4011871, lng: 30.6519413 },
      { lat: 50.40157, lng: 30.6543016 },
      { lat: 50.402801, lng: 30.662241 },
      { lat: 50.4032933, lng: 30.6664038 },
      { lat: 50.4036216, lng: 30.666275 },
      { lat: 50.4041139, lng: 30.6665754 },
      { lat: 50.4057277, lng: 30.6691504 },
      { lat: 50.4083261, lng: 30.6728411 },
      { lat: 50.4125105, lng: 30.66576 },
      { lat: 50.414671, lng: 30.6689358 },
      { lat: 50.4185267, lng: 30.6627989 },
      { lat: 50.4212064, lng: 30.6582928 },
      { lat: 50.419183, lng: 30.6550741 },
      { lat: 50.4214525, lng: 30.6509972 },
    ],
  },
  {
    name: 'М. БОРИСПОЛЬСКАЯ',
    price: 32,
    points: [
      { lat: 50.4125105, lng: 30.6756306 },
      { lat: 50.4150538, lng: 30.6729698 },
      { lat: 50.4159016, lng: 30.6712103 },
      { lat: 50.414671, lng: 30.6689358 },
      { lat: 50.4125105, lng: 30.66576 },
      { lat: 50.4083261, lng: 30.6728411 },
      { lat: 50.4057277, lng: 30.6691504 },
      { lat: 50.4041139, lng: 30.6665754 },
      { lat: 50.4036216, lng: 30.666275 },
      { lat: 50.4032933, lng: 30.6664038 },
      { lat: 50.4034506, lng: 30.6691504 },
      { lat: 50.4033481, lng: 30.6731415 },
      { lat: 50.4027873, lng: 30.6786775 },
      { lat: 50.4021239, lng: 30.685131 },
      { lat: 50.4042507, lng: 30.6888056 },
      { lat: 50.4060012, lng: 30.6912518 },
      { lat: 50.4075876, lng: 30.6889343 },
      { lat: 50.4091192, lng: 30.6853724 },
      { lat: 50.411061, lng: 30.678463 },
      { lat: 50.4125105, lng: 30.6756306 },
    ],
  },
  {
    name: 'ДАРНИЦА НОВАЯ',
    price: 33,
    points: [
      { lat: 50.4191556, lng: 30.6717253 },
      { lat: 50.4205775, lng: 30.6750298 },
      { lat: 50.4268387, lng: 30.6777763 },
      { lat: 50.4295179, lng: 30.6629705 },
      { lat: 50.4337005, lng: 30.6648159 },
      { lat: 50.4338645, lng: 30.6636143 },
      { lat: 50.4348486, lng: 30.6599236 },
      { lat: 50.4339465, lng: 30.6569195 },
      { lat: 50.4330171, lng: 30.6525421 },
      { lat: 50.4316503, lng: 30.646534 },
      { lat: 50.4289438, lng: 30.6356335 },
      { lat: 50.4275496, lng: 30.6365776 },
      { lat: 50.4270028, lng: 30.6372643 },
      { lat: 50.4262919, lng: 30.6385088 },
      { lat: 50.4258818, lng: 30.6397104 },
      { lat: 50.4250343, lng: 30.6417704 },
      { lat: 50.4236399, lng: 30.6467056 },
      { lat: 50.4226282, lng: 30.6491089 },
      { lat: 50.4214525, lng: 30.6509972 },
      { lat: 50.419183, lng: 30.6550741 },
      { lat: 50.4212064, lng: 30.6582928 },
      { lat: 50.4185267, lng: 30.6627989 },
      { lat: 50.414671, lng: 30.6689358 },
      { lat: 50.4159016, lng: 30.6712103 },
      { lat: 50.4191556, lng: 30.6717253 },
    ],
  },
  {
    name: 'РЕМБАЗА',
    price: 34,
    points: [
      { lat: 50.4186361, lng: 30.6782913 },
      { lat: 50.4166672, lng: 30.6813812 },
      { lat: 50.4114166, lng: 30.6899643 },
      { lat: 50.4058918, lng: 30.6981182 },
      { lat: 50.4115807, lng: 30.7066154 },
      { lat: 50.4156828, lng: 30.7123661 },
      { lat: 50.4119635, lng: 30.7173443 },
      { lat: 50.4159562, lng: 30.7230091 },
      { lat: 50.4243234, lng: 30.7142544 },
      { lat: 50.4303928, lng: 30.6814671 },
      { lat: 50.4337005, lng: 30.6648159 },
      { lat: 50.4295179, lng: 30.6629705 },
      { lat: 50.4268387, lng: 30.6777763 },
      { lat: 50.4205775, lng: 30.6750298 },
      { lat: 50.4186361, lng: 30.6782913 },
    ],
  },
  {
    name: 'ДАРНИЦА ДЕПО',
    price: 35,
    points: [
      { lat: 50.4332631, lng: 30.686059 },
      { lat: 50.4368987, lng: 30.6910801 },
      { lat: 50.4380194, lng: 30.6909084 },
      { lat: 50.4394407, lng: 30.6882477 },
      { lat: 50.4403153, lng: 30.6873465 },
      { lat: 50.4418459, lng: 30.6864452 },
      { lat: 50.4430484, lng: 30.6915092 },
      { lat: 50.446328, lng: 30.6900072 },
      { lat: 50.4443056, lng: 30.681982 },
      { lat: 50.4425838, lng: 30.6714678 },
      { lat: 50.4378554, lng: 30.6575203 },
      { lat: 50.4348486, lng: 30.6599236 },
      { lat: 50.4338645, lng: 30.6636143 },
      { lat: 50.4337005, lng: 30.6648159 },
      { lat: 50.4303928, lng: 30.6814671 },
      { lat: 50.4332631, lng: 30.686059 },
    ],
  },
  {
    name: 'ДВРЗ',
    price: 36,
    points: [
      { lat: 50.4502358, lng: 30.6618547 },
      { lat: 50.4484869, lng: 30.6549883 },
      { lat: 50.4425018, lng: 30.656147 },
      { lat: 50.4408893, lng: 30.6564045 },
      { lat: 50.4378554, lng: 30.6575203 },
      { lat: 50.4425838, lng: 30.6714678 },
      { lat: 50.4443056, lng: 30.681982 },
      { lat: 50.446328, lng: 30.6900072 },
      { lat: 50.4483229, lng: 30.6930971 },
      { lat: 50.4521486, lng: 30.6890202 },
      { lat: 50.4584056, lng: 30.6813812 },
      { lat: 50.4502358, lng: 30.6618547 },
    ],
  },
  {
    name: 'ДАРНИЦА-СТАРАЯ',
    price: 37,
    points: [
      { lat: 50.4482614, lng: 30.638144 },
      { lat: 50.4533782, lng: 30.6348181 },
      { lat: 50.4556461, lng: 30.6332302 },
      { lat: 50.4529683, lng: 30.6316423 },
      { lat: 50.4434857, lng: 30.6258488 },
      { lat: 50.4423378, lng: 30.6258488 },
      { lat: 50.4399873, lng: 30.6285524 },
      { lat: 50.4372267, lng: 30.6301832 },
      { lat: 50.4339739, lng: 30.6322002 },
      { lat: 50.4310762, lng: 30.6339169 },
      { lat: 50.4289438, lng: 30.6356335 },
      { lat: 50.4316503, lng: 30.646534 },
      { lat: 50.4330171, lng: 30.6525421 },
      { lat: 50.4339465, lng: 30.6569195 },
      { lat: 50.4348486, lng: 30.6599236 },
      { lat: 50.4378554, lng: 30.6575203 },
      { lat: 50.4408893, lng: 30.6564045 },
      { lat: 50.4425018, lng: 30.656147 },
      { lat: 50.4484869, lng: 30.6549883 },
      { lat: 50.4446336, lng: 30.6404829 },
      { lat: 50.4482614, lng: 30.638144 },
    ],
  },
  {
    name: 'М. ЧЕРНИГОВСКАЯ',
    price: 38,
    points: [
      { lat: 50.4549357, lng: 30.6618118 },
      { lat: 50.4575313, lng: 30.6597519 },
      { lat: 50.4537607, lng: 30.6462336 },
      { lat: 50.4551543, lng: 30.6453323 },
      { lat: 50.4538427, lng: 30.6403971 },
      { lat: 50.4621485, lng: 30.6350327 },
      { lat: 50.4607826, lng: 30.6297112 },
      { lat: 50.4556461, lng: 30.6332302 },
      { lat: 50.4533782, lng: 30.6348181 },
      { lat: 50.4481999, lng: 30.6381654 },
      { lat: 50.4446336, lng: 30.6404829 },
      { lat: 50.4484869, lng: 30.6549883 },
      { lat: 50.4502358, lng: 30.6618547 },
      { lat: 50.4549357, lng: 30.6618118 },
    ],
  },
  {
    name: 'ДАРЫНОК',
    price: 39,
    points: [
      { lat: 50.4626949, lng: 30.6567049 },
      { lat: 50.4678033, lng: 30.6552029 },
      { lat: 50.4621485, lng: 30.6350327 },
      { lat: 50.4538427, lng: 30.6403971 },
      { lat: 50.4551543, lng: 30.6453323 },
      { lat: 50.4537607, lng: 30.6462336 },
      { lat: 50.4575313, lng: 30.6597519 },
      { lat: 50.4626949, lng: 30.6567049 },
    ],
  },
  {
    name: 'БЕРЕЗНЯКИ',
    price: 40,
    points: [
      { lat: 50.4215208, lng: 30.5906796 },
      { lat: 50.4178157, lng: 30.5928898 },
      { lat: 50.4221907, lng: 30.6088543 },
      { lat: 50.4233391, lng: 30.61306 },
      { lat: 50.4241594, lng: 30.616579 },
      { lat: 50.4250069, lng: 30.6200123 },
      { lat: 50.4383474, lng: 30.6124592 },
      { lat: 50.4370627, lng: 30.6080818 },
      { lat: 50.435368, lng: 30.6038761 },
      { lat: 50.4307482, lng: 30.5932331 },
      { lat: 50.429928, lng: 30.5908727 },
      { lat: 50.4257313, lng: 30.5904436 },
      { lat: 50.4215208, lng: 30.5906796 },
    ],
  },
  {
    name: 'ГАШЕКА',
    price: 41,
    points: [
      { lat: 50.440534, lng: 30.619626 },
      { lat: 50.4383474, lng: 30.6124163 },
      { lat: 50.4250069, lng: 30.6199694 },
      { lat: 50.4274949, lng: 30.6300116 },
      { lat: 50.4289438, lng: 30.6356335 },
      { lat: 50.4310762, lng: 30.6339169 },
      { lat: 50.4339739, lng: 30.6322002 },
      { lat: 50.4399873, lng: 30.6285524 },
      { lat: 50.4423378, lng: 30.6258488 },
      { lat: 50.4420099, lng: 30.6243467 },
      { lat: 50.440534, lng: 30.619626 },
    ],
  },
  {
    name: 'РУСАНОВКА',
    price: 42,
    points: [
      { lat: 50.4315956, lng: 30.5903149 },
      { lat: 50.429928, lng: 30.5908298 },
      { lat: 50.4307482, lng: 30.5931902 },
      { lat: 50.435368, lng: 30.6038332 },
      { lat: 50.4370627, lng: 30.6080389 },
      { lat: 50.4383474, lng: 30.6124163 },
      { lat: 50.4454261, lng: 30.6081676 },
      { lat: 50.4535422, lng: 30.6033611 },
      { lat: 50.4519846, lng: 30.5979538 },
      { lat: 50.4487055, lng: 30.5854225 },
      { lat: 50.4315956, lng: 30.5903149 },
    ],
  },
  {
    name: 'ДАРНИЦА',
    price: 43,
    points: [
      { lat: 50.4535422, lng: 30.6033611 },
      { lat: 50.4454261, lng: 30.6081676 },
      { lat: 50.4383474, lng: 30.6124163 },
      { lat: 50.440534, lng: 30.619626 },
      { lat: 50.4420099, lng: 30.6243467 },
      { lat: 50.4423378, lng: 30.6258488 },
      { lat: 50.4434857, lng: 30.6258488 },
      { lat: 50.4529683, lng: 30.6316423 },
      { lat: 50.4556461, lng: 30.6332302 },
      { lat: 50.4607826, lng: 30.6297112 },
      { lat: 50.4535422, lng: 30.6033611 },
    ],
  },
  {
    name: 'БЫКОВНЯ',
    price: 44,
    points: [
      { lat: 50.4892962, lng: 30.646534 },
      { lat: 50.4721464, lng: 30.6590652 },
      { lat: 50.4691964, lng: 30.6542587 },
      { lat: 50.4678033, lng: 30.6552029 },
      { lat: 50.4694149, lng: 30.6613827 },
      { lat: 50.4709446, lng: 30.6691933 },
      { lat: 50.4688686, lng: 30.6709957 },
      { lat: 50.4677214, lng: 30.681982 },
      { lat: 50.4764071, lng: 30.705328 },
      { lat: 50.4943745, lng: 30.6934833 },
      { lat: 50.4896239, lng: 30.6711674 },
      { lat: 50.4892962, lng: 30.646534 },
    ],
  },
  {
    name: 'ЛЕСНОЙ МАССИВ',
    price: 45,
    points: [
      { lat: 50.4903337, lng: 30.6449032 },
      { lat: 50.4901153, lng: 30.6427574 },
      { lat: 50.4901153, lng: 30.6345177 },
      { lat: 50.4902245, lng: 30.6245613 },
      { lat: 50.4890778, lng: 30.6172657 },
      { lat: 50.4876375, lng: 30.6133604 },
      { lat: 50.4867125, lng: 30.6117564 },
      { lat: 50.4862381, lng: 30.6121159 },
      { lat: 50.4857466, lng: 30.6113434 },
      { lat: 50.474386, lng: 30.6134033 },
      { lat: 50.4744953, lng: 30.617609 },
      { lat: 50.4729112, lng: 30.6182957 },
      { lat: 50.4729112, lng: 30.6210423 },
      { lat: 50.4663009, lng: 30.6260204 },
      { lat: 50.4607826, lng: 30.6297112 },
      { lat: 50.4621485, lng: 30.6350327 },
      { lat: 50.4678033, lng: 30.6552029 },
      { lat: 50.4691964, lng: 30.6542587 },
      { lat: 50.4721464, lng: 30.6590652 },
      { lat: 50.4892962, lng: 30.646534 },
      { lat: 50.4903337, lng: 30.6449032 },
    ],
  },
  {
    name: 'КОМСОМОЛЬСКИЙ',
    price: 46,
    points: [
      { lat: 50.4717913, lng: 30.6072235 },
      { lat: 50.4719006, lng: 30.6021595 },
      { lat: 50.4729931, lng: 30.5971813 },
      { lat: 50.4739217, lng: 30.5940914 },
      { lat: 50.4743587, lng: 30.5922031 },
      { lat: 50.4741129, lng: 30.5918598 },
      { lat: 50.4734301, lng: 30.5903149 },
      { lat: 50.472638, lng: 30.5865812 },
      { lat: 50.462531, lng: 30.5952072 },
      { lat: 50.4535422, lng: 30.6033611 },
      { lat: 50.4607826, lng: 30.6297112 },
      { lat: 50.4663009, lng: 30.6260204 },
      { lat: 50.4729112, lng: 30.6210423 },
      { lat: 50.4729112, lng: 30.6182957 },
      { lat: 50.4744953, lng: 30.617609 },
      { lat: 50.474386, lng: 30.6134033 },
      { lat: 50.4728292, lng: 30.6137037 },
      { lat: 50.4718459, lng: 30.6111288 },
      { lat: 50.4717913, lng: 30.6072235 },
    ],
  },
  {
    name: 'НИКОЛЬСКАЯ СЛОБ.',
    price: 47,
    points: [
      { lat: 50.4487055, lng: 30.5854225 },
      { lat: 50.4519846, lng: 30.5979538 },
      { lat: 50.4535422, lng: 30.6033611 },
      { lat: 50.462531, lng: 30.5952072 },
      { lat: 50.472638, lng: 30.5865812 },
      { lat: 50.4719825, lng: 30.5860662 },
      { lat: 50.470808, lng: 30.5860233 },
      { lat: 50.4703163, lng: 30.5858088 },
      { lat: 50.4691145, lng: 30.5856371 },
      { lat: 50.4673936, lng: 30.5857658 },
      { lat: 50.466738, lng: 30.5837488 },
      { lat: 50.4662463, lng: 30.5841351 },
      { lat: 50.4656453, lng: 30.5843496 },
      { lat: 50.4649624, lng: 30.5844784 },
      { lat: 50.4643614, lng: 30.5847359 },
      { lat: 50.463815, lng: 30.5849504 },
      { lat: 50.4632959, lng: 30.5852079 },
      { lat: 50.4625037, lng: 30.5825043 },
      { lat: 50.4643614, lng: 30.5810452 },
      { lat: 50.4630774, lng: 30.5770969 },
      { lat: 50.4594985, lng: 30.5798435 },
      { lat: 50.4582417, lng: 30.5805302 },
      { lat: 50.4487055, lng: 30.5854225 },
    ],
  },
  {
    name: 'РУСАНОВСКИЕ САДЫ',
    price: 48,
    points: [
      { lat: 50.4744953, lng: 30.554266 },
      { lat: 50.4716547, lng: 30.555768 },
      { lat: 50.4696881, lng: 30.5575705 },
      { lat: 50.4685955, lng: 30.5592871 },
      { lat: 50.4692511, lng: 30.5610037 },
      { lat: 50.4696335, lng: 30.5636644 },
      { lat: 50.4694149, lng: 30.566411 },
      { lat: 50.4683496, lng: 30.5694151 },
      { lat: 50.4673116, lng: 30.5725479 },
      { lat: 50.4630774, lng: 30.5770969 },
      { lat: 50.4643614, lng: 30.5810452 },
      { lat: 50.4625037, lng: 30.5825043 },
      { lat: 50.4632959, lng: 30.5852079 },
      { lat: 50.463815, lng: 30.5849504 },
      { lat: 50.4643614, lng: 30.5847359 },
      { lat: 50.4649624, lng: 30.5844784 },
      { lat: 50.4656453, lng: 30.5843496 },
      { lat: 50.4662463, lng: 30.5841351 },
      { lat: 50.466738, lng: 30.5837488 },
      { lat: 50.4673936, lng: 30.5857658 },
      { lat: 50.4691145, lng: 30.5856371 },
      { lat: 50.4703163, lng: 30.5858088 },
      { lat: 50.470808, lng: 30.5860233 },
      { lat: 50.4719825, lng: 30.5860662 },
      { lat: 50.472638, lng: 30.5865812 },
      { lat: 50.4744134, lng: 30.5850792 },
      { lat: 50.4784281, lng: 30.5816031 },
      { lat: 50.4800394, lng: 30.5800581 },
      { lat: 50.4812682, lng: 30.5783415 },
      { lat: 50.4822513, lng: 30.5761957 },
      { lat: 50.4828316, lng: 30.57464 },
      { lat: 50.4832344, lng: 30.5730414 },
      { lat: 50.4834529, lng: 30.57199 },
      { lat: 50.4835894, lng: 30.5704021 },
      { lat: 50.483644, lng: 30.5672693 },
      { lat: 50.483644, lng: 30.5556822 },
      { lat: 50.4810498, lng: 30.5545235 },
      { lat: 50.4773084, lng: 30.5540514 },
      { lat: 50.4744953, lng: 30.554266 },
    ],
  },
  {
    name: 'ВОСКРЕСЕНСКИЕ САДЫ',
    price: 49,
    points: [
      { lat: 50.4851732, lng: 30.5574417 },
      { lat: 50.483644, lng: 30.5556822 },
      { lat: 50.483644, lng: 30.5668831 },
      { lat: 50.4835894, lng: 30.5704021 },
      { lat: 50.4834529, lng: 30.57199 },
      { lat: 50.4841082, lng: 30.5720758 },
      { lat: 50.4844632, lng: 30.575037 },
      { lat: 50.4891597, lng: 30.5749083 },
      { lat: 50.4897604, lng: 30.5747795 },
      { lat: 50.494156, lng: 30.5739212 },
      { lat: 50.4955962, lng: 30.5740284 },
      { lat: 50.4956235, lng: 30.5708099 },
      { lat: 50.4956576, lng: 30.5682457 },
      { lat: 50.4895966, lng: 30.5667543 },
      { lat: 50.4893235, lng: 30.564909 },
      { lat: 50.4871392, lng: 30.5609608 },
      { lat: 50.4851732, lng: 30.5574417 },
    ],
  },
  {
    name: 'ЧЕРЕМШИНЫ',
    price: 50,
    points: [
      { lat: 50.4898696, lng: 30.5782127 },
      { lat: 50.4897604, lng: 30.5747795 },
      { lat: 50.4891597, lng: 30.5749083 },
      { lat: 50.4844632, lng: 30.575037 },
      { lat: 50.4841082, lng: 30.5720758 },
      { lat: 50.4834529, lng: 30.57199 },
      { lat: 50.4832344, lng: 30.5730414 },
      { lat: 50.4828316, lng: 30.57464 },
      { lat: 50.4822513, lng: 30.5761957 },
      { lat: 50.4812682, lng: 30.5783415 },
      { lat: 50.4800394, lng: 30.5800581 },
      { lat: 50.4784281, lng: 30.5816031 },
      { lat: 50.4744134, lng: 30.5850792 },
      { lat: 50.472638, lng: 30.5865812 },
      { lat: 50.4734301, lng: 30.5903149 },
      { lat: 50.4741129, lng: 30.5918598 },
      { lat: 50.4743587, lng: 30.5922031 },
      { lat: 50.474823, lng: 30.5900574 },
      { lat: 50.4751781, lng: 30.5897141 },
      { lat: 50.4761886, lng: 30.5893707 },
      { lat: 50.4767895, lng: 30.5895424 },
      { lat: 50.4778546, lng: 30.5897141 },
      { lat: 50.4784827, lng: 30.5896711 },
      { lat: 50.4793566, lng: 30.589242 },
      { lat: 50.4822786, lng: 30.5833626 },
      { lat: 50.4840263, lng: 30.5825472 },
      { lat: 50.4865931, lng: 30.5822897 },
      { lat: 50.4874395, lng: 30.5820751 },
      { lat: 50.4876307, lng: 30.5810452 },
      { lat: 50.4878218, lng: 30.5800581 },
      { lat: 50.4880129, lng: 30.579586 },
      { lat: 50.4886136, lng: 30.5796289 },
      { lat: 50.4896785, lng: 30.5800581 },
      { lat: 50.4898696, lng: 30.5782127 },
    ],
  },
  {
    name: 'ВОСКРЕСЕНКА',
    price: 51,
    points: [
      { lat: 50.4913166, lng: 30.6094337 },
      { lat: 50.4937738, lng: 30.6082964 },
      { lat: 50.4946474, lng: 30.6080389 },
      { lat: 50.4951798, lng: 30.6078458 },
      { lat: 50.4953027, lng: 30.6020308 },
      { lat: 50.4953573, lng: 30.5894995 },
      { lat: 50.4955484, lng: 30.5789423 },
      { lat: 50.4955962, lng: 30.5740284 },
      { lat: 50.494156, lng: 30.5739212 },
      { lat: 50.4897604, lng: 30.5747795 },
      { lat: 50.4898696, lng: 30.5782127 },
      { lat: 50.4896785, lng: 30.5800581 },
      { lat: 50.4886136, lng: 30.5796289 },
      { lat: 50.4880129, lng: 30.579586 },
      { lat: 50.4878218, lng: 30.5800581 },
      { lat: 50.4876307, lng: 30.5810452 },
      { lat: 50.4874395, lng: 30.5820751 },
      { lat: 50.4865931, lng: 30.5822897 },
      { lat: 50.4840263, lng: 30.5825472 },
      { lat: 50.4822786, lng: 30.5833626 },
      { lat: 50.4793566, lng: 30.589242 },
      { lat: 50.4784827, lng: 30.5896711 },
      { lat: 50.4778546, lng: 30.5897141 },
      { lat: 50.4767895, lng: 30.5895424 },
      { lat: 50.4761886, lng: 30.5893707 },
      { lat: 50.4751781, lng: 30.5897141 },
      { lat: 50.474823, lng: 30.5900574 },
      { lat: 50.4743587, lng: 30.5922031 },
      { lat: 50.4739217, lng: 30.5940914 },
      { lat: 50.4729931, lng: 30.5971813 },
      { lat: 50.4719006, lng: 30.6021595 },
      { lat: 50.4717913, lng: 30.6072235 },
      { lat: 50.4718459, lng: 30.6111288 },
      { lat: 50.4728292, lng: 30.6137037 },
      { lat: 50.474386, lng: 30.6134033 },
      { lat: 50.4857466, lng: 30.6113434 },
      { lat: 50.4862381, lng: 30.6121159 },
      { lat: 50.4867125, lng: 30.6117564 },
      { lat: 50.4884566, lng: 30.610764 },
      { lat: 50.4895692, lng: 30.6101203 },
      { lat: 50.4913166, lng: 30.6094337 },
    ],
  },
  {
    name: 'ТРОЕЩ. БАЛЬЗАКА НАЧ.',
    price: 52,
    points: [
      { lat: 50.4985649, lng: 30.5685782 },
      { lat: 50.4956576, lng: 30.5682457 },
      { lat: 50.4956235, lng: 30.5708099 },
      { lat: 50.4955962, lng: 30.5740284 },
      { lat: 50.4955484, lng: 30.5789423 },
      { lat: 50.497937, lng: 30.5796504 },
      { lat: 50.4989744, lng: 30.5819035 },
      { lat: 50.4999981, lng: 30.5840278 },
      { lat: 50.503724, lng: 30.5813456 },
      { lat: 50.5042699, lng: 30.5802941 },
      { lat: 50.5040925, lng: 30.5793929 },
      { lat: 50.5042563, lng: 30.5776334 },
      { lat: 50.5039424, lng: 30.5681276 },
      { lat: 50.5011172, lng: 30.5685139 },
      { lat: 50.4985649, lng: 30.5685782 },
    ],
  },
  {
    name: 'ТРОЕЩИНА 1',
    price: 53,
    points: [
      { lat: 50.520303, lng: 30.5959797 },
      { lat: 50.5173015, lng: 30.5929756 },
      { lat: 50.5163737, lng: 30.5925465 },
      { lat: 50.5153913, lng: 30.5926323 },
      { lat: 50.5116254, lng: 30.5959797 },
      { lat: 50.5095512, lng: 30.5897999 },
      { lat: 50.5085687, lng: 30.5870533 },
      { lat: 50.5078591, lng: 30.5855083 },
      { lat: 50.5063307, lng: 30.5831051 },
      { lat: 50.5050751, lng: 30.5812168 },
      { lat: 50.5042699, lng: 30.5802941 },
      { lat: 50.503724, lng: 30.5813456 },
      { lat: 50.4999981, lng: 30.5840278 },
      { lat: 50.4989744, lng: 30.5819035 },
      { lat: 50.497937, lng: 30.5796504 },
      { lat: 50.4955484, lng: 30.5789423 },
      { lat: 50.4953573, lng: 30.5894995 },
      { lat: 50.4953027, lng: 30.6020308 },
      { lat: 50.4951798, lng: 30.6078458 },
      { lat: 50.4967562, lng: 30.60807 },
      { lat: 50.497681, lng: 30.6101563 },
      { lat: 50.5091691, lng: 30.6223727 },
      { lat: 50.509715, lng: 30.6210423 },
      { lat: 50.5128261, lng: 30.6134892 },
      { lat: 50.5164829, lng: 30.6050777 },
      { lat: 50.520303, lng: 30.5959797 },
    ],
  },
  {
    name: 'ПУХОВСКАЯ',
    price: 54,
    points: [
      { lat: 50.5237955, lng: 30.6373501 },
      { lat: 50.5117891, lng: 30.627737 },
      { lat: 50.5082412, lng: 30.624733 },
      { lat: 50.5051297, lng: 30.6317711 },
      { lat: 50.5053481, lng: 30.6377792 },
      { lat: 50.5072041, lng: 30.646019 },
      { lat: 50.5100971, lng: 30.6506538 },
      { lat: 50.5149547, lng: 30.6564903 },
      { lat: 50.5176835, lng: 30.6600094 },
      { lat: 50.5181747, lng: 30.6621552 },
      { lat: 50.5175744, lng: 30.6676483 },
      { lat: 50.5180656, lng: 30.6763172 },
      { lat: 50.5202485, lng: 30.6828403 },
      { lat: 50.5231952, lng: 30.6834412 },
      { lat: 50.5278879, lng: 30.682497 },
      { lat: 50.533453, lng: 30.6800938 },
      { lat: 50.5366717, lng: 30.6725407 },
      { lat: 50.5381446, lng: 30.6668758 },
      { lat: 50.5383082, lng: 30.660696 },
      { lat: 50.5378173, lng: 30.657692 },
      { lat: 50.536999, lng: 30.6564045 },
      { lat: 50.5341622, lng: 30.6557178 },
      { lat: 50.53258, lng: 30.6546021 },
      { lat: 50.5323073, lng: 30.6329727 },
      { lat: 50.5237955, lng: 30.6373501 },
    ],
  },
  {
    name: 'ТРОЕЩИНА 2',
    price: 55,
    points: [
      { lat: 50.5342167, lng: 30.6146908 },
      { lat: 50.5345441, lng: 30.6100559 },
      { lat: 50.5297157, lng: 30.6093264 },
      { lat: 50.5219402, lng: 30.6083822 },
      { lat: 50.5197573, lng: 30.6073952 },
      { lat: 50.5164829, lng: 30.6050777 },
      { lat: 50.5128261, lng: 30.6134892 },
      { lat: 50.509715, lng: 30.6210423 },
      { lat: 50.5091691, lng: 30.6223727 },
      { lat: 50.5082412, lng: 30.624733 },
      { lat: 50.5117891, lng: 30.627737 },
      { lat: 50.5237955, lng: 30.6373501 },
      { lat: 50.5323073, lng: 30.6329727 },
      { lat: 50.5342167, lng: 30.6146908 },
    ],
  },
  {
    name: 'ТРОЕЩИНА СЕЛО',
    price: 56,
    points: [
      { lat: 50.5060577, lng: 30.5674839 },
      { lat: 50.5039424, lng: 30.5681276 },
      { lat: 50.5042563, lng: 30.5776334 },
      { lat: 50.5040925, lng: 30.5793929 },
      { lat: 50.5042699, lng: 30.5802941 },
      { lat: 50.5050751, lng: 30.5812168 },
      { lat: 50.5063307, lng: 30.5831051 },
      { lat: 50.5078591, lng: 30.5855083 },
      { lat: 50.5085687, lng: 30.5870533 },
      { lat: 50.5095512, lng: 30.5897999 },
      { lat: 50.5116254, lng: 30.5959797 },
      { lat: 50.5153913, lng: 30.5926323 },
      { lat: 50.5163737, lng: 30.5925465 },
      { lat: 50.5173015, lng: 30.5929756 },
      { lat: 50.520303, lng: 30.5959797 },
      { lat: 50.5242047, lng: 30.5999708 },
      { lat: 50.5295247, lng: 30.5906582 },
      { lat: 50.5297566, lng: 30.5869674 },
      { lat: 50.5301931, lng: 30.5870533 },
      { lat: 50.5306705, lng: 30.5775261 },
      { lat: 50.5335075, lng: 30.5769253 },
      { lat: 50.5335075, lng: 30.5681705 },
      { lat: 50.5261964, lng: 30.5685997 },
      { lat: 50.5235227, lng: 30.5669689 },
      { lat: 50.521067, lng: 30.5667973 },
      { lat: 50.5192662, lng: 30.5661964 },
      { lat: 50.5149547, lng: 30.564394 },
      { lat: 50.5129899, lng: 30.5639648 },
      { lat: 50.5103154, lng: 30.5652523 },
      { lat: 50.5079137, lng: 30.5662823 },
      { lat: 50.5060577, lng: 30.5674839 },
    ],
  },
  {
    name: 'КП ЗАЗИМЬЕ',
    price: 57,
    points: [
      { lat: 50.5374354, lng: 30.5954218 },
      { lat: 50.53794, lng: 30.5924392 },
      { lat: 50.5332484, lng: 30.5890489 },
      { lat: 50.5335075, lng: 30.5769253 },
      { lat: 50.5306705, lng: 30.5775261 },
      { lat: 50.5301931, lng: 30.5870533 },
      { lat: 50.5297566, lng: 30.5869674 },
      { lat: 50.5295247, lng: 30.5906582 },
      { lat: 50.5347213, lng: 30.5929756 },
      { lat: 50.5374354, lng: 30.5954218 },
    ],
  },
  {
    name: 'СКАЙМОЛ',
    price: 58,
    points: [
      { lat: 50.4918217, lng: 30.5392456 },
      { lat: 50.4932142, lng: 30.5441807 },
      { lat: 50.4938967, lng: 30.5483007 },
      { lat: 50.4921903, lng: 30.552206 },
      { lat: 50.490443, lng: 30.5552959 },
      { lat: 50.4895693, lng: 30.5587292 },
      { lat: 50.4893235, lng: 30.564909 },
      { lat: 50.4895966, lng: 30.5667543 },
      { lat: 50.4956576, lng: 30.5682457 },
      { lat: 50.4985649, lng: 30.5685782 },
      { lat: 50.498797, lng: 30.5657673 },
      { lat: 50.5004894, lng: 30.5646515 },
      { lat: 50.5026185, lng: 30.5634499 },
      { lat: 50.5033428, lng: 30.5340311 },
      { lat: 50.5007078, lng: 30.5342674 },
      { lat: 50.4967768, lng: 30.5350399 },
      { lat: 50.4948112, lng: 30.5363274 },
      { lat: 50.4929002, lng: 30.5383873 },
      { lat: 50.4918217, lng: 30.5392456 },
    ],
  },
  {
    name: 'ТРУХАНОВ ОСТРОВ',
    price: 59,
    points: [
      { lat: 50.4867296, lng: 30.5452538 },
      { lat: 50.4837259, lng: 30.5460262 },
      { lat: 50.4802851, lng: 30.5453396 },
      { lat: 50.4765164, lng: 30.5426788 },
      { lat: 50.4731843, lng: 30.5386448 },
      { lat: 50.4721464, lng: 30.5365849 },
      { lat: 50.469852, lng: 30.5346107 },
      { lat: 50.4670658, lng: 30.5314994 },
      { lat: 50.4635145, lng: 30.531714 },
      { lat: 50.4586652, lng: 30.5340529 },
      { lat: 50.4571762, lng: 30.537014 },
      { lat: 50.4561379, lng: 30.5395889 },
      { lat: 50.4554275, lng: 30.5425072 },
      { lat: 50.455851, lng: 30.5445671 },
      { lat: 50.4557554, lng: 30.5451679 },
      { lat: 50.4540613, lng: 30.5461979 },
      { lat: 50.4531596, lng: 30.547346 },
      { lat: 50.4526404, lng: 30.5496098 },
      { lat: 50.4514381, lng: 30.5529785 },
      { lat: 50.4476124, lng: 30.5599308 },
      { lat: 50.4471752, lng: 30.5620766 },
      { lat: 50.4473391, lng: 30.5668831 },
      { lat: 50.4481589, lng: 30.569973 },
      { lat: 50.4497439, lng: 30.5711746 },
      { lat: 50.4512742, lng: 30.5709171 },
      { lat: 50.4547171, lng: 30.568428 },
      { lat: 50.4585422, lng: 30.5665398 },
      { lat: 50.4623125, lng: 30.565424 },
      { lat: 50.4672297, lng: 30.5597591 },
      { lat: 50.4688686, lng: 30.5564117 },
      { lat: 50.4719279, lng: 30.5530643 },
      { lat: 50.4755878, lng: 30.5522919 },
      { lat: 50.4806675, lng: 30.5511761 },
      { lat: 50.4860743, lng: 30.5515194 },
      { lat: 50.48946, lng: 30.5497169 },
      { lat: 50.4921903, lng: 30.552206 },
      { lat: 50.4938967, lng: 30.5483007 },
      { lat: 50.4932142, lng: 30.5441807 },
      { lat: 50.4918217, lng: 30.5392456 },
      { lat: 50.4867296, lng: 30.5452538 },
    ],
  },
  {
    name: 'ГИДРОПАРК',
    price: 60,
    points: [
      { lat: 50.4449377, lng: 30.5665719 },
      { lat: 50.4384567, lng: 30.57199 },
      { lat: 50.435204, lng: 30.576582 },
      { lat: 50.4330171, lng: 30.5795002 },
      { lat: 50.4305568, lng: 30.5828476 },
      { lat: 50.4288618, lng: 30.5850792 },
      { lat: 50.429682, lng: 30.5880833 },
      { lat: 50.4315956, lng: 30.5903149 },
      { lat: 50.4487055, lng: 30.5854225 },
      { lat: 50.4582417, lng: 30.5805302 },
      { lat: 50.4594985, lng: 30.5798435 },
      { lat: 50.4630774, lng: 30.5770969 },
      { lat: 50.4673116, lng: 30.5725479 },
      { lat: 50.4683496, lng: 30.5694151 },
      { lat: 50.4694149, lng: 30.566411 },
      { lat: 50.4696335, lng: 30.5636644 },
      { lat: 50.4692511, lng: 30.5610037 },
      { lat: 50.4685955, lng: 30.5592871 },
      { lat: 50.4672297, lng: 30.5597591 },
      { lat: 50.4623125, lng: 30.565424 },
      { lat: 50.4585422, lng: 30.5665398 },
      { lat: 50.4547171, lng: 30.568428 },
      { lat: 50.4512742, lng: 30.5709171 },
      { lat: 50.4497439, lng: 30.5711746 },
      { lat: 50.4481589, lng: 30.569973 },
      { lat: 50.4473391, lng: 30.5668831 },
      { lat: 50.4449377, lng: 30.5665719 },
    ],
  },
  {
    name: 'ОБОЛОНЬ 2',
    price: 61,
    points: [
      { lat: 50.5287472, lng: 30.4842388 },
      { lat: 50.5175608, lng: 30.483799 },
      { lat: 50.5175402, lng: 30.4893458 },
      { lat: 50.5122462, lng: 30.4940665 },
      { lat: 50.5121712, lng: 30.4987335 },
      { lat: 50.5120962, lng: 30.5065441 },
      { lat: 50.5130649, lng: 30.5066943 },
      { lat: 50.5135493, lng: 30.5073595 },
      { lat: 50.513232, lng: 30.507896 },
      { lat: 50.5129148, lng: 30.5085397 },
      { lat: 50.5128807, lng: 30.5139256 },
      { lat: 50.5209033, lng: 30.5244827 },
      { lat: 50.526742, lng: 30.5216503 },
      { lat: 50.5292246, lng: 30.5198907 },
      { lat: 50.5287472, lng: 30.4842388 },
    ],
  },
  {
    name: 'ОБОЛОНЬ 1',
    price: 62,
    points: [
      { lat: 50.5070403, lng: 30.4834557 },
      { lat: 50.5028369, lng: 30.4831123 },
      { lat: 50.5000321, lng: 30.4830479 },
      { lat: 50.4999435, lng: 30.4866743 },
      { lat: 50.4999845, lng: 30.4883266 },
      { lat: 50.4999642, lng: 30.4980774 },
      { lat: 50.5014823, lng: 30.4981683 },
      { lat: 50.5013543, lng: 30.5038788 },
      { lat: 50.5057802, lng: 30.5082793 },
      { lat: 50.5057687, lng: 30.5097612 },
      { lat: 50.5042754, lng: 30.5112531 },
      { lat: 50.5057396, lng: 30.5152178 },
      { lat: 50.5128807, lng: 30.5139256 },
      { lat: 50.5129148, lng: 30.5085397 },
      { lat: 50.5135493, lng: 30.5073595 },
      { lat: 50.5130649, lng: 30.5066943 },
      { lat: 50.5120962, lng: 30.5065441 },
      { lat: 50.5121712, lng: 30.4987335 },
      { lat: 50.5122462, lng: 30.4940665 },
      { lat: 50.5175402, lng: 30.4893458 },
      { lat: 50.5175608, lng: 30.483799 },
      { lat: 50.5070403, lng: 30.4834557 },
    ],
  },
  {
    name: 'БОГАТЫРСКАЯ',
    price: 63,
    points: [
      { lat: 50.528679, lng: 30.4702592 },
      { lat: 50.526251, lng: 30.4703236 },
      { lat: 50.526251, lng: 30.4735851 },
      { lat: 50.5200848, lng: 30.4734993 },
      { lat: 50.5188296, lng: 30.4735851 },
      { lat: 50.5177927, lng: 30.4738426 },
      { lat: 50.5125532, lng: 30.4773617 },
      { lat: 50.5071495, lng: 30.4812241 },
      { lat: 50.5070403, lng: 30.4834557 },
      { lat: 50.5175608, lng: 30.483799 },
      { lat: 50.5287472, lng: 30.4842388 },
      { lat: 50.5287609, lng: 30.4813957 },
      { lat: 50.528679, lng: 30.4702592 },
    ],
  },
  {
    name: 'ПЕТРОВКА',
    price: 64,
    points: [
      { lat: 50.4909207, lng: 30.4830909 },
      { lat: 50.4905522, lng: 30.4819965 },
      { lat: 50.4900061, lng: 30.4781342 },
      { lat: 50.4896239, lng: 30.4745293 },
      { lat: 50.4892962, lng: 30.472126 },
      { lat: 50.4870436, lng: 30.4736496 },
      { lat: 50.4874804, lng: 30.4751087 },
      { lat: 50.4881904, lng: 30.4791427 },
      { lat: 50.4886272, lng: 30.4834343 },
      { lat: 50.4860606, lng: 30.4897857 },
      { lat: 50.4857329, lng: 30.4915882 },
      { lat: 50.4853506, lng: 30.4949356 },
      { lat: 50.4851322, lng: 30.497253 },
      { lat: 50.484586, lng: 30.5011154 },
      { lat: 50.4832821, lng: 30.5130779 },
      { lat: 50.4841244, lng: 30.5121351 },
      { lat: 50.4877966, lng: 30.506361 },
      { lat: 50.4891067, lng: 30.5036553 },
      { lat: 50.4891733, lng: 30.5013729 },
      { lat: 50.4999845, lng: 30.4883266 },
      { lat: 50.4999435, lng: 30.4866743 },
      { lat: 50.5000321, lng: 30.4830479 },
      { lat: 50.4909207, lng: 30.4830909 },
    ],
  },
  {
    name: 'ЭЛЕКТРИКОВ',
    price: 65,
    points: [
      { lat: 50.4812136, lng: 30.4974461 },
      { lat: 50.480053, lng: 30.4962444 },
      { lat: 50.4781346, lng: 30.497446 },
      { lat: 50.4776361, lng: 30.498004 },
      { lat: 50.4768714, lng: 30.5009651 },
      { lat: 50.4776361, lng: 30.5031109 },
      { lat: 50.4807221, lng: 30.5031967 },
      { lat: 50.4806948, lng: 30.5083895 },
      { lat: 50.4785373, lng: 30.5101919 },
      { lat: 50.4776907, lng: 30.5154705 },
      { lat: 50.4768168, lng: 30.5203629 },
      { lat: 50.4742768, lng: 30.5245686 },
      { lat: 50.4727473, lng: 30.526886 },
      { lat: 50.470849, lng: 30.5265802 },
      { lat: 50.4701319, lng: 30.5275083 },
      { lat: 50.4696642, lng: 30.5284149 },
      { lat: 50.4699886, lng: 30.5289459 },
      { lat: 50.4734028, lng: 30.5331516 },
      { lat: 50.4753147, lng: 30.5352545 },
      { lat: 50.4787831, lng: 30.5374432 },
      { lat: 50.4801486, lng: 30.538044 },
      { lat: 50.4821694, lng: 30.5384302 },
      { lat: 50.4836167, lng: 30.5384302 },
      { lat: 50.4836303, lng: 30.5312633 },
      { lat: 50.4831661, lng: 30.5272078 },
      { lat: 50.4832821, lng: 30.5130779 },
      { lat: 50.4845997, lng: 30.5011368 },
      { lat: 50.4851459, lng: 30.4972744 },
      { lat: 50.4812136, lng: 30.4974461 },
    ],
  },
  {
    name: 'МИНСКИЙ',
    price: 66,
    points: [
      { lat: 50.5294156, lng: 30.4601097 },
      { lat: 50.5300703, lng: 30.4572773 },
      { lat: 50.5323618, lng: 30.4571056 },
      { lat: 50.53258, lng: 30.4542732 },
      { lat: 50.5248323, lng: 30.4504108 },
      { lat: 50.522595, lng: 30.450325 },
      { lat: 50.5203576, lng: 30.449295 },
      { lat: 50.515173, lng: 30.4499817 },
      { lat: 50.508405, lng: 30.4507649 },
      { lat: 50.5086165, lng: 30.4538548 },
      { lat: 50.50906, lng: 30.4577065 },
      { lat: 50.5093875, lng: 30.4608822 },
      { lat: 50.5095171, lng: 30.4624271 },
      { lat: 50.5104382, lng: 30.4618693 },
      { lat: 50.5124441, lng: 30.4648304 },
      { lat: 50.5120074, lng: 30.4716969 },
      { lat: 50.5125532, lng: 30.4773617 },
      { lat: 50.5177927, lng: 30.4738426 },
      { lat: 50.5188296, lng: 30.4735851 },
      { lat: 50.5200848, lng: 30.4734993 },
      { lat: 50.526251, lng: 30.4735851 },
      { lat: 50.526251, lng: 30.4703236 },
      { lat: 50.528679, lng: 30.4702592 },
      { lat: 50.5294156, lng: 30.4601097 },
    ],
  },
  {
    name: 'КАРАВАН',
    price: 67,
    points: [
      { lat: 50.5004348, lng: 30.45187 },
      { lat: 50.499807, lng: 30.4525137 },
      { lat: 50.4980599, lng: 30.4547024 },
      { lat: 50.4965857, lng: 30.4566336 },
      { lat: 50.4990699, lng: 30.464015 },
      { lat: 50.4995613, lng: 30.4656029 },
      { lat: 50.4997251, lng: 30.4682636 },
      { lat: 50.5001345, lng: 30.4735422 },
      { lat: 50.5000321, lng: 30.4830479 },
      { lat: 50.5028369, lng: 30.4831123 },
      { lat: 50.5070403, lng: 30.4834557 },
      { lat: 50.5071495, lng: 30.4812241 },
      { lat: 50.5125532, lng: 30.4773617 },
      { lat: 50.5120074, lng: 30.4716969 },
      { lat: 50.5124441, lng: 30.4648304 },
      { lat: 50.5104382, lng: 30.4618693 },
      { lat: 50.5095171, lng: 30.4624271 },
      { lat: 50.5093875, lng: 30.4608822 },
      { lat: 50.50906, lng: 30.457449 },
      { lat: 50.5086165, lng: 30.4538548 },
      { lat: 50.508405, lng: 30.4507649 },
      { lat: 50.5058121, lng: 30.4509687 },
      { lat: 50.5004348, lng: 30.45187 },
    ],
  },
  {
    name: 'КУРЕНЕВКА СКЛЯРЕНКО',
    price: 68,
    points: [
      { lat: 50.4952686, lng: 30.4593909 },
      { lat: 50.4948112, lng: 30.4601311 },
      { lat: 50.4939103, lng: 30.4613113 },
      { lat: 50.4929548, lng: 30.4626417 },
      { lat: 50.4910982, lng: 30.46453 },
      { lat: 50.4904293, lng: 30.4654313 },
      { lat: 50.4895966, lng: 30.4668903 },
      { lat: 50.4873576, lng: 30.4705381 },
      { lat: 50.4862654, lng: 30.4725981 },
      { lat: 50.4870573, lng: 30.473671 },
      { lat: 50.4892962, lng: 30.472126 },
      { lat: 50.4896239, lng: 30.4745293 },
      { lat: 50.4900061, lng: 30.4781342 },
      { lat: 50.4905522, lng: 30.4819965 },
      { lat: 50.4909344, lng: 30.4831123 },
      { lat: 50.5000321, lng: 30.4830479 },
      { lat: 50.5001345, lng: 30.4735422 },
      { lat: 50.4997251, lng: 30.4682636 },
      { lat: 50.4995613, lng: 30.4656029 },
      { lat: 50.4990699, lng: 30.464015 },
      { lat: 50.4965857, lng: 30.4566336 },
      { lat: 50.4952686, lng: 30.4593909 },
    ],
  },
  {
    name: 'ШЕВЧЕНКО ПЛ.',
    price: 69,
    points: [
      { lat: 50.5266329, lng: 30.446763 },
      { lat: 50.525678, lng: 30.4356909 },
      { lat: 50.5250914, lng: 30.4339311 },
      { lat: 50.5242593, lng: 30.4319676 },
      { lat: 50.5228132, lng: 30.4300472 },
      { lat: 50.519256, lng: 30.426727 },
      { lat: 50.5171378, lng: 30.4301977 },
      { lat: 50.5145181, lng: 30.4346609 },
      { lat: 50.5165647, lng: 30.4370642 },
      { lat: 50.5158007, lng: 30.4388666 },
      { lat: 50.5146273, lng: 30.4418278 },
      { lat: 50.5146819, lng: 30.4427398 },
      { lat: 50.5142998, lng: 30.444038 },
      { lat: 50.5149547, lng: 30.4448318 },
      { lat: 50.515173, lng: 30.4499817 },
      { lat: 50.5203576, lng: 30.449295 },
      { lat: 50.522595, lng: 30.450325 },
      { lat: 50.5248323, lng: 30.4504108 },
      { lat: 50.5266329, lng: 30.446763 },
    ],
  },
  {
    name: 'ВЕТРЯНЫЕ ГОРЫ',
    price: 70,
    points: [
      { lat: 50.5105064, lng: 30.4287815 },
      { lat: 50.508596, lng: 30.4319143 },
      { lat: 50.5055664, lng: 30.4372787 },
      { lat: 50.5046521, lng: 30.4385876 },
      { lat: 50.5043382, lng: 30.4385662 },
      { lat: 50.5050751, lng: 30.442729 },
      { lat: 50.5055391, lng: 30.4465485 },
      { lat: 50.5056756, lng: 30.4491234 },
      { lat: 50.5058121, lng: 30.4509687 },
      { lat: 50.508405, lng: 30.4507649 },
      { lat: 50.515173, lng: 30.4499817 },
      { lat: 50.5149547, lng: 30.4448318 },
      { lat: 50.5142998, lng: 30.444038 },
      { lat: 50.5146819, lng: 30.4427398 },
      { lat: 50.5146273, lng: 30.4418278 },
      { lat: 50.5158007, lng: 30.4388666 },
      { lat: 50.5165647, lng: 30.4370642 },
      { lat: 50.5145181, lng: 30.4346609 },
      { lat: 50.5125123, lng: 30.4318337 },
      { lat: 50.5105064, lng: 30.4287815 },
    ],
  },
  {
    name: 'ВИНОГРАДАРЬ',
    price: 71,
    points: [
      { lat: 50.5138086, lng: 30.3992558 },
      { lat: 50.5189865, lng: 30.3980114 },
      { lat: 50.5188159, lng: 30.3944063 },
      { lat: 50.5136448, lng: 30.3954792 },
      { lat: 50.5112979, lng: 30.3957367 },
      { lat: 50.5074224, lng: 30.3934193 },
      { lat: 50.5070198, lng: 30.3943848 },
      { lat: 50.5026731, lng: 30.3905332 },
      { lat: 50.4996159, lng: 30.3999424 },
      { lat: 50.4987424, lng: 30.4105854 },
      { lat: 50.4966949, lng: 30.4189539 },
      { lat: 50.4963537, lng: 30.4251445 },
      { lat: 50.5039287, lng: 30.4276657 },
      { lat: 50.5038741, lng: 30.4284382 },
      { lat: 50.503492, lng: 30.4323864 },
      { lat: 50.5034374, lng: 30.4338455 },
      { lat: 50.5038741, lng: 30.4364204 },
      { lat: 50.5043382, lng: 30.4385662 },
      { lat: 50.5046521, lng: 30.4385876 },
      { lat: 50.5055664, lng: 30.4372787 },
      { lat: 50.508596, lng: 30.4319143 },
      { lat: 50.5105064, lng: 30.4287815 },
      { lat: 50.5125123, lng: 30.4318337 },
      { lat: 50.5145181, lng: 30.4346609 },
      { lat: 50.5171378, lng: 30.4301977 },
      { lat: 50.5191025, lng: 30.4269791 },
      { lat: 50.5184476, lng: 30.4226875 },
      { lat: 50.5161008, lng: 30.4192543 },
      { lat: 50.5166466, lng: 30.4183102 },
      { lat: 50.5138086, lng: 30.3992558 },
    ],
  },
  {
    name: 'УЖВИЙ',
    price: 72,
    points: [
      { lat: 50.4979097, lng: 30.4296183 },
      { lat: 50.4988788, lng: 30.4425144 },
      { lat: 50.4989334, lng: 30.4443169 },
      { lat: 50.4990426, lng: 30.4449821 },
      { lat: 50.4993156, lng: 30.44554 },
      { lat: 50.4994521, lng: 30.4460979 },
      { lat: 50.4998206, lng: 30.4463553 },
      { lat: 50.4997933, lng: 30.4466772 },
      { lat: 50.4995749, lng: 30.4471493 },
      { lat: 50.499609, lng: 30.4488177 },
      { lat: 50.4993839, lng: 30.4496169 },
      { lat: 50.4994931, lng: 30.4510331 },
      { lat: 50.499807, lng: 30.4525137 },
      { lat: 50.5004348, lng: 30.45187 },
      { lat: 50.5058121, lng: 30.4509687 },
      { lat: 50.5056756, lng: 30.4491234 },
      { lat: 50.5055391, lng: 30.4465485 },
      { lat: 50.5050751, lng: 30.442729 },
      { lat: 50.5043382, lng: 30.4385662 },
      { lat: 50.5038741, lng: 30.4364204 },
      { lat: 50.5034374, lng: 30.4338455 },
      { lat: 50.503492, lng: 30.4323864 },
      { lat: 50.5038741, lng: 30.4284382 },
      { lat: 50.5039287, lng: 30.4276657 },
      { lat: 50.5003802, lng: 30.426432 },
      { lat: 50.4999503, lng: 30.4297847 },
      { lat: 50.4979097, lng: 30.4296183 },
    ],
  },
  {
    name: 'БЕЛИЦКАЯ',
    price: 73,
    points: [
      { lat: 50.489542, lng: 30.4226017 },
      { lat: 50.4887774, lng: 30.4279661 },
      { lat: 50.4882314, lng: 30.4315281 },
      { lat: 50.4868934, lng: 30.4334593 },
      { lat: 50.4865112, lng: 30.434618 },
      { lat: 50.4861562, lng: 30.4354334 },
      { lat: 50.4871665, lng: 30.4365063 },
      { lat: 50.4888321, lng: 30.4384804 },
      { lat: 50.489815, lng: 30.4401112 },
      { lat: 50.490989, lng: 30.4423428 },
      { lat: 50.4919719, lng: 30.4445744 },
      { lat: 50.4940741, lng: 30.4498959 },
      { lat: 50.4954938, lng: 30.4540586 },
      { lat: 50.4965857, lng: 30.4566336 },
      { lat: 50.4980599, lng: 30.4547024 },
      { lat: 50.499807, lng: 30.4525137 },
      { lat: 50.4994931, lng: 30.4510331 },
      { lat: 50.4993839, lng: 30.4496169 },
      { lat: 50.499609, lng: 30.4488177 },
      { lat: 50.4995749, lng: 30.4471493 },
      { lat: 50.4997933, lng: 30.4466772 },
      { lat: 50.4998206, lng: 30.4463553 },
      { lat: 50.4994521, lng: 30.4460979 },
      { lat: 50.4993156, lng: 30.44554 },
      { lat: 50.4990426, lng: 30.4449821 },
      { lat: 50.4989334, lng: 30.4443169 },
      { lat: 50.4988788, lng: 30.4425144 },
      { lat: 50.4979097, lng: 30.4296183 },
      { lat: 50.4999503, lng: 30.4297847 },
      { lat: 50.5003802, lng: 30.426432 },
      { lat: 50.4963537, lng: 30.4251445 },
      { lat: 50.489542, lng: 30.4226017 },
    ],
  },
  {
    name: 'НИВКИ ГРЕЧКО',
    price: 74,
    points: [
      { lat: 50.4908525, lng: 30.3999853 },
      { lat: 50.4909071, lng: 30.4058218 },
      { lat: 50.4812682, lng: 30.4056931 },
      { lat: 50.4780731, lng: 30.411787 },
      { lat: 50.4776361, lng: 30.412817 },
      { lat: 50.4774995, lng: 30.4135036 },
      { lat: 50.4773903, lng: 30.4157782 },
      { lat: 50.4772538, lng: 30.418911 },
      { lat: 50.4769533, lng: 30.4247046 },
      { lat: 50.4767622, lng: 30.4265499 },
      { lat: 50.4766256, lng: 30.4274511 },
      { lat: 50.4765983, lng: 30.4283094 },
      { lat: 50.4765983, lng: 30.4293394 },
      { lat: 50.4773084, lng: 30.4292107 },
      { lat: 50.4782915, lng: 30.4290819 },
      { lat: 50.4794932, lng: 30.4294682 },
      { lat: 50.480449, lng: 30.430069 },
      { lat: 50.4821421, lng: 30.4314852 },
      { lat: 50.4827156, lng: 30.4322577 },
      { lat: 50.4838898, lng: 30.4334164 },
      { lat: 50.4861562, lng: 30.4354334 },
      { lat: 50.4865112, lng: 30.434618 },
      { lat: 50.4868934, lng: 30.4334593 },
      { lat: 50.4882314, lng: 30.4315281 },
      { lat: 50.4887774, lng: 30.4279661 },
      { lat: 50.489542, lng: 30.4226017 },
      { lat: 50.4963537, lng: 30.4251445 },
      { lat: 50.4966949, lng: 30.4189539 },
      { lat: 50.4987424, lng: 30.4105854 },
      { lat: 50.4996159, lng: 30.3999424 },
      { lat: 50.4908525, lng: 30.3999853 },
    ],
  },
  {
    name: 'ГРЕЧКО ДАЧИ',
    price: 75,
    points: [
      { lat: 50.4879856, lng: 30.3947067 },
      { lat: 50.4873849, lng: 30.3939772 },
      { lat: 50.4872211, lng: 30.3944492 },
      { lat: 50.4865931, lng: 30.3959084 },
      { lat: 50.4836986, lng: 30.4013586 },
      { lat: 50.4812682, lng: 30.4056931 },
      { lat: 50.4909071, lng: 30.4058218 },
      { lat: 50.4908525, lng: 30.3999853 },
      { lat: 50.4900607, lng: 30.3983116 },
      { lat: 50.4879856, lng: 30.3947067 },
    ],
  },
  {
    name: 'НИВКИ',
    price: 76,
    points: [
      { lat: 50.4714909, lng: 30.4058647 },
      { lat: 50.4714909, lng: 30.4047918 },
      { lat: 50.4658638, lng: 30.4047918 },
      { lat: 50.4658365, lng: 30.4056931 },
      { lat: 50.4661097, lng: 30.4056931 },
      { lat: 50.4660824, lng: 30.4144907 },
      { lat: 50.4656726, lng: 30.4147053 },
      { lat: 50.4654541, lng: 30.4150057 },
      { lat: 50.4649897, lng: 30.4156065 },
      { lat: 50.4642248, lng: 30.4169369 },
      { lat: 50.4632413, lng: 30.4186964 },
      { lat: 50.4663829, lng: 30.42449 },
      { lat: 50.4674755, lng: 30.4265499 },
      { lat: 50.4685135, lng: 30.4277515 },
      { lat: 50.4693876, lng: 30.4286957 },
      { lat: 50.4704529, lng: 30.4297686 },
      { lat: 50.4711904, lng: 30.4303265 },
      { lat: 50.4722283, lng: 30.4307556 },
      { lat: 50.4735667, lng: 30.430541 },
      { lat: 50.4745499, lng: 30.4302835 },
      { lat: 50.4759974, lng: 30.4296398 },
      { lat: 50.4765983, lng: 30.4293394 },
      { lat: 50.4765983, lng: 30.4283094 },
      { lat: 50.4766256, lng: 30.4274511 },
      { lat: 50.4767622, lng: 30.4265499 },
      { lat: 50.4769533, lng: 30.4247046 },
      { lat: 50.4772538, lng: 30.418911 },
      { lat: 50.4773903, lng: 30.4157782 },
      { lat: 50.4774995, lng: 30.4135036 },
      { lat: 50.4776361, lng: 30.412817 },
      { lat: 50.4780731, lng: 30.411787 },
      { lat: 50.4812682, lng: 30.4056931 },
      { lat: 50.4714909, lng: 30.4058647 },
    ],
  },
  {
    name: 'М. НИВКИ',
    price: 77,
    points: [
      { lat: 50.4710811, lng: 30.3850079 },
      { lat: 50.4646892, lng: 30.384407 },
      { lat: 50.4641975, lng: 30.3850937 },
      { lat: 50.4635145, lng: 30.385952 },
      { lat: 50.4625857, lng: 30.3868103 },
      { lat: 50.461848, lng: 30.3872824 },
      { lat: 50.4610011, lng: 30.3878403 },
      { lat: 50.4598537, lng: 30.3883553 },
      { lat: 50.457504, lng: 30.3888702 },
      { lat: 50.4594438, lng: 30.415864 },
      { lat: 50.460564, lng: 30.4160357 },
      { lat: 50.4617114, lng: 30.4167652 },
      { lat: 50.4620393, lng: 30.4169798 },
      { lat: 50.4628042, lng: 30.417881 },
      { lat: 50.4632413, lng: 30.4186964 },
      { lat: 50.4642248, lng: 30.4169369 },
      { lat: 50.4649897, lng: 30.4156065 },
      { lat: 50.4654541, lng: 30.4150057 },
      { lat: 50.4656726, lng: 30.4147053 },
      { lat: 50.4660824, lng: 30.4144907 },
      { lat: 50.4661097, lng: 30.4056931 },
      { lat: 50.4658365, lng: 30.4056931 },
      { lat: 50.4658638, lng: 30.4047918 },
      { lat: 50.4714909, lng: 30.4047918 },
      { lat: 50.4714362, lng: 30.3990412 },
      { lat: 50.4708626, lng: 30.3967237 },
      { lat: 50.4708899, lng: 30.3954363 },
      { lat: 50.4710811, lng: 30.3850079 },
    ],
  },
  {
    name: 'НИВКИ ТУПОЛЕВА',
    price: 78,
    points: [
      { lat: 50.4937465, lng: 30.3896856 },
      { lat: 50.495603, lng: 30.3819609 },
      { lat: 50.4939922, lng: 30.3797722 },
      { lat: 50.493337, lng: 30.3790855 },
      { lat: 50.491344, lng: 30.3842783 },
      { lat: 50.4897058, lng: 30.3826904 },
      { lat: 50.4894054, lng: 30.3823042 },
      { lat: 50.4890505, lng: 30.380888 },
      { lat: 50.488286, lng: 30.3805876 },
      { lat: 50.4859377, lng: 30.3830767 },
      { lat: 50.483644, lng: 30.3855228 },
      { lat: 50.4746319, lng: 30.3910589 },
      { lat: 50.4708899, lng: 30.3954363 },
      { lat: 50.4708626, lng: 30.3967237 },
      { lat: 50.4714362, lng: 30.3990412 },
      { lat: 50.4714909, lng: 30.4047918 },
      { lat: 50.4714909, lng: 30.4058647 },
      { lat: 50.4812682, lng: 30.4056931 },
      { lat: 50.4836986, lng: 30.4013586 },
      { lat: 50.4865931, lng: 30.3959084 },
      { lat: 50.4872211, lng: 30.3944492 },
      { lat: 50.4873849, lng: 30.3939772 },
      { lat: 50.4879856, lng: 30.3947067 },
      { lat: 50.4882314, lng: 30.3941059 },
      { lat: 50.4937465, lng: 30.3896856 },
    ],
  },
  {
    name: 'БЕРКОВЕЦ САДЫ',
    price: 79,
    points: [
      { lat: 50.4834529, lng: 30.362134 },
      { lat: 50.4826336, lng: 30.3673697 },
      { lat: 50.4843813, lng: 30.3732491 },
      { lat: 50.4868934, lng: 30.3748369 },
      { lat: 50.488286, lng: 30.3805876 },
      { lat: 50.4890505, lng: 30.380888 },
      { lat: 50.4894054, lng: 30.3823042 },
      { lat: 50.4897058, lng: 30.3826904 },
      { lat: 50.491344, lng: 30.3842783 },
      { lat: 50.493337, lng: 30.3790855 },
      { lat: 50.4944017, lng: 30.3760815 },
      { lat: 50.4967495, lng: 30.3699875 },
      { lat: 50.4969679, lng: 30.3693867 },
      { lat: 50.4971044, lng: 30.3683567 },
      { lat: 50.4971044, lng: 30.3674984 },
      { lat: 50.4969474, lng: 30.3653955 },
      { lat: 50.4946747, lng: 30.3657389 },
      { lat: 50.493337, lng: 30.3658676 },
      { lat: 50.4921903, lng: 30.365653 },
      { lat: 50.4909276, lng: 30.3652239 },
      { lat: 50.4890505, lng: 30.3646231 },
      { lat: 50.4834529, lng: 30.362134 },
    ],
  },
  {
    name: 'СИНЕОЗЕРНАЯ ДАЧИ',
    price: 80,
    points: [
      { lat: 50.5016086, lng: 30.3721333 },
      { lat: 50.5010899, lng: 30.3712749 },
      { lat: 50.500271, lng: 30.3650522 },
      { lat: 50.4990426, lng: 30.3653097 },
      { lat: 50.4986332, lng: 30.3655243 },
      { lat: 50.4982783, lng: 30.3662968 },
      { lat: 50.4978142, lng: 30.3674555 },
      { lat: 50.4971044, lng: 30.3674984 },
      { lat: 50.4971044, lng: 30.3683567 },
      { lat: 50.4969679, lng: 30.3693867 },
      { lat: 50.4967495, lng: 30.3699875 },
      { lat: 50.4944017, lng: 30.3760815 },
      { lat: 50.493337, lng: 30.3790855 },
      { lat: 50.4939922, lng: 30.3797722 },
      { lat: 50.495603, lng: 30.3819609 },
      { lat: 50.4975139, lng: 30.3846216 },
      { lat: 50.4973228, lng: 30.3858232 },
      { lat: 50.498524, lng: 30.3867674 },
      { lat: 50.4987151, lng: 30.3861237 },
      { lat: 50.5007624, lng: 30.3886127 },
      { lat: 50.5016905, lng: 30.3893852 },
      { lat: 50.5026731, lng: 30.3905332 },
      { lat: 50.5070198, lng: 30.3943848 },
      { lat: 50.5074224, lng: 30.3934193 },
      { lat: 50.5054027, lng: 30.3918743 },
      { lat: 50.5049114, lng: 30.3886986 },
      { lat: 50.5031372, lng: 30.3786564 },
      { lat: 50.5016086, lng: 30.3721333 },
    ],
  },
  {
    name: 'АКАДЕМГОРОДОК',
    price: 81,
    points: [
      { lat: 50.4553182, lng: 30.3570271 },
      { lat: 50.4560833, lng: 30.3694725 },
      { lat: 50.457504, lng: 30.3888702 },
      { lat: 50.4598537, lng: 30.3883553 },
      { lat: 50.4610011, lng: 30.3878403 },
      { lat: 50.461848, lng: 30.3872824 },
      { lat: 50.4625857, lng: 30.3868103 },
      { lat: 50.4635145, lng: 30.385952 },
      { lat: 50.4641975, lng: 30.3850937 },
      { lat: 50.4646892, lng: 30.384407 },
      { lat: 50.4710811, lng: 30.3850079 },
      { lat: 50.4843813, lng: 30.3732491 },
      { lat: 50.4826336, lng: 30.3673697 },
      { lat: 50.4834529, lng: 30.362134 },
      { lat: 50.4802305, lng: 30.3604603 },
      { lat: 50.4782096, lng: 30.3595161 },
      { lat: 50.4767348, lng: 30.3588295 },
      { lat: 50.4737852, lng: 30.3577137 },
      { lat: 50.4709992, lng: 30.3565121 },
      { lat: 50.4701251, lng: 30.3560829 },
      { lat: 50.468377, lng: 30.3556538 },
      { lat: 50.4669565, lng: 30.3555679 },
      { lat: 50.4649897, lng: 30.3555679 },
      { lat: 50.4626403, lng: 30.3556538 },
      { lat: 50.4604547, lng: 30.3560829 },
      { lat: 50.4553182, lng: 30.3570271 },
    ],
  },
  {
    name: 'НОВОБЕЛИЧИ 1',
    price: 82,
    points: [
      { lat: 50.4787831, lng: 30.3324366 },
      { lat: 50.4638151, lng: 30.3224265 },
      { lat: 50.4622168, lng: 30.3290033 },
      { lat: 50.4635719, lng: 30.3290691 },
      { lat: 50.4648294, lng: 30.3287513 },
      { lat: 50.4649678, lng: 30.3301917 },
      { lat: 50.4654535, lng: 30.3318131 },
      { lat: 50.4656252, lng: 30.3335847 },
      { lat: 50.4663233, lng: 30.3341154 },
      { lat: 50.4669739, lng: 30.3331335 },
      { lat: 50.4690447, lng: 30.335585 },
      { lat: 50.4685742, lng: 30.3367627 },
      { lat: 50.4698019, lng: 30.3418529 },
      { lat: 50.4702989, lng: 30.346632 },
      { lat: 50.4710334, lng: 30.3464646 },
      { lat: 50.4708149, lng: 30.3490368 },
      { lat: 50.4707739, lng: 30.3522099 },
      { lat: 50.4701251, lng: 30.3560829 },
      { lat: 50.4709992, lng: 30.3565121 },
      { lat: 50.4737852, lng: 30.3577137 },
      { lat: 50.4767348, lng: 30.3588295 },
      { lat: 50.4782096, lng: 30.3595161 },
      { lat: 50.4802305, lng: 30.3604603 },
      { lat: 50.4829886, lng: 30.344367 },
      { lat: 50.4787831, lng: 30.3324366 },
    ],
  },
  {
    name: 'БЕЛИЧИ 1',
    price: 83,
    points: [
      { lat: 50.4577772, lng: 30.3376293 },
      { lat: 50.4548264, lng: 30.3415775 },
      { lat: 50.4544438, lng: 30.3416634 },
      { lat: 50.4553182, lng: 30.3570271 },
      { lat: 50.4604547, lng: 30.3560829 },
      { lat: 50.4626403, lng: 30.3556538 },
      { lat: 50.4649897, lng: 30.3555679 },
      { lat: 50.4647165, lng: 30.3521347 },
      { lat: 50.4665741, lng: 30.3521347 },
      { lat: 50.4672297, lng: 30.348959 },
      { lat: 50.4673389, lng: 30.347929 },
      { lat: 50.4668472, lng: 30.3470707 },
      { lat: 50.4665399, lng: 30.3461587 },
      { lat: 50.4656999, lng: 30.3445816 },
      { lat: 50.4651536, lng: 30.3446674 },
      { lat: 50.4650989, lng: 30.3440666 },
      { lat: 50.4642794, lng: 30.3432941 },
      { lat: 50.4638423, lng: 30.3379726 },
      { lat: 50.4640849, lng: 30.3377743 },
      { lat: 50.4642792, lng: 30.3374544 },
      { lat: 50.464692, lng: 30.3372419 },
      { lat: 50.4650395, lng: 30.3360872 },
      { lat: 50.4656252, lng: 30.3335847 },
      { lat: 50.4654535, lng: 30.3318131 },
      { lat: 50.4649678, lng: 30.3301917 },
      { lat: 50.4648294, lng: 30.3287513 },
      { lat: 50.4635719, lng: 30.3290691 },
      { lat: 50.4622168, lng: 30.3290033 },
      { lat: 50.4610968, lng: 30.3303552 },
      { lat: 50.4610011, lng: 30.3354836 },
      { lat: 50.4577772, lng: 30.3376293 },
    ],
  },
  {
    name: 'БЕЛИЧИ 2',
    price: 83,
    points: [
      { lat: 50.4500718, lng: 30.3426075 },
      { lat: 50.4502904, lng: 30.3481865 },
      { lat: 50.4503451, lng: 30.3500748 },
      { lat: 50.450386, lng: 30.350858 },
      { lat: 50.4494433, lng: 30.3510296 },
      { lat: 50.4494707, lng: 30.3517056 },
      { lat: 50.4496893, lng: 30.3577137 },
      { lat: 50.4506048, lng: 30.3576386 },
      { lat: 50.4517661, lng: 30.3575206 },
      { lat: 50.4553182, lng: 30.3570271 },
      { lat: 50.4544438, lng: 30.3416634 },
      { lat: 50.4541774, lng: 30.341717 },
      { lat: 50.4500718, lng: 30.3426075 },
    ],
  },
  {
    name: 'КАТЕРИНОВКА',
    price: 84,
    points: [
      { lat: 50.4502358, lng: 30.319519 },
      { lat: 50.4513288, lng: 30.3345394 },
      { lat: 50.4497986, lng: 30.3366852 },
      { lat: 50.4500718, lng: 30.3426075 },
      { lat: 50.4541774, lng: 30.341717 },
      { lat: 50.4529137, lng: 30.3220081 },
      { lat: 50.4525858, lng: 30.3192616 },
      { lat: 50.4502358, lng: 30.319519 },
    ],
  },
  {
    name: 'СВЯТОШИНО',
    price: 85,
    points: [
      { lat: 50.446123, lng: 30.3596878 },
      { lat: 50.4460685, lng: 30.3687644 },
      { lat: 50.4449889, lng: 30.369215 },
      { lat: 50.4449342, lng: 30.3738499 },
      { lat: 50.4442236, lng: 30.3741932 },
      { lat: 50.444169, lng: 30.381918 },
      { lat: 50.4442783, lng: 30.3862095 },
      { lat: 50.4437864, lng: 30.3892994 },
      { lat: 50.4446062, lng: 30.3945351 },
      { lat: 50.4457541, lng: 30.3935909 },
      { lat: 50.4472298, lng: 30.392561 },
      { lat: 50.449252, lng: 30.3909302 },
      { lat: 50.45055, lng: 30.3902006 },
      { lat: 50.4549903, lng: 30.3893852 },
      { lat: 50.457504, lng: 30.3888702 },
      { lat: 50.4560833, lng: 30.3694725 },
      { lat: 50.4553182, lng: 30.3570271 },
      { lat: 50.4517661, lng: 30.3575206 },
      { lat: 50.4506048, lng: 30.3576386 },
      { lat: 50.4506456, lng: 30.3588188 },
      { lat: 50.446123, lng: 30.3596878 },
    ],
  },
  {
    name: 'ЧАЙКА ЖК',
    price: 86,
    points: [
      { lat: 50.4431851, lng: 30.2760887 },
      { lat: 50.4395227, lng: 30.2761745 },
      { lat: 50.4320057, lng: 30.274415 },
      { lat: 50.4271668, lng: 30.2826118 },
      { lat: 50.4299554, lng: 30.2878475 },
      { lat: 50.4331811, lng: 30.2913666 },
      { lat: 50.4359147, lng: 30.2872467 },
      { lat: 50.4367552, lng: 30.28759 },
      { lat: 50.4371891, lng: 30.2889311 },
      { lat: 50.437828, lng: 30.2901649 },
      { lat: 50.4383747, lng: 30.2913666 },
      { lat: 50.439304, lng: 30.2923107 },
      { lat: 50.439796, lng: 30.293169 },
      { lat: 50.4408346, lng: 30.2951431 },
      { lat: 50.443841, lng: 30.3041553 },
      { lat: 50.4427478, lng: 30.3147125 },
      { lat: 50.4502358, lng: 30.319519 },
      { lat: 50.4525858, lng: 30.3192616 },
      { lat: 50.4520939, lng: 30.3129101 },
      { lat: 50.4511102, lng: 30.2978039 },
      { lat: 50.4495253, lng: 30.2761745 },
      { lat: 50.4431851, lng: 30.2760887 },
    ],
  },
  {
    name: 'КП ЖИТОМИР',
    price: 87,
    points: [
      { lat: 50.4502904, lng: 30.2760029 },
      { lat: 50.4495253, lng: 30.2761745 },
      { lat: 50.4511102, lng: 30.2978039 },
      { lat: 50.4520939, lng: 30.3129101 },
      { lat: 50.4525858, lng: 30.3192616 },
      { lat: 50.4529137, lng: 30.3220081 },
      { lat: 50.4541774, lng: 30.341717 },
      { lat: 50.4544438, lng: 30.3416634 },
      { lat: 50.4548264, lng: 30.3415775 },
      { lat: 50.4533748, lng: 30.3217346 },
      { lat: 50.4545702, lng: 30.3215682 },
      { lat: 50.4544814, lng: 30.3194332 },
      { lat: 50.4538291, lng: 30.3195298 },
      { lat: 50.4537915, lng: 30.3185159 },
      { lat: 50.4532962, lng: 30.3185749 },
      { lat: 50.4502904, lng: 30.2760029 },
    ],
  },
  {
    name: 'БОРЩАГОВКА ПЕТР',
    price: 88,
    points: [
      { lat: 50.4455354, lng: 30.3369427 },
      { lat: 50.4427478, lng: 30.3147125 },
      { lat: 50.4415452, lng: 30.3140259 },
      { lat: 50.4369534, lng: 30.3159142 },
      { lat: 50.4306661, lng: 30.3121376 },
      { lat: 50.4189642, lng: 30.3173733 },
      { lat: 50.4218079, lng: 30.3236389 },
      { lat: 50.4250342, lng: 30.3310848 },
      { lat: 50.4251094, lng: 30.3329945 },
      { lat: 50.4259092, lng: 30.3417492 },
      { lat: 50.4260185, lng: 30.3450108 },
      { lat: 50.4263466, lng: 30.3457832 },
      { lat: 50.4270985, lng: 30.347296 },
      { lat: 50.4309668, lng: 30.3472853 },
      { lat: 50.4314384, lng: 30.350976 },
      { lat: 50.4335365, lng: 30.3527355 },
      { lat: 50.4309532, lng: 30.3542268 },
      { lat: 50.432033, lng: 30.3581429 },
      { lat: 50.4322244, lng: 30.3610611 },
      { lat: 50.4340559, lng: 30.360117 },
      { lat: 50.435696, lng: 30.3598917 },
      { lat: 50.4448385, lng: 30.3584325 },
      { lat: 50.445795, lng: 30.3582287 },
      { lat: 50.4476398, lng: 30.3579283 },
      { lat: 50.4496893, lng: 30.3577137 },
      { lat: 50.4494433, lng: 30.3510296 },
      { lat: 50.450386, lng: 30.350858 },
      { lat: 50.4503451, lng: 30.3500748 },
      { lat: 50.4502904, lng: 30.3481865 },
      { lat: 50.4463553, lng: 30.3450108 },
      { lat: 50.4455354, lng: 30.3369427 },
    ],
  },
  {
    name: 'БОРЩАГОВКА СОФ',
    price: 90,
    points: [
      { lat: 50.4048524, lng: 30.330162 },
      { lat: 50.4037583, lng: 30.3338528 },
      { lat: 50.404743, lng: 30.3381443 },
      { lat: 50.3982233, lng: 30.3681218 },
      { lat: 50.3981199, lng: 30.3684855 },
      { lat: 50.3992124, lng: 30.3694886 },
      { lat: 50.4000382, lng: 30.3705025 },
      { lat: 50.4009682, lng: 30.3714466 },
      { lat: 50.4020077, lng: 30.3726482 },
      { lat: 50.4043601, lng: 30.37076 },
      { lat: 50.4049619, lng: 30.37076 },
      { lat: 50.4061653, lng: 30.3711033 },
      { lat: 50.4066577, lng: 30.3716183 },
      { lat: 50.4078064, lng: 30.3717899 },
      { lat: 50.408244, lng: 30.3713608 },
      { lat: 50.4089551, lng: 30.3716183 },
      { lat: 50.4101585, lng: 30.3719616 },
      { lat: 50.411526, lng: 30.3732491 },
      { lat: 50.412237, lng: 30.3761673 },
      { lat: 50.4119089, lng: 30.3816605 },
      { lat: 50.4128182, lng: 30.3830016 },
      { lat: 50.4160109, lng: 30.3780556 },
      { lat: 50.4198939, lng: 30.3716183 },
      { lat: 50.4208236, lng: 30.3701591 },
      { lat: 50.4220814, lng: 30.3689575 },
      { lat: 50.4245421, lng: 30.3668976 },
      { lat: 50.4295043, lng: 30.3631961 },
      { lat: 50.4279323, lng: 30.3584003 },
      { lat: 50.427529, lng: 30.3564262 },
      { lat: 50.4268934, lng: 30.3521347 },
      { lat: 50.4270985, lng: 30.347296 },
      { lat: 50.4263466, lng: 30.3457832 },
      { lat: 50.4260185, lng: 30.3450108 },
      { lat: 50.4259092, lng: 30.3417492 },
      { lat: 50.4251094, lng: 30.3329945 },
      { lat: 50.4250342, lng: 30.3310848 },
      { lat: 50.4218079, lng: 30.3236389 },
      { lat: 50.4189642, lng: 30.3173733 },
      { lat: 50.4048524, lng: 30.330162 },
    ],
  },
  {
    name: 'БОРЩАГОВКА ЖК СОФИЯ',
    price: 91,
    points: [
      { lat: 50.3969196, lng: 30.3760386 },
      { lat: 50.395757, lng: 30.3794451 },
      { lat: 50.3967829, lng: 30.3806628 },
      { lat: 50.3950592, lng: 30.3870035 },
      { lat: 50.3968922, lng: 30.3923464 },
      { lat: 50.4029651, lng: 30.3974962 },
      { lat: 50.4045789, lng: 30.3979683 },
      { lat: 50.4059739, lng: 30.3942347 },
      { lat: 50.4068765, lng: 30.392561 },
      { lat: 50.4082167, lng: 30.3904152 },
      { lat: 50.4090919, lng: 30.388999 },
      { lat: 50.4122917, lng: 30.3838921 },
      { lat: 50.4128182, lng: 30.3830016 },
      { lat: 50.4119089, lng: 30.3816605 },
      { lat: 50.412237, lng: 30.3761673 },
      { lat: 50.411526, lng: 30.3732491 },
      { lat: 50.4101585, lng: 30.3719616 },
      { lat: 50.4089551, lng: 30.3716183 },
      { lat: 50.408244, lng: 30.3713608 },
      { lat: 50.4078064, lng: 30.3717899 },
      { lat: 50.4069038, lng: 30.3717041 },
      { lat: 50.4066577, lng: 30.3716183 },
      { lat: 50.4061653, lng: 30.3711033 },
      { lat: 50.4049619, lng: 30.37076 },
      { lat: 50.4043601, lng: 30.37076 },
      { lat: 50.4020077, lng: 30.3726482 },
      { lat: 50.4009682, lng: 30.3714466 },
      { lat: 50.4000382, lng: 30.3705025 },
      { lat: 50.3992124, lng: 30.3694886 },
      { lat: 50.3981199, lng: 30.3684855 },
      { lat: 50.3969196, lng: 30.3760386 },
    ],
  },
  {
    name: 'БОРЩАГОВКА НИКОЛ',
    price: 92,
    points: [
      { lat: 50.4261552, lng: 30.3725624 },
      { lat: 50.4312129, lng: 30.3922606 },
      { lat: 50.4330991, lng: 30.3995991 },
      { lat: 50.4338645, lng: 30.4023457 },
      { lat: 50.4363247, lng: 30.3989124 },
      { lat: 50.438894, lng: 30.3943634 },
      { lat: 50.4396867, lng: 30.3936338 },
      { lat: 50.4402607, lng: 30.3933334 },
      { lat: 50.4410806, lng: 30.3932047 },
      { lat: 50.4415999, lng: 30.3932047 },
      { lat: 50.4425018, lng: 30.3935909 },
      { lat: 50.4430484, lng: 30.3939772 },
      { lat: 50.4439503, lng: 30.39505 },
      { lat: 50.4446062, lng: 30.3945351 },
      { lat: 50.4437864, lng: 30.3892994 },
      { lat: 50.4442783, lng: 30.3862095 },
      { lat: 50.444169, lng: 30.381918 },
      { lat: 50.4442236, lng: 30.3741932 },
      { lat: 50.4394885, lng: 30.3774226 },
      { lat: 50.4386071, lng: 30.374279 },
      { lat: 50.437746, lng: 30.3712964 },
      { lat: 50.4370286, lng: 30.3681527 },
      { lat: 50.4363384, lng: 30.3642582 },
      { lat: 50.435696, lng: 30.3598917 },
      { lat: 50.4340559, lng: 30.360117 },
      { lat: 50.4322244, lng: 30.3610611 },
      { lat: 50.4314863, lng: 30.3615761 },
      { lat: 50.4295043, lng: 30.3631961 },
      { lat: 50.4245421, lng: 30.3668976 },
      { lat: 50.4261552, lng: 30.3725624 },
    ],
  },
  {
    name: 'БОРЩАГОВКА КОЛЬЦОВА',
    price: 93,
    points: [
      { lat: 50.4130575, lng: 30.3849649 },
      { lat: 50.4159016, lng: 30.3884411 },
      { lat: 50.417679, lng: 30.3899431 },
      { lat: 50.4200033, lng: 30.3912306 },
      { lat: 50.4234485, lng: 30.393548 },
      { lat: 50.4257178, lng: 30.3948355 },
      { lat: 50.4264286, lng: 30.3953075 },
      { lat: 50.4308028, lng: 30.3977966 },
      { lat: 50.4330991, lng: 30.3995991 },
      { lat: 50.4312129, lng: 30.3922606 },
      { lat: 50.4261552, lng: 30.3725624 },
      { lat: 50.4245421, lng: 30.3668976 },
      { lat: 50.4220814, lng: 30.3689575 },
      { lat: 50.4208236, lng: 30.3701591 },
      { lat: 50.4198939, lng: 30.3716183 },
      { lat: 50.4160109, lng: 30.3780556 },
      { lat: 50.4128182, lng: 30.3830016 },
      { lat: 50.4122917, lng: 30.3838921 },
      { lat: 50.4130575, lng: 30.3849649 },
    ],
  },
  {
    name: 'БОРЩАГОВКА ЮЖНАЯ',
    price: 94,
    points: [
      { lat: 50.415464, lng: 30.4051352 },
      { lat: 50.4173782, lng: 30.4026461 },
      { lat: 50.4199486, lng: 30.3990841 },
      { lat: 50.4210423, lng: 30.3977108 },
      { lat: 50.4222181, lng: 30.3969812 },
      { lat: 50.4257178, lng: 30.3948355 },
      { lat: 50.4234485, lng: 30.393548 },
      { lat: 50.4200033, lng: 30.3912306 },
      { lat: 50.417679, lng: 30.3899431 },
      { lat: 50.4159016, lng: 30.3884411 },
      { lat: 50.4130575, lng: 30.3849649 },
      { lat: 50.4122917, lng: 30.3838921 },
      { lat: 50.4082167, lng: 30.3904152 },
      { lat: 50.4095568, lng: 30.3926468 },
      { lat: 50.40715, lng: 30.3975821 },
      { lat: 50.4054268, lng: 30.4037189 },
      { lat: 50.4049071, lng: 30.4059505 },
      { lat: 50.4060559, lng: 30.4066372 },
      { lat: 50.4064115, lng: 30.4065084 },
      { lat: 50.4071773, lng: 30.4070234 },
      { lat: 50.4070953, lng: 30.4074097 },
      { lat: 50.4085175, lng: 30.4112291 },
      { lat: 50.4100218, lng: 30.413729 },
      { lat: 50.4101585, lng: 30.4135036 },
      { lat: 50.4130575, lng: 30.4089975 },
      { lat: 50.415464, lng: 30.4051352 },
    ],
  },
  {
    name: 'БОРЩАГОВКА ПРОМЗОНА',
    price: 95,
    points: [
      { lat: 50.4294906, lng: 30.4057574 },
      { lat: 50.4322244, lng: 30.4038048 },
      { lat: 50.4338645, lng: 30.4023457 },
      { lat: 50.4330991, lng: 30.3995991 },
      { lat: 50.4308028, lng: 30.3977966 },
      { lat: 50.4264286, lng: 30.3953075 },
      { lat: 50.4257178, lng: 30.3948355 },
      { lat: 50.4222181, lng: 30.3969812 },
      { lat: 50.4210423, lng: 30.3977108 },
      { lat: 50.4199486, lng: 30.3990841 },
      { lat: 50.4173782, lng: 30.4026461 },
      { lat: 50.415464, lng: 30.4051352 },
      { lat: 50.4130575, lng: 30.4089975 },
      { lat: 50.4101585, lng: 30.4135036 },
      { lat: 50.4125105, lng: 30.4171515 },
      { lat: 50.4131395, lng: 30.4188251 },
      { lat: 50.4141514, lng: 30.4218292 },
      { lat: 50.4175149, lng: 30.4185247 },
      { lat: 50.4203041, lng: 30.4147911 },
      { lat: 50.4213431, lng: 30.4135466 },
      { lat: 50.4231751, lng: 30.4117441 },
      { lat: 50.4255537, lng: 30.4092121 },
      { lat: 50.4279323, lng: 30.4068518 },
      { lat: 50.4294906, lng: 30.4057574 },
    ],
  },
  {
    name: 'БОРЩАГОВКА МИХАЙЛ',
    price: 96,
    points: [
      { lat: 50.3995868, lng: 30.4053927 },
      { lat: 50.3975693, lng: 30.4114115 },
      { lat: 50.399792, lng: 30.4133749 },
      { lat: 50.3981506, lng: 30.4178381 },
      { lat: 50.4000929, lng: 30.4214859 },
      { lat: 50.4010777, lng: 30.422945 },
      { lat: 50.4017889, lng: 30.4238033 },
      { lat: 50.4035395, lng: 30.4257345 },
      { lat: 50.404196, lng: 30.4262495 },
      { lat: 50.4075603, lng: 30.4287815 },
      { lat: 50.410897, lng: 30.4309273 },
      { lat: 50.4115533, lng: 30.4276228 },
      { lat: 50.411608, lng: 30.4268074 },
      { lat: 50.4117721, lng: 30.4257774 },
      { lat: 50.4120182, lng: 30.4248762 },
      { lat: 50.4123738, lng: 30.4238462 },
      { lat: 50.4128387, lng: 30.4232025 },
      { lat: 50.4141514, lng: 30.4218292 },
      { lat: 50.4131395, lng: 30.4188251 },
      { lat: 50.4125105, lng: 30.4171515 },
      { lat: 50.4101585, lng: 30.4135036 },
      { lat: 50.4100218, lng: 30.413729 },
      { lat: 50.4085175, lng: 30.4112291 },
      { lat: 50.4070953, lng: 30.4074097 },
      { lat: 50.4071773, lng: 30.4070234 },
      { lat: 50.4064115, lng: 30.4065084 },
      { lat: 50.4060559, lng: 30.4066372 },
      { lat: 50.4049071, lng: 30.4059505 },
      { lat: 50.4054268, lng: 30.4037189 },
      { lat: 50.40715, lng: 30.3975821 },
      { lat: 50.4095568, lng: 30.3926468 },
      { lat: 50.4082167, lng: 30.3904152 },
      { lat: 50.4068765, lng: 30.392561 },
      { lat: 50.4059739, lng: 30.3942347 },
      { lat: 50.4045789, lng: 30.3979683 },
      { lat: 50.4017889, lng: 30.4069376 },
      { lat: 50.3995868, lng: 30.4053927 },
    ],
  },
  {
    name: 'ОТРАДНЫЙ Ч/С',
    price: 97,
    points: [
      { lat: 50.4334818, lng: 30.4459047 },
      { lat: 50.4337005, lng: 30.444231 },
      { lat: 50.433967, lng: 30.4432547 },
      { lat: 50.4344932, lng: 30.4420853 },
      { lat: 50.4362905, lng: 30.4352081 },
      { lat: 50.4341926, lng: 30.4338026 },
      { lat: 50.4286431, lng: 30.4301548 },
      { lat: 50.4211791, lng: 30.4252625 },
      { lat: 50.4184994, lng: 30.42449 },
      { lat: 50.4151359, lng: 30.4235888 },
      { lat: 50.4145137, lng: 30.4228377 },
      { lat: 50.4141514, lng: 30.4218292 },
      { lat: 50.4128387, lng: 30.4232025 },
      { lat: 50.4123738, lng: 30.4238462 },
      { lat: 50.4120182, lng: 30.4248762 },
      { lat: 50.4117721, lng: 30.4257774 },
      { lat: 50.411608, lng: 30.4268074 },
      { lat: 50.4115533, lng: 30.4276228 },
      { lat: 50.410897, lng: 30.4309273 },
      { lat: 50.4220745, lng: 30.4384911 },
      { lat: 50.4259638, lng: 30.4410124 },
      { lat: 50.4334818, lng: 30.4459047 },
    ],
  },
  {
    name: 'МЕДГОРОДОК',
    price: 98,
    points: [
      { lat: 50.4319715, lng: 30.4134286 },
      { lat: 50.4328531, lng: 30.4169798 },
      { lat: 50.4339944, lng: 30.4213464 },
      { lat: 50.4380194, lng: 30.4188251 },
      { lat: 50.4359557, lng: 30.4109716 },
      { lat: 50.4343702, lng: 30.4119802 },
      { lat: 50.4319715, lng: 30.4134286 },
    ],
  },
  {
    name: 'ОТРАДНЫЙ',
    price: 98,
    points: [
      { lat: 50.4296615, lng: 30.4264319 },
      { lat: 50.4300647, lng: 30.4250479 },
      { lat: 50.4308848, lng: 30.4236746 },
      { lat: 50.4315409, lng: 30.4229343 },
      { lat: 50.4324225, lng: 30.4223335 },
      { lat: 50.4339944, lng: 30.4213464 },
      { lat: 50.4319715, lng: 30.4134286 },
      { lat: 50.4359557, lng: 30.4109716 },
      { lat: 50.4338645, lng: 30.4023457 },
      { lat: 50.4294906, lng: 30.4057574 },
      { lat: 50.4279323, lng: 30.4068518 },
      { lat: 50.4255537, lng: 30.4092121 },
      { lat: 50.4231751, lng: 30.4117441 },
      { lat: 50.4213431, lng: 30.4135466 },
      { lat: 50.4203041, lng: 30.4147911 },
      { lat: 50.4175149, lng: 30.4185247 },
      { lat: 50.4141514, lng: 30.4218292 },
      { lat: 50.4145137, lng: 30.4228377 },
      { lat: 50.4151359, lng: 30.4235888 },
      { lat: 50.4184994, lng: 30.42449 },
      { lat: 50.4211791, lng: 30.4252625 },
      { lat: 50.4286431, lng: 30.4301548 },
      { lat: 50.4341926, lng: 30.4338026 },
      { lat: 50.4343566, lng: 30.4329443 },
      { lat: 50.4321492, lng: 30.4280734 },
      { lat: 50.4296615, lng: 30.4264319 },
    ],
  },
  {
    name: 'КОЦЮБИНСКОЕ',
    price: 99,
    points: [
      { lat: 50.4829886, lng: 30.344367 },
      { lat: 50.4802305, lng: 30.3604603 },
      { lat: 50.4834529, lng: 30.362134 },
      { lat: 50.4890505, lng: 30.3646231 },
      { lat: 50.4911936, lng: 30.3509985 },
      { lat: 50.5018543, lng: 30.3492164 },
      { lat: 50.4962036, lng: 30.3174162 },
      { lat: 50.4920806, lng: 30.311624 },
      { lat: 50.4787831, lng: 30.3324366 },
      { lat: 50.4829886, lng: 30.344367 },
    ],
  },
  {
    name: 'НАУ',
    price: 100,
    points: [
      { lat: 50.4395227, lng: 30.4247475 },
      { lat: 50.4380194, lng: 30.4188251 },
      { lat: 50.4339944, lng: 30.4213464 },
      { lat: 50.4324225, lng: 30.4223335 },
      { lat: 50.4315409, lng: 30.4229343 },
      { lat: 50.4308848, lng: 30.4236746 },
      { lat: 50.4300647, lng: 30.4250479 },
      { lat: 50.4296615, lng: 30.4264319 },
      { lat: 50.4321492, lng: 30.4280734 },
      { lat: 50.4343566, lng: 30.4329443 },
      { lat: 50.4341926, lng: 30.4338026 },
      { lat: 50.4362905, lng: 30.4352081 },
      { lat: 50.4344932, lng: 30.4420853 },
      { lat: 50.433967, lng: 30.4432547 },
      { lat: 50.4337005, lng: 30.444231 },
      { lat: 50.4334818, lng: 30.4459047 },
      { lat: 50.4383474, lng: 30.4492092 },
      { lat: 50.438976, lng: 30.4468918 },
      { lat: 50.439304, lng: 30.4460335 },
      { lat: 50.4396593, lng: 30.4453897 },
      { lat: 50.4403153, lng: 30.4444885 },
      { lat: 50.440698, lng: 30.4441452 },
      { lat: 50.4422012, lng: 30.4431581 },
      { lat: 50.4431851, lng: 30.4425573 },
      { lat: 50.4439503, lng: 30.4420853 },
      { lat: 50.4426658, lng: 30.4368496 },
      { lat: 50.4411353, lng: 30.4309702 },
      { lat: 50.4408073, lng: 30.4297686 },
      { lat: 50.4395227, lng: 30.4247475 },
    ],
  },
  {
    name: 'ДАШАВСКАЯ',
    price: 101,
    points: [
      { lat: 50.4450299, lng: 30.4624915 },
      { lat: 50.4456857, lng: 30.4620409 },
      { lat: 50.445918, lng: 30.4591656 },
      { lat: 50.4465739, lng: 30.4533076 },
      { lat: 50.4465193, lng: 30.4524279 },
      { lat: 50.4463006, lng: 30.451591 },
      { lat: 50.4439503, lng: 30.4420853 },
      { lat: 50.4431851, lng: 30.4425573 },
      { lat: 50.4422012, lng: 30.4431581 },
      { lat: 50.440698, lng: 30.4441452 },
      { lat: 50.4403153, lng: 30.4444885 },
      { lat: 50.4396593, lng: 30.4453897 },
      { lat: 50.439304, lng: 30.4460335 },
      { lat: 50.438976, lng: 30.4468918 },
      { lat: 50.4383474, lng: 30.4492092 },
      { lat: 50.439755, lng: 30.4501748 },
      { lat: 50.4405613, lng: 30.4509258 },
      { lat: 50.4416409, lng: 30.4523206 },
      { lat: 50.4426521, lng: 30.4539943 },
      { lat: 50.4432671, lng: 30.4553676 },
      { lat: 50.4438137, lng: 30.4569554 },
      { lat: 50.4444013, lng: 30.4597449 },
      { lat: 50.4450299, lng: 30.4624915 },
    ],
  },
  {
    name: 'ГАЛАГАНЫ',
    price: 102,
    points: [
      { lat: 50.4555914, lng: 30.4147482 },
      { lat: 50.4563838, lng: 30.4150915 },
      { lat: 50.4576133, lng: 30.4157352 },
      { lat: 50.4567317, lng: 30.4179743 },
      { lat: 50.4572914, lng: 30.4186039 },
      { lat: 50.4576799, lng: 30.4193161 },
      { lat: 50.4579839, lng: 30.4199705 },
      { lat: 50.4583015, lng: 30.4208502 },
      { lat: 50.4584876, lng: 30.4217005 },
      { lat: 50.4594438, lng: 30.415864 },
      { lat: 50.457504, lng: 30.3888702 },
      { lat: 50.4549903, lng: 30.3893852 },
      { lat: 50.45055, lng: 30.3902006 },
      { lat: 50.449252, lng: 30.3909302 },
      { lat: 50.4472298, lng: 30.392561 },
      { lat: 50.4457541, lng: 30.3935909 },
      { lat: 50.4446062, lng: 30.3945351 },
      { lat: 50.4439503, lng: 30.39505 },
      { lat: 50.4466286, lng: 30.3982687 },
      { lat: 50.4472845, lng: 30.3996849 },
      { lat: 50.4486782, lng: 30.4025602 },
      { lat: 50.4542799, lng: 30.4129887 },
      { lat: 50.4555914, lng: 30.4147482 },
    ],
  },
  {
    name: 'ЖОВТНЕВЫЙ',
    price: 103,
    points: [
      { lat: 50.4545327, lng: 30.4230308 },
      { lat: 50.4570669, lng: 30.4212284 },
      { lat: 50.4584876, lng: 30.4217005 },
      { lat: 50.4583015, lng: 30.4208502 },
      { lat: 50.4579839, lng: 30.4199705 },
      { lat: 50.4576799, lng: 30.4193161 },
      { lat: 50.4572914, lng: 30.4186039 },
      { lat: 50.4567317, lng: 30.4179743 },
      { lat: 50.4576133, lng: 30.4157352 },
      { lat: 50.4563838, lng: 30.4150915 },
      { lat: 50.4555914, lng: 30.4147482 },
      { lat: 50.4542799, lng: 30.4129887 },
      { lat: 50.4486782, lng: 30.4025602 },
      { lat: 50.4472845, lng: 30.3996849 },
      { lat: 50.4466286, lng: 30.3982687 },
      { lat: 50.4439503, lng: 30.39505 },
      { lat: 50.4430484, lng: 30.3939772 },
      { lat: 50.4425018, lng: 30.3935909 },
      { lat: 50.4415999, lng: 30.3932047 },
      { lat: 50.4410806, lng: 30.3932047 },
      { lat: 50.4402607, lng: 30.3933334 },
      { lat: 50.4396867, lng: 30.3936338 },
      { lat: 50.438894, lng: 30.3943634 },
      { lat: 50.4363247, lng: 30.3989124 },
      { lat: 50.4338645, lng: 30.4023457 },
      { lat: 50.4359557, lng: 30.4109716 },
      { lat: 50.4380194, lng: 30.4188251 },
      { lat: 50.4395227, lng: 30.4247475 },
      { lat: 50.4408073, lng: 30.4297686 },
      { lat: 50.4414906, lng: 30.4293394 },
      { lat: 50.4419552, lng: 30.4298544 },
      { lat: 50.4430484, lng: 30.4289532 },
      { lat: 50.4449889, lng: 30.4303694 },
      { lat: 50.4451528, lng: 30.431056 },
      { lat: 50.4457814, lng: 30.4310989 },
      { lat: 50.4470385, lng: 30.4314852 },
      { lat: 50.4471478, lng: 30.4309273 },
      { lat: 50.4482956, lng: 30.4312706 },
      { lat: 50.4495526, lng: 30.42346 },
      { lat: 50.4514928, lng: 30.4241467 },
      { lat: 50.4518207, lng: 30.4221725 },
      { lat: 50.4545327, lng: 30.4230308 },
    ],
  },
  {
    name: 'ГАРМАТНАЯ',
    price: 104,
    points: [
      { lat: 50.4445926, lng: 30.441978 },
      { lat: 50.4453031, lng: 30.4421711 },
      { lat: 50.4484596, lng: 30.4433727 },
      { lat: 50.4545805, lng: 30.4460335 },
      { lat: 50.4547547, lng: 30.4449767 },
      { lat: 50.4551269, lng: 30.4427719 },
      { lat: 50.4561925, lng: 30.4358196 },
      { lat: 50.4567663, lng: 30.4321932 },
      { lat: 50.4531425, lng: 30.430734 },
      { lat: 50.4545327, lng: 30.4230308 },
      { lat: 50.4518207, lng: 30.4221725 },
      { lat: 50.4514928, lng: 30.4241467 },
      { lat: 50.4495526, lng: 30.42346 },
      { lat: 50.4482956, lng: 30.4312706 },
      { lat: 50.4471478, lng: 30.4309273 },
      { lat: 50.4470385, lng: 30.4314852 },
      { lat: 50.4457814, lng: 30.4310989 },
      { lat: 50.4451528, lng: 30.431056 },
      { lat: 50.4449889, lng: 30.4303694 },
      { lat: 50.4430484, lng: 30.4289532 },
      { lat: 50.4419552, lng: 30.4298544 },
      { lat: 50.4414906, lng: 30.4293394 },
      { lat: 50.4408073, lng: 30.4297686 },
      { lat: 50.4411353, lng: 30.4309702 },
      { lat: 50.4426658, lng: 30.4368496 },
      { lat: 50.4439503, lng: 30.4420853 },
      { lat: 50.4445926, lng: 30.441978 },
    ],
  },
  {
    name: 'КПИ',
    price: 105,
    points: [
      { lat: 50.4465193, lng: 30.4614401 },
      { lat: 50.447831, lng: 30.4615688 },
      { lat: 50.4486782, lng: 30.4624271 },
      { lat: 50.4513835, lng: 30.4653025 },
      { lat: 50.4522032, lng: 30.4603243 },
      { lat: 50.4532689, lng: 30.4536724 },
      { lat: 50.4545805, lng: 30.4460335 },
      { lat: 50.4484596, lng: 30.4433727 },
      { lat: 50.4453031, lng: 30.4421711 },
      { lat: 50.4445926, lng: 30.441978 },
      { lat: 50.4439503, lng: 30.4420853 },
      { lat: 50.4463006, lng: 30.451591 },
      { lat: 50.4465193, lng: 30.4524279 },
      { lat: 50.4465739, lng: 30.4533076 },
      { lat: 50.445918, lng: 30.4591656 },
      { lat: 50.4456857, lng: 30.4620409 },
      { lat: 50.4465193, lng: 30.4614401 },
    ],
  },
  {
    name: 'М. ПОЛИТЕХНИЧЕСКАЯ',
    price: 106,
    points: [
      { lat: 50.4502904, lng: 30.4720831 },
      { lat: 50.4513835, lng: 30.4653025 },
      { lat: 50.4486782, lng: 30.4624271 },
      { lat: 50.447831, lng: 30.4615688 },
      { lat: 50.4465193, lng: 30.4614401 },
      { lat: 50.4456857, lng: 30.4620409 },
      { lat: 50.4450299, lng: 30.4624915 },
      { lat: 50.4465193, lng: 30.4698944 },
      { lat: 50.4469839, lng: 30.4724264 },
      { lat: 50.4471205, lng: 30.4740572 },
      { lat: 50.4471752, lng: 30.4752159 },
      { lat: 50.4470112, lng: 30.4768896 },
      { lat: 50.4466013, lng: 30.4790354 },
      { lat: 50.4458634, lng: 30.4813528 },
      { lat: 50.4470249, lng: 30.4818249 },
      { lat: 50.4484801, lng: 30.4823827 },
      { lat: 50.4502904, lng: 30.4720831 },
    ],
  },
  {
    name: 'ЖУЛЯНЫ АЭРОПОРТ',
    price: 107,
    points: [
      { lat: 50.4206869, lng: 30.4443598 },
      { lat: 50.4220745, lng: 30.4384911 },
      { lat: 50.410897, lng: 30.4309273 },
      { lat: 50.4103773, lng: 30.4325151 },
      { lat: 50.4068765, lng: 30.4299831 },
      { lat: 50.4059192, lng: 30.4329014 },
      { lat: 50.4026095, lng: 30.4306269 },
      { lat: 50.4015974, lng: 30.4306698 },
      { lat: 50.4003391, lng: 30.4309702 },
      { lat: 50.3989439, lng: 30.4380941 },
      { lat: 50.4003117, lng: 30.4709673 },
      { lat: 50.4034848, lng: 30.4705811 },
      { lat: 50.4069038, lng: 30.4731131 },
      { lat: 50.4075876, lng: 30.4709244 },
      { lat: 50.4101585, lng: 30.4719543 },
      { lat: 50.4117721, lng: 30.4614401 },
      { lat: 50.4122917, lng: 30.4587793 },
      { lat: 50.4136318, lng: 30.4587793 },
      { lat: 50.4137138, lng: 30.4552174 },
      { lat: 50.4174056, lng: 30.455389 },
      { lat: 50.4206869, lng: 30.4443598 },
    ],
  },
  {
    name: 'ЖУЛЯНЫ',
    price: 108,
    points: [
      { lat: 50.4059192, lng: 30.4329014 },
      { lat: 50.4068765, lng: 30.4299831 },
      { lat: 50.4103773, lng: 30.4325151 },
      { lat: 50.410897, lng: 30.4309273 },
      { lat: 50.4075603, lng: 30.4287815 },
      { lat: 50.404196, lng: 30.4262495 },
      { lat: 50.4035395, lng: 30.4257345 },
      { lat: 50.4017889, lng: 30.4238033 },
      { lat: 50.4010777, lng: 30.422945 },
      { lat: 50.4000929, lng: 30.4214859 },
      { lat: 50.3981506, lng: 30.4178381 },
      { lat: 50.3957706, lng: 30.4230738 },
      { lat: 50.3949464, lng: 30.4222101 },
      { lat: 50.3928432, lng: 30.4226875 },
      { lat: 50.3905656, lng: 30.4258745 },
      { lat: 50.3920772, lng: 30.4289972 },
      { lat: 50.3821718, lng: 30.4422998 },
      { lat: 50.3863859, lng: 30.4493809 },
      { lat: 50.3841968, lng: 30.453887 },
      { lat: 50.3846073, lng: 30.4544449 },
      { lat: 50.387583, lng: 30.4557753 },
      { lat: 50.3886023, lng: 30.4567623 },
      { lat: 50.3881508, lng: 30.4585621 },
      { lat: 50.389259, lng: 30.4592514 },
      { lat: 50.3889306, lng: 30.4613113 },
      { lat: 50.3904355, lng: 30.4613972 },
      { lat: 50.3913658, lng: 30.4646158 },
      { lat: 50.3915846, lng: 30.4678345 },
      { lat: 50.3963998, lng: 30.4669762 },
      { lat: 50.3973299, lng: 30.4715252 },
      { lat: 50.4003117, lng: 30.4709673 },
      { lat: 50.3989439, lng: 30.4380941 },
      { lat: 50.4003391, lng: 30.4309702 },
      { lat: 50.4015974, lng: 30.4306698 },
      { lat: 50.4026095, lng: 30.4306269 },
      { lat: 50.4059192, lng: 30.4329014 },
    ],
  },
  {
    name: 'ЧОКОЛОВКА',
    price: 109,
    points: [
      { lat: 50.4257451, lng: 30.4595947 },
      { lat: 50.4268114, lng: 30.4586506 },
      { lat: 50.4308028, lng: 30.4556894 },
      { lat: 50.4318416, lng: 30.4551315 },
      { lat: 50.4358327, lng: 30.4532862 },
      { lat: 50.4371447, lng: 30.4526424 },
      { lat: 50.437582, lng: 30.4519558 },
      { lat: 50.4383474, lng: 30.4492092 },
      { lat: 50.4334818, lng: 30.4459047 },
      { lat: 50.4259638, lng: 30.4410124 },
      { lat: 50.4220745, lng: 30.4384911 },
      { lat: 50.4206869, lng: 30.4443598 },
      { lat: 50.4174056, lng: 30.455389 },
      { lat: 50.4211517, lng: 30.4573631 },
      { lat: 50.4257451, lng: 30.4595947 },
    ],
  },
  {
    name: 'КАДЕТСКИЙ ГАЙ',
    price: 110,
    points: [
      { lat: 50.4256426, lng: 30.460099 },
      { lat: 50.4257451, lng: 30.4595947 },
      { lat: 50.4211517, lng: 30.4573631 },
      { lat: 50.4174056, lng: 30.455389 },
      { lat: 50.4137138, lng: 30.4552174 },
      { lat: 50.4136318, lng: 30.4587793 },
      { lat: 50.4122917, lng: 30.4587793 },
      { lat: 50.4117721, lng: 30.4614401 },
      { lat: 50.4101585, lng: 30.4719543 },
      { lat: 50.4075876, lng: 30.4709244 },
      { lat: 50.4069038, lng: 30.4731131 },
      { lat: 50.4081346, lng: 30.4767179 },
      { lat: 50.4102406, lng: 30.478735 },
      { lat: 50.4111704, lng: 30.4800224 },
      { lat: 50.4130096, lng: 30.4794109 },
      { lat: 50.4142061, lng: 30.4804516 },
      { lat: 50.4160588, lng: 30.4790568 },
      { lat: 50.417426, lng: 30.4783166 },
      { lat: 50.4206048, lng: 30.4709673 },
      { lat: 50.4233391, lng: 30.4647875 },
      { lat: 50.4256426, lng: 30.460099 },
    ],
  },
  {
    name: 'ЕРЕВАНСКАЯ',
    price: 111,
    points: [
      { lat: 50.4425292, lng: 30.4716969 },
      { lat: 50.4471205, lng: 30.4740572 },
      { lat: 50.4469839, lng: 30.4724264 },
      { lat: 50.4465193, lng: 30.4698944 },
      { lat: 50.4450299, lng: 30.4624915 },
      { lat: 50.4444013, lng: 30.4597449 },
      { lat: 50.4438137, lng: 30.4569554 },
      { lat: 50.4432671, lng: 30.4553676 },
      { lat: 50.4426521, lng: 30.4539943 },
      { lat: 50.4416409, lng: 30.4523206 },
      { lat: 50.4405613, lng: 30.4509258 },
      { lat: 50.439755, lng: 30.4501748 },
      { lat: 50.4383474, lng: 30.4492092 },
      { lat: 50.437582, lng: 30.4519558 },
      { lat: 50.4371447, lng: 30.4526424 },
      { lat: 50.4358327, lng: 30.4532862 },
      { lat: 50.4346025, lng: 30.453844 },
      { lat: 50.4348076, lng: 30.454874 },
      { lat: 50.4349716, lng: 30.4558182 },
      { lat: 50.4350946, lng: 30.4565048 },
      { lat: 50.4352381, lng: 30.4572666 },
      { lat: 50.4354569, lng: 30.4586185 },
      { lat: 50.4326754, lng: 30.4677272 },
      { lat: 50.4349306, lng: 30.4699372 },
      { lat: 50.4342745, lng: 30.4717397 },
      { lat: 50.4366801, lng: 30.4741002 },
      { lat: 50.4377734, lng: 30.4753447 },
      { lat: 50.439632, lng: 30.4781771 },
      { lat: 50.4400967, lng: 30.4775333 },
      { lat: 50.4406706, lng: 30.476675 },
      { lat: 50.4425292, lng: 30.4716969 },
    ],
  },
  {
    name: 'КЛИМЕНКО',
    price: 112,
    points: [
      { lat: 50.4215721, lng: 30.4826886 },
      { lat: 50.4221087, lng: 30.4831553 },
      { lat: 50.424132, lng: 30.4785633 },
      { lat: 50.4244533, lng: 30.4779088 },
      { lat: 50.4251847, lng: 30.4772115 },
      { lat: 50.4274197, lng: 30.4758382 },
      { lat: 50.4312266, lng: 30.4719543 },
      { lat: 50.432197, lng: 30.4696798 },
      { lat: 50.4302014, lng: 30.4675341 },
      { lat: 50.4286227, lng: 30.4661501 },
      { lat: 50.4256426, lng: 30.460099 },
      { lat: 50.4233391, lng: 30.4647875 },
      { lat: 50.4206048, lng: 30.4709673 },
      { lat: 50.417426, lng: 30.4783166 },
      { lat: 50.4215721, lng: 30.4826886 },
    ],
  },
  {
    name: 'ЖД ЮЖНЫЙ',
    price: 113,
    points: [
      { lat: 50.4452348, lng: 30.4836273 },
      { lat: 50.4458634, lng: 30.4813528 },
      { lat: 50.4418731, lng: 30.4799901 },
      { lat: 50.4405612, lng: 30.4791855 },
      { lat: 50.439632, lng: 30.4781771 },
      { lat: 50.4377734, lng: 30.4753447 },
      { lat: 50.4366801, lng: 30.4741002 },
      { lat: 50.432197, lng: 30.4696798 },
      { lat: 50.4312266, lng: 30.4719543 },
      { lat: 50.4320057, lng: 30.4727697 },
      { lat: 50.4326617, lng: 30.4797649 },
      { lat: 50.4333998, lng: 30.4835844 },
      { lat: 50.4349853, lng: 30.4870176 },
      { lat: 50.4364613, lng: 30.4896784 },
      { lat: 50.4369534, lng: 30.4909229 },
      { lat: 50.43709, lng: 30.4918242 },
      { lat: 50.4372814, lng: 30.4923391 },
      { lat: 50.439468, lng: 30.4890347 },
      { lat: 50.4452348, lng: 30.4836273 },
    ],
  },
  {
    name: 'СОЛОМЕНКА',
    price: 114,
    points: [
      { lat: 50.4203861, lng: 30.485816 },
      { lat: 50.4199759, lng: 30.4867601 },
      { lat: 50.4199486, lng: 30.4876184 },
      { lat: 50.4199759, lng: 30.4885626 },
      { lat: 50.4200853, lng: 30.4895926 },
      { lat: 50.4205775, lng: 30.4909552 },
      { lat: 50.4215037, lng: 30.4909551 },
      { lat: 50.4220199, lng: 30.4915881 },
      { lat: 50.4222864, lng: 30.4923928 },
      { lat: 50.4239133, lng: 30.4937553 },
      { lat: 50.4264423, lng: 30.4969525 },
      { lat: 50.4253009, lng: 30.4982508 },
      { lat: 50.4264286, lng: 30.5001068 },
      { lat: 50.4260185, lng: 30.5008364 },
      { lat: 50.4264936, lng: 30.5035508 },
      { lat: 50.4266747, lng: 30.5034542 },
      { lat: 50.4269481, lng: 30.5021238 },
      { lat: 50.4290532, lng: 30.500536 },
      { lat: 50.4286978, lng: 30.4991627 },
      { lat: 50.4285338, lng: 30.4978323 },
      { lat: 50.4286158, lng: 30.4965448 },
      { lat: 50.4290532, lng: 30.4958582 },
      { lat: 50.4294633, lng: 30.4963732 },
      { lat: 50.4294633, lng: 30.4968452 },
      { lat: 50.4295453, lng: 30.4979181 },
      { lat: 50.4301194, lng: 30.4997635 },
      { lat: 50.432443, lng: 30.4976606 },
      { lat: 50.4329898, lng: 30.4963732 },
      { lat: 50.4334272, lng: 30.495928 },
      { lat: 50.4343566, lng: 30.4971886 },
      { lat: 50.4351766, lng: 30.4956865 },
      { lat: 50.43586, lng: 30.4943991 },
      { lat: 50.4364887, lng: 30.4931974 },
      { lat: 50.4372814, lng: 30.4923391 },
      { lat: 50.43709, lng: 30.4918242 },
      { lat: 50.4369534, lng: 30.4909229 },
      { lat: 50.4364613, lng: 30.4896784 },
      { lat: 50.4361401, lng: 30.4889488 },
      { lat: 50.4350501, lng: 30.4900646 },
      { lat: 50.4347025, lng: 30.4903328 },
      { lat: 50.4341361, lng: 30.4902577 },
      { lat: 50.4333588, lng: 30.4892492 },
      { lat: 50.4326891, lng: 30.4886055 },
      { lat: 50.4316366, lng: 30.4881334 },
      { lat: 50.4296683, lng: 30.4875326 },
      { lat: 50.429354, lng: 30.4871893 },
      { lat: 50.4294907, lng: 30.4847646 },
      { lat: 50.4280007, lng: 30.4772651 },
      { lat: 50.4274197, lng: 30.4758382 },
      { lat: 50.4251847, lng: 30.4772115 },
      { lat: 50.4244533, lng: 30.4779088 },
      { lat: 50.424132, lng: 30.4785633 },
      { lat: 50.4221087, lng: 30.4831553 },
      { lat: 50.4215721, lng: 30.4826886 },
      { lat: 50.4203861, lng: 30.485816 },
    ],
  },
  {
    name: 'ПРОТАСОВ ЯР',
    price: 115,
    points: [
      { lat: 50.4167766, lng: 30.4984331 },
      { lat: 50.4186087, lng: 30.5004501 },
      { lat: 50.4206595, lng: 30.5026817 },
      { lat: 50.4220472, lng: 30.5036366 },
      { lat: 50.4214662, lng: 30.5047846 },
      { lat: 50.4221087, lng: 30.5066729 },
      { lat: 50.422676, lng: 30.5081106 },
      { lat: 50.4241884, lng: 30.5058764 },
      { lat: 50.4262749, lng: 30.5039531 },
      { lat: 50.4259673, lng: 30.502435 },
      { lat: 50.4256562, lng: 30.5006755 },
      { lat: 50.4253145, lng: 30.500021 },
      { lat: 50.424795, lng: 30.4992807 },
      { lat: 50.4253009, lng: 30.4982508 },
      { lat: 50.4264423, lng: 30.4969525 },
      { lat: 50.4239133, lng: 30.4937553 },
      { lat: 50.4225667, lng: 30.4925966 },
      { lat: 50.4222864, lng: 30.4923928 },
      { lat: 50.4220199, lng: 30.4915881 },
      { lat: 50.4215037, lng: 30.4909551 },
      { lat: 50.4205775, lng: 30.4909552 },
      { lat: 50.4200853, lng: 30.4895926 },
      { lat: 50.4199759, lng: 30.4885626 },
      { lat: 50.4199486, lng: 30.4876184 },
      { lat: 50.4199759, lng: 30.4867601 },
      { lat: 50.4203861, lng: 30.485816 },
      { lat: 50.4197914, lng: 30.4852581 },
      { lat: 50.4181165, lng: 30.4894209 },
      { lat: 50.4184173, lng: 30.4907513 },
      { lat: 50.4182806, lng: 30.4911804 },
      { lat: 50.4177064, lng: 30.4906654 },
      { lat: 50.4175149, lng: 30.4910946 },
      { lat: 50.4182157, lng: 30.4918348 },
      { lat: 50.4179593, lng: 30.4924571 },
      { lat: 50.417761, lng: 30.4957294 },
      { lat: 50.4167766, lng: 30.4984331 },
    ],
  },
  {
    name: 'МОНТАЖНИКОВ',
    price: 116,
    points: [
      { lat: 50.4084355, lng: 30.4917812 },
      { lat: 50.4092423, lng: 30.4926288 },
      { lat: 50.4095842, lng: 30.4934549 },
      { lat: 50.4105414, lng: 30.4968882 },
      { lat: 50.4105961, lng: 30.4974461 },
      { lat: 50.4106782, lng: 30.4985189 },
      { lat: 50.4107055, lng: 30.4998064 },
      { lat: 50.4106782, lng: 30.5006647 },
      { lat: 50.4103226, lng: 30.5040979 },
      { lat: 50.41035, lng: 30.50457 },
      { lat: 50.410432, lng: 30.506587 },
      { lat: 50.4106235, lng: 30.5074024 },
      { lat: 50.4112798, lng: 30.5094194 },
      { lat: 50.4119909, lng: 30.511694 },
      { lat: 50.4124558, lng: 30.5140543 },
      { lat: 50.4128934, lng: 30.5153418 },
      { lat: 50.4142881, lng: 30.5186892 },
      { lat: 50.4157375, lng: 30.5174017 },
      { lat: 50.4179798, lng: 30.5149984 },
      { lat: 50.417597, lng: 30.5133247 },
      { lat: 50.422676, lng: 30.5081106 },
      { lat: 50.4221087, lng: 30.5066729 },
      { lat: 50.4214662, lng: 30.5047846 },
      { lat: 50.4220472, lng: 30.5036366 },
      { lat: 50.4206595, lng: 30.5026817 },
      { lat: 50.4167766, lng: 30.4984331 },
      { lat: 50.417761, lng: 30.4957294 },
      { lat: 50.4179593, lng: 30.4924571 },
      { lat: 50.4182157, lng: 30.4918348 },
      { lat: 50.4175149, lng: 30.4910946 },
      { lat: 50.4177064, lng: 30.4906654 },
      { lat: 50.4182806, lng: 30.4911804 },
      { lat: 50.4184173, lng: 30.4907513 },
      { lat: 50.4181165, lng: 30.4894209 },
      { lat: 50.4197914, lng: 30.4852581 },
      { lat: 50.4203861, lng: 30.485816 },
      { lat: 50.4215721, lng: 30.4826886 },
      { lat: 50.417426, lng: 30.4783166 },
      { lat: 50.4160588, lng: 30.4790568 },
      { lat: 50.4142061, lng: 30.4804516 },
      { lat: 50.4132489, lng: 30.4822969 },
      { lat: 50.4122644, lng: 30.4840565 },
      { lat: 50.41035, lng: 30.4879618 },
      { lat: 50.4084355, lng: 30.4917812 },
    ],
  },
  {
    name: 'МОСКОВСКАЯ ПЛ.',
    price: 117,
    points: [
      { lat: 50.4081073, lng: 30.5046558 },
      { lat: 50.407697, lng: 30.5048704 },
      { lat: 50.4075876, lng: 30.507617 },
      { lat: 50.4074509, lng: 30.5103207 },
      { lat: 50.4072594, lng: 30.5123377 },
      { lat: 50.407232, lng: 30.5134964 },
      { lat: 50.4059192, lng: 30.5133247 },
      { lat: 50.4047772, lng: 30.5131316 },
      { lat: 50.4040045, lng: 30.5127132 },
      { lat: 50.4035942, lng: 30.5121231 },
      { lat: 50.4031839, lng: 30.5112648 },
      { lat: 50.4027189, lng: 30.5100203 },
      { lat: 50.4001202, lng: 30.5117261 },
      { lat: 50.3992176, lng: 30.5090118 },
      { lat: 50.398773, lng: 30.5085719 },
      { lat: 50.3976821, lng: 30.5089742 },
      { lat: 50.3977129, lng: 30.5102348 },
      { lat: 50.398561, lng: 30.5111361 },
      { lat: 50.4003117, lng: 30.5132818 },
      { lat: 50.4026368, lng: 30.5156851 },
      { lat: 50.4054815, lng: 30.5177879 },
      { lat: 50.4054268, lng: 30.5193329 },
      { lat: 50.4055362, lng: 30.5198908 },
      { lat: 50.4059739, lng: 30.5206633 },
      { lat: 50.4059739, lng: 30.5213928 },
      { lat: 50.4058098, lng: 30.5221224 },
      { lat: 50.4039772, lng: 30.5246544 },
      { lat: 50.4058098, lng: 30.5302763 },
      { lat: 50.4060559, lng: 30.5292034 },
      { lat: 50.4065346, lng: 30.5270791 },
      { lat: 50.4067671, lng: 30.5262423 },
      { lat: 50.4073141, lng: 30.5251694 },
      { lat: 50.4090372, lng: 30.5228949 },
      { lat: 50.4142881, lng: 30.5186892 },
      { lat: 50.4128934, lng: 30.5153418 },
      { lat: 50.4124558, lng: 30.5140543 },
      { lat: 50.4119909, lng: 30.511694 },
      { lat: 50.4112798, lng: 30.5094194 },
      { lat: 50.4106235, lng: 30.5074024 },
      { lat: 50.410432, lng: 30.506587 },
      { lat: 50.41035, lng: 30.50457 },
      { lat: 50.4081073, lng: 30.5046558 },
    ],
  },
  {
    name: 'ШИРМА',
    price: 118,
    points: [
      { lat: 50.408162, lng: 30.4925537 },
      { lat: 50.4084355, lng: 30.4917812 },
      { lat: 50.4076697, lng: 30.491395 },
      { lat: 50.406685, lng: 30.4908371 },
      { lat: 50.4049071, lng: 30.490365 },
      { lat: 50.403136, lng: 30.4898178 },
      { lat: 50.4014606, lng: 30.488863 },
      { lat: 50.4007767, lng: 30.4882622 },
      { lat: 50.399792, lng: 30.4869747 },
      { lat: 50.3977882, lng: 30.4890776 },
      { lat: 50.3983968, lng: 30.4916954 },
      { lat: 50.3986157, lng: 30.4946566 },
      { lat: 50.3982053, lng: 30.4980469 },
      { lat: 50.3989439, lng: 30.4987764 },
      { lat: 50.3987251, lng: 30.500021 },
      { lat: 50.3986704, lng: 30.5007505 },
      { lat: 50.3987798, lng: 30.5016195 },
      { lat: 50.3989713, lng: 30.5023813 },
      { lat: 50.3992996, lng: 30.5034542 },
      { lat: 50.3996005, lng: 30.5046988 },
      { lat: 50.4001202, lng: 30.5063295 },
      { lat: 50.4005853, lng: 30.5074883 },
      { lat: 50.4010229, lng: 30.5079603 },
      { lat: 50.4016247, lng: 30.508604 },
      { lat: 50.4023359, lng: 30.5092907 },
      { lat: 50.4027189, lng: 30.5100203 },
      { lat: 50.4031839, lng: 30.5112648 },
      { lat: 50.4035942, lng: 30.5121231 },
      { lat: 50.4040045, lng: 30.5127132 },
      { lat: 50.4047772, lng: 30.5131316 },
      { lat: 50.4059192, lng: 30.5133247 },
      { lat: 50.407232, lng: 30.5134964 },
      { lat: 50.4072594, lng: 30.5123377 },
      { lat: 50.4074509, lng: 30.5103207 },
      { lat: 50.4075876, lng: 30.507617 },
      { lat: 50.407697, lng: 30.5048704 },
      { lat: 50.4077244, lng: 30.5014372 },
      { lat: 50.407697, lng: 30.49796 },
      { lat: 50.407697, lng: 30.4956865 },
      { lat: 50.4078338, lng: 30.4941845 },
      { lat: 50.408162, lng: 30.4925537 },
    ],
  },
  {
    name: 'СОВКИ',
    price: 119,
    points: [
      { lat: 50.3973299, lng: 30.4744864 },
      { lat: 50.3973573, lng: 30.4784775 },
      { lat: 50.3974941, lng: 30.4801941 },
      { lat: 50.397877, lng: 30.481782 },
      { lat: 50.3985883, lng: 30.4844427 },
      { lat: 50.399792, lng: 30.4869747 },
      { lat: 50.4007767, lng: 30.4882622 },
      { lat: 50.4014606, lng: 30.488863 },
      { lat: 50.403136, lng: 30.4898178 },
      { lat: 50.4049071, lng: 30.490365 },
      { lat: 50.406685, lng: 30.4908371 },
      { lat: 50.4076697, lng: 30.491395 },
      { lat: 50.4084355, lng: 30.4917812 },
      { lat: 50.41035, lng: 30.4879618 },
      { lat: 50.4122644, lng: 30.4840565 },
      { lat: 50.4132489, lng: 30.4822969 },
      { lat: 50.4142061, lng: 30.4804516 },
      { lat: 50.4130096, lng: 30.4794109 },
      { lat: 50.4111704, lng: 30.4800224 },
      { lat: 50.4102406, lng: 30.478735 },
      { lat: 50.4081346, lng: 30.4767179 },
      { lat: 50.4069038, lng: 30.4731131 },
      { lat: 50.4034848, lng: 30.4705811 },
      { lat: 50.4003117, lng: 30.4709673 },
      { lat: 50.3973299, lng: 30.4715252 },
      { lat: 50.3973299, lng: 30.4744864 },
    ],
  },
  {
    name: 'ДЕМЕЕВКА',
    price: 120,
    points: [
      { lat: 50.3938828, lng: 30.4915667 },
      { lat: 50.3910374, lng: 30.4927683 },
      { lat: 50.3897241, lng: 30.4931974 },
      { lat: 50.3885749, lng: 30.4943132 },
      { lat: 50.3881371, lng: 30.4947853 },
      { lat: 50.3893958, lng: 30.4980469 },
      { lat: 50.3897788, lng: 30.5016088 },
      { lat: 50.3918582, lng: 30.506587 },
      { lat: 50.3925696, lng: 30.5058146 },
      { lat: 50.3935819, lng: 30.5070591 },
      { lat: 50.3947036, lng: 30.508132 },
      { lat: 50.3961809, lng: 30.5090761 },
      { lat: 50.3969743, lng: 30.5096769 },
      { lat: 50.3977129, lng: 30.5102348 },
      { lat: 50.3976821, lng: 30.5089742 },
      { lat: 50.398773, lng: 30.5085719 },
      { lat: 50.3992176, lng: 30.5090118 },
      { lat: 50.4001202, lng: 30.5117261 },
      { lat: 50.4027189, lng: 30.5100203 },
      { lat: 50.4023359, lng: 30.5092907 },
      { lat: 50.4016247, lng: 30.508604 },
      { lat: 50.4010229, lng: 30.5079603 },
      { lat: 50.4005853, lng: 30.5074883 },
      { lat: 50.4001202, lng: 30.5063295 },
      { lat: 50.3996005, lng: 30.5046988 },
      { lat: 50.3992996, lng: 30.5034542 },
      { lat: 50.3989713, lng: 30.5023813 },
      { lat: 50.3987798, lng: 30.5016195 },
      { lat: 50.3986704, lng: 30.5007505 },
      { lat: 50.3987251, lng: 30.500021 },
      { lat: 50.3989439, lng: 30.4987764 },
      { lat: 50.3982053, lng: 30.4980469 },
      { lat: 50.3986157, lng: 30.4946566 },
      { lat: 50.3983968, lng: 30.4916954 },
      { lat: 50.3977882, lng: 30.4890776 },
      { lat: 50.393746, lng: 30.490365 },
      { lat: 50.3938828, lng: 30.4915667 },
    ],
  },
  {
    name: 'М. ВАСИЛЬКОВСКАЯ',
    price: 121,
    points: [
      { lat: 50.3845526, lng: 30.4759884 },
      { lat: 50.3837043, lng: 30.4774904 },
      { lat: 50.3834853, lng: 30.4783058 },
      { lat: 50.3836496, lng: 30.4783058 },
      { lat: 50.3899978, lng: 30.4827261 },
      { lat: 50.3921729, lng: 30.4843138 },
      { lat: 50.3927953, lng: 30.485494 },
      { lat: 50.393746, lng: 30.490365 },
      { lat: 50.3977882, lng: 30.4890776 },
      { lat: 50.399792, lng: 30.4869747 },
      { lat: 50.3985883, lng: 30.4844427 },
      { lat: 50.397877, lng: 30.481782 },
      { lat: 50.3974941, lng: 30.4801941 },
      { lat: 50.3973573, lng: 30.4784775 },
      { lat: 50.3973299, lng: 30.4744864 },
      { lat: 50.3973299, lng: 30.4715252 },
      { lat: 50.3963998, lng: 30.4669762 },
      { lat: 50.3915846, lng: 30.4678345 },
      { lat: 50.387973, lng: 30.4722977 },
      { lat: 50.3845526, lng: 30.4759884 },
    ],
  },
  {
    name: 'ТЕРЕМКИ 2',
    price: 122,
    points: [
      { lat: 50.3702937, lng: 30.4585648 },
      { lat: 50.3740983, lng: 30.4644012 },
      { lat: 50.3777658, lng: 30.4698515 },
      { lat: 50.3789152, lng: 30.4718685 },
      { lat: 50.3801194, lng: 30.474658 },
      { lat: 50.3812414, lng: 30.4775333 },
      { lat: 50.3815424, lng: 30.4773617 },
      { lat: 50.3834853, lng: 30.4783058 },
      { lat: 50.3837043, lng: 30.4774904 },
      { lat: 50.3845526, lng: 30.4759884 },
      { lat: 50.387973, lng: 30.4722977 },
      { lat: 50.3915846, lng: 30.4678345 },
      { lat: 50.3913658, lng: 30.4646158 },
      { lat: 50.3904355, lng: 30.4613972 },
      { lat: 50.3889306, lng: 30.4613113 },
      { lat: 50.389259, lng: 30.4592514 },
      { lat: 50.3881508, lng: 30.4585621 },
      { lat: 50.3886023, lng: 30.4567623 },
      { lat: 50.387583, lng: 30.4557753 },
      { lat: 50.3846073, lng: 30.4544449 },
      { lat: 50.3841968, lng: 30.453887 },
      { lat: 50.3863859, lng: 30.4493809 },
      { lat: 50.3821718, lng: 30.4422998 },
      { lat: 50.3740709, lng: 30.4535007 },
      { lat: 50.3732224, lng: 30.4545736 },
      { lat: 50.3702937, lng: 30.4585648 },
    ],
  },
  {
    name: 'ТЕРЕМКИ 1',
    price: 123,
    points: [
      { lat: 50.3676932, lng: 30.4370642 },
      { lat: 50.3667351, lng: 30.4374075 },
      { lat: 50.3647368, lng: 30.4369354 },
      { lat: 50.3631763, lng: 30.4362488 },
      { lat: 50.3613421, lng: 30.4355192 },
      { lat: 50.3608766, lng: 30.4359484 },
      { lat: 50.3601101, lng: 30.4374075 },
      { lat: 50.3567424, lng: 30.4374719 },
      { lat: 50.3637512, lng: 30.4479647 },
      { lat: 50.3634501, lng: 30.4487801 },
      { lat: 50.3585495, lng: 30.4418278 },
      { lat: 50.3613968, lng: 30.4561186 },
      { lat: 50.3607671, lng: 30.4634142 },
      { lat: 50.3602196, lng: 30.4653883 },
      { lat: 50.3601443, lng: 30.4682851 },
      { lat: 50.359097, lng: 30.4677057 },
      { lat: 50.3584947, lng: 30.467062 },
      { lat: 50.3573721, lng: 30.4710102 },
      { lat: 50.3561127, lng: 30.4721689 },
      { lat: 50.3572626, lng: 30.4748297 },
      { lat: 50.3587137, lng: 30.4730272 },
      { lat: 50.3608493, lng: 30.4707956 },
      { lat: 50.3619991, lng: 30.4697227 },
      { lat: 50.3649558, lng: 30.4657745 },
      { lat: 50.3729214, lng: 30.4696369 },
      { lat: 50.3785457, lng: 30.4721475 },
      { lat: 50.3789152, lng: 30.4718685 },
      { lat: 50.3777658, lng: 30.4698515 },
      { lat: 50.3740983, lng: 30.4644012 },
      { lat: 50.3702937, lng: 30.4585648 },
      { lat: 50.3732224, lng: 30.4545736 },
      { lat: 50.3740709, lng: 30.4535007 },
      { lat: 50.3734414, lng: 30.4522991 },
      { lat: 50.3715803, lng: 30.4476423 },
      { lat: 50.3676932, lng: 30.4370642 },
    ],
  },
  {
    name: 'ВИШНЁВОЕ-ЗАЗ',
    price: 124,
    points: [
      { lat: 50.3855924, lng: 30.3300762 },
      { lat: 50.3830201, lng: 30.3331661 },
      { lat: 50.3791341, lng: 30.3378224 },
      { lat: 50.3767258, lng: 30.3411484 },
      { lat: 50.3804478, lng: 30.3543663 },
      { lat: 50.3818161, lng: 30.3596878 },
      { lat: 50.3836769, lng: 30.3661251 },
      { lat: 50.3865227, lng: 30.3772831 },
      { lat: 50.388055, lng: 30.3830338 },
      { lat: 50.3902987, lng: 30.3909302 },
      { lat: 50.3950592, lng: 30.3870035 },
      { lat: 50.3967829, lng: 30.3806628 },
      { lat: 50.395757, lng: 30.3794451 },
      { lat: 50.3969196, lng: 30.3760386 },
      { lat: 50.3982233, lng: 30.3681218 },
      { lat: 50.3928158, lng: 30.3637218 },
      { lat: 50.3957158, lng: 30.3552246 },
      { lat: 50.3916667, lng: 30.3432941 },
      { lat: 50.3855924, lng: 30.3300762 },
    ],
  },
  {
    name: 'ВИШНЁВОЕ-ЖУЛЯНЫ',
    price: 125,
    points: [
      { lat: 50.3939649, lng: 30.4118729 },
      { lat: 50.3900251, lng: 30.4189968 },
      { lat: 50.3928432, lng: 30.4226875 },
      { lat: 50.3949464, lng: 30.4222101 },
      { lat: 50.3957706, lng: 30.4230738 },
      { lat: 50.3981506, lng: 30.4178381 },
      { lat: 50.3965913, lng: 30.4142761 },
      { lat: 50.3902987, lng: 30.3909302 },
      { lat: 50.388055, lng: 30.3830338 },
      { lat: 50.3865227, lng: 30.3772831 },
      { lat: 50.3836769, lng: 30.3661251 },
      { lat: 50.3818161, lng: 30.3596878 },
      { lat: 50.3804478, lng: 30.3543663 },
      { lat: 50.3798457, lng: 30.3567696 },
      { lat: 50.3793531, lng: 30.3603745 },
      { lat: 50.3794078, lng: 30.3653526 },
      { lat: 50.378751, lng: 30.3717041 },
      { lat: 50.3810088, lng: 30.3724122 },
      { lat: 50.3798457, lng: 30.3816605 },
      { lat: 50.3855924, lng: 30.3897285 },
      { lat: 50.3881098, lng: 30.3939342 },
      { lat: 50.3939649, lng: 30.4118729 },
    ],
  },
  {
    name: 'СВЯТОПЕТРОВСКОЕ',
    price: 126,
    points: [
      { lat: 50.3912837, lng: 30.3206348 },
      { lat: 50.392597, lng: 30.3246689 },
      { lat: 50.3958253, lng: 30.3212357 },
      { lat: 50.396646, lng: 30.3222656 },
      { lat: 50.397795, lng: 30.3201199 },
      { lat: 50.393363, lng: 30.3034687 },
      { lat: 50.3944574, lng: 30.3039837 },
      { lat: 50.3950592, lng: 30.3005505 },
      { lat: 50.3944574, lng: 30.3004646 },
      { lat: 50.3945668, lng: 30.2989197 },
      { lat: 50.3921045, lng: 30.2981472 },
      { lat: 50.3905723, lng: 30.3032112 },
      { lat: 50.3888759, lng: 30.3067303 },
      { lat: 50.3830749, lng: 30.3128242 },
      { lat: 50.3834033, lng: 30.3141117 },
      { lat: 50.379791, lng: 30.3166008 },
      { lat: 50.3748099, lng: 30.2966881 },
      { lat: 50.3739888, lng: 30.2960873 },
      { lat: 50.3720729, lng: 30.3005505 },
      { lat: 50.3718539, lng: 30.3044987 },
      { lat: 50.3749194, lng: 30.3185749 },
      { lat: 50.3743173, lng: 30.3194332 },
      { lat: 50.3738246, lng: 30.3180599 },
      { lat: 50.3725655, lng: 30.3197765 },
      { lat: 50.373113, lng: 30.3208923 },
      { lat: 50.3716349, lng: 30.3222656 },
      { lat: 50.3760689, lng: 30.3293037 },
      { lat: 50.3761784, lng: 30.330162 },
      { lat: 50.3762879, lng: 30.3329086 },
      { lat: 50.3791341, lng: 30.3378224 },
      { lat: 50.3830201, lng: 30.3331661 },
      { lat: 50.3817613, lng: 30.3299904 },
      { lat: 50.3854829, lng: 30.3257847 },
      { lat: 50.3912837, lng: 30.3206348 },
    ],
  },
  {
    name: 'КРЮКОВЩИНА',
    price: 127,
    points: [
      { lat: 50.3734961, lng: 30.3343678 },
      { lat: 50.3638607, lng: 30.3513622 },
      { lat: 50.3593708, lng: 30.3579712 },
      { lat: 50.35729, lng: 30.3583145 },
      { lat: 50.3561948, lng: 30.3586579 },
      { lat: 50.3552091, lng: 30.3603745 },
      { lat: 50.3523614, lng: 30.3665543 },
      { lat: 50.3573995, lng: 30.3698158 },
      { lat: 50.3581114, lng: 30.3735065 },
      { lat: 50.3587137, lng: 30.3766823 },
      { lat: 50.3598089, lng: 30.3780556 },
      { lat: 50.3614516, lng: 30.3809738 },
      { lat: 50.3627109, lng: 30.3802872 },
      { lat: 50.3646273, lng: 30.3812313 },
      { lat: 50.3686787, lng: 30.3818321 },
      { lat: 50.368186, lng: 30.3865528 },
      { lat: 50.3658318, lng: 30.3856087 },
      { lat: 50.3652295, lng: 30.3890419 },
      { lat: 50.3698283, lng: 30.3909302 },
      { lat: 50.3838411, lng: 30.3978825 },
      { lat: 50.3855924, lng: 30.3897285 },
      { lat: 50.3798457, lng: 30.3816605 },
      { lat: 50.3810088, lng: 30.3724122 },
      { lat: 50.378751, lng: 30.3717041 },
      { lat: 50.3794078, lng: 30.3653526 },
      { lat: 50.3793531, lng: 30.3603745 },
      { lat: 50.3798457, lng: 30.3567696 },
      { lat: 50.3804478, lng: 30.3543663 },
      { lat: 50.3767258, lng: 30.3411484 },
      { lat: 50.3748099, lng: 30.3360844 },
      { lat: 50.3734961, lng: 30.3343678 },
    ],
  },
  {
    name: 'ГАТНОЕ',
    price: 128,
    points: [
      { lat: 50.3701568, lng: 30.4146194 },
      { lat: 50.3698283, lng: 30.3909302 },
      { lat: 50.3652295, lng: 30.3890419 },
      { lat: 50.3638607, lng: 30.3856945 },
      { lat: 50.3615063, lng: 30.3847504 },
      { lat: 50.3616158, lng: 30.3828621 },
      { lat: 50.3614516, lng: 30.3809738 },
      { lat: 50.3598089, lng: 30.3780556 },
      { lat: 50.3587137, lng: 30.3766823 },
      { lat: 50.3581114, lng: 30.3735065 },
      { lat: 50.3573995, lng: 30.3698158 },
      { lat: 50.3523614, lng: 30.3665543 },
      { lat: 50.3483085, lng: 30.378828 },
      { lat: 50.3473774, lng: 30.3782272 },
      { lat: 50.3388871, lng: 30.3984833 },
      { lat: 50.3392706, lng: 30.4036331 },
      { lat: 50.3399827, lng: 30.4041481 },
      { lat: 50.3421739, lng: 30.400629 },
      { lat: 50.3458986, lng: 30.4020023 },
      { lat: 50.3479799, lng: 30.4002857 },
      { lat: 50.3526352, lng: 30.4044056 },
      { lat: 50.3493492, lng: 30.413332 },
      { lat: 50.3501159, lng: 30.4150486 },
      { lat: 50.3481442, lng: 30.4180527 },
      { lat: 50.3500612, lng: 30.4214859 },
      { lat: 50.3494039, lng: 30.4223442 },
      { lat: 50.3500064, lng: 30.4241467 },
      { lat: 50.3488562, lng: 30.4263783 },
      { lat: 50.3520875, lng: 30.4312706 },
      { lat: 50.351047, lng: 30.433073 },
      { lat: 50.3530733, lng: 30.4358196 },
      { lat: 50.3538948, lng: 30.4344463 },
      { lat: 50.3567424, lng: 30.4374719 },
      { lat: 50.3601101, lng: 30.4374075 },
      { lat: 50.3608766, lng: 30.4359484 },
      { lat: 50.3613421, lng: 30.4355192 },
      { lat: 50.3631763, lng: 30.4362488 },
      { lat: 50.3647368, lng: 30.4369354 },
      { lat: 50.3667351, lng: 30.4374075 },
      { lat: 50.3676932, lng: 30.4370642 },
      { lat: 50.3749194, lng: 30.4258633 },
      { lat: 50.3701568, lng: 30.4146194 },
    ],
  },
  {
    name: 'КП ОДЕССА',
    price: 129,
    points: [
      { lat: 50.3567424, lng: 30.4374719 },
      { lat: 50.356318, lng: 30.4384696 },
      { lat: 50.3585495, lng: 30.4418278 },
      { lat: 50.3634501, lng: 30.4487801 },
      { lat: 50.3637512, lng: 30.4479647 },
      { lat: 50.3567424, lng: 30.4374719 },
    ],
  },
  {
    name: 'НОВОСЁЛКИ (ТЕРЕМКИ)',
    price: 130,
    points: [
      { lat: 50.3460903, lng: 30.4448318 },
      { lat: 50.3483907, lng: 30.4535437 },
      { lat: 50.3486372, lng: 30.4542303 },
      { lat: 50.3436802, lng: 30.4519987 },
      { lat: 50.3430229, lng: 30.4526854 },
      { lat: 50.3471857, lng: 30.4602385 },
      { lat: 50.3494313, lng: 30.459938 },
      { lat: 50.3530733, lng: 30.4677486 },
      { lat: 50.3547162, lng: 30.4688644 },
      { lat: 50.3573721, lng: 30.4710102 },
      { lat: 50.3584947, lng: 30.467062 },
      { lat: 50.359097, lng: 30.4677057 },
      { lat: 50.3601443, lng: 30.4682851 },
      { lat: 50.3602196, lng: 30.4653883 },
      { lat: 50.3607671, lng: 30.4634142 },
      { lat: 50.3613968, lng: 30.4561186 },
      { lat: 50.3600005, lng: 30.4546165 },
      { lat: 50.3555924, lng: 30.4524279 },
      { lat: 50.3548257, lng: 30.4512691 },
      { lat: 50.3557567, lng: 30.4433727 },
      { lat: 50.3545519, lng: 30.444231 },
      { lat: 50.3533198, lng: 30.4399824 },
      { lat: 50.3460903, lng: 30.4448318 },
    ],
  },
  {
    name: 'ЧАБАНЫ',
    price: 130,
    points: [
      { lat: 50.3422287, lng: 30.430069 },
      { lat: 50.3443375, lng: 30.4291248 },
      { lat: 50.3464463, lng: 30.4301548 },
      { lat: 50.3488562, lng: 30.4263783 },
      { lat: 50.3471857, lng: 30.4229879 },
      { lat: 50.3453509, lng: 30.4203272 },
      { lat: 50.3359563, lng: 30.4063797 },
      { lat: 50.3341757, lng: 30.4103279 },
      { lat: 50.33308, lng: 30.4142761 },
      { lat: 50.3324773, lng: 30.4139757 },
      { lat: 50.3314363, lng: 30.418911 },
      { lat: 50.33371, lng: 30.419898 },
      { lat: 50.3365589, lng: 30.4253483 },
      { lat: 50.3361206, lng: 30.4261637 },
      { lat: 50.335518, lng: 30.4296827 },
      { lat: 50.3365315, lng: 30.4329872 },
      { lat: 50.3352441, lng: 30.4348326 },
      { lat: 50.3356823, lng: 30.4362488 },
      { lat: 50.3363398, lng: 30.4408407 },
      { lat: 50.3373806, lng: 30.4408836 },
      { lat: 50.3399006, lng: 30.4425144 },
      { lat: 50.3426121, lng: 30.4404545 },
      { lat: 50.3419, lng: 30.4377508 },
      { lat: 50.3439267, lng: 30.4362917 },
      { lat: 50.3422287, lng: 30.430069 },
    ],
  },
  {
    name: 'ХОТОВ',
    price: 131,
    points: [
      { lat: 50.3301214, lng: 30.4320431 },
      { lat: 50.3243679, lng: 30.4408836 },
      { lat: 50.3202579, lng: 30.4467201 },
      { lat: 50.3168052, lng: 30.4566765 },
      { lat: 50.3157639, lng: 30.4601097 },
      { lat: 50.312859, lng: 30.4672337 },
      { lat: 50.3179562, lng: 30.4692936 },
      { lat: 50.3174629, lng: 30.4719543 },
      { lat: 50.3189427, lng: 30.472641 },
      { lat: 50.318011, lng: 30.4789066 },
      { lat: 50.3180658, lng: 30.4834557 },
      { lat: 50.3203675, lng: 30.4968452 },
      { lat: 50.322176, lng: 30.4962444 },
      { lat: 50.3268886, lng: 30.4886055 },
      { lat: 50.3281077, lng: 30.4893351 },
      { lat: 50.3302447, lng: 30.4957724 },
      { lat: 50.3315459, lng: 30.4947853 },
      { lat: 50.3328745, lng: 30.498004 },
      { lat: 50.3339018, lng: 30.4936695 },
      { lat: 50.3344497, lng: 30.4924679 },
      { lat: 50.3356549, lng: 30.4910088 },
      { lat: 50.3360932, lng: 30.4862022 },
      { lat: 50.3360932, lng: 30.4850006 },
      { lat: 50.3339566, lng: 30.4846573 },
      { lat: 50.3334087, lng: 30.4779625 },
      { lat: 50.3335183, lng: 30.4747868 },
      { lat: 50.335381, lng: 30.4744434 },
      { lat: 50.3367506, lng: 30.474894 },
      { lat: 50.3368054, lng: 30.4770184 },
      { lat: 50.3373532, lng: 30.4770184 },
      { lat: 50.337408, lng: 30.4781342 },
      { lat: 50.339928, lng: 30.4778767 },
      { lat: 50.3397636, lng: 30.4763317 },
      { lat: 50.339928, lng: 30.4693794 },
      { lat: 50.3408592, lng: 30.4576206 },
      { lat: 50.334614, lng: 30.4517841 },
      { lat: 50.33308, lng: 30.4515266 },
      { lat: 50.3328061, lng: 30.4470634 },
      { lat: 50.3340662, lng: 30.4464626 },
      { lat: 50.3363398, lng: 30.4408407 },
      { lat: 50.3356823, lng: 30.4362488 },
      { lat: 50.3352441, lng: 30.4348326 },
      { lat: 50.3342305, lng: 30.4364204 },
      { lat: 50.3301214, lng: 30.4320431 },
    ],
  },
  {
    name: 'ФЕОФАНИЯ',
    price: 132,
    points: [
      { lat: 50.3630942, lng: 30.5001068 },
      { lat: 50.3650105, lng: 30.5026817 },
      { lat: 50.3667078, lng: 30.5017376 },
      { lat: 50.3658318, lng: 30.4958153 },
      { lat: 50.3652843, lng: 30.4840565 },
      { lat: 50.3608493, lng: 30.4707956 },
      { lat: 50.3587137, lng: 30.4730272 },
      { lat: 50.3572626, lng: 30.4748297 },
      { lat: 50.3561127, lng: 30.4721689 },
      { lat: 50.3573721, lng: 30.4710102 },
      { lat: 50.3547162, lng: 30.4688644 },
      { lat: 50.3530733, lng: 30.4677486 },
      { lat: 50.340421, lng: 30.4723835 },
      { lat: 50.3397636, lng: 30.4763317 },
      { lat: 50.339928, lng: 30.4778767 },
      { lat: 50.337408, lng: 30.4781342 },
      { lat: 50.336915, lng: 30.4919529 },
      { lat: 50.339928, lng: 30.4935837 },
      { lat: 50.3507731, lng: 30.4963303 },
      { lat: 50.352909, lng: 30.5052567 },
      { lat: 50.3564686, lng: 30.5039692 },
      { lat: 50.3630942, lng: 30.5001068 },
    ],
  },
  {
    name: 'ВДНХ',
    price: 133,
    points: [
      { lat: 50.3753573, lng: 30.4866743 },
      { lat: 50.3763426, lng: 30.487318 },
      { lat: 50.3783679, lng: 30.4878759 },
      { lat: 50.3788605, lng: 30.4868031 },
      { lat: 50.3815971, lng: 30.4843569 },
      { lat: 50.3828286, lng: 30.4845285 },
      { lat: 50.3836222, lng: 30.4837561 },
      { lat: 50.3812414, lng: 30.4775333 },
      { lat: 50.3801194, lng: 30.474658 },
      { lat: 50.3789152, lng: 30.4718685 },
      { lat: 50.3785457, lng: 30.4721475 },
      { lat: 50.3729214, lng: 30.4696369 },
      { lat: 50.3649558, lng: 30.4657745 },
      { lat: 50.3619991, lng: 30.4697227 },
      { lat: 50.3608493, lng: 30.4707956 },
      { lat: 50.3652843, lng: 30.4840565 },
      { lat: 50.3753573, lng: 30.4866743 },
    ],
  },
  {
    name: 'ПИРОГОВ',
    price: 134,
    points: [
      { lat: 50.360904, lng: 30.5154705 },
      { lat: 50.3564686, lng: 30.5039692 },
      { lat: 50.352909, lng: 30.5052567 },
      { lat: 50.3507731, lng: 30.4963303 },
      { lat: 50.3454604, lng: 30.5009651 },
      { lat: 50.3437076, lng: 30.5031967 },
      { lat: 50.3412427, lng: 30.5087757 },
      { lat: 50.3393254, lng: 30.5127239 },
      { lat: 50.3388871, lng: 30.5151272 },
      { lat: 50.3388871, lng: 30.5163288 },
      { lat: 50.3390515, lng: 30.5189896 },
      { lat: 50.3395445, lng: 30.5210495 },
      { lat: 50.3411331, lng: 30.5243969 },
      { lat: 50.3418452, lng: 30.5271435 },
      { lat: 50.3420643, lng: 30.53092 },
      { lat: 50.3421191, lng: 30.5357265 },
      { lat: 50.3417904, lng: 30.5399323 },
      { lat: 50.342393, lng: 30.5399323 },
      { lat: 50.343872, lng: 30.5401039 },
      { lat: 50.3452413, lng: 30.5403614 },
      { lat: 50.346282, lng: 30.5407906 },
      { lat: 50.3496778, lng: 30.542593 },
      { lat: 50.3543329, lng: 30.5445671 },
      { lat: 50.3550448, lng: 30.5450821 },
      { lat: 50.3567972, lng: 30.5455112 },
      { lat: 50.3621086, lng: 30.5460262 },
      { lat: 50.364025, lng: 30.5462837 },
      { lat: 50.3652295, lng: 30.5465412 },
      { lat: 50.3654485, lng: 30.5443096 },
      { lat: 50.3605207, lng: 30.5395889 },
      { lat: 50.360904, lng: 30.5154705 },
    ],
  },
  {
    name: 'КП ОБУХОВ',
    price: 135,
    points: [
      { lat: 50.3165312, lng: 30.5352116 },
      { lat: 50.3163668, lng: 30.5362415 },
      { lat: 50.324094, lng: 30.538044 },
      { lat: 50.3287516, lng: 30.5382156 },
      { lat: 50.3300666, lng: 30.538559 },
      { lat: 50.3317651, lng: 30.5395889 },
      { lat: 50.333847, lng: 30.5406189 },
      { lat: 50.3350523, lng: 30.5407906 },
      { lat: 50.3368054, lng: 30.5409622 },
      { lat: 50.3379011, lng: 30.5414772 },
      { lat: 50.338668, lng: 30.5422497 },
      { lat: 50.3392158, lng: 30.541563 },
      { lat: 50.3385037, lng: 30.5406189 },
      { lat: 50.3365315, lng: 30.5400181 },
      { lat: 50.3347784, lng: 30.5398464 },
      { lat: 50.3339566, lng: 30.5396748 },
      { lat: 50.3326417, lng: 30.5391598 },
      { lat: 50.3305597, lng: 30.5377007 },
      { lat: 50.3288064, lng: 30.5371857 },
      { lat: 50.3248611, lng: 30.5369282 },
      { lat: 50.3222856, lng: 30.536499 },
      { lat: 50.3165312, lng: 30.5352116 },
    ],
  },
  {
    name: 'КОНЧА-ЗАСПА',
    price: 136,
    points: [
      { lat: 50.3368054, lng: 30.5549526 },
      { lat: 50.3354906, lng: 30.5565834 },
      { lat: 50.3342853, lng: 30.5578709 },
      { lat: 50.3335731, lng: 30.5584717 },
      { lat: 50.3312172, lng: 30.5592442 },
      { lat: 50.328642, lng: 30.5601883 },
      { lat: 50.3245323, lng: 30.5618191 },
      { lat: 50.3209704, lng: 30.5632782 },
      { lat: 50.3166408, lng: 30.5655098 },
      { lat: 50.3050752, lng: 30.5728054 },
      { lat: 50.3019504, lng: 30.5748653 },
      { lat: 50.3012925, lng: 30.5710888 },
      { lat: 50.296687, lng: 30.5733204 },
      { lat: 50.2975643, lng: 30.5794144 },
      { lat: 50.2942813, lng: 30.5830407 },
      { lat: 50.2955698, lng: 30.5889951 },
      { lat: 50.298743, lng: 30.5867743 },
      { lat: 50.3007991, lng: 30.5776978 },
      { lat: 50.3169149, lng: 30.5667114 },
      { lat: 50.3211896, lng: 30.5641365 },
      { lat: 50.3257927, lng: 30.5619907 },
      { lat: 50.3287516, lng: 30.5607891 },
      { lat: 50.3317651, lng: 30.559845 },
      { lat: 50.3336827, lng: 30.5590725 },
      { lat: 50.3345592, lng: 30.5583858 },
      { lat: 50.3358741, lng: 30.5568409 },
      { lat: 50.3371136, lng: 30.5554354 },
      { lat: 50.3368054, lng: 30.5549526 },
    ],
  },
  {
    name: 'ЧАПАЕВКА',
    price: 137,
    points: [
      { lat: 50.3202579, lng: 30.5833626 },
      { lat: 50.3255187, lng: 30.5867958 },
      { lat: 50.336915, lng: 30.6072235 },
      { lat: 50.3413522, lng: 30.6022882 },
      { lat: 50.3452413, lng: 30.5995417 },
      { lat: 50.3497325, lng: 30.5940056 },
      { lat: 50.3525804, lng: 30.5854225 },
      { lat: 50.3424066, lng: 30.5579996 },
      { lat: 50.3407685, lng: 30.5565803 },
      { lat: 50.340349, lng: 30.5540025 },
      { lat: 50.3374832, lng: 30.5547693 },
      { lat: 50.3332854, lng: 30.5511539 },
      { lat: 50.3336552, lng: 30.5471849 },
      { lat: 50.3387949, lng: 30.5481182 },
      { lat: 50.3400171, lng: 30.5433439 },
      { lat: 50.3404348, lng: 30.5423783 },
      { lat: 50.3410509, lng: 30.5413055 },
      { lat: 50.3417904, lng: 30.5399323 },
      { lat: 50.3421191, lng: 30.5357265 },
      { lat: 50.3420643, lng: 30.53092 },
      { lat: 50.3418452, lng: 30.5271435 },
      { lat: 50.3411331, lng: 30.5243969 },
      { lat: 50.3395445, lng: 30.5210495 },
      { lat: 50.3390515, lng: 30.5189896 },
      { lat: 50.3388871, lng: 30.5163288 },
      { lat: 50.3388871, lng: 30.5151272 },
      { lat: 50.3393254, lng: 30.5127239 },
      { lat: 50.333847, lng: 30.5023384 },
      { lat: 50.3215732, lng: 30.5004501 },
      { lat: 50.3165312, lng: 30.5352116 },
      { lat: 50.3222856, lng: 30.536499 },
      { lat: 50.3248611, lng: 30.5369282 },
      { lat: 50.3288064, lng: 30.5371857 },
      { lat: 50.3305597, lng: 30.5377007 },
      { lat: 50.3326417, lng: 30.5391598 },
      { lat: 50.3339566, lng: 30.5396748 },
      { lat: 50.3347784, lng: 30.5398464 },
      { lat: 50.3365315, lng: 30.5400181 },
      { lat: 50.3385037, lng: 30.5406189 },
      { lat: 50.3392158, lng: 30.541563 },
      { lat: 50.338668, lng: 30.5422497 },
      { lat: 50.3379011, lng: 30.5414772 },
      { lat: 50.3368054, lng: 30.5409622 },
      { lat: 50.333847, lng: 30.5406189 },
      { lat: 50.3317651, lng: 30.5395889 },
      { lat: 50.3300666, lng: 30.538559 },
      { lat: 50.3287516, lng: 30.5382156 },
      { lat: 50.324094, lng: 30.538044 },
      { lat: 50.3163668, lng: 30.5362415 },
      { lat: 50.31401, lng: 30.5514336 },
      { lat: 50.3166408, lng: 30.5655098 },
      { lat: 50.3209704, lng: 30.5632782 },
      { lat: 50.3245323, lng: 30.5618191 },
      { lat: 50.328642, lng: 30.5601883 },
      { lat: 50.3312172, lng: 30.5592442 },
      { lat: 50.3335731, lng: 30.5584717 },
      { lat: 50.3342853, lng: 30.5578709 },
      { lat: 50.335696, lng: 30.5563474 },
      { lat: 50.3368054, lng: 30.5549526 },
      { lat: 50.3371136, lng: 30.5554354 },
      { lat: 50.3345592, lng: 30.5583858 },
      { lat: 50.3336827, lng: 30.5590725 },
      { lat: 50.3317651, lng: 30.559845 },
      { lat: 50.3287516, lng: 30.5607891 },
      { lat: 50.3257927, lng: 30.5619907 },
      { lat: 50.3211896, lng: 30.5641365 },
      { lat: 50.3169149, lng: 30.5667114 },
      { lat: 50.3202579, lng: 30.5833626 },
    ],
  },
  {
    name: 'КОРЧЕВАТОЕ',
    price: 138,
    points: [
      { lat: 50.3506362, lng: 30.565424 },
      { lat: 50.3717991, lng: 30.5662823 },
      { lat: 50.3738793, lng: 30.564394 },
      { lat: 50.374372, lng: 30.5607033 },
      { lat: 50.3857018, lng: 30.5529785 },
      { lat: 50.3830201, lng: 30.5517769 },
      { lat: 50.3807762, lng: 30.5510044 },
      { lat: 50.3782584, lng: 30.5503178 },
      { lat: 50.3760142, lng: 30.5492878 },
      { lat: 50.3732772, lng: 30.5484295 },
      { lat: 50.3715254, lng: 30.5479145 },
      { lat: 50.3689387, lng: 30.5475283 },
      { lat: 50.3652295, lng: 30.5465412 },
      { lat: 50.364025, lng: 30.5462837 },
      { lat: 50.3621086, lng: 30.5460262 },
      { lat: 50.3567972, lng: 30.5455112 },
      { lat: 50.3550448, lng: 30.5450821 },
      { lat: 50.3543329, lng: 30.5445671 },
      { lat: 50.3529912, lng: 30.5439663 },
      { lat: 50.3515398, lng: 30.5477428 },
      { lat: 50.3504719, lng: 30.5516481 },
      { lat: 50.3509648, lng: 30.5569267 },
      { lat: 50.3506362, lng: 30.565424 },
    ],
  },
  {
    name: 'МЫШЕЛОВКА',
    price: 139,
    points: [
      { lat: 50.3696094, lng: 30.5098915 },
      { lat: 50.3661603, lng: 30.5128527 },
      { lat: 50.3643809, lng: 30.5184746 },
      { lat: 50.3654485, lng: 30.5443096 },
      { lat: 50.3652295, lng: 30.5465412 },
      { lat: 50.3689387, lng: 30.5475283 },
      { lat: 50.3715254, lng: 30.5479145 },
      { lat: 50.3732772, lng: 30.5484295 },
      { lat: 50.3735783, lng: 30.5467987 },
      { lat: 50.3742078, lng: 30.5423355 },
      { lat: 50.3738246, lng: 30.5412197 },
      { lat: 50.3737425, lng: 30.5401468 },
      { lat: 50.3743173, lng: 30.534997 },
      { lat: 50.3748852, lng: 30.5312097 },
      { lat: 50.3758226, lng: 30.5285168 },
      { lat: 50.3777931, lng: 30.5244398 },
      { lat: 50.3794626, lng: 30.5210066 },
      { lat: 50.3764521, lng: 30.5190754 },
      { lat: 50.3742899, lng: 30.5170584 },
      { lat: 50.3735509, lng: 30.5125523 },
      { lat: 50.3716349, lng: 30.5109215 },
      { lat: 50.3696094, lng: 30.5098915 },
    ],
  },
  {
    name: 'НАУКИ',
    price: 140,
    points: [
      { lat: 50.3810498, lng: 30.5208349 },
      { lat: 50.3794626, lng: 30.5210066 },
      { lat: 50.3777931, lng: 30.5244398 },
      { lat: 50.3758226, lng: 30.5285168 },
      { lat: 50.3748852, lng: 30.5312097 },
      { lat: 50.3743173, lng: 30.534997 },
      { lat: 50.3737425, lng: 30.5401468 },
      { lat: 50.3738246, lng: 30.5412197 },
      { lat: 50.3742078, lng: 30.5423355 },
      { lat: 50.3735783, lng: 30.5467987 },
      { lat: 50.3732772, lng: 30.5484295 },
      { lat: 50.3760142, lng: 30.5492878 },
      { lat: 50.3782584, lng: 30.5503178 },
      { lat: 50.3807762, lng: 30.5510044 },
      { lat: 50.3830201, lng: 30.5517769 },
      { lat: 50.3857018, lng: 30.5529785 },
      { lat: 50.3890127, lng: 30.5543089 },
      { lat: 50.3905176, lng: 30.5503178 },
      { lat: 50.3919403, lng: 30.5419922 },
      { lat: 50.3949772, lng: 30.5383444 },
      { lat: 50.3949498, lng: 30.5352116 },
      { lat: 50.3945121, lng: 30.5313492 },
      { lat: 50.3902987, lng: 30.5293751 },
      { lat: 50.3897788, lng: 30.5292892 },
      { lat: 50.3880277, lng: 30.5298901 },
      { lat: 50.3876173, lng: 30.5256844 },
      { lat: 50.3837316, lng: 30.5246973 },
      { lat: 50.3810498, lng: 30.5208349 },
    ],
  },
  {
    name: 'ГЕРОЕВ ОБОРОНЫ',
    price: 141,
    points: [
      { lat: 50.3750836, lng: 30.4992056 },
      { lat: 50.3745362, lng: 30.5062008 },
      { lat: 50.3766163, lng: 30.5112219 },
      { lat: 50.3776837, lng: 30.5147409 },
      { lat: 50.3818161, lng: 30.5178308 },
      { lat: 50.3847715, lng: 30.5198908 },
      { lat: 50.3876173, lng: 30.5256844 },
      { lat: 50.3903534, lng: 30.5217791 },
      { lat: 50.3918582, lng: 30.506587 },
      { lat: 50.3897788, lng: 30.5016088 },
      { lat: 50.3893958, lng: 30.4980469 },
      { lat: 50.3881371, lng: 30.4947853 },
      { lat: 50.3849357, lng: 30.4869318 },
      { lat: 50.3836222, lng: 30.4837561 },
      { lat: 50.3828286, lng: 30.4845285 },
      { lat: 50.3815971, lng: 30.4843569 },
      { lat: 50.3788605, lng: 30.4868031 },
      { lat: 50.3783679, lng: 30.4878759 },
      { lat: 50.3750836, lng: 30.4992056 },
    ],
  },
  {
    name: 'ГОЛОСЕЕВО',
    price: 142,
    points: [
      { lat: 50.4001134, lng: 30.5290854 },
      { lat: 50.4016521, lng: 30.5276585 },
      { lat: 50.4039772, lng: 30.5246544 },
      { lat: 50.4058098, lng: 30.5221224 },
      { lat: 50.4059739, lng: 30.5213928 },
      { lat: 50.4059739, lng: 30.5206633 },
      { lat: 50.4055362, lng: 30.5198908 },
      { lat: 50.4054268, lng: 30.5193329 },
      { lat: 50.4054815, lng: 30.5177879 },
      { lat: 50.4026368, lng: 30.5156851 },
      { lat: 50.4003117, lng: 30.5132818 },
      { lat: 50.398561, lng: 30.5111361 },
      { lat: 50.3977129, lng: 30.5102348 },
      { lat: 50.3969743, lng: 30.5096769 },
      { lat: 50.3947036, lng: 30.508132 },
      { lat: 50.3935819, lng: 30.5070591 },
      { lat: 50.3925696, lng: 30.5058146 },
      { lat: 50.3918582, lng: 30.506587 },
      { lat: 50.3903534, lng: 30.5217791 },
      { lat: 50.3876173, lng: 30.5256844 },
      { lat: 50.3880277, lng: 30.5298901 },
      { lat: 50.3897788, lng: 30.5292892 },
      { lat: 50.3902987, lng: 30.5293751 },
      { lat: 50.3945121, lng: 30.5313492 },
      { lat: 50.3960441, lng: 30.5315208 },
      { lat: 50.3972752, lng: 30.5311346 },
      { lat: 50.3976856, lng: 30.5309629 },
      { lat: 50.3988619, lng: 30.5302334 },
      { lat: 50.4001134, lng: 30.5290854 },
    ],
  },
  {
    name: 'САПЕРНО-СЛОБОДСКАЯ',
    price: 143,
    points: [
      { lat: 50.3963314, lng: 30.5405974 },
      { lat: 50.4002433, lng: 30.5442452 },
      { lat: 50.4017068, lng: 30.5457902 },
      { lat: 50.40084, lng: 30.5514952 },
      { lat: 50.4020427, lng: 30.5516575 },
      { lat: 50.4032523, lng: 30.5516911 },
      { lat: 50.4034438, lng: 30.5508971 },
      { lat: 50.4044832, lng: 30.5464125 },
      { lat: 50.4050576, lng: 30.5433869 },
      { lat: 50.4052901, lng: 30.5412197 },
      { lat: 50.4054268, lng: 30.5382371 },
      { lat: 50.4055089, lng: 30.5355763 },
      { lat: 50.4055499, lng: 30.5320573 },
      { lat: 50.4056867, lng: 30.5310488 },
      { lat: 50.4058098, lng: 30.5302763 },
      { lat: 50.4039772, lng: 30.5246544 },
      { lat: 50.4016521, lng: 30.5276585 },
      { lat: 50.4001134, lng: 30.5290854 },
      { lat: 50.3988619, lng: 30.5302334 },
      { lat: 50.3976856, lng: 30.5309629 },
      { lat: 50.3972752, lng: 30.5311346 },
      { lat: 50.3960441, lng: 30.5315208 },
      { lat: 50.3945121, lng: 30.5313492 },
      { lat: 50.3949498, lng: 30.5352116 },
      { lat: 50.3949772, lng: 30.5383444 },
      { lat: 50.3963314, lng: 30.5405974 },
    ],
  },
  {
    name: 'ВЫДУБИЧИ',
    price: 144,
    points: [
      { lat: 50.3710875, lng: 30.569458 },
      { lat: 50.3726203, lng: 30.5758095 },
      { lat: 50.3875078, lng: 30.5818176 },
      { lat: 50.4044695, lng: 30.5838776 },
      { lat: 50.4145342, lng: 30.5802727 },
      { lat: 50.4172415, lng: 30.5775263 },
      { lat: 50.4198666, lng: 30.5743933 },
      { lat: 50.4229973, lng: 30.5734706 },
      { lat: 50.4245558, lng: 30.5724192 },
      { lat: 50.4238313, lng: 30.569458 },
      { lat: 50.4238313, lng: 30.5668831 },
      { lat: 50.4219993, lng: 30.5664539 },
      { lat: 50.4200853, lng: 30.5653596 },
      { lat: 50.4168313, lng: 30.5656815 },
      { lat: 50.4148624, lng: 30.569458 },
      { lat: 50.4113756, lng: 30.5689001 },
      { lat: 50.4081893, lng: 30.5672264 },
      { lat: 50.4030471, lng: 30.5615616 },
      { lat: 50.4025001, lng: 30.5576777 },
      { lat: 50.4029377, lng: 30.5540085 },
      { lat: 50.4032523, lng: 30.5516911 },
      { lat: 50.4012281, lng: 30.5516481 },
      { lat: 50.3997646, lng: 30.5510902 },
      { lat: 50.398561, lng: 30.5541801 },
      { lat: 50.3905176, lng: 30.5503178 },
      { lat: 50.3890127, lng: 30.5543089 },
      { lat: 50.3857018, lng: 30.5529785 },
      { lat: 50.374372, lng: 30.5607033 },
      { lat: 50.3738793, lng: 30.564394 },
      { lat: 50.3717991, lng: 30.5662823 },
      { lat: 50.3710875, lng: 30.569458 },
    ],
  },
  {
    name: 'ЧЁРНАЯ ГОРА',
    price: 145,
    points: [
      { lat: 50.4105688, lng: 30.5262852 },
      { lat: 50.4090372, lng: 30.5228949 },
      { lat: 50.4073141, lng: 30.5251694 },
      { lat: 50.4067671, lng: 30.5262423 },
      { lat: 50.4065346, lng: 30.5270791 },
      { lat: 50.4060559, lng: 30.5292034 },
      { lat: 50.4058098, lng: 30.5302763 },
      { lat: 50.4056867, lng: 30.5310488 },
      { lat: 50.4055499, lng: 30.5320573 },
      { lat: 50.4055089, lng: 30.5355763 },
      { lat: 50.4054268, lng: 30.5382371 },
      { lat: 50.4052901, lng: 30.5412197 },
      { lat: 50.4050576, lng: 30.5433869 },
      { lat: 50.4056867, lng: 30.5439878 },
      { lat: 50.4076833, lng: 30.5487514 },
      { lat: 50.4083261, lng: 30.5483222 },
      { lat: 50.4078611, lng: 30.5427432 },
      { lat: 50.4093654, lng: 30.5424643 },
      { lat: 50.4139599, lng: 30.5413699 },
      { lat: 50.414459, lng: 30.5409394 },
      { lat: 50.4155529, lng: 30.5417641 },
      { lat: 50.4162024, lng: 30.5410747 },
      { lat: 50.4156007, lng: 30.5399108 },
      { lat: 50.4142744, lng: 30.5369496 },
      { lat: 50.4137138, lng: 30.5354476 },
      { lat: 50.4130438, lng: 30.5333018 },
      { lat: 50.4126473, lng: 30.5320573 },
      { lat: 50.4121687, lng: 30.530405 },
      { lat: 50.4105688, lng: 30.5262852 },
    ],
  },
  {
    name: 'КИКВИДЗЕ',
    price: 146,
    points: [
      { lat: 50.4197025, lng: 30.5478287 },
      { lat: 50.4162024, lng: 30.5410747 },
      { lat: 50.4155529, lng: 30.5417641 },
      { lat: 50.414459, lng: 30.5409394 },
      { lat: 50.4139599, lng: 30.5413699 },
      { lat: 50.4093654, lng: 30.5424643 },
      { lat: 50.4078611, lng: 30.5427432 },
      { lat: 50.4083261, lng: 30.5483222 },
      { lat: 50.4076833, lng: 30.5487514 },
      { lat: 50.4056867, lng: 30.5439878 },
      { lat: 50.4050576, lng: 30.5433869 },
      { lat: 50.4044832, lng: 30.5464125 },
      { lat: 50.4032523, lng: 30.5516911 },
      { lat: 50.4029377, lng: 30.5540085 },
      { lat: 50.4025001, lng: 30.5576777 },
      { lat: 50.4030471, lng: 30.5615616 },
      { lat: 50.4081893, lng: 30.5672264 },
      { lat: 50.4113756, lng: 30.5689001 },
      { lat: 50.4148624, lng: 30.569458 },
      { lat: 50.4168313, lng: 30.5656815 },
      { lat: 50.4200853, lng: 30.5653596 },
      { lat: 50.4219993, lng: 30.5664539 },
      { lat: 50.4238313, lng: 30.5668831 },
      { lat: 50.4242687, lng: 30.5639219 },
      { lat: 50.4245148, lng: 30.5620766 },
      { lat: 50.4244601, lng: 30.5609608 },
      { lat: 50.4240226, lng: 30.5593729 },
      { lat: 50.4231204, lng: 30.5559397 },
      { lat: 50.4228196, lng: 30.5548239 },
      { lat: 50.42189, lng: 30.5520773 },
      { lat: 50.4197025, lng: 30.5478287 },
    ],
  },
  {
    name: 'М. БЕРЕСТЕЙСКАЯ',
    price: 147,
    points: [
      { lat: 50.4581871, lng: 30.4470634 },
      { lat: 50.4589794, lng: 30.447278 },
      { lat: 50.4628315, lng: 30.4471493 },
      { lat: 50.4701524, lng: 30.4467201 },
      { lat: 50.4703163, lng: 30.4435873 },
      { lat: 50.4696881, lng: 30.4407978 },
      { lat: 50.4691691, lng: 30.4367208 },
      { lat: 50.4708353, lng: 30.4363775 },
      { lat: 50.4722283, lng: 30.4307556 },
      { lat: 50.4711904, lng: 30.4303265 },
      { lat: 50.4704529, lng: 30.4297686 },
      { lat: 50.4693876, lng: 30.4286957 },
      { lat: 50.4685135, lng: 30.4277515 },
      { lat: 50.4674755, lng: 30.4265499 },
      { lat: 50.4663829, lng: 30.42449 },
      { lat: 50.4632413, lng: 30.4186964 },
      { lat: 50.4628042, lng: 30.417881 },
      { lat: 50.4620393, lng: 30.4169798 },
      { lat: 50.4617114, lng: 30.4167652 },
      { lat: 50.460564, lng: 30.4160357 },
      { lat: 50.4594438, lng: 30.415864 },
      { lat: 50.4584876, lng: 30.4217005 },
      { lat: 50.4567663, lng: 30.4321932 },
      { lat: 50.4561925, lng: 30.4358196 },
      { lat: 50.4551269, lng: 30.4427719 },
      { lat: 50.4547547, lng: 30.4449767 },
      { lat: 50.4552883, lng: 30.4452033 },
      { lat: 50.4551184, lng: 30.4461809 },
      { lat: 50.4581871, lng: 30.4470634 },
    ],
  },
  {
    name: 'СЫРЕЦ ЩУСЕВА',
    price: 148,
    points: [
      { lat: 50.4808586, lng: 30.4407549 },
      { lat: 50.4819236, lng: 30.4393387 },
      { lat: 50.4834256, lng: 30.4412699 },
      { lat: 50.4840263, lng: 30.4415274 },
      { lat: 50.4843267, lng: 30.441184 },
      { lat: 50.4844086, lng: 30.4401112 },
      { lat: 50.4838898, lng: 30.4334164 },
      { lat: 50.4827156, lng: 30.4322577 },
      { lat: 50.4821421, lng: 30.4314852 },
      { lat: 50.480449, lng: 30.430069 },
      { lat: 50.4794932, lng: 30.4294682 },
      { lat: 50.4782915, lng: 30.4290819 },
      { lat: 50.4773084, lng: 30.4292107 },
      { lat: 50.4765983, lng: 30.4293394 },
      { lat: 50.4759974, lng: 30.4296398 },
      { lat: 50.4745499, lng: 30.4302835 },
      { lat: 50.4735667, lng: 30.430541 },
      { lat: 50.4722283, lng: 30.4307556 },
      { lat: 50.4708353, lng: 30.4363775 },
      { lat: 50.4691691, lng: 30.4367208 },
      { lat: 50.4696881, lng: 30.4407978 },
      { lat: 50.4703163, lng: 30.4435873 },
      { lat: 50.4701524, lng: 30.4467201 },
      { lat: 50.4729658, lng: 30.4475355 },
      { lat: 50.4732935, lng: 30.4475784 },
      { lat: 50.4735394, lng: 30.4472351 },
      { lat: 50.4740583, lng: 30.4476643 },
      { lat: 50.4747684, lng: 30.4483509 },
      { lat: 50.4774858, lng: 30.4504645 },
      { lat: 50.4792201, lng: 30.4465055 },
      { lat: 50.4803671, lng: 30.443759 },
      { lat: 50.4812956, lng: 30.4416561 },
      { lat: 50.4808586, lng: 30.4407549 },
    ],
  },
  {
    name: 'СЫРЕЦКАЯ',
    price: 149,
    points: [
      { lat: 50.482224, lng: 30.4431152 },
      { lat: 50.4841082, lng: 30.4444456 },
      { lat: 50.4844359, lng: 30.4451752 },
      { lat: 50.4898423, lng: 30.464015 },
      { lat: 50.4904293, lng: 30.4654313 },
      { lat: 50.4910982, lng: 30.46453 },
      { lat: 50.4929548, lng: 30.4626417 },
      { lat: 50.4939103, lng: 30.4613113 },
      { lat: 50.4948112, lng: 30.4601311 },
      { lat: 50.4952686, lng: 30.4593909 },
      { lat: 50.4965857, lng: 30.4566336 },
      { lat: 50.4954938, lng: 30.4540586 },
      { lat: 50.4940741, lng: 30.4498959 },
      { lat: 50.4919719, lng: 30.4445744 },
      { lat: 50.490989, lng: 30.4423428 },
      { lat: 50.489815, lng: 30.4401112 },
      { lat: 50.4888321, lng: 30.4384804 },
      { lat: 50.4871665, lng: 30.4365063 },
      { lat: 50.4861562, lng: 30.4354334 },
      { lat: 50.4838898, lng: 30.4334164 },
      { lat: 50.4844086, lng: 30.4401112 },
      { lat: 50.4843267, lng: 30.441184 },
      { lat: 50.4840263, lng: 30.4415274 },
      { lat: 50.4834256, lng: 30.4412699 },
      { lat: 50.4819236, lng: 30.4393387 },
      { lat: 50.4808586, lng: 30.4407549 },
      { lat: 50.4812956, lng: 30.4416561 },
      { lat: 50.482224, lng: 30.4431152 },
    ],
  },
  {
    name: 'КУРЕНЕВКА БАКИНСКАЯ',
    price: 150,
    points: [
      { lat: 50.4772878, lng: 30.4519021 },
      { lat: 50.4787626, lng: 30.4528999 },
      { lat: 50.4793566, lng: 30.4527068 },
      { lat: 50.4791723, lng: 30.4534042 },
      { lat: 50.4796229, lng: 30.4538333 },
      { lat: 50.4799575, lng: 30.4543805 },
      { lat: 50.4804763, lng: 30.4555821 },
      { lat: 50.4808313, lng: 30.4563331 },
      { lat: 50.4812546, lng: 30.4576421 },
      { lat: 50.4817052, lng: 30.4595733 },
      { lat: 50.4819919, lng: 30.460689 },
      { lat: 50.4822104, lng: 30.4616761 },
      { lat: 50.4824562, lng: 30.4624915 },
      { lat: 50.4828794, lng: 30.4632854 },
      { lat: 50.4830433, lng: 30.4651308 },
      { lat: 50.4831798, lng: 30.4673195 },
      { lat: 50.4835894, lng: 30.4688644 },
      { lat: 50.4839717, lng: 30.4698086 },
      { lat: 50.4843813, lng: 30.4705811 },
      { lat: 50.4847363, lng: 30.4709673 },
      { lat: 50.4856647, lng: 30.4718256 },
      { lat: 50.4862654, lng: 30.4725981 },
      { lat: 50.4873576, lng: 30.4705381 },
      { lat: 50.4895966, lng: 30.4668903 },
      { lat: 50.4904293, lng: 30.4654313 },
      { lat: 50.4898423, lng: 30.464015 },
      { lat: 50.4844359, lng: 30.4451752 },
      { lat: 50.4841082, lng: 30.4444456 },
      { lat: 50.482224, lng: 30.4431152 },
      { lat: 50.4812956, lng: 30.4416561 },
      { lat: 50.4803671, lng: 30.443759 },
      { lat: 50.4792201, lng: 30.4465055 },
      { lat: 50.4774858, lng: 30.4504645 },
      { lat: 50.4772878, lng: 30.4519021 },
    ],
  },
  {
    name: 'ШУЛЯВКА',
    price: 151,
    points: [
      { lat: 50.4629681, lng: 30.4516554 },
      { lat: 50.4628315, lng: 30.4471493 },
      { lat: 50.4589794, lng: 30.447278 },
      { lat: 50.4581871, lng: 30.4470634 },
      { lat: 50.4551184, lng: 30.4461809 },
      { lat: 50.4552883, lng: 30.4452033 },
      { lat: 50.4547547, lng: 30.4449767 },
      { lat: 50.4545805, lng: 30.4460335 },
      { lat: 50.4532689, lng: 30.4536724 },
      { lat: 50.4522032, lng: 30.4603243 },
      { lat: 50.4533782, lng: 30.4606247 },
      { lat: 50.459881, lng: 30.4668474 },
      { lat: 50.4614109, lng: 30.4672337 },
      { lat: 50.4624764, lng: 30.4674053 },
      { lat: 50.462613, lng: 30.4647446 },
      { lat: 50.4628315, lng: 30.4628134 },
      { lat: 50.4630501, lng: 30.4604101 },
      { lat: 50.4631047, lng: 30.4580069 },
      { lat: 50.4630501, lng: 30.4551744 },
      { lat: 50.4629681, lng: 30.4516554 },
    ],
  },
  {
    name: 'ЗООПАРК',
    price: 152,
    points: [
      { lat: 50.4609738, lng: 30.4722977 },
      { lat: 50.4614109, lng: 30.4672337 },
      { lat: 50.459881, lng: 30.4668474 },
      { lat: 50.4533782, lng: 30.4606247 },
      { lat: 50.4522032, lng: 30.4603243 },
      { lat: 50.4513835, lng: 30.4653025 },
      { lat: 50.4502904, lng: 30.4720831 },
      { lat: 50.4484801, lng: 30.4823827 },
      { lat: 50.4513357, lng: 30.4836649 },
      { lat: 50.4557554, lng: 30.4854083 },
      { lat: 50.4579138, lng: 30.4861593 },
      { lat: 50.4598537, lng: 30.4840994 },
      { lat: 50.4585149, lng: 30.4806232 },
      { lat: 50.4588701, lng: 30.4804516 },
      { lat: 50.4593346, lng: 30.4798937 },
      { lat: 50.4602635, lng: 30.4800224 },
      { lat: 50.4606186, lng: 30.4754734 },
      { lat: 50.461247, lng: 30.4755592 },
      { lat: 50.4613563, lng: 30.4737568 },
      { lat: 50.4609738, lng: 30.4722977 },
    ],
  },
  {
    name: 'М. ДОРОГОЖИЧИ',
    price: 153,
    points: [
      { lat: 50.4648258, lng: 30.4580069 },
      { lat: 50.4661916, lng: 30.4595947 },
      { lat: 50.4666526, lng: 30.4586077 },
      { lat: 50.467175, lng: 30.4583931 },
      { lat: 50.4684589, lng: 30.4581356 },
      { lat: 50.4701251, lng: 30.4577923 },
      { lat: 50.4718459, lng: 30.4589939 },
      { lat: 50.4715728, lng: 30.4608822 },
      { lat: 50.4756424, lng: 30.4619551 },
      { lat: 50.4774858, lng: 30.4504645 },
      { lat: 50.4747684, lng: 30.4483509 },
      { lat: 50.4740583, lng: 30.4476643 },
      { lat: 50.4735394, lng: 30.4472351 },
      { lat: 50.4732935, lng: 30.4475784 },
      { lat: 50.4729658, lng: 30.4475355 },
      { lat: 50.4701524, lng: 30.4467201 },
      { lat: 50.4628315, lng: 30.4471493 },
      { lat: 50.4629681, lng: 30.4516554 },
      { lat: 50.4630501, lng: 30.4551744 },
      { lat: 50.4631047, lng: 30.4580069 },
      { lat: 50.4648258, lng: 30.4580069 },
    ],
  },
  {
    name: 'ЛУКЬЯНОВКА',
    price: 154,
    points: [
      { lat: 50.4668199, lng: 30.4612684 },
      { lat: 50.4661916, lng: 30.4595947 },
      { lat: 50.4648258, lng: 30.4580069 },
      { lat: 50.4631047, lng: 30.4580069 },
      { lat: 50.4630501, lng: 30.4604101 },
      { lat: 50.4628315, lng: 30.4628134 },
      { lat: 50.462613, lng: 30.4647446 },
      { lat: 50.4624764, lng: 30.4674053 },
      { lat: 50.4614109, lng: 30.4672337 },
      { lat: 50.4609738, lng: 30.4722977 },
      { lat: 50.4613563, lng: 30.4737568 },
      { lat: 50.461247, lng: 30.4755592 },
      { lat: 50.4606186, lng: 30.4754734 },
      { lat: 50.4602635, lng: 30.4800224 },
      { lat: 50.4593346, lng: 30.4798937 },
      { lat: 50.4588701, lng: 30.4804516 },
      { lat: 50.4585149, lng: 30.4806232 },
      { lat: 50.4598537, lng: 30.4840994 },
      { lat: 50.4579138, lng: 30.4861593 },
      { lat: 50.4587881, lng: 30.4864168 },
      { lat: 50.4600995, lng: 30.4851294 },
      { lat: 50.4629135, lng: 30.4821682 },
      { lat: 50.4641565, lng: 30.4792607 },
      { lat: 50.4663555, lng: 30.4746151 },
      { lat: 50.4670931, lng: 30.473156 },
      { lat: 50.467776, lng: 30.4717398 },
      { lat: 50.4668199, lng: 30.470624 },
      { lat: 50.4676667, lng: 30.4683924 },
      { lat: 50.4693876, lng: 30.4644012 },
      { lat: 50.4668199, lng: 30.4612684 },
    ],
  },
  {
    name: 'КИМО',
    price: 155,
    points: [
      { lat: 50.4703436, lng: 30.4732418 },
      { lat: 50.4712997, lng: 30.4742289 },
      { lat: 50.4715728, lng: 30.4738426 },
      { lat: 50.4737579, lng: 30.4763746 },
      { lat: 50.4750688, lng: 30.4762459 },
      { lat: 50.4788924, lng: 30.4793358 },
      { lat: 50.4806675, lng: 30.4739285 },
      { lat: 50.4793566, lng: 30.4683065 },
      { lat: 50.4789197, lng: 30.4653454 },
      { lat: 50.4763525, lng: 30.4650021 },
      { lat: 50.4756424, lng: 30.4619551 },
      { lat: 50.4715728, lng: 30.4608822 },
      { lat: 50.4718459, lng: 30.4589939 },
      { lat: 50.4701251, lng: 30.4577923 },
      { lat: 50.4684589, lng: 30.4581356 },
      { lat: 50.467175, lng: 30.4583931 },
      { lat: 50.4666526, lng: 30.4586077 },
      { lat: 50.4661916, lng: 30.4595947 },
      { lat: 50.4668199, lng: 30.4612684 },
      { lat: 50.4693876, lng: 30.4644012 },
      { lat: 50.4676667, lng: 30.4683924 },
      { lat: 50.4668199, lng: 30.470624 },
      { lat: 50.467776, lng: 30.4717398 },
      { lat: 50.4670931, lng: 30.473156 },
      { lat: 50.4703436, lng: 30.4732418 },
    ],
  },
  {
    name: 'ТАТАРКА',
    price: 156,
    points: [
      { lat: 50.4780458, lng: 30.4821682 },
      { lat: 50.4788924, lng: 30.4793358 },
      { lat: 50.4750688, lng: 30.4762459 },
      { lat: 50.4737579, lng: 30.4763746 },
      { lat: 50.4715728, lng: 30.4738426 },
      { lat: 50.4712997, lng: 30.4742289 },
      { lat: 50.4703436, lng: 30.4732418 },
      { lat: 50.4670931, lng: 30.473156 },
      { lat: 50.4663555, lng: 30.4746151 },
      { lat: 50.4641565, lng: 30.4792607 },
      { lat: 50.4629135, lng: 30.4821682 },
      { lat: 50.4600995, lng: 30.4851294 },
      { lat: 50.4587881, lng: 30.4864168 },
      { lat: 50.4593619, lng: 30.4865456 },
      { lat: 50.4599356, lng: 30.4870605 },
      { lat: 50.4607279, lng: 30.4880476 },
      { lat: 50.4623125, lng: 30.4897642 },
      { lat: 50.4627701, lng: 30.4890024 },
      { lat: 50.465215, lng: 30.4889381 },
      { lat: 50.4661575, lng: 30.4892492 },
      { lat: 50.466219, lng: 30.4900646 },
      { lat: 50.4665331, lng: 30.4919958 },
      { lat: 50.468015, lng: 30.4921138 },
      { lat: 50.4686228, lng: 30.4943991 },
      { lat: 50.4710811, lng: 30.4947424 },
      { lat: 50.473157, lng: 30.4936695 },
      { lat: 50.4731023, lng: 30.4894209 },
      { lat: 50.4729385, lng: 30.4867387 },
      { lat: 50.4754239, lng: 30.4834557 },
      { lat: 50.4761613, lng: 30.4843998 },
      { lat: 50.4780458, lng: 30.4821682 },
    ],
  },
  {
    name: 'СТ СПАРТАК',
    price: 157,
    points: [
      { lat: 50.4741675, lng: 30.4944849 },
      { lat: 50.4765164, lng: 30.4962444 },
      { lat: 50.477008, lng: 30.4956436 },
      { lat: 50.4776361, lng: 30.498004 },
      { lat: 50.4781346, lng: 30.497446 },
      { lat: 50.480053, lng: 30.4962444 },
      { lat: 50.4812136, lng: 30.4974461 },
      { lat: 50.4851459, lng: 30.4972744 },
      { lat: 50.4853643, lng: 30.494957 },
      { lat: 50.4857466, lng: 30.4916096 },
      { lat: 50.4860743, lng: 30.4898071 },
      { lat: 50.4886409, lng: 30.4834557 },
      { lat: 50.4882041, lng: 30.4791641 },
      { lat: 50.4874941, lng: 30.4751301 },
      { lat: 50.4870573, lng: 30.473671 },
      { lat: 50.4862654, lng: 30.4725981 },
      { lat: 50.4856647, lng: 30.4718256 },
      { lat: 50.4847363, lng: 30.4709673 },
      { lat: 50.4843813, lng: 30.4705811 },
      { lat: 50.4839717, lng: 30.4698086 },
      { lat: 50.4835894, lng: 30.4688644 },
      { lat: 50.4831798, lng: 30.4673195 },
      { lat: 50.4830433, lng: 30.4651308 },
      { lat: 50.4828794, lng: 30.4632854 },
      { lat: 50.4789197, lng: 30.4653454 },
      { lat: 50.4793566, lng: 30.4683065 },
      { lat: 50.4806675, lng: 30.4739285 },
      { lat: 50.4788924, lng: 30.4793358 },
      { lat: 50.4780458, lng: 30.4821682 },
      { lat: 50.4761613, lng: 30.4843998 },
      { lat: 50.4754239, lng: 30.4834557 },
      { lat: 50.4729385, lng: 30.4867387 },
      { lat: 50.4731023, lng: 30.4894209 },
      { lat: 50.473157, lng: 30.4936695 },
      { lat: 50.4741675, lng: 30.4944849 },
    ],
  },
  {
    name: 'ПОДОЛ 2',
    price: 158,
    points: [
      { lat: 50.4728292, lng: 30.5224657 },
      { lat: 50.4742768, lng: 30.5245686 },
      { lat: 50.4768168, lng: 30.5203629 },
      { lat: 50.4776907, lng: 30.5154705 },
      { lat: 50.4785373, lng: 30.5101919 },
      { lat: 50.4806948, lng: 30.5083895 },
      { lat: 50.4807221, lng: 30.5031967 },
      { lat: 50.4776361, lng: 30.5031109 },
      { lat: 50.4768714, lng: 30.5009651 },
      { lat: 50.4776361, lng: 30.498004 },
      { lat: 50.477008, lng: 30.4956436 },
      { lat: 50.4765164, lng: 30.4962444 },
      { lat: 50.4741675, lng: 30.4944849 },
      { lat: 50.473157, lng: 30.4936695 },
      { lat: 50.4710811, lng: 30.4947424 },
      { lat: 50.4686228, lng: 30.4943991 },
      { lat: 50.468015, lng: 30.4921138 },
      { lat: 50.4665331, lng: 30.4919958 },
      { lat: 50.4660004, lng: 30.493927 },
      { lat: 50.4654541, lng: 30.4959869 },
      { lat: 50.4655907, lng: 30.4997206 },
      { lat: 50.4661097, lng: 30.5017376 },
      { lat: 50.4669838, lng: 30.5022526 },
      { lat: 50.4673389, lng: 30.5027676 },
      { lat: 50.4672297, lng: 30.5050421 },
      { lat: 50.4665194, lng: 30.5061579 },
      { lat: 50.4663009, lng: 30.5063295 },
      { lat: 50.4657272, lng: 30.50457 },
      { lat: 50.4647985, lng: 30.5043554 },
      { lat: 50.4630774, lng: 30.5058575 },
      { lat: 50.4627325, lng: 30.5066675 },
      { lat: 50.4627496, lng: 30.5069733 },
      { lat: 50.4646619, lng: 30.5098486 },
      { lat: 50.466417, lng: 30.5125952 },
      { lat: 50.4675506, lng: 30.5143762 },
      { lat: 50.4700978, lng: 30.5182279 },
      { lat: 50.4714909, lng: 30.5204916 },
      { lat: 50.4728292, lng: 30.5224657 },
    ],
  },
  {
    name: 'ПОДОЛ 1',
    price: 159,
    points: [
      { lat: 50.4720098, lng: 30.5238282 },
      { lat: 50.4728292, lng: 30.5224657 },
      { lat: 50.4714909, lng: 30.5204916 },
      { lat: 50.4700978, lng: 30.5182279 },
      { lat: 50.4675506, lng: 30.5143762 },
      { lat: 50.466417, lng: 30.5125952 },
      { lat: 50.4654438, lng: 30.5141294 },
      { lat: 50.4651689, lng: 30.5137378 },
      { lat: 50.4648948, lng: 30.5137298 },
      { lat: 50.4647817, lng: 30.5135755 },
      { lat: 50.4639466, lng: 30.5144426 },
      { lat: 50.4636349, lng: 30.5148814 },
      { lat: 50.4632742, lng: 30.5155944 },
      { lat: 50.4631621, lng: 30.5160313 },
      { lat: 50.4617866, lng: 30.5139095 },
      { lat: 50.4611445, lng: 30.5148482 },
      { lat: 50.4613341, lng: 30.5158218 },
      { lat: 50.4609157, lng: 30.5163449 },
      { lat: 50.4605367, lng: 30.5158192 },
      { lat: 50.4601166, lng: 30.5171979 },
      { lat: 50.4587881, lng: 30.5212641 },
      { lat: 50.457668, lng: 30.5246115 },
      { lat: 50.4579001, lng: 30.5258829 },
      { lat: 50.4583476, lng: 30.5259794 },
      { lat: 50.4594097, lng: 30.5244022 },
      { lat: 50.4605264, lng: 30.5261041 },
      { lat: 50.4606528, lng: 30.5267438 },
      { lat: 50.4610831, lng: 30.5267143 },
      { lat: 50.4633779, lng: 30.5261135 },
      { lat: 50.4666696, lng: 30.5253303 },
      { lat: 50.4670282, lng: 30.5250997 },
      { lat: 50.4678784, lng: 30.5245471 },
      { lat: 50.4691145, lng: 30.5241555 },
      { lat: 50.4696745, lng: 30.5235386 },
      { lat: 50.4706919, lng: 30.5219507 },
      { lat: 50.4720098, lng: 30.5238282 },
    ],
  },
  {
    name: 'МИХАЙЛОВСКАЯ ПЛ.',
    price: 160,
    points: [
      { lat: 50.4585013, lng: 30.5143332 },
      { lat: 50.4573401, lng: 30.5147195 },
      { lat: 50.4567663, lng: 30.5143976 },
      { lat: 50.4565204, lng: 30.5134964 },
      { lat: 50.4560833, lng: 30.513947 },
      { lat: 50.4554821, lng: 30.5139685 },
      { lat: 50.4555231, lng: 30.5149341 },
      { lat: 50.4554548, lng: 30.5174661 },
      { lat: 50.4553182, lng: 30.5197406 },
      { lat: 50.4548332, lng: 30.5208242 },
      { lat: 50.455045, lng: 30.5215994 },
      { lat: 50.4551713, lng: 30.5222565 },
      { lat: 50.4551184, lng: 30.5228922 },
      { lat: 50.4556871, lng: 30.5245471 },
      { lat: 50.457668, lng: 30.5246115 },
      { lat: 50.4587881, lng: 30.5212641 },
      { lat: 50.4597375, lng: 30.5183297 },
      { lat: 50.4601457, lng: 30.5170909 },
      { lat: 50.4605367, lng: 30.5158192 },
      { lat: 50.45984, lng: 30.515205 },
      { lat: 50.4596351, lng: 30.5155953 },
      { lat: 50.459239, lng: 30.5168009 },
      { lat: 50.4585013, lng: 30.5143332 },
    ],
  },
  {
    name: 'ВОЗДВИЖЕНКА',
    price: 161,
    points: [
      { lat: 50.4612333, lng: 30.506115 },
      { lat: 50.4602908, lng: 30.5079603 },
      { lat: 50.4594985, lng: 30.5081105 },
      { lat: 50.4588974, lng: 30.5085826 },
      { lat: 50.4573401, lng: 30.5087543 },
      { lat: 50.4569986, lng: 30.5095267 },
      { lat: 50.4570669, lng: 30.5114794 },
      { lat: 50.4565204, lng: 30.5134964 },
      { lat: 50.4567663, lng: 30.5143976 },
      { lat: 50.4573401, lng: 30.5147195 },
      { lat: 50.4585013, lng: 30.5143332 },
      { lat: 50.459239, lng: 30.5168009 },
      { lat: 50.4596351, lng: 30.5155953 },
      { lat: 50.45984, lng: 30.515205 },
      { lat: 50.4605367, lng: 30.5158192 },
      { lat: 50.4609157, lng: 30.5163449 },
      { lat: 50.4613341, lng: 30.5158218 },
      { lat: 50.4611445, lng: 30.5148482 },
      { lat: 50.4617866, lng: 30.5139095 },
      { lat: 50.4631621, lng: 30.5160313 },
      { lat: 50.4632742, lng: 30.5155944 },
      { lat: 50.4636349, lng: 30.5148814 },
      { lat: 50.4639466, lng: 30.5144426 },
      { lat: 50.4647817, lng: 30.5135755 },
      { lat: 50.4648948, lng: 30.5137298 },
      { lat: 50.4651689, lng: 30.5137378 },
      { lat: 50.4654438, lng: 30.5141294 },
      { lat: 50.466417, lng: 30.5125952 },
      { lat: 50.4646619, lng: 30.5098486 },
      { lat: 50.4627496, lng: 30.5069733 },
      { lat: 50.4627325, lng: 30.5066675 },
      { lat: 50.4620666, lng: 30.505321 },
      { lat: 50.4618207, lng: 30.506351 },
      { lat: 50.4612333, lng: 30.506115 },
    ],
  },
  {
    name: 'ГЛУБОЧИЦКАЯ',
    price: 162,
    points: [
      { lat: 50.4602225, lng: 30.488863 },
      { lat: 50.4606323, lng: 30.4899359 },
      { lat: 50.4600449, lng: 30.4920387 },
      { lat: 50.4600449, lng: 30.4943991 },
      { lat: 50.4596351, lng: 30.4961586 },
      { lat: 50.4590955, lng: 30.4966092 },
      { lat: 50.45942, lng: 30.4975801 },
      { lat: 50.4594848, lng: 30.4990768 },
      { lat: 50.459881, lng: 30.5012655 },
      { lat: 50.4586379, lng: 30.5055141 },
      { lat: 50.4590485, lng: 30.5060171 },
      { lat: 50.4608389, lng: 30.5041811 },
      { lat: 50.4614963, lng: 30.503406 },
      { lat: 50.462128, lng: 30.5036152 },
      { lat: 50.4620666, lng: 30.505321 },
      { lat: 50.4627325, lng: 30.5066675 },
      { lat: 50.4630774, lng: 30.5058575 },
      { lat: 50.4647985, lng: 30.5043554 },
      { lat: 50.4657272, lng: 30.50457 },
      { lat: 50.4663009, lng: 30.5063295 },
      { lat: 50.4665194, lng: 30.5061579 },
      { lat: 50.4672297, lng: 30.5050421 },
      { lat: 50.4673389, lng: 30.5027676 },
      { lat: 50.4669838, lng: 30.5022526 },
      { lat: 50.4661097, lng: 30.5017376 },
      { lat: 50.4655907, lng: 30.4997206 },
      { lat: 50.4654541, lng: 30.4959869 },
      { lat: 50.4665331, lng: 30.4919958 },
      { lat: 50.466219, lng: 30.4900646 },
      { lat: 50.4661575, lng: 30.4892492 },
      { lat: 50.465215, lng: 30.4889381 },
      { lat: 50.4627701, lng: 30.4890024 },
      { lat: 50.4623125, lng: 30.4897642 },
      { lat: 50.4607279, lng: 30.4880476 },
      { lat: 50.4602225, lng: 30.488863 },
    ],
  },
  {
    name: 'АРТЕМА',
    price: 163,
    points: [
      { lat: 50.4561789, lng: 30.4905367 },
      { lat: 50.4561789, lng: 30.4922533 },
      { lat: 50.4563018, lng: 30.4953003 },
      { lat: 50.4563428, lng: 30.4975319 },
      { lat: 50.4565136, lng: 30.5017054 },
      { lat: 50.4569303, lng: 30.5016732 },
      { lat: 50.4570395, lng: 30.5010509 },
      { lat: 50.4573128, lng: 30.5009651 },
      { lat: 50.457586, lng: 30.5004072 },
      { lat: 50.4584193, lng: 30.499742 },
      { lat: 50.4585696, lng: 30.4993129 },
      { lat: 50.4594848, lng: 30.4990768 },
      { lat: 50.45942, lng: 30.4975801 },
      { lat: 50.4590955, lng: 30.4966092 },
      { lat: 50.4596351, lng: 30.4961586 },
      { lat: 50.4600449, lng: 30.4943991 },
      { lat: 50.4600449, lng: 30.4920387 },
      { lat: 50.4606323, lng: 30.4899359 },
      { lat: 50.4602225, lng: 30.488863 },
      { lat: 50.4607279, lng: 30.4880476 },
      { lat: 50.4599356, lng: 30.4870605 },
      { lat: 50.4593619, lng: 30.4865456 },
      { lat: 50.4587881, lng: 30.4864168 },
      { lat: 50.4579412, lng: 30.4872322 },
      { lat: 50.4573128, lng: 30.4879832 },
      { lat: 50.4561789, lng: 30.4905367 },
    ],
  },
  {
    name: 'ПОЛТАВСКАЯ',
    price: 164,
    points: [
      { lat: 50.4532962, lng: 30.4933691 },
      { lat: 50.4543755, lng: 30.4983902 },
      { lat: 50.4556802, lng: 30.4977036 },
      { lat: 50.4563428, lng: 30.4975319 },
      { lat: 50.4563018, lng: 30.4953003 },
      { lat: 50.4561789, lng: 30.4922533 },
      { lat: 50.4561789, lng: 30.4905367 },
      { lat: 50.4573128, lng: 30.4879832 },
      { lat: 50.4579412, lng: 30.4872322 },
      { lat: 50.4587881, lng: 30.4864168 },
      { lat: 50.4579138, lng: 30.4861593 },
      { lat: 50.4557554, lng: 30.4854083 },
      { lat: 50.4513357, lng: 30.4836649 },
      { lat: 50.4532962, lng: 30.4933691 },
    ],
  },
  {
    name: 'ЛЬВОВСКАЯ ПЛ.',
    price: 165,
    points: [
      { lat: 50.4504134, lng: 30.4964805 },
      { lat: 50.4522442, lng: 30.5007076 },
      { lat: 50.4521349, lng: 30.501287 },
      { lat: 50.4516977, lng: 30.5018878 },
      { lat: 50.4519027, lng: 30.5026817 },
      { lat: 50.4511785, lng: 30.5033684 },
      { lat: 50.4518753, lng: 30.5053854 },
      { lat: 50.4525311, lng: 30.5047631 },
      { lat: 50.4529137, lng: 30.5058146 },
      { lat: 50.4523809, lng: 30.5064797 },
      { lat: 50.4525585, lng: 30.5072737 },
      { lat: 50.4518207, lng: 30.5080891 },
      { lat: 50.4521623, lng: 30.5086684 },
      { lat: 50.4523809, lng: 30.5090761 },
      { lat: 50.4527361, lng: 30.5096555 },
      { lat: 50.452982, lng: 30.5102992 },
      { lat: 50.4539247, lng: 30.5120158 },
      { lat: 50.4544302, lng: 30.5123591 },
      { lat: 50.4553865, lng: 30.5136037 },
      { lat: 50.4554821, lng: 30.5139685 },
      { lat: 50.4560833, lng: 30.513947 },
      { lat: 50.4565204, lng: 30.5134964 },
      { lat: 50.4570669, lng: 30.5114794 },
      { lat: 50.4569986, lng: 30.5095267 },
      { lat: 50.4573401, lng: 30.5087543 },
      { lat: 50.4588974, lng: 30.5085826 },
      { lat: 50.4594985, lng: 30.5081105 },
      { lat: 50.4602908, lng: 30.5079603 },
      { lat: 50.4612333, lng: 30.506115 },
      { lat: 50.4618207, lng: 30.506351 },
      { lat: 50.4620666, lng: 30.505321 },
      { lat: 50.462128, lng: 30.5036152 },
      { lat: 50.4614963, lng: 30.503406 },
      { lat: 50.4608389, lng: 30.5041811 },
      { lat: 50.4590485, lng: 30.5060171 },
      { lat: 50.4586379, lng: 30.5055141 },
      { lat: 50.459881, lng: 30.5012655 },
      { lat: 50.4594848, lng: 30.4990768 },
      { lat: 50.4585696, lng: 30.4993129 },
      { lat: 50.4584193, lng: 30.499742 },
      { lat: 50.457586, lng: 30.5004072 },
      { lat: 50.4573128, lng: 30.5009651 },
      { lat: 50.4570395, lng: 30.5010509 },
      { lat: 50.4569303, lng: 30.5016732 },
      { lat: 50.4565136, lng: 30.5017054 },
      { lat: 50.4563428, lng: 30.4975319 },
      { lat: 50.4556802, lng: 30.4977036 },
      { lat: 50.4543755, lng: 30.4983902 },
      { lat: 50.4532962, lng: 30.4933691 },
      { lat: 50.4504134, lng: 30.4964805 },
    ],
  },
  {
    name: 'ЦИРК',
    price: 166,
    points: [
      { lat: 50.4470385, lng: 30.494163 },
      { lat: 50.4486508, lng: 30.4982185 },
      { lat: 50.4504134, lng: 30.4964805 },
      { lat: 50.4532962, lng: 30.4933691 },
      { lat: 50.4513357, lng: 30.4836649 },
      { lat: 50.4484801, lng: 30.4823827 },
      { lat: 50.4471205, lng: 30.4908156 },
      { lat: 50.4464646, lng: 30.4946995 },
      { lat: 50.4470385, lng: 30.494163 },
    ],
  },
  {
    name: 'ЯР ВАЛ',
    price: 167,
    points: [
      { lat: 50.4464578, lng: 30.5114365 },
      { lat: 50.4492384, lng: 30.5125308 },
      { lat: 50.4491154, lng: 30.5135393 },
      { lat: 50.449457, lng: 30.5136466 },
      { lat: 50.4495936, lng: 30.5129814 },
      { lat: 50.4523809, lng: 30.5090761 },
      { lat: 50.4521623, lng: 30.5086684 },
      { lat: 50.4518207, lng: 30.5080891 },
      { lat: 50.4525585, lng: 30.5072737 },
      { lat: 50.4523809, lng: 30.5064797 },
      { lat: 50.4529137, lng: 30.5058146 },
      { lat: 50.4525311, lng: 30.5047631 },
      { lat: 50.4518753, lng: 30.5053854 },
      { lat: 50.4511785, lng: 30.5033684 },
      { lat: 50.4519027, lng: 30.5026817 },
      { lat: 50.4516977, lng: 30.5018878 },
      { lat: 50.4521349, lng: 30.501287 },
      { lat: 50.4522442, lng: 30.5007076 },
      { lat: 50.4504134, lng: 30.4964805 },
      { lat: 50.4486508, lng: 30.4982185 },
      { lat: 50.4470385, lng: 30.494163 },
      { lat: 50.4464646, lng: 30.4946995 },
      { lat: 50.4455218, lng: 30.5010295 },
      { lat: 50.444046, lng: 30.5097842 },
      { lat: 50.4436087, lng: 30.512445 },
      { lat: 50.4460957, lng: 30.5134535 },
      { lat: 50.4464578, lng: 30.5114365 },
    ],
  },
  {
    name: 'СОФИЕВСКАЯ ПЛ.',
    price: 168,
    points: [
      { lat: 50.4529957, lng: 30.5161357 },
      { lat: 50.4535148, lng: 30.5172086 },
      { lat: 50.454116, lng: 30.5187535 },
      { lat: 50.4548332, lng: 30.5208242 },
      { lat: 50.4553182, lng: 30.5197406 },
      { lat: 50.4554548, lng: 30.5174661 },
      { lat: 50.4555231, lng: 30.5149341 },
      { lat: 50.4554821, lng: 30.5139685 },
      { lat: 50.4553865, lng: 30.5136037 },
      { lat: 50.4544302, lng: 30.5123591 },
      { lat: 50.4539247, lng: 30.5120158 },
      { lat: 50.452982, lng: 30.5102992 },
      { lat: 50.4527361, lng: 30.5096555 },
      { lat: 50.4523809, lng: 30.5090761 },
      { lat: 50.4495936, lng: 30.5129814 },
      { lat: 50.449457, lng: 30.5136466 },
      { lat: 50.4491154, lng: 30.5135393 },
      { lat: 50.4489241, lng: 30.5144405 },
      { lat: 50.4529957, lng: 30.5161357 },
    ],
  },
  {
    name: 'МАЙДАН',
    price: 169,
    points: [
      { lat: 50.4485757, lng: 30.5211031 },
      { lat: 50.4495663, lng: 30.5217576 },
      { lat: 50.4502563, lng: 30.5226911 },
      { lat: 50.4499643, lng: 30.5233227 },
      { lat: 50.4505219, lng: 30.5240134 },
      { lat: 50.4514535, lng: 30.5223557 },
      { lat: 50.4514381, lng: 30.5220687 },
      { lat: 50.4519436, lng: 30.5215805 },
      { lat: 50.4520598, lng: 30.5219722 },
      { lat: 50.4530776, lng: 30.5214143 },
      { lat: 50.4529547, lng: 30.5209207 },
      { lat: 50.4536309, lng: 30.5203092 },
      { lat: 50.454034, lng: 30.521425 },
      { lat: 50.4548332, lng: 30.5208242 },
      { lat: 50.454116, lng: 30.5187535 },
      { lat: 50.4535148, lng: 30.5172086 },
      { lat: 50.4529957, lng: 30.5161357 },
      { lat: 50.4489241, lng: 30.5144405 },
      { lat: 50.447831, lng: 30.521307 },
      { lat: 50.4483879, lng: 30.5215484 },
      { lat: 50.4485757, lng: 30.5211031 },
    ],
  },
  {
    name: 'ЗОЛОТЫЕ ВОРОТА',
    price: 170,
    points: [
      { lat: 50.4422832, lng: 30.520277 },
      { lat: 50.4429289, lng: 30.5204433 },
      { lat: 50.4434823, lng: 30.5206471 },
      { lat: 50.447708, lng: 30.5220366 },
      { lat: 50.447831, lng: 30.521307 },
      { lat: 50.4489241, lng: 30.5144405 },
      { lat: 50.4491154, lng: 30.5135393 },
      { lat: 50.4492384, lng: 30.5125308 },
      { lat: 50.4464578, lng: 30.5114365 },
      { lat: 50.4460957, lng: 30.5134535 },
      { lat: 50.4436087, lng: 30.512445 },
      { lat: 50.4422832, lng: 30.520277 },
    ],
  },
  {
    name: 'ЖД ЦЕНТРАЛЬНЫЙ',
    price: 171,
    points: [
      { lat: 50.4445243, lng: 30.4987764 },
      { lat: 50.4455218, lng: 30.5010295 },
      { lat: 50.4464646, lng: 30.4946995 },
      { lat: 50.4471205, lng: 30.4908156 },
      { lat: 50.4484801, lng: 30.4823827 },
      { lat: 50.4470249, lng: 30.4818249 },
      { lat: 50.446545, lng: 30.4836542 },
      { lat: 50.4462435, lng: 30.4858455 },
      { lat: 50.4461747, lng: 30.4867052 },
      { lat: 50.446195, lng: 30.4886907 },
      { lat: 50.4461881, lng: 30.4894794 },
      { lat: 50.4461129, lng: 30.4902252 },
      { lat: 50.4459283, lng: 30.4910302 },
      { lat: 50.4424317, lng: 30.4862827 },
      { lat: 50.439468, lng: 30.4890347 },
      { lat: 50.4372814, lng: 30.4923391 },
      { lat: 50.4364887, lng: 30.4931974 },
      { lat: 50.43586, lng: 30.4943991 },
      { lat: 50.4351766, lng: 30.4956865 },
      { lat: 50.4343566, lng: 30.4971886 },
      { lat: 50.4335092, lng: 30.4986477 },
      { lat: 50.4343019, lng: 30.500021 },
      { lat: 50.437008, lng: 30.4960728 },
      { lat: 50.438115, lng: 30.4945171 },
      { lat: 50.439427, lng: 30.4973066 },
      { lat: 50.4388171, lng: 30.498299 },
      { lat: 50.4377836, lng: 30.4991412 },
      { lat: 50.4396798, lng: 30.5028534 },
      { lat: 50.4412719, lng: 30.5057931 },
      { lat: 50.4419825, lng: 30.5014372 },
      { lat: 50.4445243, lng: 30.4987764 },
    ],
  },
  {
    name: 'УН-Т ШЕВЧЕНКО',
    price: 172,
    points: [
      { lat: 50.4403973, lng: 30.5111361 },
      { lat: 50.4436087, lng: 30.512445 },
      { lat: 50.444046, lng: 30.5097842 },
      { lat: 50.4455218, lng: 30.5010295 },
      { lat: 50.4445243, lng: 30.4987764 },
      { lat: 50.4419825, lng: 30.5014372 },
      { lat: 50.4412719, lng: 30.5057931 },
      { lat: 50.4403973, lng: 30.5111361 },
    ],
  },
  {
    name: 'ПАНЬКОВЩИНА',
    price: 173,
    points: [
      { lat: 50.4381219, lng: 30.5102026 },
      { lat: 50.4403973, lng: 30.5111361 },
      { lat: 50.4412719, lng: 30.5057931 },
      { lat: 50.4396798, lng: 30.5028534 },
      { lat: 50.4377836, lng: 30.4991412 },
      { lat: 50.4388171, lng: 30.498299 },
      { lat: 50.439427, lng: 30.4973066 },
      { lat: 50.438115, lng: 30.4945171 },
      { lat: 50.437008, lng: 30.4960728 },
      { lat: 50.4343019, lng: 30.500021 },
      { lat: 50.4324704, lng: 30.5018663 },
      { lat: 50.4309122, lng: 30.503583 },
      { lat: 50.4328258, lng: 30.5077243 },
      { lat: 50.4335092, lng: 30.5083036 },
      { lat: 50.4381219, lng: 30.5102026 },
    ],
  },
  {
    name: 'ЛЬВА ТОЛСТОГО',
    price: 174,
    points: [
      { lat: 50.4332768, lng: 30.5135608 },
      { lat: 50.4359522, lng: 30.5136198 },
      { lat: 50.4359557, lng: 30.5160928 },
      { lat: 50.439017, lng: 30.5160499 },
      { lat: 50.4392664, lng: 30.5165326 },
      { lat: 50.4389692, lng: 30.5168385 },
      { lat: 50.4384703, lng: 30.5168546 },
      { lat: 50.4382156, lng: 30.5175288 },
      { lat: 50.438651, lng: 30.5180957 },
      { lat: 50.4388658, lng: 30.5178134 },
      { lat: 50.4394799, lng: 30.5192337 },
      { lat: 50.4401274, lng: 30.5184692 },
      { lat: 50.4407116, lng: 30.519762 },
      { lat: 50.440862, lng: 30.519805 },
      { lat: 50.4422832, lng: 30.520277 },
      { lat: 50.4436087, lng: 30.512445 },
      { lat: 50.4403973, lng: 30.5111361 },
      { lat: 50.4381219, lng: 30.5102026 },
      { lat: 50.4335092, lng: 30.5083036 },
      { lat: 50.4331128, lng: 30.5113077 },
      { lat: 50.4332768, lng: 30.5135608 },
    ],
  },
  {
    name: 'ОЛИМПИЙСКИЙ',
    price: 175,
    points: [
      { lat: 50.4275086, lng: 30.5099774 },
      { lat: 50.4278161, lng: 30.5130673 },
      { lat: 50.4279323, lng: 30.5161894 },
      { lat: 50.4280895, lng: 30.5161142 },
      { lat: 50.4304918, lng: 30.516007 },
      { lat: 50.4304953, lng: 30.5195314 },
      { lat: 50.4304406, lng: 30.5244291 },
      { lat: 50.4326345, lng: 30.5264462 },
      { lat: 50.4329078, lng: 30.5233133 },
      { lat: 50.4344659, lng: 30.5231738 },
      { lat: 50.4352313, lng: 30.5213928 },
      { lat: 50.4349033, lng: 30.5201054 },
      { lat: 50.4355046, lng: 30.51929 },
      { lat: 50.4354841, lng: 30.5168438 },
      { lat: 50.4384703, lng: 30.5168546 },
      { lat: 50.4389692, lng: 30.5168385 },
      { lat: 50.4392664, lng: 30.5165326 },
      { lat: 50.439017, lng: 30.5160499 },
      { lat: 50.4359557, lng: 30.5160928 },
      { lat: 50.4359522, lng: 30.5136198 },
      { lat: 50.4332768, lng: 30.5135608 },
      { lat: 50.4331128, lng: 30.5113077 },
      { lat: 50.4335092, lng: 30.5083036 },
      { lat: 50.4328258, lng: 30.5077243 },
      { lat: 50.4309122, lng: 30.503583 },
      { lat: 50.4287251, lng: 30.5056429 },
      { lat: 50.4270369, lng: 30.5065602 },
      { lat: 50.4263432, lng: 30.5072845 },
      { lat: 50.4275086, lng: 30.5099774 },
    ],
  },
  {
    name: 'ДВОРЕЦ УКРАИНА',
    price: 176,
    points: [
      { lat: 50.4170364, lng: 30.5226803 },
      { lat: 50.4180208, lng: 30.52665 },
      { lat: 50.4186634, lng: 30.5287957 },
      { lat: 50.422013, lng: 30.5264997 },
      { lat: 50.4228059, lng: 30.5260062 },
      { lat: 50.4230247, lng: 30.52459 },
      { lat: 50.4243507, lng: 30.5235815 },
      { lat: 50.4255811, lng: 30.5268002 },
      { lat: 50.4261108, lng: 30.526253 },
      { lat: 50.4267789, lng: 30.5275726 },
      { lat: 50.427987, lng: 30.5291176 },
      { lat: 50.4283014, lng: 30.5284846 },
      { lat: 50.4294907, lng: 30.5298364 },
      { lat: 50.4288209, lng: 30.5319929 },
      { lat: 50.4308096, lng: 30.5333126 },
      { lat: 50.4326345, lng: 30.5264462 },
      { lat: 50.4304406, lng: 30.5244291 },
      { lat: 50.4304953, lng: 30.5195314 },
      { lat: 50.4304918, lng: 30.516007 },
      { lat: 50.4280895, lng: 30.5161142 },
      { lat: 50.4279323, lng: 30.5161894 },
      { lat: 50.4278161, lng: 30.5130673 },
      { lat: 50.4275086, lng: 30.5099774 },
      { lat: 50.4263432, lng: 30.5072845 },
      { lat: 50.4233118, lng: 30.5106961 },
      { lat: 50.4219447, lng: 30.5117369 },
      { lat: 50.4210697, lng: 30.5123806 },
      { lat: 50.4179798, lng: 30.5149984 },
      { lat: 50.4157375, lng: 30.5174017 },
      { lat: 50.4170364, lng: 30.5226803 },
    ],
  },
  {
    name: 'М. ЛЫБЕДСКАЯ',
    price: 177,
    points: [
      { lat: 50.4188001, lng: 30.5296326 },
      { lat: 50.4186634, lng: 30.5287957 },
      { lat: 50.4180208, lng: 30.52665 },
      { lat: 50.4170364, lng: 30.5226803 },
      { lat: 50.4157375, lng: 30.5174017 },
      { lat: 50.4142881, lng: 30.5186892 },
      { lat: 50.4090372, lng: 30.5228949 },
      { lat: 50.4105688, lng: 30.5262852 },
      { lat: 50.4121687, lng: 30.530405 },
      { lat: 50.4126473, lng: 30.5320573 },
      { lat: 50.4130438, lng: 30.5333018 },
      { lat: 50.4157511, lng: 30.5315208 },
      { lat: 50.4188001, lng: 30.5296326 },
    ],
  },
  {
    name: 'САПЕРНОЕ ПОЛЕ',
    price: 178,
    points: [
      { lat: 50.4233938, lng: 30.5334949 },
      { lat: 50.4243234, lng: 30.5335808 },
      { lat: 50.4250616, lng: 30.5316067 },
      { lat: 50.4228059, lng: 30.5260062 },
      { lat: 50.422013, lng: 30.5264997 },
      { lat: 50.4186634, lng: 30.5287957 },
      { lat: 50.4188001, lng: 30.5296326 },
      { lat: 50.4157511, lng: 30.5315208 },
      { lat: 50.4130438, lng: 30.5333018 },
      { lat: 50.4137138, lng: 30.5354476 },
      { lat: 50.4142744, lng: 30.5369496 },
      { lat: 50.4156007, lng: 30.5399108 },
      { lat: 50.4162024, lng: 30.5410747 },
      { lat: 50.4197025, lng: 30.5478287 },
      { lat: 50.4228196, lng: 30.5439663 },
      { lat: 50.4235032, lng: 30.5432367 },
      { lat: 50.423093, lng: 30.5422068 },
      { lat: 50.4234485, lng: 30.5414343 },
      { lat: 50.4227102, lng: 30.5402756 },
      { lat: 50.4229016, lng: 30.5395031 },
      { lat: 50.4221087, lng: 30.5384302 },
      { lat: 50.4231751, lng: 30.5363703 },
      { lat: 50.4233938, lng: 30.5334949 },
    ],
  },
  {
    name: 'М. ПЕЧЕРСКАЯ',
    price: 179,
    points: [
      { lat: 50.4241867, lng: 30.542593 },
      { lat: 50.425253, lng: 30.5418205 },
      { lat: 50.4279323, lng: 30.5386877 },
      { lat: 50.4292719, lng: 30.5409622 },
      { lat: 50.4312676, lng: 30.5370998 },
      { lat: 50.4315409, lng: 30.537529 },
      { lat: 50.4334272, lng: 30.533967 },
      { lat: 50.4330718, lng: 30.5331945 },
      { lat: 50.4338099, lng: 30.5322504 },
      { lat: 50.4344112, lng: 30.5316067 },
      { lat: 50.4340832, lng: 30.53092 },
      { lat: 50.4336732, lng: 30.5312634 },
      { lat: 50.4334272, lng: 30.5307913 },
      { lat: 50.4320057, lng: 30.5324221 },
      { lat: 50.4313222, lng: 30.5313492 },
      { lat: 50.4308096, lng: 30.5333126 },
      { lat: 50.4288209, lng: 30.5319929 },
      { lat: 50.4294907, lng: 30.5298364 },
      { lat: 50.4283014, lng: 30.5284846 },
      { lat: 50.427987, lng: 30.5291176 },
      { lat: 50.4267789, lng: 30.5275726 },
      { lat: 50.4261108, lng: 30.526253 },
      { lat: 50.4255811, lng: 30.5268002 },
      { lat: 50.4243507, lng: 30.5235815 },
      { lat: 50.4230247, lng: 30.52459 },
      { lat: 50.4228059, lng: 30.5260062 },
      { lat: 50.4250616, lng: 30.5316067 },
      { lat: 50.4243234, lng: 30.5335808 },
      { lat: 50.4233938, lng: 30.5334949 },
      { lat: 50.4231751, lng: 30.5363703 },
      { lat: 50.4221087, lng: 30.5384302 },
      { lat: 50.4229016, lng: 30.5395031 },
      { lat: 50.4227102, lng: 30.5402756 },
      { lat: 50.4234485, lng: 30.5414343 },
      { lat: 50.423093, lng: 30.5422068 },
      { lat: 50.4235032, lng: 30.5432367 },
      { lat: 50.4241867, lng: 30.542593 },
    ],
  },
  {
    name: 'КАМЕНЕВА',
    price: 180,
    points: [
      { lat: 50.4246788, lng: 30.5454683 },
      { lat: 50.4235032, lng: 30.5432367 },
      { lat: 50.4228196, lng: 30.5439663 },
      { lat: 50.4197025, lng: 30.5478287 },
      { lat: 50.42189, lng: 30.5520773 },
      { lat: 50.4228196, lng: 30.5548239 },
      { lat: 50.4231204, lng: 30.5559397 },
      { lat: 50.4245148, lng: 30.5549955 },
      { lat: 50.4249249, lng: 30.5521202 },
      { lat: 50.4253624, lng: 30.5506611 },
      { lat: 50.4249796, lng: 30.5494165 },
      { lat: 50.4250069, lng: 30.5482149 },
      { lat: 50.4256631, lng: 30.5467558 },
      { lat: 50.4246788, lng: 30.5454683 },
    ],
  },
  {
    name: 'ЦАРСКОЕ СЕЛ',
    price: 181,
    points: [
      { lat: 50.4257725, lng: 30.5509186 },
      { lat: 50.4262372, lng: 30.5501032 },
      { lat: 50.4272215, lng: 30.5512619 },
      { lat: 50.4278776, lng: 30.5504894 },
      { lat: 50.4296205, lng: 30.5491912 },
      { lat: 50.430386, lng: 30.5488908 },
      { lat: 50.4309669, lng: 30.5487299 },
      { lat: 50.4308848, lng: 30.5480003 },
      { lat: 50.4322517, lng: 30.5476999 },
      { lat: 50.431951, lng: 30.5457687 },
      { lat: 50.431705, lng: 30.5455542 },
      { lat: 50.4317596, lng: 30.5444813 },
      { lat: 50.4292719, lng: 30.5409622 },
      { lat: 50.4279323, lng: 30.5386877 },
      { lat: 50.425253, lng: 30.5418205 },
      { lat: 50.4241867, lng: 30.542593 },
      { lat: 50.4235032, lng: 30.5432367 },
      { lat: 50.4246788, lng: 30.5454683 },
      { lat: 50.4256631, lng: 30.5467558 },
      { lat: 50.4250069, lng: 30.5482149 },
      { lat: 50.4249796, lng: 30.5494165 },
      { lat: 50.4253624, lng: 30.5506611 },
      { lat: 50.4257725, lng: 30.5509186 },
    ],
  },
  {
    name: 'ПАНФИЛОВЦЕВ',
    price: 182,
    points: [
      { lat: 50.4257178, lng: 30.5551243 },
      { lat: 50.4265653, lng: 30.5554891 },
      { lat: 50.4263877, lng: 30.557254 },
      { lat: 50.4258955, lng: 30.5596948 },
      { lat: 50.4259638, lng: 30.5612183 },
      { lat: 50.4260732, lng: 30.5645657 },
      { lat: 50.4283834, lng: 30.5644798 },
      { lat: 50.4316776, lng: 30.5634069 },
      { lat: 50.4311172, lng: 30.5597806 },
      { lat: 50.4302424, lng: 30.5579782 },
      { lat: 50.4307071, lng: 30.5566478 },
      { lat: 50.4317733, lng: 30.5519271 },
      { lat: 50.4310625, lng: 30.5504251 },
      { lat: 50.4309669, lng: 30.5487299 },
      { lat: 50.430386, lng: 30.5488908 },
      { lat: 50.4296205, lng: 30.5491912 },
      { lat: 50.4278776, lng: 30.5504894 },
      { lat: 50.4272215, lng: 30.5512619 },
      { lat: 50.4262372, lng: 30.5501032 },
      { lat: 50.4257725, lng: 30.5509186 },
      { lat: 50.4257178, lng: 30.5551243 },
    ],
  },
  {
    name: 'ЛАВРА',
    price: 183,
    points: [
      { lat: 50.4346436, lng: 30.5622268 },
      { lat: 50.43586, lng: 30.5599737 },
      { lat: 50.4405613, lng: 30.5537081 },
      { lat: 50.440288, lng: 30.5529571 },
      { lat: 50.4403017, lng: 30.5524635 },
      { lat: 50.4404656, lng: 30.55197 },
      { lat: 50.4405613, lng: 30.5503607 },
      { lat: 50.44037, lng: 30.5498886 },
      { lat: 50.439345, lng: 30.5486226 },
      { lat: 50.4360513, lng: 30.5453825 },
      { lat: 50.435942, lng: 30.5449319 },
      { lat: 50.4350673, lng: 30.5450177 },
      { lat: 50.4333451, lng: 30.545125 },
      { lat: 50.4327027, lng: 30.5450392 },
      { lat: 50.4317596, lng: 30.5444813 },
      { lat: 50.431705, lng: 30.5455542 },
      { lat: 50.431951, lng: 30.5457687 },
      { lat: 50.4322517, lng: 30.5476999 },
      { lat: 50.4308848, lng: 30.5480003 },
      { lat: 50.4309669, lng: 30.5487299 },
      { lat: 50.4310625, lng: 30.5504251 },
      { lat: 50.4317733, lng: 30.5519271 },
      { lat: 50.4307071, lng: 30.5566478 },
      { lat: 50.4302424, lng: 30.5579782 },
      { lat: 50.4311172, lng: 30.5597806 },
      { lat: 50.4316776, lng: 30.5634069 },
      { lat: 50.431787, lng: 30.5641794 },
      { lat: 50.4330034, lng: 30.5639648 },
      { lat: 50.4346436, lng: 30.5622268 },
    ],
  },
  {
    name: 'ПЕЧЕРСКАЯ ПЛ',
    price: 184,
    points: [
      { lat: 50.4344659, lng: 30.5351686 },
      { lat: 50.4346846, lng: 30.5340314 },
      { lat: 50.4343292, lng: 30.5334306 },
      { lat: 50.4336595, lng: 30.5335808 },
      { lat: 50.4334272, lng: 30.533967 },
      { lat: 50.4315409, lng: 30.537529 },
      { lat: 50.4312676, lng: 30.5370998 },
      { lat: 50.4292719, lng: 30.5409622 },
      { lat: 50.4317596, lng: 30.5444813 },
      { lat: 50.4327027, lng: 30.5450392 },
      { lat: 50.4333451, lng: 30.545125 },
      { lat: 50.4350673, lng: 30.5450177 },
      { lat: 50.435081, lng: 30.5442238 },
      { lat: 50.4353406, lng: 30.5427432 },
      { lat: 50.4355593, lng: 30.5415416 },
      { lat: 50.4343429, lng: 30.5409622 },
      { lat: 50.4346163, lng: 30.5398893 },
      { lat: 50.4351903, lng: 30.5401897 },
      { lat: 50.4352996, lng: 30.5399537 },
      { lat: 50.43627, lng: 30.540576 },
      { lat: 50.43709, lng: 30.5375504 },
      { lat: 50.4371174, lng: 30.5367136 },
      { lat: 50.4356003, lng: 30.5357695 },
      { lat: 50.4344659, lng: 30.5351686 },
    ],
  },
  {
    name: 'ДВОРЕЦ СПОРТА',
    price: 185,
    points: [
      { lat: 50.4358737, lng: 30.531199 },
      { lat: 50.4364613, lng: 30.5304909 },
      { lat: 50.4369124, lng: 30.5284739 },
      { lat: 50.4370251, lng: 30.5281412 },
      { lat: 50.4372472, lng: 30.5276075 },
      { lat: 50.4370592, lng: 30.5273205 },
      { lat: 50.4377324, lng: 30.526135 },
      { lat: 50.4387164, lng: 30.5249119 },
      { lat: 50.4389487, lng: 30.5251265 },
      { lat: 50.4391042, lng: 30.5254215 },
      { lat: 50.4406159, lng: 30.5275887 },
      { lat: 50.4416204, lng: 30.5262959 },
      { lat: 50.4426249, lng: 30.525738 },
      { lat: 50.4429515, lng: 30.525481 },
      { lat: 50.4429091, lng: 30.5228206 },
      { lat: 50.443289, lng: 30.522081 },
      { lat: 50.4434823, lng: 30.5206471 },
      { lat: 50.4429289, lng: 30.5204433 },
      { lat: 50.4422832, lng: 30.520277 },
      { lat: 50.440862, lng: 30.519805 },
      { lat: 50.4407116, lng: 30.519762 },
      { lat: 50.4401274, lng: 30.5184692 },
      { lat: 50.4394799, lng: 30.5192337 },
      { lat: 50.4388658, lng: 30.5178134 },
      { lat: 50.438651, lng: 30.5180957 },
      { lat: 50.4382156, lng: 30.5175288 },
      { lat: 50.4384703, lng: 30.5168546 },
      { lat: 50.4354841, lng: 30.5168438 },
      { lat: 50.4355046, lng: 30.51929 },
      { lat: 50.4349033, lng: 30.5201054 },
      { lat: 50.4352313, lng: 30.5213928 },
      { lat: 50.4344659, lng: 30.5231738 },
      { lat: 50.4329078, lng: 30.5233133 },
      { lat: 50.4326345, lng: 30.5264462 },
      { lat: 50.4313222, lng: 30.5313492 },
      { lat: 50.4320057, lng: 30.5324221 },
      { lat: 50.4334272, lng: 30.5307913 },
      { lat: 50.4336732, lng: 30.5312634 },
      { lat: 50.4340832, lng: 30.53092 },
      { lat: 50.4344112, lng: 30.5316067 },
      { lat: 50.4358737, lng: 30.531199 },
    ],
  },
  {
    name: 'М. КЛОВСКАЯ',
    price: 186,
    points: [
      { lat: 50.436516, lng: 30.5448246 },
      { lat: 50.436557, lng: 30.5443096 },
      { lat: 50.4371584, lng: 30.5417776 },
      { lat: 50.4405476, lng: 30.541048 },
      { lat: 50.4422012, lng: 30.5411339 },
      { lat: 50.4427068, lng: 30.5401897 },
      { lat: 50.4435131, lng: 30.5396104 },
      { lat: 50.4433627, lng: 30.5389023 },
      { lat: 50.4428298, lng: 30.5383015 },
      { lat: 50.4431714, lng: 30.5376148 },
      { lat: 50.4425975, lng: 30.5369282 },
      { lat: 50.4408073, lng: 30.5375075 },
      { lat: 50.4398917, lng: 30.5370355 },
      { lat: 50.439427, lng: 30.5350614 },
      { lat: 50.4391127, lng: 30.5338383 },
      { lat: 50.438935, lng: 30.5335808 },
      { lat: 50.438689, lng: 30.5326581 },
      { lat: 50.4387027, lng: 30.53195 },
      { lat: 50.4380057, lng: 30.530684 },
      { lat: 50.4383064, lng: 30.5295897 },
      { lat: 50.43832, lng: 30.5264783 },
      { lat: 50.4389487, lng: 30.5251265 },
      { lat: 50.4387164, lng: 30.5249119 },
      { lat: 50.4377324, lng: 30.526135 },
      { lat: 50.4370592, lng: 30.5273205 },
      { lat: 50.4372472, lng: 30.5276075 },
      { lat: 50.4370251, lng: 30.5281412 },
      { lat: 50.4369124, lng: 30.5284739 },
      { lat: 50.4364613, lng: 30.5304909 },
      { lat: 50.4358737, lng: 30.531199 },
      { lat: 50.4344112, lng: 30.5316067 },
      { lat: 50.4338099, lng: 30.5322504 },
      { lat: 50.4330718, lng: 30.5331945 },
      { lat: 50.4334272, lng: 30.533967 },
      { lat: 50.4336595, lng: 30.5335808 },
      { lat: 50.4343292, lng: 30.5334306 },
      { lat: 50.4346846, lng: 30.5340314 },
      { lat: 50.4344659, lng: 30.5351686 },
      { lat: 50.4356003, lng: 30.5357695 },
      { lat: 50.4371174, lng: 30.5367136 },
      { lat: 50.43709, lng: 30.5375504 },
      { lat: 50.43627, lng: 30.540576 },
      { lat: 50.4352996, lng: 30.5399537 },
      { lat: 50.4351903, lng: 30.5401897 },
      { lat: 50.4346163, lng: 30.5398893 },
      { lat: 50.4343429, lng: 30.5409622 },
      { lat: 50.4355593, lng: 30.5415416 },
      { lat: 50.4353406, lng: 30.5427432 },
      { lat: 50.435081, lng: 30.5442238 },
      { lat: 50.4350673, lng: 30.5450177 },
      { lat: 50.435942, lng: 30.5449319 },
      { lat: 50.436516, lng: 30.5448246 },
    ],
  },
  {
    name: 'ЛИПКИ',
    price: 187,
    points: [
      { lat: 50.4440187, lng: 30.5399537 },
      { lat: 50.4446199, lng: 30.5408549 },
      { lat: 50.4449069, lng: 30.5419064 },
      { lat: 50.446369, lng: 30.5437088 },
      { lat: 50.447708, lng: 30.5412841 },
      { lat: 50.4495663, lng: 30.5373788 },
      { lat: 50.447872, lng: 30.534997 },
      { lat: 50.4470385, lng: 30.5340958 },
      { lat: 50.448118, lng: 30.531435 },
      { lat: 50.4483229, lng: 30.5303193 },
      { lat: 50.4466764, lng: 30.5282914 },
      { lat: 50.4455661, lng: 30.5289083 },
      { lat: 50.4451169, lng: 30.5283477 },
      { lat: 50.4449709, lng: 30.5267156 },
      { lat: 50.4442078, lng: 30.5247301 },
      { lat: 50.444694, lng: 30.5235872 },
      { lat: 50.4439464, lng: 30.5222647 },
      { lat: 50.443289, lng: 30.522081 },
      { lat: 50.4429091, lng: 30.5228206 },
      { lat: 50.4429515, lng: 30.525481 },
      { lat: 50.4426249, lng: 30.525738 },
      { lat: 50.4416204, lng: 30.5262959 },
      { lat: 50.4406159, lng: 30.5275887 },
      { lat: 50.4391042, lng: 30.5254215 },
      { lat: 50.4389487, lng: 30.5251265 },
      { lat: 50.43832, lng: 30.5264783 },
      { lat: 50.4383064, lng: 30.5295897 },
      { lat: 50.4380057, lng: 30.530684 },
      { lat: 50.4387027, lng: 30.53195 },
      { lat: 50.438689, lng: 30.5326581 },
      { lat: 50.438935, lng: 30.5335808 },
      { lat: 50.4391127, lng: 30.5338383 },
      { lat: 50.439427, lng: 30.5350614 },
      { lat: 50.4398917, lng: 30.5370355 },
      { lat: 50.4408073, lng: 30.5375075 },
      { lat: 50.4425975, lng: 30.5369282 },
      { lat: 50.4431714, lng: 30.5376148 },
      { lat: 50.4428298, lng: 30.5383015 },
      { lat: 50.4433627, lng: 30.5389023 },
      { lat: 50.4435131, lng: 30.5396104 },
      { lat: 50.4440187, lng: 30.5399537 },
    ],
  },
  {
    name: 'М. КРЕЩАТИК',
    price: 188,
    points: [
      { lat: 50.4477764, lng: 30.5296755 },
      { lat: 50.4483229, lng: 30.5303193 },
      { lat: 50.4496619, lng: 30.5287957 },
      { lat: 50.4504817, lng: 30.5281949 },
      { lat: 50.4508506, lng: 30.5264997 },
      { lat: 50.4516021, lng: 30.5268002 },
      { lat: 50.451807, lng: 30.5264997 },
      { lat: 50.4507687, lng: 30.5250621 },
      { lat: 50.4503007, lng: 30.524405 },
      { lat: 50.449457, lng: 30.5231524 },
      { lat: 50.4486099, lng: 30.5225086 },
      { lat: 50.447708, lng: 30.5220366 },
      { lat: 50.4434823, lng: 30.5206471 },
      { lat: 50.443289, lng: 30.522081 },
      { lat: 50.4439464, lng: 30.5222647 },
      { lat: 50.444694, lng: 30.5235872 },
      { lat: 50.4442078, lng: 30.5247301 },
      { lat: 50.4449709, lng: 30.5267156 },
      { lat: 50.4451169, lng: 30.5283477 },
      { lat: 50.4455661, lng: 30.5289083 },
      { lat: 50.4466764, lng: 30.5282914 },
      { lat: 50.4477764, lng: 30.5296755 },
    ],
  },
  {
    name: 'ЕВРОПЕЙСКАЯ ПЛ.',
    price: 189,
    points: [
      { lat: 50.4506867, lng: 30.5372071 },
      { lat: 50.4514518, lng: 30.5354905 },
      { lat: 50.451889, lng: 30.5340743 },
      { lat: 50.4537061, lng: 30.5313491 },
      { lat: 50.4545395, lng: 30.5318535 },
      { lat: 50.4553336, lng: 30.5301609 },
      { lat: 50.455004, lng: 30.5274868 },
      { lat: 50.4556871, lng: 30.5245471 },
      { lat: 50.4551184, lng: 30.5228922 },
      { lat: 50.4551713, lng: 30.5222565 },
      { lat: 50.455045, lng: 30.5215994 },
      { lat: 50.4548332, lng: 30.5208242 },
      { lat: 50.454034, lng: 30.521425 },
      { lat: 50.4536309, lng: 30.5203092 },
      { lat: 50.4529547, lng: 30.5209207 },
      { lat: 50.4530776, lng: 30.5214143 },
      { lat: 50.4520598, lng: 30.5219722 },
      { lat: 50.4519436, lng: 30.5215805 },
      { lat: 50.4514381, lng: 30.5220687 },
      { lat: 50.4514535, lng: 30.5223557 },
      { lat: 50.4505219, lng: 30.5240134 },
      { lat: 50.4499643, lng: 30.5233227 },
      { lat: 50.4502563, lng: 30.5226911 },
      { lat: 50.4495663, lng: 30.5217576 },
      { lat: 50.4485757, lng: 30.5211031 },
      { lat: 50.4483879, lng: 30.5215484 },
      { lat: 50.447831, lng: 30.521307 },
      { lat: 50.447708, lng: 30.5220366 },
      { lat: 50.4486099, lng: 30.5225086 },
      { lat: 50.449457, lng: 30.5231524 },
      { lat: 50.4503007, lng: 30.524405 },
      { lat: 50.4507687, lng: 30.5250621 },
      { lat: 50.451807, lng: 30.5264997 },
      { lat: 50.4516021, lng: 30.5268002 },
      { lat: 50.4508506, lng: 30.5264997 },
      { lat: 50.4504817, lng: 30.5281949 },
      { lat: 50.4496619, lng: 30.5287957 },
      { lat: 50.4483229, lng: 30.5303193 },
      { lat: 50.448118, lng: 30.531435 },
      { lat: 50.4470385, lng: 30.5340958 },
      { lat: 50.447872, lng: 30.534997 },
      { lat: 50.4495663, lng: 30.5373788 },
      { lat: 50.4506867, lng: 30.5372071 },
    ],
  },
  {
    name: 'М. АРСЕНАЛЬНАЯ',
    price: 190,
    points: [
      { lat: 50.4426453, lng: 30.5520988 },
      { lat: 50.443718, lng: 30.5490732 },
      { lat: 50.446369, lng: 30.5437088 },
      { lat: 50.4449069, lng: 30.5419064 },
      { lat: 50.4446199, lng: 30.5408549 },
      { lat: 50.4440187, lng: 30.5399537 },
      { lat: 50.4435131, lng: 30.5396104 },
      { lat: 50.4427068, lng: 30.5401897 },
      { lat: 50.4422012, lng: 30.5411339 },
      { lat: 50.4405476, lng: 30.541048 },
      { lat: 50.4371584, lng: 30.5417776 },
      { lat: 50.436557, lng: 30.5443096 },
      { lat: 50.436516, lng: 30.5448246 },
      { lat: 50.435942, lng: 30.5449319 },
      { lat: 50.4360513, lng: 30.5453825 },
      { lat: 50.439345, lng: 30.5486226 },
      { lat: 50.44037, lng: 30.5498886 },
      { lat: 50.4405613, lng: 30.5503607 },
      { lat: 50.4404656, lng: 30.55197 },
      { lat: 50.4403017, lng: 30.5524635 },
      { lat: 50.440288, lng: 30.5529571 },
      { lat: 50.4405613, lng: 30.5537081 },
      { lat: 50.4426453, lng: 30.5520988 },
    ],
  },
  {
    name: 'ПАРКОВАЯ ДОРОГА',
    price: 191,
    points: [
      { lat: 50.4484596, lng: 30.5445993 },
      { lat: 50.4513288, lng: 30.5384195 },
      { lat: 50.4545395, lng: 30.5318428 },
      { lat: 50.4537061, lng: 30.5313491 },
      { lat: 50.451889, lng: 30.5340636 },
      { lat: 50.4514518, lng: 30.5354798 },
      { lat: 50.4506867, lng: 30.5371964 },
      { lat: 50.4495663, lng: 30.5373681 },
      { lat: 50.447708, lng: 30.5412734 },
      { lat: 50.446369, lng: 30.5436981 },
      { lat: 50.443718, lng: 30.5490625 },
      { lat: 50.4426453, lng: 30.5520881 },
      { lat: 50.4405613, lng: 30.5536974 },
      { lat: 50.4419825, lng: 30.5558861 },
      { lat: 50.4447976, lng: 30.5519378 },
      { lat: 50.4484596, lng: 30.5445993 },
    ],
  },
  {
    name: 'М. ДНЕПР',
    price: 192,
    points: [
      { lat: 50.4426589, lng: 30.5567014 },
      { lat: 50.4419825, lng: 30.5558968 },
      { lat: 50.4405613, lng: 30.5537081 },
      { lat: 50.43586, lng: 30.5599737 },
      { lat: 50.4346436, lng: 30.5622268 },
      { lat: 50.4330034, lng: 30.5639648 },
      { lat: 50.431787, lng: 30.5641794 },
      { lat: 50.4316776, lng: 30.5634069 },
      { lat: 50.4283834, lng: 30.5644798 },
      { lat: 50.4260732, lng: 30.5645657 },
      { lat: 50.4242687, lng: 30.5639219 },
      { lat: 50.4238313, lng: 30.5668831 },
      { lat: 50.4238313, lng: 30.569458 },
      { lat: 50.4245558, lng: 30.5724192 },
      { lat: 50.4254717, lng: 30.57199 },
      { lat: 50.4375274, lng: 30.563879 },
      { lat: 50.4419005, lng: 30.5601025 },
      { lat: 50.443964, lng: 30.5573237 },
      { lat: 50.4432124, lng: 30.5560684 },
      { lat: 50.4426589, lng: 30.5567014 },
    ],
  },
  {
    name: 'ОБОЛОНЬ КОСА',
    price: 193,
    points: [
      { lat: 50.5209579, lng: 30.5323792 },
      { lat: 50.5255143, lng: 30.5353403 },
      { lat: 50.5342986, lng: 30.5336237 },
      { lat: 50.537299, lng: 30.5280876 },
      { lat: 50.5343804, lng: 30.5192471 },
      { lat: 50.5331802, lng: 30.491395 },
      { lat: 50.5287472, lng: 30.4842388 },
      { lat: 50.5292246, lng: 30.5198907 },
      { lat: 50.526742, lng: 30.5216503 },
      { lat: 50.5209033, lng: 30.5244827 },
      { lat: 50.5209579, lng: 30.5323792 },
    ],
  },
  {
    name: 'ОБОЛОНЬ ЖК П-К СТОУН',
    price: 194,
    points: [
      { lat: 50.4944017, lng: 30.5307484 },
      { lat: 50.4925862, lng: 30.5270576 },
      { lat: 50.4923951, lng: 30.5230236 },
      { lat: 50.4922722, lng: 30.5226374 },
      { lat: 50.4884225, lng: 30.5266285 },
      { lat: 50.4890369, lng: 30.5301905 },
      { lat: 50.4905386, lng: 30.534718 },
      { lat: 50.4934052, lng: 30.5317998 },
      { lat: 50.4944017, lng: 30.5307484 },
    ],
  },
  {
    name: 'ОБОЛОНЬ НАЧАЛО',
    price: 195,
    points: [
      { lat: 50.5020726, lng: 30.5219936 },
      { lat: 50.5057396, lng: 30.5152178 },
      { lat: 50.5042754, lng: 30.5112531 },
      { lat: 50.5057687, lng: 30.5097612 },
      { lat: 50.5057802, lng: 30.5082793 },
      { lat: 50.5013543, lng: 30.5038788 },
      { lat: 50.5014823, lng: 30.4981683 },
      { lat: 50.4999642, lng: 30.4980774 },
      { lat: 50.4999845, lng: 30.4883266 },
      { lat: 50.4891733, lng: 30.5013729 },
      { lat: 50.4887024, lng: 30.5131532 },
      { lat: 50.4882177, lng: 30.5234957 },
      { lat: 50.4884225, lng: 30.5266285 },
      { lat: 50.4922722, lng: 30.5226374 },
      { lat: 50.4923951, lng: 30.5230236 },
      { lat: 50.4925862, lng: 30.5270576 },
      { lat: 50.4944017, lng: 30.5307484 },
      { lat: 50.5020726, lng: 30.5219936 },
    ],
  },
  {
    name: 'ТРОЕЩИНА РЫНОК',
    price: 196,
    points: [
      { lat: 50.5002983, lng: 30.6297112 },
      { lat: 50.5051297, lng: 30.6317711 },
      { lat: 50.5082412, lng: 30.624733 },
      { lat: 50.5091691, lng: 30.6223727 },
      { lat: 50.497681, lng: 30.6101563 },
      { lat: 50.4967562, lng: 30.60807 },
      { lat: 50.4951798, lng: 30.6078458 },
      { lat: 50.4946474, lng: 30.6080389 },
      { lat: 50.4937738, lng: 30.6082964 },
      { lat: 50.4913166, lng: 30.6094337 },
      { lat: 50.4895692, lng: 30.6101203 },
      { lat: 50.4884566, lng: 30.610764 },
      { lat: 50.4867125, lng: 30.6117564 },
      { lat: 50.4876375, lng: 30.6133604 },
      { lat: 50.4890778, lng: 30.6172657 },
      { lat: 50.4902245, lng: 30.6245613 },
      { lat: 50.4914532, lng: 30.6244755 },
      { lat: 50.5002983, lng: 30.6297112 },
    ],
  },
  {
    name: 'ЦСКА',
    price: 197,
    points: [
      { lat: 50.4458634, lng: 30.4813528 },
      { lat: 50.4466013, lng: 30.4790354 },
      { lat: 50.4470112, lng: 30.4768896 },
      { lat: 50.4471752, lng: 30.4752159 },
      { lat: 50.4471205, lng: 30.4740572 },
      { lat: 50.4425292, lng: 30.4716969 },
      { lat: 50.4406706, lng: 30.476675 },
      { lat: 50.439632, lng: 30.4781771 },
      { lat: 50.4405612, lng: 30.4791855 },
      { lat: 50.4418731, lng: 30.4799901 },
      { lat: 50.4458634, lng: 30.4813528 },
    ],
  },
  {
    name: 'ЛЕСНИКИ',
    price: 198,
    points: [
      { lat: 50.3149418, lng: 30.5217361 },
      { lat: 50.3145581, lng: 30.5037975 },
      { lat: 50.3149418, lng: 30.4999352 },
      { lat: 50.3136264, lng: 30.4959011 },
      { lat: 50.3143937, lng: 30.4941845 },
      { lat: 50.3142841, lng: 30.4934978 },
      { lat: 50.3138456, lng: 30.4934978 },
      { lat: 50.3136812, lng: 30.4918671 },
      { lat: 50.3131331, lng: 30.4910088 },
      { lat: 50.312585, lng: 30.4908371 },
      { lat: 50.3123109, lng: 30.4914379 },
      { lat: 50.3109509, lng: 30.4911858 },
      { lat: 50.3096182, lng: 30.4916417 },
      { lat: 50.30981, lng: 30.4936803 },
      { lat: 50.3082547, lng: 30.4940128 },
      { lat: 50.3071035, lng: 30.4948712 },
      { lat: 50.3067815, lng: 30.4937124 },
      { lat: 50.3063497, lng: 30.4929185 },
      { lat: 50.3057947, lng: 30.4923177 },
      { lat: 50.3052397, lng: 30.4920387 },
      { lat: 50.3041433, lng: 30.4918671 },
      { lat: 50.3038143, lng: 30.4925537 },
      { lat: 50.3041981, lng: 30.4932404 },
      { lat: 50.3041981, lng: 30.4942703 },
      { lat: 50.3040336, lng: 30.4953003 },
      { lat: 50.3031016, lng: 30.4972744 },
      { lat: 50.2997231, lng: 30.5015982 },
      { lat: 50.2983319, lng: 30.5062115 },
      { lat: 50.2936164, lng: 30.5208778 },
      { lat: 50.2938906, lng: 30.5266285 },
      { lat: 50.2933971, lng: 30.5293751 },
      { lat: 50.2942744, lng: 30.530405 },
      { lat: 50.2940551, lng: 30.5313492 },
      { lat: 50.2964129, lng: 30.5335808 },
      { lat: 50.3016762, lng: 30.5338383 },
      { lat: 50.3019504, lng: 30.532465 },
      { lat: 50.312585, lng: 30.5339241 },
      { lat: 50.3129138, lng: 30.5225086 },
      { lat: 50.3149418, lng: 30.5217361 },
    ],
  },
  {
    name: 'БОРЩАГОВКА НИК Ч/С',
    price: 199,
    points: [
      { lat: 50.4465534, lng: 30.358175 },
      { lat: 50.4448385, lng: 30.3584325 },
      { lat: 50.435696, lng: 30.3598917 },
      { lat: 50.4363384, lng: 30.3642582 },
      { lat: 50.4370286, lng: 30.3681527 },
      { lat: 50.437746, lng: 30.3712964 },
      { lat: 50.4386071, lng: 30.374279 },
      { lat: 50.4394885, lng: 30.3774226 },
      { lat: 50.4442236, lng: 30.3741932 },
      { lat: 50.4449342, lng: 30.3738499 },
      { lat: 50.4449889, lng: 30.369215 },
      { lat: 50.4460685, lng: 30.3687644 },
      { lat: 50.446123, lng: 30.3596878 },
      { lat: 50.4506456, lng: 30.3588188 },
      { lat: 50.4506048, lng: 30.3576386 },
      { lat: 50.4496893, lng: 30.3577137 },
      { lat: 50.4465534, lng: 30.358175 },
    ],
  },
  {
    name: 'БЛОКБАСТЕР',
    price: 200,
    points: [
      { lat: 50.4895693, lng: 30.5355978 },
      { lat: 50.4905386, lng: 30.534718 },
      { lat: 50.4890369, lng: 30.5301905 },
      { lat: 50.4884225, lng: 30.5266285 },
      { lat: 50.4882177, lng: 30.5234957 },
      { lat: 50.4884635, lng: 30.5183245 },
      { lat: 50.4891067, lng: 30.5036553 },
      { lat: 50.4877966, lng: 30.506361 },
      { lat: 50.4841244, lng: 30.5121351 },
      { lat: 50.4832821, lng: 30.5130779 },
      { lat: 50.4832548, lng: 30.5180345 },
      { lat: 50.4831661, lng: 30.5272078 },
      { lat: 50.4836303, lng: 30.5312633 },
      { lat: 50.4836167, lng: 30.5384302 },
      { lat: 50.4895693, lng: 30.5355978 },
    ],
  },
  {
    name: 'БОРИСПОЛЬ ЛОЗОВКА',
    price: 201,
    points: [
      { lat: 50.401488, lng: 30.9520054 },
      { lat: 50.3976971, lng: 30.9519456 },
      { lat: 50.3960435, lng: 30.9649453 },
      { lat: 50.3954904, lng: 30.9681599 },
      { lat: 50.3945677, lng: 30.9711454 },
      { lat: 50.3977403, lng: 30.969944 },
      { lat: 50.399026, lng: 30.964365 },
      { lat: 50.4078064, lng: 30.9675407 },
      { lat: 50.4086816, lng: 30.9680986 },
      { lat: 50.4091466, lng: 30.9648371 },
      { lat: 50.4081073, lng: 30.9641933 },
      { lat: 50.4082714, lng: 30.9622139 },
      { lat: 50.4110679, lng: 30.9609425 },
      { lat: 50.4121276, lng: 30.9542799 },
      { lat: 50.4104047, lng: 30.9485722 },
      { lat: 50.4026368, lng: 30.9470272 },
      { lat: 50.401488, lng: 30.9520054 },
    ],
  },
  {
    name: 'БОРИСПОЛЬ НЕСТЕР.',
    price: 202,
    points: [
      { lat: 50.3899704, lng: 30.9462976 },
      { lat: 50.3871795, lng: 30.9484863 },
      { lat: 50.3834588, lng: 30.9648797 },
      { lat: 50.3839921, lng: 30.9655877 },
      { lat: 50.388958, lng: 30.9759522 },
      { lat: 50.3906954, lng: 30.9753513 },
      { lat: 50.3923918, lng: 30.9742355 },
      { lat: 50.3935272, lng: 30.9728622 },
      { lat: 50.3945677, lng: 30.9711454 },
      { lat: 50.3954904, lng: 30.9681599 },
      { lat: 50.3960435, lng: 30.9649453 },
      { lat: 50.3976971, lng: 30.9519456 },
      { lat: 50.3973436, lng: 30.9518123 },
      { lat: 50.3967144, lng: 30.9529924 },
      { lat: 50.3912016, lng: 30.9504175 },
      { lat: 50.3899704, lng: 30.9462976 },
    ],
  },
  {
    name: 'ГЛЕВАХА',
    price: 202,
    points: [
      { lat: 50.2859392, lng: 30.3169441 },
      { lat: 50.2870772, lng: 30.3064084 },
      { lat: 50.2684962, lng: 30.3148842 },
      { lat: 50.2646008, lng: 30.3153992 },
      { lat: 50.2607051, lng: 30.3134251 },
      { lat: 50.2586887, lng: 30.3087469 },
      { lat: 50.2615061, lng: 30.3037419 },
      { lat: 50.259961, lng: 30.3007968 },
      { lat: 50.2572551, lng: 30.3055497 },
      { lat: 50.2544494, lng: 30.2991772 },
      { lat: 50.2479732, lng: 30.2822678 },
      { lat: 50.2438018, lng: 30.2868175 },
      { lat: 50.2491808, lng: 30.3034687 },
      { lat: 50.2502785, lng: 30.3071594 },
      { lat: 50.2492906, lng: 30.3117085 },
      { lat: 50.2532421, lng: 30.3160858 },
      { lat: 50.2508822, lng: 30.3226089 },
      { lat: 50.2497296, lng: 30.3287888 },
      { lat: 50.2817161, lng: 30.3471565 },
      { lat: 50.2820452, lng: 30.3459549 },
      { lat: 50.2819355, lng: 30.3439808 },
      { lat: 50.2838003, lng: 30.3262138 },
      { lat: 50.2859392, lng: 30.3169441 },
    ],
  },
  {
    name: 'СЧАСТЛИВОЕ',
    price: 203,
    points: [
      { lat: 50.3788057, lng: 30.8128738 },
      { lat: 50.3797909, lng: 30.8063292 },
      { lat: 50.3813235, lng: 30.806694 },
      { lat: 50.3818708, lng: 30.80163 },
      { lat: 50.3842242, lng: 30.8019733 },
      { lat: 50.3853735, lng: 30.7939053 },
      { lat: 50.3874531, lng: 30.7849789 },
      { lat: 50.38707, lng: 30.7833481 },
      { lat: 50.3847715, lng: 30.7806873 },
      { lat: 50.3829654, lng: 30.7779408 },
      { lat: 50.3823634, lng: 30.7731342 },
      { lat: 50.3807762, lng: 30.7724476 },
      { lat: 50.3800646, lng: 30.7738209 },
      { lat: 50.3784773, lng: 30.7759666 },
      { lat: 50.3776563, lng: 30.7743359 },
      { lat: 50.3747552, lng: 30.7739925 },
      { lat: 50.3734962, lng: 30.775162 },
      { lat: 50.3732532, lng: 30.7776994 },
      { lat: 50.3707727, lng: 30.7775116 },
      { lat: 50.3703484, lng: 30.7803869 },
      { lat: 50.361123, lng: 30.7766319 },
      { lat: 50.356893, lng: 30.7828653 },
      { lat: 50.3568177, lng: 30.7840884 },
      { lat: 50.3576186, lng: 30.7842064 },
      { lat: 50.3573448, lng: 30.7888412 },
      { lat: 50.3584947, lng: 30.7896996 },
      { lat: 50.3583304, lng: 30.7913303 },
      { lat: 50.3635322, lng: 30.7921886 },
      { lat: 50.3637512, lng: 30.790987 },
      { lat: 50.3659413, lng: 30.7916737 },
      { lat: 50.3669268, lng: 30.7942486 },
      { lat: 50.3663793, lng: 30.7997417 },
      { lat: 50.36731, lng: 30.8002567 },
      { lat: 50.367091, lng: 30.80266 },
      { lat: 50.3719634, lng: 30.8040333 },
      { lat: 50.371197, lng: 30.8107281 },
      { lat: 50.3788057, lng: 30.8128738 },
    ],
  },
  {
    name: 'ГОРА',
    price: 205,
    points: [
      { lat: 50.3601237, lng: 30.8552313 },
      { lat: 50.3640797, lng: 30.8562183 },
      { lat: 50.3639702, lng: 30.857935 },
      { lat: 50.3633132, lng: 30.8598232 },
      { lat: 50.3630394, lng: 30.8610249 },
      { lat: 50.3627657, lng: 30.8641148 },
      { lat: 50.3626562, lng: 30.868063 },
      { lat: 50.3674195, lng: 30.8691788 },
      { lat: 50.366653, lng: 30.8759594 },
      { lat: 50.366434, lng: 30.8786201 },
      { lat: 50.3725655, lng: 30.8812809 },
      { lat: 50.3732772, lng: 30.8783627 },
      { lat: 50.3736193, lng: 30.8775312 },
      { lat: 50.3742078, lng: 30.8772791 },
      { lat: 50.3750289, lng: 30.8774185 },
      { lat: 50.3773279, lng: 30.8653164 },
      { lat: 50.3760279, lng: 30.8624625 },
      { lat: 50.3761099, lng: 30.8619261 },
      { lat: 50.3779847, lng: 30.8606815 },
      { lat: 50.3786963, lng: 30.8580208 },
      { lat: 50.380393, lng: 30.8408546 },
      { lat: 50.3773826, lng: 30.839653 },
      { lat: 50.3774373, lng: 30.8387947 },
      { lat: 50.3771637, lng: 30.836134 },
      { lat: 50.3767805, lng: 30.8352757 },
      { lat: 50.3727298, lng: 30.8337307 },
      { lat: 50.3730035, lng: 30.8307266 },
      { lat: 50.3687334, lng: 30.8303833 },
      { lat: 50.3683502, lng: 30.8370781 },
      { lat: 50.3656128, lng: 30.836134 },
      { lat: 50.3637512, lng: 30.8370781 },
      { lat: 50.3623824, lng: 30.8428288 },
      { lat: 50.3612531, lng: 30.8434296 },
      { lat: 50.3601237, lng: 30.8552313 },
    ],
  },
  {
    name: 'ЧУБИНСКОЕ',
    price: 206,
    points: [
      { lat: 50.3860849, lng: 30.8650589 },
      { lat: 50.3867416, lng: 30.8627415 },
      { lat: 50.3872889, lng: 30.862484 },
      { lat: 50.3885476, lng: 30.8634281 },
      { lat: 50.3892042, lng: 30.861969 },
      { lat: 50.3926654, lng: 30.8490515 },
      { lat: 50.3931441, lng: 30.8493519 },
      { lat: 50.3939102, lng: 30.8466911 },
      { lat: 50.3899156, lng: 30.8446312 },
      { lat: 50.3901345, lng: 30.8436871 },
      { lat: 50.3881645, lng: 30.8426571 },
      { lat: 50.3873984, lng: 30.8421421 },
      { lat: 50.3880003, lng: 30.8383656 },
      { lat: 50.3866595, lng: 30.8377862 },
      { lat: 50.3879456, lng: 30.8314133 },
      { lat: 50.3839505, lng: 30.8302975 },
      { lat: 50.3836769, lng: 30.8320141 },
      { lat: 50.3822539, lng: 30.8317566 },
      { lat: 50.3819255, lng: 30.8341599 },
      { lat: 50.3813782, lng: 30.8343315 },
      { lat: 50.3788605, lng: 30.8607674 },
      { lat: 50.3860849, lng: 30.8650589 },
    ],
  },
  {
    name: 'ГРИНЧЕНКО БЦ',
    price: 207,
    points: [
      { lat: 50.4262749, lng: 30.5039531 },
      { lat: 50.4241884, lng: 30.5058764 },
      { lat: 50.422676, lng: 30.5081106 },
      { lat: 50.417597, lng: 30.5133247 },
      { lat: 50.4179798, lng: 30.5149984 },
      { lat: 50.4210697, lng: 30.5123806 },
      { lat: 50.4233118, lng: 30.5106961 },
      { lat: 50.4263432, lng: 30.5072845 },
      { lat: 50.4270369, lng: 30.5065602 },
      { lat: 50.4287251, lng: 30.5056429 },
      { lat: 50.4309122, lng: 30.503583 },
      { lat: 50.4324704, lng: 30.5018663 },
      { lat: 50.4343019, lng: 30.500021 },
      { lat: 50.4335092, lng: 30.4986477 },
      { lat: 50.4343566, lng: 30.4971886 },
      { lat: 50.4334272, lng: 30.495928 },
      { lat: 50.4329898, lng: 30.4963732 },
      { lat: 50.432443, lng: 30.4976606 },
      { lat: 50.4301194, lng: 30.4997635 },
      { lat: 50.4295453, lng: 30.4979181 },
      { lat: 50.4294633, lng: 30.4968452 },
      { lat: 50.4294633, lng: 30.4963732 },
      { lat: 50.4290532, lng: 30.4958582 },
      { lat: 50.4286158, lng: 30.4965448 },
      { lat: 50.4285338, lng: 30.4978323 },
      { lat: 50.4286978, lng: 30.4991627 },
      { lat: 50.4290532, lng: 30.500536 },
      { lat: 50.4269481, lng: 30.5021238 },
      { lat: 50.4266747, lng: 30.5034542 },
      { lat: 50.4264936, lng: 30.5035508 },
      { lat: 50.4260185, lng: 30.5008364 },
      { lat: 50.4264286, lng: 30.5001068 },
      { lat: 50.4253009, lng: 30.4982508 },
      { lat: 50.424795, lng: 30.4992807 },
      { lat: 50.4253145, lng: 30.500021 },
      { lat: 50.4256562, lng: 30.5006755 },
      { lat: 50.4259673, lng: 30.502435 },
      { lat: 50.4262749, lng: 30.5039531 },
    ],
  },
  {
    name: 'ИРПЕНЬ 2',
    price: 208,
    points: [
      { lat: 50.5397129, lng: 30.243237 },
      { lat: 50.5395083, lng: 30.2419281 },
      { lat: 50.5376248, lng: 30.2429594 },
      { lat: 50.5361139, lng: 30.2435498 },
      { lat: 50.5241755, lng: 30.2467364 },
      { lat: 50.5161404, lng: 30.2490105 },
      { lat: 50.5149609, lng: 30.2496108 },
      { lat: 50.5139754, lng: 30.2505117 },
      { lat: 50.5126367, lng: 30.2528021 },
      { lat: 50.5119119, lng: 30.2551138 },
      { lat: 50.5110386, lng: 30.2580428 },
      { lat: 50.5116527, lng: 30.2583218 },
      { lat: 50.5125874, lng: 30.2581501 },
      { lat: 50.5150912, lng: 30.2569914 },
      { lat: 50.5172469, lng: 30.2584505 },
      { lat: 50.5180928, lng: 30.2584076 },
      { lat: 50.524423, lng: 30.2608967 },
      { lat: 50.5255689, lng: 30.2636862 },
      { lat: 50.5278606, lng: 30.2681065 },
      { lat: 50.5316116, lng: 30.2742005 },
      { lat: 50.533262, lng: 30.2765393 },
      { lat: 50.5359216, lng: 30.2809596 },
      { lat: 50.5385264, lng: 30.2823973 },
      { lat: 50.5369853, lng: 30.2853799 },
      { lat: 50.5374081, lng: 30.2862167 },
      { lat: 50.5380764, lng: 30.2859807 },
      { lat: 50.5413357, lng: 30.2847362 },
      { lat: 50.5432994, lng: 30.2845645 },
      { lat: 50.5446357, lng: 30.2830625 },
      { lat: 50.5454402, lng: 30.2793932 },
      { lat: 50.5457402, lng: 30.2749085 },
      { lat: 50.5447584, lng: 30.2723551 },
      { lat: 50.5447311, lng: 30.2714109 },
      { lat: 50.5455629, lng: 30.2673554 },
      { lat: 50.546381, lng: 30.2630854 },
      { lat: 50.5445402, lng: 30.2591801 },
      { lat: 50.5420993, lng: 30.256176 },
      { lat: 50.541063, lng: 30.2542233 },
      { lat: 50.5404902, lng: 30.2514768 },
      { lat: 50.5404493, lng: 30.2494597 },
      { lat: 50.5394947, lng: 30.243988 },
      { lat: 50.5397129, lng: 30.243237 },
    ],
  },
  {
    name: 'БУЧА 2',
    price: 209,
    points: [
      { lat: 50.5200575, lng: 30.1889277 },
      { lat: 50.5240683, lng: 30.1984978 },
      { lat: 50.5269876, lng: 30.2055788 },
      { lat: 50.5339712, lng: 30.2225304 },
      { lat: 50.5388265, lng: 30.2309847 },
      { lat: 50.5419129, lng: 30.2404236 },
      { lat: 50.543821, lng: 30.2398699 },
      { lat: 50.5481017, lng: 30.2395736 },
      { lat: 50.5496817, lng: 30.2387233 },
      { lat: 50.5517512, lng: 30.2359926 },
      { lat: 50.5525633, lng: 30.2335349 },
      { lat: 50.5529169, lng: 30.2311192 },
      { lat: 50.5527797, lng: 30.2288751 },
      { lat: 50.5521779, lng: 30.2264469 },
      { lat: 50.5511924, lng: 30.2241656 },
      { lat: 50.5417521, lng: 30.2109849 },
      { lat: 50.5370468, lng: 30.2081654 },
      { lat: 50.5357781, lng: 30.2062903 },
      { lat: 50.535041, lng: 30.2043428 },
      { lat: 50.5327482, lng: 30.1960285 },
      { lat: 50.5325153, lng: 30.1945816 },
      { lat: 50.532446, lng: 30.1928343 },
      { lat: 50.5326075, lng: 30.191142 },
      { lat: 50.533094, lng: 30.1889592 },
      { lat: 50.5351033, lng: 30.1818895 },
      { lat: 50.5267347, lng: 30.1858812 },
      { lat: 50.5200575, lng: 30.1889277 },
    ],
  },
  {
    name: 'ВОРЗЕЛЬ',
    price: 210,
    points: [
      { lat: 50.5260327, lng: 30.1837349 },
      { lat: 50.5267347, lng: 30.1858812 },
      { lat: 50.5351033, lng: 30.1818895 },
      { lat: 50.536474, lng: 30.1815146 },
      { lat: 50.5378446, lng: 30.1816547 },
      { lat: 50.5429448, lng: 30.1844215 },
      { lat: 50.5507442, lng: 30.1597881 },
      { lat: 50.5525438, lng: 30.1600456 },
      { lat: 50.5539481, lng: 30.1610541 },
      { lat: 50.5543434, lng: 30.1591015 },
      { lat: 50.5530892, lng: 30.1584148 },
      { lat: 50.5534709, lng: 30.1520634 },
      { lat: 50.5518349, lng: 30.1508617 },
      { lat: 50.5521621, lng: 30.1443386 },
      { lat: 50.5535254, lng: 30.138588 },
      { lat: 50.5523802, lng: 30.136528 },
      { lat: 50.5493807, lng: 30.132494 },
      { lat: 50.5559794, lng: 30.1079464 },
      { lat: 50.5459447, lng: 30.0973034 },
      { lat: 50.5424539, lng: 30.1082039 },
      { lat: 50.5432175, lng: 30.1094055 },
      { lat: 50.5414175, lng: 30.1158428 },
      { lat: 50.5375445, lng: 30.1134396 },
      { lat: 50.5326346, lng: 30.1302624 },
      { lat: 50.5338894, lng: 30.1318932 },
      { lat: 50.5358534, lng: 30.1353264 },
      { lat: 50.5354169, lng: 30.1371288 },
      { lat: 50.5359625, lng: 30.1379013 },
      { lat: 50.5332893, lng: 30.1430511 },
      { lat: 50.5366171, lng: 30.1488876 },
      { lat: 50.5324164, lng: 30.1566982 },
      { lat: 50.5326346, lng: 30.1591873 },
      { lat: 50.5309433, lng: 30.1616764 },
      { lat: 50.5277788, lng: 30.1664829 },
      { lat: 50.5232498, lng: 30.1706028 },
      { lat: 50.5260327, lng: 30.1837349 },
    ],
  },
  {
    name: 'ПРОЛИСКИ',
    price: 211,
    points: [
      { lat: 50.3870974, lng: 30.7878113 },
      { lat: 50.3894779, lng: 30.7889271 },
      { lat: 50.3901893, lng: 30.790236 },
      { lat: 50.3901893, lng: 30.7962656 },
      { lat: 50.3887117, lng: 30.7970381 },
      { lat: 50.3875899, lng: 30.8053637 },
      { lat: 50.3901893, lng: 30.8054924 },
      { lat: 50.3906817, lng: 30.8065224 },
      { lat: 50.3908185, lng: 30.8084106 },
      { lat: 50.3920498, lng: 30.8083677 },
      { lat: 50.3927885, lng: 30.8064795 },
      { lat: 50.3930347, lng: 30.8025741 },
      { lat: 50.3938555, lng: 30.7978535 },
      { lat: 50.3942932, lng: 30.7977247 },
      { lat: 50.3970563, lng: 30.7916737 },
      { lat: 50.3978497, lng: 30.7909441 },
      { lat: 50.3967828, lng: 30.7857513 },
      { lat: 50.3969675, lng: 30.7847428 },
      { lat: 50.3950045, lng: 30.7754517 },
      { lat: 50.3941632, lng: 30.7751191 },
      { lat: 50.3908733, lng: 30.7753658 },
      { lat: 50.3897241, lng: 30.775795 },
      { lat: 50.3870974, lng: 30.7878113 },
    ],
  },
  {
    name: 'ХОДОСОВКА',
    price: 212,
    points: [
      { lat: 50.2706358, lng: 30.5007076 },
      { lat: 50.2652592, lng: 30.5031967 },
      { lat: 50.2645459, lng: 30.504055 },
      { lat: 50.2619123, lng: 30.5015659 },
      { lat: 50.2585651, lng: 30.5066299 },
      { lat: 50.2566445, lng: 30.5110073 },
      { lat: 50.2576322, lng: 30.5168438 },
      { lat: 50.2608697, lng: 30.5192471 },
      { lat: 50.2638327, lng: 30.5166721 },
      { lat: 50.264107, lng: 30.5176163 },
      { lat: 50.2639698, lng: 30.5204916 },
      { lat: 50.26493, lng: 30.5228519 },
      { lat: 50.2653141, lng: 30.5250835 },
      { lat: 50.2660273, lng: 30.5254269 },
      { lat: 50.2664662, lng: 30.5262852 },
      { lat: 50.2678378, lng: 30.5261993 },
      { lat: 50.268167, lng: 30.5292034 },
      { lat: 50.2678378, lng: 30.5354691 },
      { lat: 50.269374, lng: 30.5364132 },
      { lat: 50.2739272, lng: 30.5343533 },
      { lat: 50.2759294, lng: 30.5327118 },
      { lat: 50.2819904, lng: 30.5311775 },
      { lat: 50.2868715, lng: 30.5310059 },
      { lat: 50.2882425, lng: 30.521307 },
      { lat: 50.2819355, lng: 30.5166721 },
      { lat: 50.2845132, lng: 30.5074024 },
      { lat: 50.2853907, lng: 30.5019951 },
      { lat: 50.2867207, lng: 30.5021024 },
      { lat: 50.2869811, lng: 30.4942274 },
      { lat: 50.2848972, lng: 30.4925537 },
      { lat: 50.2801255, lng: 30.4935837 },
      { lat: 50.2808386, lng: 30.5043125 },
      { lat: 50.2730495, lng: 30.5063725 },
      { lat: 50.2726655, lng: 30.5023384 },
      { lat: 50.2706358, lng: 30.5007076 },
    ],
  },
  {
    name: 'ТЕРЕМКИ ОКРУЖНАЯ',
    price: 213,
    points: [
      { lat: 50.3860302, lng: 30.4200483 },
      { lat: 50.3794492, lng: 30.428998 },
      { lat: 50.3715803, lng: 30.4476423 },
      { lat: 50.3734414, lng: 30.4522991 },
      { lat: 50.3740709, lng: 30.4535007 },
      { lat: 50.3821718, lng: 30.4422998 },
      { lat: 50.3920772, lng: 30.4289972 },
      { lat: 50.3905656, lng: 30.4258745 },
      { lat: 50.3860302, lng: 30.4200483 },
    ],
  },
  {
    name: 'ТАРАСОВКА',
    price: 214,
    points: [
      { lat: 50.333217, lng: 30.3009796 },
      { lat: 50.3304227, lng: 30.3181028 },
      { lat: 50.3310254, lng: 30.3186607 },
      { lat: 50.329957, lng: 30.3278017 },
      { lat: 50.3312172, lng: 30.3293466 },
      { lat: 50.3316007, lng: 30.3375006 },
      { lat: 50.3326417, lng: 30.3384876 },
      { lat: 50.3305049, lng: 30.3415775 },
      { lat: 50.3308884, lng: 30.3451395 },
      { lat: 50.3345045, lng: 30.3510618 },
      { lat: 50.342393, lng: 30.3530788 },
      { lat: 50.3441184, lng: 30.3400326 },
      { lat: 50.3442554, lng: 30.3366423 },
      { lat: 50.3479252, lng: 30.3373289 },
      { lat: 50.3493492, lng: 30.3310633 },
      { lat: 50.350335, lng: 30.3266859 },
      { lat: 50.3497325, lng: 30.3254414 },
      { lat: 50.3523066, lng: 30.3107643 },
      { lat: 50.3482812, lng: 30.3062153 },
      { lat: 50.3434885, lng: 30.3029108 },
      { lat: 50.340777, lng: 30.3015804 },
      { lat: 50.333217, lng: 30.3009796 },
    ],
  },
  {
    name: 'ВЕЛ. АЛЕКСАНДРОВКА',
    price: 215,
    points: [
      { lat: 50.3937187, lng: 30.8501244 },
      { lat: 50.393363, lng: 30.8511972 },
      { lat: 50.3844705, lng: 30.8820105 },
      { lat: 50.3975488, lng: 30.8884478 },
      { lat: 50.4018436, lng: 30.8732128 },
      { lat: 50.3991628, lng: 30.871067 },
      { lat: 50.4039498, lng: 30.8576345 },
      { lat: 50.3959347, lng: 30.8491373 },
      { lat: 50.3937187, lng: 30.8501244 },
    ],
  },
  {
    name: 'ГОРЕНИЧИ',
    price: 216,
    points: [
      { lat: 50.4232297, lng: 30.1769543 },
      { lat: 50.4222454, lng: 30.1803017 },
      { lat: 50.4209877, lng: 30.1920605 },
      { lat: 50.4168313, lng: 30.190258 },
      { lat: 50.4171595, lng: 30.1859665 },
      { lat: 50.4146436, lng: 30.1837349 },
      { lat: 50.4132216, lng: 30.1890564 },
      { lat: 50.4096115, lng: 30.1888847 },
      { lat: 50.4088047, lng: 30.1953864 },
      { lat: 50.4025001, lng: 30.195837 },
      { lat: 50.4024454, lng: 30.2044201 },
      { lat: 50.4033754, lng: 30.2043343 },
      { lat: 50.4048524, lng: 30.20854 },
      { lat: 50.409885, lng: 30.2079391 },
      { lat: 50.4155187, lng: 30.2123165 },
      { lat: 50.4169407, lng: 30.2133465 },
      { lat: 50.4185267, lng: 30.2160931 },
      { lat: 50.4190189, lng: 30.2190971 },
      { lat: 50.4247609, lng: 30.222187 },
      { lat: 50.4285338, lng: 30.2269936 },
      { lat: 50.429682, lng: 30.2245903 },
      { lat: 50.4367347, lng: 30.2287102 },
      { lat: 50.4395773, lng: 30.2178097 },
      { lat: 50.4372267, lng: 30.2052784 },
      { lat: 50.4349306, lng: 30.2033901 },
      { lat: 50.436188, lng: 30.1966953 },
      { lat: 50.4348213, lng: 30.1932621 },
      { lat: 50.4325251, lng: 30.1942921 },
      { lat: 50.4302834, lng: 30.1966095 },
      { lat: 50.4288071, lng: 30.1915455 },
      { lat: 50.4291899, lng: 30.1896572 },
      { lat: 50.431869, lng: 30.1869106 },
      { lat: 50.4321423, lng: 30.1774693 },
      { lat: 50.4232297, lng: 30.1769543 },
    ],
  },
  {
    name: 'ПУХОВКА',
    price: 217,
    points: [
      { lat: 50.6251275, lng: 30.7219791 },
      { lat: 50.6231673, lng: 30.7192326 },
      { lat: 50.6212614, lng: 30.7187176 },
      { lat: 50.6187564, lng: 30.7181168 },
      { lat: 50.6184841, lng: 30.7153702 },
      { lat: 50.6160334, lng: 30.7156277 },
      { lat: 50.6147944, lng: 30.7155204 },
      { lat: 50.6123844, lng: 30.7153702 },
      { lat: 50.6105326, lng: 30.7146835 },
      { lat: 50.609525, lng: 30.713911 },
      { lat: 50.6076457, lng: 30.708847 },
      { lat: 50.6066108, lng: 30.7027531 },
      { lat: 50.6070466, lng: 30.7010365 },
      { lat: 50.6055758, lng: 30.6923676 },
      { lat: 50.6030155, lng: 30.6939125 },
      { lat: 50.6043229, lng: 30.7012939 },
      { lat: 50.6032879, lng: 30.7018089 },
      { lat: 50.6034513, lng: 30.7028389 },
      { lat: 50.5967504, lng: 30.7032681 },
      { lat: 50.5960421, lng: 30.6990623 },
      { lat: 50.5929364, lng: 30.6908226 },
      { lat: 50.5881957, lng: 30.6950283 },
      { lat: 50.5855255, lng: 30.6978607 },
      { lat: 50.5837271, lng: 30.7028389 },
      { lat: 50.5813291, lng: 30.7085896 },
      { lat: 50.5810566, lng: 30.7122803 },
      { lat: 50.5834955, lng: 30.7165718 },
      { lat: 50.5824736, lng: 30.7180739 },
      { lat: 50.585362, lng: 30.7206059 },
      { lat: 50.5872694, lng: 30.7204342 },
      { lat: 50.5890131, lng: 30.7190609 },
      { lat: 50.5903754, lng: 30.7200909 },
      { lat: 50.5902664, lng: 30.7237816 },
      { lat: 50.5891221, lng: 30.7291889 },
      { lat: 50.5892311, lng: 30.7303047 },
      { lat: 50.589776, lng: 30.7308197 },
      { lat: 50.5898305, lng: 30.7328796 },
      { lat: 50.5891221, lng: 30.7384586 },
      { lat: 50.5920101, lng: 30.7397461 },
      { lat: 50.5925005, lng: 30.7351971 },
      { lat: 50.5930999, lng: 30.7352829 },
      { lat: 50.5937537, lng: 30.7366562 },
      { lat: 50.5941351, lng: 30.736227 },
      { lat: 50.5965324, lng: 30.7358837 },
      { lat: 50.5970773, lng: 30.7352829 },
      { lat: 50.5952793, lng: 30.7318497 },
      { lat: 50.594353, lng: 30.7274723 },
      { lat: 50.5933723, lng: 30.7247257 },
      { lat: 50.5928819, lng: 30.715971 },
      { lat: 50.5947889, lng: 30.7147694 },
      { lat: 50.5954972, lng: 30.7133102 },
      { lat: 50.5984938, lng: 30.7148552 },
      { lat: 50.5982214, lng: 30.7176018 },
      { lat: 50.5996379, lng: 30.7234383 },
      { lat: 50.6026342, lng: 30.7273865 },
      { lat: 50.6066653, lng: 30.7242107 },
      { lat: 50.610587, lng: 30.728159 },
      { lat: 50.611513, lng: 30.7253265 },
      { lat: 50.6155978, lng: 30.7310772 },
      { lat: 50.6167414, lng: 30.7295322 },
      { lat: 50.6183207, lng: 30.7304764 },
      { lat: 50.6224594, lng: 30.7307339 },
      { lat: 50.6230039, lng: 30.7291031 },
      { lat: 50.6250049, lng: 30.7264586 },
      { lat: 50.6276049, lng: 30.726303 },
      { lat: 50.6276594, lng: 30.7241035 },
      { lat: 50.6251275, lng: 30.7219791 },
    ],
  },
  {
    name: 'РОЖЕВКА',
    price: 218,
    points: [
      { lat: 50.6026342, lng: 30.7466125 },
      { lat: 50.5997468, lng: 30.7510757 },
      { lat: 50.5968593, lng: 30.7588863 },
      { lat: 50.5919556, lng: 30.7685852 },
      { lat: 50.5893401, lng: 30.7756233 },
      { lat: 50.596151, lng: 30.7838631 },
      { lat: 50.5983304, lng: 30.7818031 },
      { lat: 50.5987662, lng: 30.7794857 },
      { lat: 50.601463, lng: 30.7759237 },
      { lat: 50.6029611, lng: 30.7704091 },
      { lat: 50.6041595, lng: 30.7703876 },
      { lat: 50.6048132, lng: 30.7665253 },
      { lat: 50.6057937, lng: 30.7653236 },
      { lat: 50.6068287, lng: 30.7649588 },
      { lat: 50.6080407, lng: 30.7617831 },
      { lat: 50.6073734, lng: 30.7603455 },
      { lat: 50.6079726, lng: 30.7588005 },
      { lat: 50.6077002, lng: 30.7581139 },
      { lat: 50.6084628, lng: 30.7566119 },
      { lat: 50.6077547, lng: 30.7554531 },
      { lat: 50.608136, lng: 30.7547665 },
      { lat: 50.6026342, lng: 30.7466125 },
    ],
  },
  {
    name: 'РОЖНЫ',
    price: 219,
    points: [
      { lat: 50.672149, lng: 30.7442951 },
      { lat: 50.6710067, lng: 30.7439518 },
      { lat: 50.6707347, lng: 30.7429218 },
      { lat: 50.6697011, lng: 30.7427502 },
      { lat: 50.6700819, lng: 30.7399178 },
      { lat: 50.6756846, lng: 30.7388878 },
      { lat: 50.6816673, lng: 30.7389736 },
      { lat: 50.6848487, lng: 30.7403469 },
      { lat: 50.685746, lng: 30.7348537 },
      { lat: 50.6857324, lng: 30.732944 },
      { lat: 50.6848759, lng: 30.7304764 },
      { lat: 50.6854741, lng: 30.728159 },
      { lat: 50.6860179, lng: 30.7213783 },
      { lat: 50.6852566, lng: 30.7193184 },
      { lat: 50.6841689, lng: 30.7188034 },
      { lat: 50.6807971, lng: 30.7184601 },
      { lat: 50.6806884, lng: 30.7166147 },
      { lat: 50.6753039, lng: 30.7171727 },
      { lat: 50.6755758, lng: 30.7223225 },
      { lat: 50.6770444, lng: 30.7226658 },
      { lat: 50.6785129, lng: 30.7232666 },
      { lat: 50.6788392, lng: 30.7244682 },
      { lat: 50.6780234, lng: 30.7262707 },
      { lat: 50.6778058, lng: 30.7288456 },
      { lat: 50.6770988, lng: 30.7298756 },
      { lat: 50.6746512, lng: 30.7302189 },
      { lat: 50.6667091, lng: 30.7305622 },
      { lat: 50.6649138, lng: 30.7287598 },
      { lat: 50.6655123, lng: 30.7237816 },
      { lat: 50.6629008, lng: 30.72155 },
      { lat: 50.6614862, lng: 30.7208633 },
      { lat: 50.6601804, lng: 30.7195759 },
      { lat: 50.6601804, lng: 30.7186317 },
      { lat: 50.6594187, lng: 30.7176876 },
      { lat: 50.6589834, lng: 30.717001 },
      { lat: 50.6577863, lng: 30.7182026 },
      { lat: 50.6561539, lng: 30.7185459 },
      { lat: 50.6558274, lng: 30.7199192 },
      { lat: 50.6537596, lng: 30.7206059 },
      { lat: 50.6516917, lng: 30.7194901 },
      { lat: 50.6500047, lng: 30.7173443 },
      { lat: 50.6493516, lng: 30.7163143 },
      { lat: 50.6493516, lng: 30.7147694 },
      { lat: 50.6449977, lng: 30.7145977 },
      { lat: 50.6445078, lng: 30.7188892 },
      { lat: 50.6453786, lng: 30.7194042 },
      { lat: 50.6452154, lng: 30.7212067 },
      { lat: 50.6452698, lng: 30.7254124 },
      { lat: 50.6460862, lng: 30.7309055 },
      { lat: 50.6472835, lng: 30.7346821 },
      { lat: 50.6491339, lng: 30.7352829 },
      { lat: 50.6496237, lng: 30.7363129 },
      { lat: 50.6546847, lng: 30.7395744 },
      { lat: 50.6562628, lng: 30.7401752 },
      { lat: 50.6600172, lng: 30.7419777 },
      { lat: 50.6619759, lng: 30.741291 },
      { lat: 50.6624112, lng: 30.7457542 },
      { lat: 50.6640977, lng: 30.7478142 },
      { lat: 50.6638801, lng: 30.7503033 },
      { lat: 50.6671987, lng: 30.7520199 },
      { lat: 50.6682868, lng: 30.7511616 },
      { lat: 50.6682868, lng: 30.7500458 },
      { lat: 50.6722034, lng: 30.7492733 },
      { lat: 50.672149, lng: 30.7442951 },
    ],
  },
  {
    name: 'ЛЕТКИ',
    price: 220,
    points: [
      { lat: 50.6952072, lng: 30.7627487 },
      { lat: 50.6985236, lng: 30.765152 },
      { lat: 50.7015679, lng: 30.7672119 },
      { lat: 50.7020572, lng: 30.7695293 },
      { lat: 50.7014048, lng: 30.7700443 },
      { lat: 50.7030357, lng: 30.7749367 },
      { lat: 50.7050469, lng: 30.7732201 },
      { lat: 50.7041228, lng: 30.7691002 },
      { lat: 50.7039598, lng: 30.7644653 },
      { lat: 50.7034705, lng: 30.7638645 },
      { lat: 50.7034162, lng: 30.7622337 },
      { lat: 50.703851, lng: 30.7611179 },
      { lat: 50.7045034, lng: 30.7594013 },
      { lat: 50.7053731, lng: 30.7578564 },
      { lat: 50.7053731, lng: 30.7560539 },
      { lat: 50.7071668, lng: 30.7557964 },
      { lat: 50.7070581, lng: 30.758028 },
      { lat: 50.7063515, lng: 30.7664394 },
      { lat: 50.7068407, lng: 30.7666111 },
      { lat: 50.7077104, lng: 30.774765 },
      { lat: 50.7116237, lng: 30.7728767 },
      { lat: 50.7121672, lng: 30.7720184 },
      { lat: 50.7120585, lng: 30.7696152 },
      { lat: 50.7111889, lng: 30.7677269 },
      { lat: 50.7114607, lng: 30.7663536 },
      { lat: 50.7119498, lng: 30.7654095 },
      { lat: 50.7126564, lng: 30.7654953 },
      { lat: 50.7132542, lng: 30.7648945 },
      { lat: 50.7139607, lng: 30.760603 },
      { lat: 50.7136347, lng: 30.758543 },
      { lat: 50.7140151, lng: 30.7581997 },
      { lat: 50.7155368, lng: 30.7620621 },
      { lat: 50.7162976, lng: 30.7654953 },
      { lat: 50.7172215, lng: 30.769186 },
      { lat: 50.7191778, lng: 30.7682419 },
      { lat: 50.7187974, lng: 30.765667 },
      { lat: 50.7187974, lng: 30.7634354 },
      { lat: 50.7193408, lng: 30.7630062 },
      { lat: 50.7178736, lng: 30.7596588 },
      { lat: 50.7181453, lng: 30.7576847 },
      { lat: 50.7198299, lng: 30.7560539 },
      { lat: 50.7197755, lng: 30.7533932 },
      { lat: 50.7193952, lng: 30.7511616 },
      { lat: 50.7179279, lng: 30.7495308 },
      { lat: 50.7153738, lng: 30.7487583 },
      { lat: 50.7149933, lng: 30.7479858 },
      { lat: 50.7148303, lng: 30.7464409 },
      { lat: 50.7159715, lng: 30.7394028 },
      { lat: 50.7145042, lng: 30.7389736 },
      { lat: 50.7109715, lng: 30.7359695 },
      { lat: 50.7086888, lng: 30.7340813 },
      { lat: 50.7056449, lng: 30.733223 },
      { lat: 50.7029269, lng: 30.732193 },
      { lat: 50.7020028, lng: 30.731163 },
      { lat: 50.7003176, lng: 30.7279015 },
      { lat: 50.6992166, lng: 30.7266354 },
      { lat: 50.6948809, lng: 30.7246399 },
      { lat: 50.6911837, lng: 30.7219791 },
      { lat: 50.6885737, lng: 30.7201767 },
      { lat: 50.6878939, lng: 30.7222151 },
      { lat: 50.6916187, lng: 30.7248331 },
      { lat: 50.691877, lng: 30.726378 },
      { lat: 50.692543, lng: 30.727644 },
      { lat: 50.6926517, lng: 30.7294464 },
      { lat: 50.6929236, lng: 30.7343388 },
      { lat: 50.6908574, lng: 30.7399178 },
      { lat: 50.6892262, lng: 30.7395744 },
      { lat: 50.6880299, lng: 30.7397461 },
      { lat: 50.6877036, lng: 30.7465267 },
      { lat: 50.6875949, lng: 30.7481575 },
      { lat: 50.6855285, lng: 30.7531357 },
      { lat: 50.6845496, lng: 30.7527065 },
      { lat: 50.6842233, lng: 30.7533932 },
      { lat: 50.6847128, lng: 30.7552814 },
      { lat: 50.6858547, lng: 30.7558823 },
      { lat: 50.6851478, lng: 30.7577705 },
      { lat: 50.6856372, lng: 30.7592297 },
      { lat: 50.6870511, lng: 30.7573414 },
      { lat: 50.6882474, lng: 30.7562256 },
      { lat: 50.6910749, lng: 30.7563972 },
      { lat: 50.6941741, lng: 30.7564831 },
      { lat: 50.6943372, lng: 30.7577705 },
      { lat: 50.6952072, lng: 30.7627487 },
    ],
  },
  {
    name: 'КРАСИЛОВКА',
    price: 220,
    points: [
      { lat: 50.5219402, lng: 30.88377 },
      { lat: 50.5200302, lng: 30.8902073 },
      { lat: 50.5187204, lng: 30.8901215 },
      { lat: 50.5175744, lng: 30.8882332 },
      { lat: 50.5134811, lng: 30.893383 },
      { lat: 50.5134811, lng: 30.8958721 },
      { lat: 50.511025, lng: 30.9005928 },
      { lat: 50.5091145, lng: 30.9000778 },
      { lat: 50.507477, lng: 30.9021378 },
      { lat: 50.5063852, lng: 30.9012794 },
      { lat: 50.5051297, lng: 30.905056 },
      { lat: 50.5036012, lng: 30.9049702 },
      { lat: 50.5012537, lng: 30.904541 },
      { lat: 50.4993975, lng: 30.9048843 },
      { lat: 50.4978142, lng: 30.9063435 },
      { lat: 50.4981964, lng: 30.9103775 },
      { lat: 50.500271, lng: 30.9099483 },
      { lat: 50.5027823, lng: 30.9089184 },
      { lat: 50.5064944, lng: 30.9109783 },
      { lat: 50.5048568, lng: 30.9149265 },
      { lat: 50.503765, lng: 30.9141541 },
      { lat: 50.5021818, lng: 30.9181881 },
      { lat: 50.502018, lng: 30.920763 },
      { lat: 50.5027277, lng: 30.921793 },
      { lat: 50.5040379, lng: 30.9215355 },
      { lat: 50.5049114, lng: 30.920248 },
      { lat: 50.5073678, lng: 30.9203339 },
      { lat: 50.5079683, lng: 30.9223938 },
      { lat: 50.5072586, lng: 30.9240246 },
      { lat: 50.5075862, lng: 30.9296036 },
      { lat: 50.5097696, lng: 30.9295177 },
      { lat: 50.5098241, lng: 30.9281445 },
      { lat: 50.5153368, lng: 30.9281445 },
      { lat: 50.5151185, lng: 30.93853 },
      { lat: 50.5189387, lng: 30.938015 },
      { lat: 50.5188296, lng: 30.9367275 },
      { lat: 50.520685, lng: 30.936555 },
      { lat: 50.520685, lng: 30.9375 },
      { lat: 50.5216673, lng: 30.938015 },
      { lat: 50.5215036, lng: 30.9392166 },
      { lat: 50.5226496, lng: 30.9393024 },
      { lat: 50.5289246, lng: 30.938015 },
      { lat: 50.5290337, lng: 30.9231663 },
      { lat: 50.5295793, lng: 30.9183598 },
      { lat: 50.5294702, lng: 30.9160423 },
      { lat: 50.5288154, lng: 30.9126091 },
      { lat: 50.5291974, lng: 30.9083176 },
      { lat: 50.5296338, lng: 30.9053564 },
      { lat: 50.5313252, lng: 30.9053135 },
      { lat: 50.5313252, lng: 30.9032536 },
      { lat: 50.5329619, lng: 30.9027386 },
      { lat: 50.5323073, lng: 30.8920097 },
      { lat: 50.5284335, lng: 30.8911514 },
      { lat: 50.5287609, lng: 30.8863449 },
      { lat: 50.5219402, lng: 30.88377 },
    ],
  },
  {
    name: 'ЮРОВКА',
    price: 221,
    points: [
      { lat: 50.3401471, lng: 30.3541946 },
      { lat: 50.3356549, lng: 30.3554392 },
      { lat: 50.3333265, lng: 30.3606749 },
      { lat: 50.328779, lng: 30.3608036 },
      { lat: 50.3260392, lng: 30.3665543 },
      { lat: 50.3276831, lng: 30.3748798 },
      { lat: 50.3259023, lng: 30.3759098 },
      { lat: 50.326368, lng: 30.3790426 },
      { lat: 50.3269708, lng: 30.3787422 },
      { lat: 50.3308062, lng: 30.3750515 },
      { lat: 50.3326691, lng: 30.3768969 },
      { lat: 50.3331622, lng: 30.3767252 },
      { lat: 50.3262447, lng: 30.3942131 },
      { lat: 50.3289227, lng: 30.3971643 },
      { lat: 50.3326178, lng: 30.4022018 },
      { lat: 50.3370678, lng: 30.3923608 },
      { lat: 50.3340605, lng: 30.3887272 },
      { lat: 50.3368602, lng: 30.3809738 },
      { lat: 50.339161, lng: 30.3755236 },
      { lat: 50.3377093, lng: 30.3733778 },
      { lat: 50.3384215, lng: 30.3721333 },
      { lat: 50.3387776, lng: 30.3729486 },
      { lat: 50.3392706, lng: 30.3733349 },
      { lat: 50.3414892, lng: 30.3726482 },
      { lat: 50.3420096, lng: 30.3720474 },
      { lat: 50.3440227, lng: 30.3699984 },
      { lat: 50.3456522, lng: 30.3666617 },
      { lat: 50.3444197, lng: 30.3617907 },
      { lat: 50.343242, lng: 30.3582716 },
      { lat: 50.3418726, lng: 30.355525 },
      { lat: 50.3401471, lng: 30.3541946 },
    ],
  },
  {
    name: 'МАЛЮТЯНКА',
    price: 222,
    points: [
      { lat: 50.2959468, lng: 30.2427864 },
      { lat: 50.2927117, lng: 30.2454901 },
      { lat: 50.2902441, lng: 30.2480221 },
      { lat: 50.2872279, lng: 30.2500391 },
      { lat: 50.2816064, lng: 30.2573776 },
      { lat: 50.2855278, lng: 30.2643728 },
      { lat: 50.2834712, lng: 30.2670336 },
      { lat: 50.2830599, lng: 30.2689648 },
      { lat: 50.2818533, lng: 30.2706814 },
      { lat: 50.2808934, lng: 30.2684927 },
      { lat: 50.2778218, lng: 30.2724409 },
      { lat: 50.2797827, lng: 30.276239 },
      { lat: 50.2819629, lng: 30.2835989 },
      { lat: 50.2876118, lng: 30.2930832 },
      { lat: 50.2882699, lng: 30.2929115 },
      { lat: 50.2905457, lng: 30.28862 },
      { lat: 50.292273, lng: 30.2889633 },
      { lat: 50.2932874, lng: 30.2800798 },
      { lat: 50.292931, lng: 30.2788782 },
      { lat: 50.2931778, lng: 30.2714109 },
      { lat: 50.2940551, lng: 30.2642012 },
      { lat: 50.296358, lng: 30.2614117 },
      { lat: 50.2956178, lng: 30.2600813 },
      { lat: 50.2977562, lng: 30.2575064 },
      { lat: 50.2990172, lng: 30.2553177 },
      { lat: 50.2974272, lng: 30.2529573 },
      { lat: 50.2964677, lng: 30.2539873 },
      { lat: 50.2955356, lng: 30.2523994 },
      { lat: 50.2972353, lng: 30.245018 },
      { lat: 50.2959468, lng: 30.2427864 },
    ],
  },
  {
    name: 'БЕЗРАДИЧИ',
    price: 223,
    points: [
      { lat: 50.1404957, lng: 30.5320358 },
      { lat: 50.1401656, lng: 30.5409622 },
      { lat: 50.1417059, lng: 30.5462837 },
      { lat: 50.1447862, lng: 30.5536652 },
      { lat: 50.1481964, lng: 30.5612183 },
      { lat: 50.1501764, lng: 30.5646515 },
      { lat: 50.1568857, lng: 30.5713463 },
      { lat: 50.1608449, lng: 30.5770111 },
      { lat: 50.164034, lng: 30.5792427 },
      { lat: 50.1648037, lng: 30.5819464 },
      { lat: 50.1643639, lng: 30.5866241 },
      { lat: 50.1649137, lng: 30.592289 },
      { lat: 50.166673, lng: 30.591259 },
      { lat: 50.1677726, lng: 30.592804 },
      { lat: 50.1695319, lng: 30.6007004 },
      { lat: 50.1735999, lng: 30.6012154 },
      { lat: 50.1797564, lng: 30.6037903 },
      { lat: 50.1814053, lng: 30.6067085 },
      { lat: 50.1816251, lng: 30.6149483 },
      { lat: 50.1801961, lng: 30.6199265 },
      { lat: 50.181845, lng: 30.6207848 },
      { lat: 50.1851426, lng: 30.6118584 },
      { lat: 50.1869012, lng: 30.6043053 },
      { lat: 50.1865714, lng: 30.6003571 },
      { lat: 50.1842632, lng: 30.5988121 },
      { lat: 50.1808556, lng: 30.5979538 },
      { lat: 50.180306, lng: 30.5945206 },
      { lat: 50.1823121, lng: 30.5935335 },
      { lat: 50.1849227, lng: 30.5925035 },
      { lat: 50.1927262, lng: 30.5898857 },
      { lat: 50.1947043, lng: 30.5874825 },
      { lat: 50.1959131, lng: 30.5843925 },
      { lat: 50.1956934, lng: 30.5804443 },
      { lat: 50.1953637, lng: 30.5770111 },
      { lat: 50.1922866, lng: 30.5776978 },
      { lat: 50.1880003, lng: 30.5797577 },
      { lat: 50.1834938, lng: 30.5838776 },
      { lat: 50.1763484, lng: 30.5910873 },
      { lat: 50.1742596, lng: 30.5926323 },
      { lat: 50.1733801, lng: 30.5898857 },
      { lat: 50.1722806, lng: 30.5819893 },
      { lat: 50.1748093, lng: 30.5749512 },
      { lat: 50.1772279, lng: 30.5687714 },
      { lat: 50.1784372, lng: 30.5631065 },
      { lat: 50.1777776, lng: 30.5605316 },
      { lat: 50.1740397, lng: 30.5607033 },
      { lat: 50.1726105, lng: 30.5641365 },
      { lat: 50.1695319, lng: 30.5685997 },
      { lat: 50.1665631, lng: 30.5720329 },
      { lat: 50.164034, lng: 30.5698013 },
      { lat: 50.1623845, lng: 30.5627632 },
      { lat: 50.1612848, lng: 30.5579567 },
      { lat: 50.1589753, lng: 30.5524635 },
      { lat: 50.1569957, lng: 30.5510902 },
      { lat: 50.1553459, lng: 30.5497169 },
      { lat: 50.1520463, lng: 30.5504036 },
      { lat: 50.1494064, lng: 30.5526352 },
      { lat: 50.1480864, lng: 30.5510902 },
      { lat: 50.1465463, lng: 30.5473137 },
      { lat: 50.1434661, lng: 30.5418205 },
      { lat: 50.142476, lng: 30.5351257 },
      { lat: 50.142256, lng: 30.5323792 },
      { lat: 50.1404957, lng: 30.5320358 },
    ],
  },
  {
    name: 'ПЕРВОМАЙСКИЙ',
    price: 224,
    points: [
      { lat: 50.4349306, lng: 30.4699372 },
      { lat: 50.4326754, lng: 30.4677272 },
      { lat: 50.4354569, lng: 30.4586185 },
      { lat: 50.4346025, lng: 30.453844 },
      { lat: 50.4318416, lng: 30.4551315 },
      { lat: 50.4308028, lng: 30.4556894 },
      { lat: 50.4268114, lng: 30.4586506 },
      { lat: 50.4257451, lng: 30.4595947 },
      { lat: 50.4256426, lng: 30.460099 },
      { lat: 50.4286227, lng: 30.4661501 },
      { lat: 50.4302014, lng: 30.4675341 },
      { lat: 50.432197, lng: 30.4696798 },
      { lat: 50.4342745, lng: 30.4717397 },
      { lat: 50.4349306, lng: 30.4699372 },
    ],
  },
  {
    name: 'ЧАПАЕВКА АВТОРЫНОК',
    price: 225,
    points: [
      { lat: 50.3417904, lng: 30.5399323 },
      { lat: 50.3404348, lng: 30.5423783 },
      { lat: 50.3400171, lng: 30.5433439 },
      { lat: 50.3387949, lng: 30.5481182 },
      { lat: 50.3336552, lng: 30.5471849 },
      { lat: 50.3332854, lng: 30.5511539 },
      { lat: 50.3374832, lng: 30.5547693 },
      { lat: 50.340349, lng: 30.5540025 },
      { lat: 50.3407685, lng: 30.5565803 },
      { lat: 50.3424066, lng: 30.5579996 },
      { lat: 50.3506362, lng: 30.565424 },
      { lat: 50.3509648, lng: 30.5569267 },
      { lat: 50.3504719, lng: 30.5516481 },
      { lat: 50.3515398, lng: 30.5477428 },
      { lat: 50.3529912, lng: 30.5439663 },
      { lat: 50.3496778, lng: 30.542593 },
      { lat: 50.346282, lng: 30.5407906 },
      { lat: 50.3452413, lng: 30.5403614 },
      { lat: 50.3436802, lng: 30.540061 },
      { lat: 50.3417904, lng: 30.5399323 },
    ],
  },
  {
    name: 'КУДРЯШОВА',
    price: 226,
    points: [
      { lat: 50.4312266, lng: 30.4719543 },
      { lat: 50.4274197, lng: 30.4758382 },
      { lat: 50.4280007, lng: 30.4772651 },
      { lat: 50.4294907, lng: 30.4847646 },
      { lat: 50.429354, lng: 30.4871893 },
      { lat: 50.4296683, lng: 30.4875326 },
      { lat: 50.4315273, lng: 30.4881763 },
      { lat: 50.4326891, lng: 30.4886055 },
      { lat: 50.4333588, lng: 30.4892492 },
      { lat: 50.4341361, lng: 30.4902577 },
      { lat: 50.4347025, lng: 30.4903328 },
      { lat: 50.4361401, lng: 30.4889488 },
      { lat: 50.4349853, lng: 30.4870176 },
      { lat: 50.4333998, lng: 30.4835844 },
      { lat: 50.4326617, lng: 30.4797649 },
      { lat: 50.4320057, lng: 30.4727697 },
      { lat: 50.4312266, lng: 30.4719543 },
    ],
  },
  {
    name: 'ЗАГРЕБ',
    price: 227,
    points: [
      { lat: 50.3938828, lng: 30.4915667 },
      { lat: 50.393746, lng: 30.490365 },
      { lat: 50.3927953, lng: 30.485494 },
      { lat: 50.3921729, lng: 30.4843138 },
      { lat: 50.3847441, lng: 30.4790354 },
      { lat: 50.3834853, lng: 30.4783058 },
      { lat: 50.3815424, lng: 30.4773617 },
      { lat: 50.3812414, lng: 30.4775333 },
      { lat: 50.3836222, lng: 30.4837561 },
      { lat: 50.3881371, lng: 30.4947853 },
      { lat: 50.3897241, lng: 30.4931974 },
      { lat: 50.3908185, lng: 30.492897 },
      { lat: 50.3938828, lng: 30.4915667 },
    ],
  },
  {
    name: 'ПЕТРОВЦЫ СТАРЫЕ',
    price: 228,
    points: [
      { lat: 50.6342198, lng: 30.4159927 },
      { lat: 50.6399357, lng: 30.429554 },
      { lat: 50.6420041, lng: 30.4371929 },
      { lat: 50.6496782, lng: 30.4256058 },
      { lat: 50.6575143, lng: 30.4163361 },
      { lat: 50.6650226, lng: 30.4146194 },
      { lat: 50.6682868, lng: 30.4166794 },
      { lat: 50.6668723, lng: 30.4005432 },
      { lat: 50.664805, lng: 30.4020882 },
      { lat: 50.6634993, lng: 30.3926468 },
      { lat: 50.6426028, lng: 30.3957367 },
      { lat: 50.646195, lng: 30.4043198 },
      { lat: 50.6342198, lng: 30.4159927 },
    ],
  },
  {
    name: 'НЕМЕШАЕВО',
    price: 229,
    points: [
      { lat: 50.5476355, lng: 30.0741291 },
      { lat: 50.5445266, lng: 30.0792789 },
      { lat: 50.549108, lng: 30.0833988 },
      { lat: 50.5487808, lng: 30.0870895 },
      { lat: 50.5538526, lng: 30.094986 },
      { lat: 50.5534164, lng: 30.0961876 },
      { lat: 50.5584877, lng: 30.0982475 },
      { lat: 50.5600144, lng: 30.0972176 },
      { lat: 50.566339, lng: 30.105629 },
      { lat: 50.5654121, lng: 30.1097488 },
      { lat: 50.5721176, lng: 30.109148 },
      { lat: 50.5734258, lng: 30.1129246 },
      { lat: 50.5767508, lng: 30.1140404 },
      { lat: 50.5781136, lng: 30.1124526 },
      { lat: 50.5782225, lng: 30.1038265 },
      { lat: 50.5781952, lng: 30.1028395 },
      { lat: 50.5739709, lng: 30.1025391 },
      { lat: 50.5737257, lng: 30.0987196 },
      { lat: 50.5784678, lng: 30.0991488 },
      { lat: 50.578495, lng: 30.0971317 },
      { lat: 50.578386, lng: 30.0930119 },
      { lat: 50.5764783, lng: 30.0915527 },
      { lat: 50.5751701, lng: 30.0930119 },
      { lat: 50.5715179, lng: 30.0904369 },
      { lat: 50.5707547, lng: 30.0833988 },
      { lat: 50.5683016, lng: 30.0816822 },
      { lat: 50.5669932, lng: 30.0757599 },
      { lat: 50.5651396, lng: 30.0740433 },
      { lat: 50.5632313, lng: 30.0797081 },
      { lat: 50.5476355, lng: 30.0741291 },
    ],
  },
  {
    name: 'МИКУЛИЧИ',
    price: 230,
    points: [
      { lat: 50.5651396, lng: 30.0740433 },
      { lat: 50.5669932, lng: 30.0757599 },
      { lat: 50.5683016, lng: 30.0816822 },
      { lat: 50.5707547, lng: 30.0833988 },
      { lat: 50.5715179, lng: 30.0904369 },
      { lat: 50.5751701, lng: 30.0930119 },
      { lat: 50.5764783, lng: 30.0915527 },
      { lat: 50.578386, lng: 30.0930119 },
      { lat: 50.5784678, lng: 30.0991488 },
      { lat: 50.5827733, lng: 30.0991488 },
      { lat: 50.5834546, lng: 30.0912094 },
      { lat: 50.5901574, lng: 30.0734425 },
      { lat: 50.586234, lng: 30.0704384 },
      { lat: 50.5829641, lng: 30.0643444 },
      { lat: 50.5847081, lng: 30.0608253 },
      { lat: 50.5868334, lng: 30.0615978 },
      { lat: 50.5879778, lng: 30.0575638 },
      { lat: 50.5796941, lng: 30.0494099 },
      { lat: 50.5774049, lng: 30.0532722 },
      { lat: 50.5752246, lng: 30.0506973 },
      { lat: 50.564976, lng: 30.0521564 },
      { lat: 50.5620863, lng: 30.0580788 },
      { lat: 50.5642672, lng: 30.0614262 },
      { lat: 50.5620863, lng: 30.0694084 },
      { lat: 50.5651396, lng: 30.0740433 },
    ],
  },
  {
    name: 'КЛАВДИЕВО-ТАРАСОВЕ',
    price: 231,
    points: [
      { lat: 50.5760422, lng: 29.9847794 },
      { lat: 50.5667751, lng: 30.001688 },
      { lat: 50.5730988, lng: 30.0107002 },
      { lat: 50.5720085, lng: 30.0132751 },
      { lat: 50.5744615, lng: 30.0193691 },
      { lat: 50.5739709, lng: 30.0243473 },
      { lat: 50.578604, lng: 30.0265789 },
      { lat: 50.5797486, lng: 30.021944 },
      { lat: 50.5858525, lng: 30.034132 },
      { lat: 50.585689, lng: 30.0365353 },
      { lat: 50.5892311, lng: 30.0384235 },
      { lat: 50.5908113, lng: 30.0296688 },
      { lat: 50.5981669, lng: 30.0150776 },
      { lat: 50.589776, lng: 30.0047779 },
      { lat: 50.5903754, lng: 30.0014305 },
      { lat: 50.5840541, lng: 29.9922466 },
      { lat: 50.5831276, lng: 29.9938774 },
      { lat: 50.5760422, lng: 29.9847794 },
    ],
  },
  {
    name: 'РОМАНОВКА',
    price: 232,
    points: [
      { lat: 50.4925452, lng: 30.2567768 },
      { lat: 50.4897604, lng: 30.2526569 },
      { lat: 50.4875487, lng: 30.2523994 },
      { lat: 50.4853097, lng: 30.2487516 },
      { lat: 50.4821967, lng: 30.2472496 },
      { lat: 50.4799574, lng: 30.2484083 },
      { lat: 50.4770353, lng: 30.2481508 },
      { lat: 50.4767348, lng: 30.2501678 },
      { lat: 50.4811863, lng: 30.2520132 },
      { lat: 50.4816779, lng: 30.2548885 },
      { lat: 50.4836986, lng: 30.2555752 },
      { lat: 50.484354, lng: 30.2517128 },
      { lat: 50.4866204, lng: 30.2534294 },
      { lat: 50.4863473, lng: 30.2560472 },
      { lat: 50.4865385, lng: 30.2584505 },
      { lat: 50.4858558, lng: 30.258708 },
      { lat: 50.4857739, lng: 30.2607679 },
      { lat: 50.4885044, lng: 30.2638578 },
      { lat: 50.4928183, lng: 30.2675486 },
      { lat: 50.494975, lng: 30.2646303 },
      { lat: 50.4916443, lng: 30.2588797 },
      { lat: 50.4925452, lng: 30.2567768 },
    ],
  },
  {
    name: 'СТОЯНКА',
    price: 233,
    points: [
      { lat: 50.4470658, lng: 30.2351475 },
      { lat: 50.4597444, lng: 30.2345467 },
      { lat: 50.4607279, lng: 30.2286243 },
      { lat: 50.462531, lng: 30.2233887 },
      { lat: 50.4623671, lng: 30.217638 },
      { lat: 50.4513288, lng: 30.2136898 },
      { lat: 50.4511102, lng: 30.2203846 },
      { lat: 50.4500172, lng: 30.2248478 },
      { lat: 50.4463553, lng: 30.2263069 },
      { lat: 50.4470658, lng: 30.2351475 },
    ],
  },
  {
    name: 'КАПИТАНОВКА',
    price: 234,
    points: [
      { lat: 50.4477217, lng: 30.1887131 },
      { lat: 50.4414906, lng: 30.1863527 },
      { lat: 50.4407526, lng: 30.1886702 },
      { lat: 50.440698, lng: 30.192275 },
      { lat: 50.4427205, lng: 30.1939487 },
      { lat: 50.4427205, lng: 30.1975107 },
      { lat: 50.4372267, lng: 30.2052784 },
      { lat: 50.438402, lng: 30.2116728 },
      { lat: 50.440616, lng: 30.2097416 },
      { lat: 50.440534, lng: 30.2063513 },
      { lat: 50.4432671, lng: 30.206008 },
      { lat: 50.4429391, lng: 30.2013302 },
      { lat: 50.4444149, lng: 30.2006435 },
      { lat: 50.443677, lng: 30.1924896 },
      { lat: 50.4473664, lng: 30.1939917 },
      { lat: 50.4477217, lng: 30.1887131 },
    ],
  },
  {
    name: 'МИЛА',
    price: 235,
    points: [
      { lat: 50.4414906, lng: 30.1863527 },
      { lat: 50.4432124, lng: 30.1869965 },
      { lat: 50.4429118, lng: 30.1828766 },
      { lat: 50.4448522, lng: 30.1825333 },
      { lat: 50.4443329, lng: 30.1750231 },
      { lat: 50.447831, lng: 30.1741648 },
      { lat: 50.4475304, lng: 30.1704311 },
      { lat: 50.4453715, lng: 30.1705599 },
      { lat: 50.444087, lng: 30.1558399 },
      { lat: 50.4421192, lng: 30.1560974 },
      { lat: 50.4417912, lng: 30.1529217 },
      { lat: 50.4404246, lng: 30.1530504 },
      { lat: 50.4325251, lng: 30.1605177 },
      { lat: 50.4324977, lng: 30.1643801 },
      { lat: 50.4307755, lng: 30.165453 },
      { lat: 50.4309122, lng: 30.1724911 },
      { lat: 50.432033, lng: 30.1731777 },
      { lat: 50.4338919, lng: 30.1730061 },
      { lat: 50.4340285, lng: 30.1763105 },
      { lat: 50.4360513, lng: 30.1761389 },
      { lat: 50.440616, lng: 30.175581 },
      { lat: 50.4414906, lng: 30.1863527 },
    ],
  },
  {
    name: 'ПЕТРУШКИ',
    price: 235,
    points: [
      { lat: 50.4325251, lng: 30.1605177 },
      { lat: 50.4283697, lng: 30.1578569 },
      { lat: 50.4283697, lng: 30.15378 },
      { lat: 50.4238313, lng: 30.1500463 },
      { lat: 50.4183626, lng: 30.1550245 },
      { lat: 50.4181439, lng: 30.1540804 },
      { lat: 50.4165032, lng: 30.1542091 },
      { lat: 50.4163938, lng: 30.1569557 },
      { lat: 50.4138505, lng: 30.1584148 },
      { lat: 50.4137959, lng: 30.1612902 },
      { lat: 50.4159289, lng: 30.1696157 },
      { lat: 50.4180072, lng: 30.1711607 },
      { lat: 50.4245695, lng: 30.1720619 },
      { lat: 50.4232297, lng: 30.1769543 },
      { lat: 50.4321423, lng: 30.1774693 },
      { lat: 50.432033, lng: 30.1731777 },
      { lat: 50.4309122, lng: 30.1724911 },
      { lat: 50.4307755, lng: 30.165453 },
      { lat: 50.4324977, lng: 30.1643801 },
      { lat: 50.4325251, lng: 30.1605177 },
    ],
  },
  {
    name: 'ШЕВЧЕНКОВО',
    price: 237,
    points: [
      { lat: 50.4029924, lng: 30.2580643 },
      { lat: 50.4151359, lng: 30.2759171 },
      { lat: 50.419183, lng: 30.2700806 },
      { lat: 50.4235578, lng: 30.2760887 },
      { lat: 50.4307208, lng: 30.2624417 },
      { lat: 50.4218354, lng: 30.2483225 },
      { lat: 50.4218079, lng: 30.2466488 },
      { lat: 50.4197709, lng: 30.2433873 },
      { lat: 50.4208783, lng: 30.2418423 },
      { lat: 50.4162297, lng: 30.2395248 },
      { lat: 50.4124558, lng: 30.2454042 },
      { lat: 50.4101038, lng: 30.2505326 },
      { lat: 50.4078064, lng: 30.2511978 },
      { lat: 50.4029924, lng: 30.2580643 },
    ],
  },
  {
    name: 'БОБРИЦА',
    price: 238,
    points: [
      { lat: 50.3686787, lng: 30.1975536 },
      { lat: 50.3682407, lng: 30.1952362 },
      { lat: 50.3620539, lng: 30.1930904 },
      { lat: 50.359097, lng: 30.1809883 },
      { lat: 50.3537852, lng: 30.1754093 },
      { lat: 50.348199, lng: 30.1668262 },
      { lat: 50.3460629, lng: 30.1706886 },
      { lat: 50.3464463, lng: 30.1758385 },
      { lat: 50.3443649, lng: 30.1793575 },
      { lat: 50.3379011, lng: 30.2056217 },
      { lat: 50.3437624, lng: 30.2087975 },
      { lat: 50.3462272, lng: 30.2065659 },
      { lat: 50.346775, lng: 30.2035618 },
      { lat: 50.345077, lng: 30.1996136 },
      { lat: 50.346282, lng: 30.1960087 },
      { lat: 50.3517042, lng: 30.1960945 },
      { lat: 50.3573448, lng: 30.200386 },
      { lat: 50.3534019, lng: 30.2167797 },
      { lat: 50.3607945, lng: 30.2227879 },
      { lat: 50.3680217, lng: 30.2009869 },
      { lat: 50.3686787, lng: 30.1975536 },
    ],
  },
  {
    name: 'БЫШЕВ',
    price: 239,
    points: [
      { lat: 50.2423746, lng: 29.8821259 },
      { lat: 50.2419355, lng: 29.8913956 },
      { lat: 50.2553274, lng: 29.8937988 },
      { lat: 50.2608149, lng: 29.9008369 },
      { lat: 50.2660822, lng: 29.9044418 },
      { lat: 50.2731044, lng: 29.9039268 },
      { lat: 50.2749695, lng: 29.8960304 },
      { lat: 50.2791383, lng: 29.897747 },
      { lat: 50.2800159, lng: 29.8922539 },
      { lat: 50.2826485, lng: 29.8908806 },
      { lat: 50.2826485, lng: 29.8860741 },
      { lat: 50.2761763, lng: 29.8816109 },
      { lat: 50.2423746, lng: 29.8821259 },
    ],
  },
  {
    name: 'БОЯРКА СТАРАЯ',
    price: 239,
    points: [
      { lat: 50.3035402, lng: 30.299263 },
      { lat: 50.3032935, lng: 30.3026104 },
      { lat: 50.3042529, lng: 30.3035116 },
      { lat: 50.3036225, lng: 30.3077602 },
      { lat: 50.3023067, lng: 30.3083611 },
      { lat: 50.3014295, lng: 30.3110218 },
      { lat: 50.3026082, lng: 30.3120947 },
      { lat: 50.3021148, lng: 30.3226948 },
      { lat: 50.3008539, lng: 30.331192 },
      { lat: 50.3060071, lng: 30.3405046 },
      { lat: 50.308008, lng: 30.3410625 },
      { lat: 50.3089125, lng: 30.3351831 },
      { lat: 50.3103102, lng: 30.3370714 },
      { lat: 50.3120368, lng: 30.3241968 },
      { lat: 50.3131331, lng: 30.3144979 },
      { lat: 50.3140648, lng: 30.3147984 },
      { lat: 50.3146129, lng: 30.3109789 },
      { lat: 50.3167778, lng: 30.3117085 },
      { lat: 50.3173807, lng: 30.3074169 },
      { lat: 50.3169423, lng: 30.3069878 },
      { lat: 50.3172985, lng: 30.3037262 },
      { lat: 50.3184494, lng: 30.3040266 },
      { lat: 50.3187782, lng: 30.3015804 },
      { lat: 50.3209704, lng: 30.2990913 },
      { lat: 50.317415, lng: 30.2986837 },
      { lat: 50.3121465, lng: 30.2985764 },
      { lat: 50.3086932, lng: 30.2984905 },
      { lat: 50.3035402, lng: 30.299263 },
    ],
  },
  {
    name: 'НОВОЕ (БОЯРКА)',
    price: 240,
    points: [
      { lat: 50.3120368, lng: 30.3241968 },
      { lat: 50.3168601, lng: 30.3260422 },
      { lat: 50.3177918, lng: 30.321579 },
      { lat: 50.3225048, lng: 30.3228235 },
      { lat: 50.3238748, lng: 30.3138113 },
      { lat: 50.3269982, lng: 30.3155279 },
      { lat: 50.3296282, lng: 30.2996922 },
      { lat: 50.3209704, lng: 30.2990913 },
      { lat: 50.3187782, lng: 30.3015804 },
      { lat: 50.3184494, lng: 30.3040266 },
      { lat: 50.3172985, lng: 30.3037262 },
      { lat: 50.3169423, lng: 30.3069878 },
      { lat: 50.3173807, lng: 30.3074169 },
      { lat: 50.3167778, lng: 30.3117085 },
      { lat: 50.3146129, lng: 30.3109789 },
      { lat: 50.3140648, lng: 30.3147984 },
      { lat: 50.3131331, lng: 30.3144979 },
      { lat: 50.3120368, lng: 30.3241968 },
    ],
  },
  {
    name: 'ЗАЙЦЕВ',
    price: 241,
    points: [
      { lat: 50.2817161, lng: 30.3471565 },
      { lat: 50.2722815, lng: 30.3417063 },
      { lat: 50.2712941, lng: 30.3466845 },
      { lat: 50.2684139, lng: 30.3451395 },
      { lat: 50.2670423, lng: 30.3531647 },
      { lat: 50.2721444, lng: 30.3554392 },
      { lat: 50.2716507, lng: 30.3597307 },
      { lat: 50.2767797, lng: 30.3619623 },
      { lat: 50.2787269, lng: 30.3606749 },
      { lat: 50.2793028, lng: 30.3590012 },
      { lat: 50.2817161, lng: 30.3471565 },
    ],
  },
  {
    name: 'МАРХАЛОВКА',
    price: 241,
    points: [
      { lat: 50.2670423, lng: 30.3531647 },
      { lat: 50.2662194, lng: 30.3586578 },
      { lat: 50.2643265, lng: 30.3580141 },
      { lat: 50.2638601, lng: 30.3619194 },
      { lat: 50.2663565, lng: 30.3628635 },
      { lat: 50.2673715, lng: 30.3696871 },
      { lat: 50.2705535, lng: 30.3701591 },
      { lat: 50.2713489, lng: 30.378871 },
      { lat: 50.2744484, lng: 30.3855658 },
      { lat: 50.2774927, lng: 30.3856516 },
      { lat: 50.2783429, lng: 30.3775406 },
      { lat: 50.2796319, lng: 30.3783989 },
      { lat: 50.2797142, lng: 30.373764 },
      { lat: 50.2809757, lng: 30.3723907 },
      { lat: 50.2793028, lng: 30.3590012 },
      { lat: 50.2787269, lng: 30.3606749 },
      { lat: 50.2767797, lng: 30.3619623 },
      { lat: 50.2716507, lng: 30.3597307 },
      { lat: 50.2721444, lng: 30.3554392 },
      { lat: 50.2670423, lng: 30.3531647 },
    ],
  },
  {
    name: 'ВИТА-ПОЧТОВАЯ',
    price: 242,
    points: [
      { lat: 50.3299844, lng: 30.3985262 },
      { lat: 50.3289227, lng: 30.3971643 },
      { lat: 50.3262447, lng: 30.3942131 },
      { lat: 50.3271625, lng: 30.3867245 },
      { lat: 50.3282036, lng: 30.3830338 },
      { lat: 50.326368, lng: 30.3790426 },
      { lat: 50.3259023, lng: 30.3759098 },
      { lat: 50.3228336, lng: 30.3711891 },
      { lat: 50.3226144, lng: 30.3623486 },
      { lat: 50.3172985, lng: 30.3598595 },
      { lat: 50.3177369, lng: 30.3525639 },
      { lat: 50.3120917, lng: 30.3533363 },
      { lat: 50.3106117, lng: 30.3582287 },
      { lat: 50.3121465, lng: 30.3594303 },
      { lat: 50.3115983, lng: 30.3633785 },
      { lat: 50.3089673, lng: 30.3685284 },
      { lat: 50.3080902, lng: 30.3749657 },
      { lat: 50.307268, lng: 30.3857803 },
      { lat: 50.3096799, lng: 30.3878403 },
      { lat: 50.3106665, lng: 30.3909302 },
      { lat: 50.3089125, lng: 30.3973675 },
      { lat: 50.305733, lng: 30.3992558 },
      { lat: 50.3020052, lng: 30.4198551 },
      { lat: 50.3088576, lng: 30.4223442 },
      { lat: 50.3120917, lng: 30.4014874 },
      { lat: 50.3133249, lng: 30.3968096 },
      { lat: 50.3204771, lng: 30.3912306 },
      { lat: 50.3256283, lng: 30.3943205 },
      { lat: 50.3234912, lng: 30.39608 },
      { lat: 50.323135, lng: 30.406251 },
      { lat: 50.3202579, lng: 30.4056931 },
      { lat: 50.3194359, lng: 30.4127312 },
      { lat: 50.326094, lng: 30.4140615 },
      { lat: 50.3276831, lng: 30.4018736 },
      { lat: 50.3299844, lng: 30.3985262 },
    ],
  },
  {
    name: 'ПОДГОРЦЫ',
    price: 243,
    points: [
      { lat: 50.2537909, lng: 30.5539227 },
      { lat: 50.2516505, lng: 30.5511761 },
      { lat: 50.2468756, lng: 30.5497169 },
      { lat: 50.2463816, lng: 30.5450821 },
      { lat: 50.2424844, lng: 30.5413914 },
      { lat: 50.2453936, lng: 30.5394173 },
      { lat: 50.2526933, lng: 30.5368423 },
      { lat: 50.2535713, lng: 30.5378723 },
      { lat: 50.2580164, lng: 30.5362415 },
      { lat: 50.2575774, lng: 30.5292892 },
      { lat: 50.2529128, lng: 30.53092 },
      { lat: 50.2414414, lng: 30.5356407 },
      { lat: 50.2350734, lng: 30.5358982 },
      { lat: 50.2345793, lng: 30.5307484 },
      { lat: 50.2304066, lng: 30.5297184 },
      { lat: 50.229583, lng: 30.5349541 },
      { lat: 50.226563, lng: 30.5344391 },
      { lat: 50.2267827, lng: 30.5224228 },
      { lat: 50.2245862, lng: 30.5221653 },
      { lat: 50.2240371, lng: 30.5347824 },
      { lat: 50.2226642, lng: 30.5370998 },
      { lat: 50.224696, lng: 30.5440521 },
      { lat: 50.2165683, lng: 30.5526352 },
      { lat: 50.2186553, lng: 30.5570126 },
      { lat: 50.2244764, lng: 30.5510044 },
      { lat: 50.2252452, lng: 30.5525494 },
      { lat: 50.2302968, lng: 30.5502319 },
      { lat: 50.2317243, lng: 30.5526352 },
      { lat: 50.2356773, lng: 30.5514336 },
      { lat: 50.2365008, lng: 30.5570126 },
      { lat: 50.2436371, lng: 30.5626774 },
      { lat: 50.2494552, lng: 30.5581284 },
      { lat: 50.2537909, lng: 30.5539227 },
    ],
  },
  {
    name: 'ПЛЮТЫ',
    price: 244,
    points: [
      { lat: 50.1624944, lng: 30.7249403 },
      { lat: 50.1604325, lng: 30.7283735 },
      { lat: 50.1625494, lng: 30.7313347 },
      { lat: 50.1650236, lng: 30.7280731 },
      { lat: 50.1677176, lng: 30.7280302 },
      { lat: 50.1693395, lng: 30.7290602 },
      { lat: 50.1696418, lng: 30.7307768 },
      { lat: 50.1715935, lng: 30.7331371 },
      { lat: 50.1740122, lng: 30.7351542 },
      { lat: 50.1774478, lng: 30.7295322 },
      { lat: 50.176266, lng: 30.7249832 },
      { lat: 50.1730502, lng: 30.7217646 },
      { lat: 50.1725555, lng: 30.7173872 },
      { lat: 50.174452, lng: 30.717 },
      { lat: 50.1749742, lng: 30.7136106 },
      { lat: 50.1768157, lng: 30.7136106 },
      { lat: 50.1769256, lng: 30.7090616 },
      { lat: 50.1745894, lng: 30.7077742 },
      { lat: 50.1750017, lng: 30.7039547 },
      { lat: 50.1732151, lng: 30.7027531 },
      { lat: 50.1720607, lng: 30.7064438 },
      { lat: 50.1679376, lng: 30.7188463 },
      { lat: 50.1624944, lng: 30.7249403 },
    ],
  },
  {
    name: 'ПРОЦЕВ',
    price: 245,
    points: [
      { lat: 50.2365008, lng: 30.7516766 },
      { lat: 50.2323832, lng: 30.753994 },
      { lat: 50.2299674, lng: 30.760088 },
      { lat: 50.2400142, lng: 30.7689285 },
      { lat: 50.2342499, lng: 30.7832623 },
      { lat: 50.2199733, lng: 30.789957 },
      { lat: 50.2153051, lng: 30.8093548 },
      { lat: 50.220193, lng: 30.8159637 },
      { lat: 50.2369949, lng: 30.8321857 },
      { lat: 50.2439665, lng: 30.8278942 },
      { lat: 50.2397946, lng: 30.8177662 },
      { lat: 50.2419355, lng: 30.8127022 },
      { lat: 50.2521444, lng: 30.8125305 },
      { lat: 50.2603759, lng: 30.8085823 },
      { lat: 50.2574676, lng: 30.780859 },
      { lat: 50.2502236, lng: 30.7649803 },
      { lat: 50.2365008, lng: 30.7516766 },
    ],
  },
  {
    name: 'ВИШЕНКИ',
    price: 246,
    points: [
      { lat: 50.3096799, lng: 30.7047272 },
      { lat: 50.2961935, lng: 30.6917667 },
      { lat: 50.2863231, lng: 30.6950283 },
      { lat: 50.2862682, lng: 30.6999207 },
      { lat: 50.2834164, lng: 30.7151985 },
      { lat: 50.2851165, lng: 30.7165718 },
      { lat: 50.2878038, lng: 30.7216358 },
      { lat: 50.2844584, lng: 30.7309055 },
      { lat: 50.2813322, lng: 30.7325363 },
      { lat: 50.2819355, lng: 30.7436085 },
      { lat: 50.2950421, lng: 30.7440376 },
      { lat: 50.2990994, lng: 30.7387161 },
      { lat: 50.3023341, lng: 30.7434368 },
      { lat: 50.2964677, lng: 30.7538223 },
      { lat: 50.2912037, lng: 30.759573 },
      { lat: 50.2923004, lng: 30.7631779 },
      { lat: 50.2978384, lng: 30.7581997 },
      { lat: 50.3000315, lng: 30.7657528 },
      { lat: 50.3022793, lng: 30.7660961 },
      { lat: 50.3056234, lng: 30.7479858 },
      { lat: 50.3074872, lng: 30.7459259 },
      { lat: 50.3116532, lng: 30.7327938 },
      { lat: 50.3173533, lng: 30.7242966 },
      { lat: 50.3145033, lng: 30.7156277 },
      { lat: 50.3131879, lng: 30.7165718 },
      { lat: 50.3096799, lng: 30.7047272 },
    ],
  },
  {
    name: 'ГНЕДИН',
    price: 245,
    points: [
      { lat: 50.3173533, lng: 30.7242966 },
      { lat: 50.3199839, lng: 30.7297897 },
      { lat: 50.3297926, lng: 30.7324505 },
      { lat: 50.3362576, lng: 30.7197475 },
      { lat: 50.3385037, lng: 30.7186317 },
      { lat: 50.3435433, lng: 30.7259274 },
      { lat: 50.3489658, lng: 30.7226658 },
      { lat: 50.3486372, lng: 30.7160568 },
      { lat: 50.3428312, lng: 30.7151985 },
      { lat: 50.3405305, lng: 30.7112503 },
      { lat: 50.3435433, lng: 30.704813 },
      { lat: 50.3422834, lng: 30.7006073 },
      { lat: 50.3382845, lng: 30.7024956 },
      { lat: 50.3352715, lng: 30.6988907 },
      { lat: 50.3327513, lng: 30.6939983 },
      { lat: 50.3275461, lng: 30.692625 },
      { lat: 50.3248063, lng: 30.6940842 },
      { lat: 50.323272, lng: 30.6990623 },
      { lat: 50.3203675, lng: 30.6984615 },
      { lat: 50.3179562, lng: 30.6982899 },
      { lat: 50.314887, lng: 30.7036972 },
      { lat: 50.3096799, lng: 30.7047272 },
      { lat: 50.3131879, lng: 30.7165718 },
      { lat: 50.3145033, lng: 30.7156277 },
      { lat: 50.3173533, lng: 30.7242966 },
    ],
  },
  {
    name: 'ВИШНЕВЫЙ ХУТОРОК',
    price: 247,
    points: [
      { lat: 50.3284228, lng: 30.7569122 },
      { lat: 50.3217376, lng: 30.757513 },
      { lat: 50.322724, lng: 30.7837772 },
      { lat: 50.3295734, lng: 30.7824898 },
      { lat: 50.3284228, lng: 30.7569122 },
    ],
  },
  {
    name: 'МАРТУСОВКА',
    price: 248,
    points: [
      { lat: 50.3185042, lng: 30.8446312 },
      { lat: 50.3068294, lng: 30.8547592 },
      { lat: 50.3027179, lng: 30.8527851 },
      { lat: 50.2985512, lng: 30.8725262 },
      { lat: 50.3074324, lng: 30.8728695 },
      { lat: 50.3089125, lng: 30.8707237 },
      { lat: 50.3131331, lng: 30.8745861 },
      { lat: 50.316586, lng: 30.8756161 },
      { lat: 50.3199839, lng: 30.8757019 },
      { lat: 50.322724, lng: 30.8454037 },
      { lat: 50.3185042, lng: 30.8446312 },
    ],
  },
  {
    name: 'БЕЗУГЛОВКА',
    price: 249,
    points: [
      { lat: 50.3973573, lng: 30.8504677 },
      { lat: 50.3985062, lng: 30.8473778 },
      { lat: 50.4085722, lng: 30.85639 },
      { lat: 50.4132216, lng: 30.8451462 },
      { lat: 50.4055636, lng: 30.8370781 },
      { lat: 50.4069312, lng: 30.8284092 },
      { lat: 50.4013512, lng: 30.8209419 },
      { lat: 50.3939102, lng: 30.8466911 },
      { lat: 50.3959347, lng: 30.8491373 },
      { lat: 50.3973573, lng: 30.8504677 },
    ],
  },
  {
    name: 'КНЯЖИЧИ',
    price: 250,
    points: [
      { lat: 50.4827429, lng: 30.7634354 },
      { lat: 50.4683223, lng: 30.7405186 },
      { lat: 50.4701251, lng: 30.7519341 },
      { lat: 50.4687594, lng: 30.7528782 },
      { lat: 50.4675028, lng: 30.7521057 },
      { lat: 50.4661916, lng: 30.7530499 },
      { lat: 50.4641701, lng: 30.7503891 },
      { lat: 50.4612743, lng: 30.755024 },
      { lat: 50.4581597, lng: 30.7422352 },
      { lat: 50.4474484, lng: 30.7618904 },
      { lat: 50.4497986, lng: 30.7837772 },
      { lat: 50.4485415, lng: 30.8006001 },
      { lat: 50.4582144, lng: 30.813818 },
      { lat: 50.4588701, lng: 30.8221436 },
      { lat: 50.4720918, lng: 30.823431 },
      { lat: 50.4724741, lng: 30.803175 },
      { lat: 50.4737852, lng: 30.8009434 },
      { lat: 50.4720371, lng: 30.7895279 },
      { lat: 50.4653175, lng: 30.7890129 },
      { lat: 50.4650989, lng: 30.7819748 },
      { lat: 50.4638423, lng: 30.7793999 },
      { lat: 50.4632413, lng: 30.7713318 },
      { lat: 50.4666287, lng: 30.7660103 },
      { lat: 50.4824698, lng: 30.7672119 },
      { lat: 50.4827429, lng: 30.7634354 },
    ],
  },
  {
    name: 'ВЕЛИКАЯ ДЫМЕРКА',
    price: 251,
    points: [
      { lat: 50.5619773, lng: 30.8889198 },
      { lat: 50.5989296, lng: 30.9519196 },
      { lat: 50.6094977, lng: 30.9316635 },
      { lat: 50.613855, lng: 30.9350967 },
      { lat: 50.6169048, lng: 30.9241104 },
      { lat: 50.6094977, lng: 30.9177589 },
      { lat: 50.6081904, lng: 30.9117508 },
      { lat: 50.6097156, lng: 30.8908081 },
      { lat: 50.5971862, lng: 30.8793068 },
      { lat: 50.5992565, lng: 30.8708954 },
      { lat: 50.594353, lng: 30.8631706 },
      { lat: 50.5914107, lng: 30.8623123 },
      { lat: 50.5894491, lng: 30.8746719 },
      { lat: 50.5806206, lng: 30.871582 },
      { lat: 50.5738619, lng: 30.8791351 },
      { lat: 50.5695009, lng: 30.873642 },
      { lat: 50.5619773, lng: 30.8889198 },
    ],
  },
  {
    name: 'ПИРНОВО',
    price: 252,
    points: [
      { lat: 50.7469923, lng: 30.6615114 },
      { lat: 50.7464492, lng: 30.660181 },
      { lat: 50.7407192, lng: 30.6602669 },
      { lat: 50.7398501, lng: 30.666275 },
      { lat: 50.7417512, lng: 30.6690645 },
      { lat: 50.7426745, lng: 30.6847286 },
      { lat: 50.7496533, lng: 30.6856298 },
      { lat: 50.7504136, lng: 30.6790638 },
      { lat: 50.753889, lng: 30.6786776 },
      { lat: 50.7578257, lng: 30.6724548 },
      { lat: 50.7572556, lng: 30.669837 },
      { lat: 50.7612191, lng: 30.6659746 },
      { lat: 50.7599432, lng: 30.6612968 },
      { lat: 50.7561153, lng: 30.6580782 },
      { lat: 50.7522871, lng: 30.6635714 },
      { lat: 50.7511196, lng: 30.6595802 },
      { lat: 50.7485944, lng: 30.6640005 },
      { lat: 50.7469923, lng: 30.6615114 },
    ],
  },
  {
    name: 'ХОТЯНОВКА - 2',
    price: 253,
    points: [
      { lat: 50.5855255, lng: 30.5621624 },
      { lat: 50.5830731, lng: 30.5612183 },
      { lat: 50.5815471, lng: 30.5708313 },
      { lat: 50.5843266, lng: 30.5716896 },
      { lat: 50.5886862, lng: 30.5771828 },
      { lat: 50.5922281, lng: 30.5837917 },
      { lat: 50.5947889, lng: 30.5800152 },
      { lat: 50.5967504, lng: 30.5768394 },
      { lat: 50.6005096, lng: 30.5767536 },
      { lat: 50.6061205, lng: 30.5788136 },
      { lat: 50.6100968, lng: 30.5655956 },
      { lat: 50.6039416, lng: 30.5592442 },
      { lat: 50.6022528, lng: 30.5632782 },
      { lat: 50.5990386, lng: 30.5612183 },
      { lat: 50.5855255, lng: 30.5621624 },
    ],
  },
  {
    name: 'ЛЮТЕЖ',
    price: 254,
    points: [
      { lat: 50.6723122, lng: 30.4134178 },
      { lat: 50.6875949, lng: 30.396595 },
      { lat: 50.6978712, lng: 30.402689 },
      { lat: 50.7036336, lng: 30.4020882 },
      { lat: 50.7035793, lng: 30.3979683 },
      { lat: 50.6981974, lng: 30.3958225 },
      { lat: 50.6973275, lng: 30.3777981 },
      { lat: 50.675739, lng: 30.3804588 },
      { lat: 50.6683412, lng: 30.3771973 },
      { lat: 50.6695923, lng: 30.4008865 },
      { lat: 50.6714419, lng: 30.4002857 },
      { lat: 50.6723122, lng: 30.4134178 },
    ],
  },
  {
    name: 'ДЕМИДОВ',
    price: 255,
    points: [
      { lat: 50.7398229, lng: 30.2910233 },
      { lat: 50.7345539, lng: 30.2894783 },
      { lat: 50.7342822, lng: 30.3100777 },
      { lat: 50.7294472, lng: 30.3038979 },
      { lat: 50.7268393, lng: 30.3136826 },
      { lat: 50.7238509, lng: 30.3120518 },
      { lat: 50.7146673, lng: 30.3148842 },
      { lat: 50.7114063, lng: 30.3204632 },
      { lat: 50.7174932, lng: 30.3275871 },
      { lat: 50.7184714, lng: 30.3400326 },
      { lat: 50.7223838, lng: 30.3402901 },
      { lat: 50.7306424, lng: 30.3456974 },
      { lat: 50.7397686, lng: 30.3414059 },
      { lat: 50.7391711, lng: 30.3278446 },
      { lat: 50.7372156, lng: 30.3273296 },
      { lat: 50.7372699, lng: 30.3239822 },
      { lat: 50.7400402, lng: 30.3226089 },
      { lat: 50.7416697, lng: 30.3090477 },
      { lat: 50.739497, lng: 30.3057003 },
      { lat: 50.7398229, lng: 30.2910233 },
    ],
  },
  {
    name: 'ДЫМЕР',
    price: 256,
    points: [
      { lat: 50.8009986, lng: 30.3092194 },
      { lat: 50.8020835, lng: 30.3014946 },
      { lat: 50.7965502, lng: 30.2983189 },
      { lat: 50.7961161, lng: 30.2932549 },
      { lat: 50.8020835, lng: 30.2916241 },
      { lat: 50.8036023, lng: 30.2778053 },
      { lat: 50.7964417, lng: 30.2760887 },
      { lat: 50.7937832, lng: 30.2874184 },
      { lat: 50.7916673, lng: 30.289135 },
      { lat: 50.7852102, lng: 30.2882767 },
      { lat: 50.7856986, lng: 30.2804661 },
      { lat: 50.782931, lng: 30.2797794 },
      { lat: 50.7690908, lng: 30.3026962 },
      { lat: 50.7633907, lng: 30.3191757 },
      { lat: 50.7633907, lng: 30.3254414 },
      { lat: 50.7682766, lng: 30.3249264 },
      { lat: 50.7710993, lng: 30.3282738 },
      { lat: 50.7704479, lng: 30.3309345 },
      { lat: 50.7739218, lng: 30.3335953 },
      { lat: 50.7819, lng: 30.3325653 },
      { lat: 50.7847761, lng: 30.330677 },
      { lat: 50.7839621, lng: 30.327673 },
      { lat: 50.7893341, lng: 30.3242397 },
      { lat: 50.7948683, lng: 30.3085327 },
      { lat: 50.8009986, lng: 30.3092194 },
    ],
  },
  {
    name: 'ГЛЕБОВКА',
    price: 257,
    points: [
      { lat: 50.7999136, lng: 30.3541088 },
      { lat: 50.7967129, lng: 30.34338 },
      { lat: 50.786187, lng: 30.3522205 },
      { lat: 50.7870552, lng: 30.3683567 },
      { lat: 50.7924269, lng: 30.3754807 },
      { lat: 50.7957906, lng: 30.3750515 },
      { lat: 50.8064229, lng: 30.3811455 },
      { lat: 50.814504, lng: 30.3826904 },
      { lat: 50.8186795, lng: 30.389986 },
      { lat: 50.8214992, lng: 30.3796864 },
      { lat: 50.8046872, lng: 30.3674984 },
      { lat: 50.8077789, lng: 30.3337669 },
      { lat: 50.8032226, lng: 30.3317928 },
      { lat: 50.7999136, lng: 30.3541088 },
    ],
  },
  {
    name: 'ЯСНОГОРОДКА ВЫШГОРОД',
    price: 258,
    points: [
      { lat: 50.8646404, lng: 30.3810596 },
      { lat: 50.8579225, lng: 30.3862095 },
      { lat: 50.8521791, lng: 30.3824329 },
      { lat: 50.8474104, lng: 30.3837204 },
      { lat: 50.8471936, lng: 30.3914452 },
      { lat: 50.8486026, lng: 30.392046 },
      { lat: 50.848711, lng: 30.3957367 },
      { lat: 50.8373837, lng: 30.3962517 },
      { lat: 50.8353239, lng: 30.4050064 },
      { lat: 50.8403106, lng: 30.4081821 },
      { lat: 50.8475188, lng: 30.4085255 },
      { lat: 50.8546716, lng: 30.4204559 },
      { lat: 50.8575433, lng: 30.4232025 },
      { lat: 50.8631777, lng: 30.4262924 },
      { lat: 50.8647487, lng: 30.4172802 },
      { lat: 50.8587894, lng: 30.4135895 },
      { lat: 50.8608482, lng: 30.3916168 },
      { lat: 50.8664822, lng: 30.3867245 },
      { lat: 50.8646404, lng: 30.3810596 },
    ],
  },
  {
    name: 'ТОЛОКУНЬ',
    price: 259,
    points: [
      { lat: 50.8933971, lng: 30.4150486 },
      { lat: 50.8862502, lng: 30.4284382 },
      { lat: 50.8822431, lng: 30.429554 },
      { lat: 50.8845716, lng: 30.4426861 },
      { lat: 50.8884702, lng: 30.4479218 },
      { lat: 50.896916, lng: 30.4428577 },
      { lat: 50.8963205, lng: 30.4365921 },
      { lat: 50.8931805, lng: 30.4370213 },
      { lat: 50.8925309, lng: 30.4340172 },
      { lat: 50.8981611, lng: 30.4230309 },
      { lat: 50.8933971, lng: 30.4150486 },
    ],
  },
  {
    name: 'СУХОЛУЧЬЕ',
    price: 260,
    points: [
      { lat: 50.9541555, lng: 30.4270649 },
      { lat: 50.9465853, lng: 30.4299831 },
      { lat: 50.9465853, lng: 30.4262066 },
      { lat: 50.9428539, lng: 30.4266357 },
      { lat: 50.9416641, lng: 30.4311848 },
      { lat: 50.9370127, lng: 30.4327297 },
      { lat: 50.9333345, lng: 30.4417419 },
      { lat: 50.9289528, lng: 30.4454327 },
      { lat: 50.9288987, lng: 30.450325 },
      { lat: 50.9327395, lng: 30.4506683 },
      { lat: 50.9389598, lng: 30.4438877 },
      { lat: 50.9454497, lng: 30.4448318 },
      { lat: 50.9501543, lng: 30.4401112 },
      { lat: 50.9551287, lng: 30.4399395 },
      { lat: 50.9541555, lng: 30.4270649 },
    ],
  },
  {
    name: 'ВЫСШАЯ ДУБЕЧНЯ',
    price: 261,
    points: [
      { lat: 50.7421042, lng: 30.630827 },
      { lat: 50.7369983, lng: 30.6295395 },
      { lat: 50.7343909, lng: 30.6184673 },
      { lat: 50.7292299, lng: 30.619669 },
      { lat: 50.7298818, lng: 30.6261921 },
      { lat: 50.7246116, lng: 30.6354618 },
      { lat: 50.7190148, lng: 30.6273079 },
      { lat: 50.7170584, lng: 30.6324577 },
      { lat: 50.7152107, lng: 30.6405258 },
      { lat: 50.7127107, lng: 30.6421566 },
      { lat: 50.7122759, lng: 30.6452465 },
      { lat: 50.7129825, lng: 30.6458473 },
      { lat: 50.7161346, lng: 30.6490231 },
      { lat: 50.7222208, lng: 30.6578636 },
      { lat: 50.7221665, lng: 30.6618118 },
      { lat: 50.7206993, lng: 30.6651592 },
      { lat: 50.7154824, lng: 30.6677341 },
      { lat: 50.714939, lng: 30.6704807 },
      { lat: 50.7189604, lng: 30.6735706 },
      { lat: 50.7220578, lng: 30.6771755 },
      { lat: 50.7328155, lng: 30.6758022 },
      { lat: 50.7324352, lng: 30.6600094 },
      { lat: 50.7344452, lng: 30.6603527 },
      { lat: 50.7365638, lng: 30.6558037 },
      { lat: 50.7409093, lng: 30.6570053 },
      { lat: 50.7450371, lng: 30.6523705 },
      { lat: 50.7443311, lng: 30.6435299 },
      { lat: 50.7457431, lng: 30.6381226 },
      { lat: 50.7421042, lng: 30.630827 },
    ],
  },
  {
    name: 'НИЗШАЯ ДУБЕЧНЯ',
    price: 262,
    points: [
      { lat: 50.6903137, lng: 30.6642151 },
      { lat: 50.7033075, lng: 30.6602669 },
      { lat: 50.7034162, lng: 30.6541729 },
      { lat: 50.6996109, lng: 30.6540012 },
      { lat: 50.6990128, lng: 30.6508255 },
      { lat: 50.6922711, lng: 30.645504 },
      { lat: 50.688193, lng: 30.6330585 },
      { lat: 50.6892806, lng: 30.6303978 },
      { lat: 50.6906399, lng: 30.6309986 },
      { lat: 50.6940654, lng: 30.6177807 },
      { lat: 50.6852566, lng: 30.6113434 },
      { lat: 50.6770988, lng: 30.6116009 },
      { lat: 50.6767724, lng: 30.6170082 },
      { lat: 50.668722, lng: 30.6174374 },
      { lat: 50.6669811, lng: 30.6291962 },
      { lat: 50.6637169, lng: 30.628767 },
      { lat: 50.6636625, lng: 30.6310844 },
      { lat: 50.6594187, lng: 30.6370068 },
      { lat: 50.6688851, lng: 30.6485939 },
      { lat: 50.6729105, lng: 30.646534 },
      { lat: 50.6775883, lng: 30.6481647 },
      { lat: 50.6803621, lng: 30.6599236 },
      { lat: 50.6903137, lng: 30.6642151 },
    ],
  },
  {
    name: 'НОВОСЕЛКИ (ВИШГОРОД)',
    price: 263,
    points: [
      { lat: 50.6027431, lng: 30.5853367 },
      { lat: 50.597949, lng: 30.5970097 },
      { lat: 50.5977311, lng: 30.6031036 },
      { lat: 50.6119487, lng: 30.611515 },
      { lat: 50.6143996, lng: 30.6164932 },
      { lat: 50.6146719, lng: 30.6316853 },
      { lat: 50.6196277, lng: 30.6454182 },
      { lat: 50.6257809, lng: 30.6484222 },
      { lat: 50.6269788, lng: 30.6431007 },
      { lat: 50.6305722, lng: 30.6433582 },
      { lat: 50.6308444, lng: 30.6319427 },
      { lat: 50.6259443, lng: 30.6312561 },
      { lat: 50.6238207, lng: 30.6220722 },
      { lat: 50.6282311, lng: 30.6168365 },
      { lat: 50.6027431, lng: 30.5853367 },
    ],
  },
  {
    name: 'ОСЕЩИНА',
    price: 264,
    points: [
      { lat: 50.5845991, lng: 30.543623 },
      { lat: 50.5787948, lng: 30.5384302 },
      { lat: 50.5745705, lng: 30.5381727 },
      { lat: 50.5681108, lng: 30.543623 },
      { lat: 50.5710273, lng: 30.5555105 },
      { lat: 50.5818196, lng: 30.5503178 },
      { lat: 50.5820104, lng: 30.5457687 },
      { lat: 50.5845173, lng: 30.5462408 },
      { lat: 50.5845991, lng: 30.543623 },
    ],
  },
  {
    name: 'СЕЩИНА САДЫ',
    price: 265,
    points: [
      { lat: 50.5845991, lng: 30.543623 },
      { lat: 50.5855255, lng: 30.5282164 },
      { lat: 50.5799666, lng: 30.5259418 },
      { lat: 50.5766145, lng: 30.5290318 },
      { lat: 50.5728807, lng: 30.5298471 },
      { lat: 50.5700733, lng: 30.5260277 },
      { lat: 50.564349, lng: 30.529933 },
      { lat: 50.5705912, lng: 30.5414343 },
      { lat: 50.5745705, lng: 30.5381727 },
      { lat: 50.5787948, lng: 30.5384302 },
      { lat: 50.5845991, lng: 30.543623 },
    ],
  },
  {
    name: 'СОБОЛЕВКА',
    price: 266,
    points: [
      { lat: 50.7444125, lng: 30.741334 },
      { lat: 50.7384107, lng: 30.7369566 },
      { lat: 50.7352329, lng: 30.7409048 },
      { lat: 50.7321907, lng: 30.7578564 },
      { lat: 50.7293657, lng: 30.7582426 },
      { lat: 50.7244486, lng: 30.77631 },
      { lat: 50.7295558, lng: 30.7812881 },
      { lat: 50.7301806, lng: 30.7718039 },
      { lat: 50.7366996, lng: 30.76262 },
      { lat: 50.7419141, lng: 30.7528782 },
      { lat: 50.7444125, lng: 30.741334 },
    ],
  },
  {
    name: 'ЛЕТОЧКИ',
    price: 267,
    points: [
      { lat: 50.7614362, lng: 30.7582426 },
      { lat: 50.7606219, lng: 30.7545948 },
      { lat: 50.754785, lng: 30.7548094 },
      { lat: 50.7512553, lng: 30.7500029 },
      { lat: 50.7495176, lng: 30.7507324 },
      { lat: 50.7486215, lng: 30.7536077 },
      { lat: 50.750305, lng: 30.7593155 },
      { lat: 50.7500878, lng: 30.7661819 },
      { lat: 50.7519884, lng: 30.7716322 },
      { lat: 50.7509838, lng: 30.7845497 },
      { lat: 50.7608662, lng: 30.7839489 },
      { lat: 50.7629835, lng: 30.7793999 },
      { lat: 50.7603504, lng: 30.769186 },
      { lat: 50.7628207, lng: 30.7668257 },
      { lat: 50.7614362, lng: 30.7582426 },
    ],
  },
  {
    name: 'КАЛИНОВКА (БРОВАРЫ)',
    price: 268,
    points: [
      { lat: 50.5504169, lng: 30.82798 },
      { lat: 50.5533073, lng: 30.83004 },
      { lat: 50.5467628, lng: 30.8454895 },
      { lat: 50.5541253, lng: 30.8537292 },
      { lat: 50.5644308, lng: 30.8372498 },
      { lat: 50.5707547, lng: 30.8333874 },
      { lat: 50.5739164, lng: 30.822916 },
      { lat: 50.5688467, lng: 30.8196545 },
      { lat: 50.5718995, lng: 30.8053207 },
      { lat: 50.5678655, lng: 30.8020592 },
      { lat: 50.5652486, lng: 30.8127022 },
      { lat: 50.5578333, lng: 30.8130455 },
      { lat: 50.5567428, lng: 30.8170795 },
      { lat: 50.5535254, lng: 30.8179379 },
      { lat: 50.5504169, lng: 30.82798 },
    ],
  },
  {
    name: 'ЗАЛЕСЬЕ',
    price: 269,
    points: [
      { lat: 50.625672, lng: 30.8643723 },
      { lat: 50.6223505, lng: 30.8744144 },
      { lat: 50.6381938, lng: 30.8848 },
      { lat: 50.6417863, lng: 30.8772469 },
      { lat: 50.625672, lng: 30.8643723 },
    ],
  },
  {
    name: 'БОГДАНОВКА',
    price: 270,
    points: [
      { lat: 50.6307356, lng: 30.8797359 },
      { lat: 50.6277411, lng: 30.8914948 },
      { lat: 50.6183207, lng: 30.8905506 },
      { lat: 50.6118398, lng: 30.9198189 },
      { lat: 50.6169048, lng: 30.9241104 },
      { lat: 50.613855, lng: 30.9350967 },
      { lat: 50.6169593, lng: 30.9370708 },
      { lat: 50.617286, lng: 30.9514904 },
      { lat: 50.6236029, lng: 30.9520912 },
      { lat: 50.6237662, lng: 30.9475422 },
      { lat: 50.6282855, lng: 30.9487438 },
      { lat: 50.6304089, lng: 30.93647 },
      { lat: 50.6324777, lng: 30.9355259 },
      { lat: 50.6335121, lng: 30.9288311 },
      { lat: 50.6319877, lng: 30.925827 },
      { lat: 50.6353086, lng: 30.9077168 },
      { lat: 50.6409699, lng: 30.8957005 },
      { lat: 50.6385204, lng: 30.892868 },
      { lat: 50.6381938, lng: 30.8848 },
      { lat: 50.6307356, lng: 30.8797359 },
    ],
  },
  {
    name: 'БОГДАНОВКА НОВАЯ',
    price: 271,
    points: [
      { lat: 50.6409699, lng: 30.8957005 },
      { lat: 50.6353086, lng: 30.9077168 },
      { lat: 50.6460318, lng: 30.9169865 },
      { lat: 50.652508, lng: 30.9174156 },
      { lat: 50.6566436, lng: 30.9016228 },
      { lat: 50.6409699, lng: 30.8957005 },
    ],
  },
  {
    name: 'ЗАРЯ',
    price: 272,
    points: [
      { lat: 50.5617865, lng: 30.9353971 },
      { lat: 50.5572608, lng: 30.9382296 },
      { lat: 50.5559248, lng: 30.9335518 },
      { lat: 50.5485354, lng: 30.9382725 },
      { lat: 50.5503351, lng: 30.9453106 },
      { lat: 50.5425084, lng: 30.9516621 },
      { lat: 50.545672, lng: 30.9612322 },
      { lat: 50.5486717, lng: 30.9585714 },
      { lat: 50.5496261, lng: 30.961318 },
      { lat: 50.5589512, lng: 30.9543657 },
      { lat: 50.557588, lng: 30.9503746 },
      { lat: 50.5635857, lng: 30.9440231 },
      { lat: 50.5617865, lng: 30.9353971 },
    ],
  },
  {
    name: 'ГОГОЛЕВ',
    price: 273,
    points: [
      { lat: 50.5180656, lng: 30.968399 },
      { lat: 50.5098787, lng: 30.9837627 },
      { lat: 50.506549, lng: 30.98299 },
      { lat: 50.4985786, lng: 30.996809 },
      { lat: 50.4954665, lng: 31.0133743 },
      { lat: 50.490989, lng: 31.0113144 },
      { lat: 50.4898969, lng: 31.0229015 },
      { lat: 50.5024002, lng: 31.0369778 },
      { lat: 50.5084049, lng: 31.0376644 },
      { lat: 50.5170286, lng: 31.0484791 },
      { lat: 50.5265238, lng: 31.0528564 },
      { lat: 50.5367808, lng: 31.0492516 },
      { lat: 50.5398902, lng: 31.0517406 },
      { lat: 50.5483445, lng: 31.0471916 },
      { lat: 50.550526, lng: 31.0235882 },
      { lat: 50.5385264, lng: 31.0139751 },
      { lat: 50.5361261, lng: 30.9981823 },
      { lat: 50.5281607, lng: 30.996809 },
      { lat: 50.5268512, lng: 31.0142326 },
      { lat: 50.517629, lng: 31.014061 },
      { lat: 50.5162646, lng: 30.9989548 },
      { lat: 50.522595, lng: 30.9965515 },
      { lat: 50.5298521, lng: 30.987711 },
      { lat: 50.5261964, lng: 30.9783554 },
      { lat: 50.5180656, lng: 30.968399 },
    ],
  },
  {
    name: 'ПЕРЕМОЖЕЦ',
    price: 274,
    points: [
      { lat: 50.4924633, lng: 30.9508896 },
      { lat: 50.4840809, lng: 30.9514475 },
      { lat: 50.4833709, lng: 30.9564257 },
      { lat: 50.476134, lng: 30.9493446 },
      { lat: 50.4724195, lng: 30.9589148 },
      { lat: 50.4860743, lng: 30.9681845 },
      { lat: 50.4878218, lng: 30.9611893 },
      { lat: 50.4899515, lng: 30.9620047 },
      { lat: 50.4953573, lng: 30.9575415 },
      { lat: 50.4939649, lng: 30.9510183 },
      { lat: 50.4924633, lng: 30.9508896 },
    ],
  },
  {
    name: 'ТРЕБУХОВ',
    price: 275,
    points: [
      { lat: 50.4604001, lng: 30.8833408 },
      { lat: 50.4601815, lng: 30.8955288 },
      { lat: 50.4638423, lng: 30.8955288 },
      { lat: 50.4662463, lng: 30.8932114 },
      { lat: 50.4687047, lng: 30.8931255 },
      { lat: 50.474823, lng: 30.9022236 },
      { lat: 50.4689779, lng: 30.9046268 },
      { lat: 50.4653721, lng: 30.9084034 },
      { lat: 50.4673936, lng: 30.9181881 },
      { lat: 50.4798482, lng: 30.9270287 },
      { lat: 50.4829613, lng: 30.9255695 },
      { lat: 50.484982, lng: 30.9328651 },
      { lat: 50.4827975, lng: 30.9368992 },
      { lat: 50.4838898, lng: 30.9452248 },
      { lat: 50.4958487, lng: 30.9439373 },
      { lat: 50.4915351, lng: 30.919733 },
      { lat: 50.4923541, lng: 30.9143257 },
      { lat: 50.4981964, lng: 30.9103775 },
      { lat: 50.4978142, lng: 30.9063435 },
      { lat: 50.4901699, lng: 30.9028244 },
      { lat: 50.4909344, lng: 30.8981037 },
      { lat: 50.4970498, lng: 30.8981895 },
      { lat: 50.5006532, lng: 30.8985329 },
      { lat: 50.5049659, lng: 30.8904648 },
      { lat: 50.4978688, lng: 30.8815384 },
      { lat: 50.500817, lng: 30.8758736 },
      { lat: 50.4964492, lng: 30.8698654 },
      { lat: 50.4900607, lng: 30.8657455 },
      { lat: 50.4890232, lng: 30.8708096 },
      { lat: 50.4830159, lng: 30.8704662 },
      { lat: 50.4802851, lng: 30.8605957 },
      { lat: 50.4749869, lng: 30.8633423 },
      { lat: 50.4762979, lng: 30.8719254 },
      { lat: 50.4715455, lng: 30.879221 },
      { lat: 50.4663009, lng: 30.8789635 },
      { lat: 50.466137, lng: 30.8841133 },
      { lat: 50.4604001, lng: 30.8833408 },
    ],
  },
  {
    name: 'ДУДАРКОВ',
    price: 276,
    points: [
      { lat: 50.4571215, lng: 30.9170723 },
      { lat: 50.4589247, lng: 30.9183598 },
      { lat: 50.4594712, lng: 30.9162998 },
      { lat: 50.4519846, lng: 30.9082317 },
      { lat: 50.4489241, lng: 30.9163857 },
      { lat: 50.4343292, lng: 30.9084034 },
      { lat: 50.4293539, lng: 30.9253979 },
      { lat: 50.4259092, lng: 30.9347534 },
      { lat: 50.4325251, lng: 30.9417057 },
      { lat: 50.4321423, lng: 30.9453964 },
      { lat: 50.436024, lng: 30.949173 },
      { lat: 50.432361, lng: 30.9567261 },
      { lat: 50.4332905, lng: 30.9681416 },
      { lat: 50.4400693, lng: 30.9774113 },
      { lat: 50.4421465, lng: 30.9690857 },
      { lat: 50.4487602, lng: 30.9705448 },
      { lat: 50.449252, lng: 30.9729481 },
      { lat: 50.452859, lng: 30.9733772 },
      { lat: 50.4590887, lng: 30.9584427 },
      { lat: 50.4632959, lng: 30.9532928 },
      { lat: 50.4600176, lng: 30.9457397 },
      { lat: 50.4622032, lng: 30.9340668 },
      { lat: 50.4640609, lng: 30.9333801 },
      { lat: 50.4614929, lng: 30.9205914 },
      { lat: 50.4568483, lng: 30.9211922 },
      { lat: 50.4571215, lng: 30.9170723 },
    ],
  },
  {
    name: 'ПЕТРОПАВЛОВСКОЕ',
    price: 277,
    points: [
      { lat: 50.3309432, lng: 30.7969952 },
      { lat: 50.3225596, lng: 30.8008575 },
      { lat: 50.3216828, lng: 30.7961369 },
      { lat: 50.3090769, lng: 30.8213711 },
      { lat: 50.3187234, lng: 30.824976 },
      { lat: 50.3191619, lng: 30.8399105 },
      { lat: 50.3336279, lng: 30.8405972 },
      { lat: 50.3309432, lng: 30.7979393 },
      { lat: 50.3309432, lng: 30.7969952 },
    ],
  },
  {
    name: 'ГЛУБОКОЕ',
    price: 278,
    points: [
      { lat: 50.2530225, lng: 30.9223938 },
      { lat: 50.2486868, lng: 30.941534 },
      { lat: 50.2476989, lng: 30.9540653 },
      { lat: 50.2571384, lng: 30.9611034 },
      { lat: 50.2629548, lng: 30.9566402 },
      { lat: 50.2636132, lng: 30.9632492 },
      { lat: 50.2694837, lng: 30.9616184 },
      { lat: 50.2751889, lng: 30.9475422 },
      { lat: 50.2746404, lng: 30.9240246 },
      { lat: 50.2598272, lng: 30.9226513 },
      { lat: 50.2530225, lng: 30.9223938 },
    ],
  },
  {
    name: 'ВОРОНКОВ',
    price: 279,
    points: [
      { lat: 50.2454485, lng: 30.8801651 },
      { lat: 50.2413866, lng: 30.8796501 },
      { lat: 50.2320538, lng: 30.8631706 },
      { lat: 50.2203028, lng: 30.8815384 },
      { lat: 50.216788, lng: 30.8815384 },
      { lat: 50.2109659, lng: 30.8873749 },
      { lat: 50.2047037, lng: 30.8796501 },
      { lat: 50.1949241, lng: 30.8763885 },
      { lat: 50.1855822, lng: 30.9031677 },
      { lat: 50.2034951, lng: 30.9186172 },
      { lat: 50.2007481, lng: 30.9289169 },
      { lat: 50.206901, lng: 30.9362984 },
      { lat: 50.2118448, lng: 30.9246254 },
      { lat: 50.2151403, lng: 30.9244537 },
      { lat: 50.2159092, lng: 30.9294319 },
      { lat: 50.2231585, lng: 30.9292603 },
      { lat: 50.2421551, lng: 30.8990479 },
      { lat: 50.2416061, lng: 30.8925247 },
      { lat: 50.2459974, lng: 30.8914948 },
      { lat: 50.2454485, lng: 30.8801651 },
    ],
  },
  {
    name: 'ВИШЕНКИ ДАЧИ',
    price: 280,
    points: [
      { lat: 50.332039, lng: 30.6225872 },
      { lat: 50.3202579, lng: 30.6281662 },
      { lat: 50.3070487, lng: 30.6424999 },
      { lat: 50.2886264, lng: 30.6567478 },
      { lat: 50.281771, lng: 30.6689358 },
      { lat: 50.2863231, lng: 30.6950283 },
      { lat: 50.2961935, lng: 30.6917667 },
      { lat: 50.3005797, lng: 30.6869602 },
      { lat: 50.2982222, lng: 30.6709957 },
      { lat: 50.3055686, lng: 30.6743431 },
      { lat: 50.3215184, lng: 30.661211 },
      { lat: 50.3233268, lng: 30.6504822 },
      { lat: 50.3261762, lng: 30.6494522 },
      { lat: 50.3358193, lng: 30.6311703 },
      { lat: 50.332039, lng: 30.6225872 },
    ],
  },
  {
    name: 'КАЛИНОВКА',
    price: 281,
    points: [
      { lat: 50.213163, lng: 30.2283669 },
      { lat: 50.2189299, lng: 30.2382374 },
      { lat: 50.2172823, lng: 30.2413273 },
      { lat: 50.2227191, lng: 30.250597 },
      { lat: 50.2253001, lng: 30.2483654 },
      { lat: 50.2267827, lng: 30.251627 },
      { lat: 50.2291438, lng: 30.250082 },
      { lat: 50.2297477, lng: 30.2458763 },
      { lat: 50.2316145, lng: 30.2476788 },
      { lat: 50.2285398, lng: 30.2654457 },
      { lat: 50.2326028, lng: 30.2703381 },
      { lat: 50.2349087, lng: 30.2626991 },
      { lat: 50.2389163, lng: 30.2669048 },
      { lat: 50.2403985, lng: 30.2634716 },
      { lat: 50.233042, lng: 30.2439022 },
      { lat: 50.2339754, lng: 30.2369499 },
      { lat: 50.2324381, lng: 30.2318001 },
      { lat: 50.2339754, lng: 30.228796 },
      { lat: 50.2290888, lng: 30.2195263 },
      { lat: 50.2277161, lng: 30.2200413 },
      { lat: 50.2244215, lng: 30.2093124 },
      { lat: 50.2222798, lng: 30.2101707 },
      { lat: 50.2198086, lng: 30.2025318 },
      { lat: 50.217447, lng: 30.2043343 },
      { lat: 50.2232683, lng: 30.2187538 },
      { lat: 50.2217307, lng: 30.2187538 },
      { lat: 50.2188749, lng: 30.2200413 },
      { lat: 50.2157994, lng: 30.2254486 },
      { lat: 50.213163, lng: 30.2283669 },
    ],
  },
  {
    name: 'НОВОСЕЛКИ (МАКАРОВ)',
    price: 282,
    points: [
      { lat: 50.2963854, lng: 30.0510406 },
      { lat: 50.294247, lng: 30.0453758 },
      { lat: 50.2915053, lng: 30.0461054 },
      { lat: 50.2929036, lng: 30.055418 },
      { lat: 50.3018407, lng: 30.0674772 },
      { lat: 50.3022519, lng: 30.076232 },
      { lat: 50.3040062, lng: 30.0831413 },
      { lat: 50.3033483, lng: 30.0864887 },
      { lat: 50.30513, lng: 30.0940847 },
      { lat: 50.3101458, lng: 30.0878191 },
      { lat: 50.3172437, lng: 30.0955009 },
      { lat: 50.3251077, lng: 30.0753736 },
      { lat: 50.3167778, lng: 30.065074 },
      { lat: 50.3128864, lng: 30.0627995 },
      { lat: 50.3159557, lng: 30.0591087 },
      { lat: 50.3149144, lng: 30.0516415 },
      { lat: 50.3105569, lng: 30.0467062 },
      { lat: 50.3049382, lng: 30.0603533 },
      { lat: 50.3009909, lng: 30.0542164 },
      { lat: 50.2985786, lng: 30.0552464 },
      { lat: 50.2963854, lng: 30.0510406 },
    ],
  },
  {
    name: 'ЯСНОГОРОДКА МАКАРОВ',
    price: 283,
    points: [
      { lat: 50.3736056, lng: 30.0170517 },
      { lat: 50.3679122, lng: 30.0158501 },
      { lat: 50.3668173, lng: 30.0112152 },
      { lat: 50.3600827, lng: 30.0085545 },
      { lat: 50.3618349, lng: 30.0052071 },
      { lat: 50.3555924, lng: 29.9983406 },
      { lat: 50.3502802, lng: 30.0094128 },
      { lat: 50.3523066, lng: 30.0131035 },
      { lat: 50.3476513, lng: 30.01791 },
      { lat: 50.3392706, lng: 30.0177383 },
      { lat: 50.3390515, lng: 30.0248623 },
      { lat: 50.3434885, lng: 30.0367069 },
      { lat: 50.3540043, lng: 30.0333595 },
      { lat: 50.3624919, lng: 30.0297546 },
      { lat: 50.3680765, lng: 30.0363636 },
      { lat: 50.3727845, lng: 30.0378227 },
      { lat: 50.3730582, lng: 30.0324154 },
      { lat: 50.3707043, lng: 30.0312996 },
      { lat: 50.370978, lng: 30.0259781 },
      { lat: 50.3732772, lng: 30.0248623 },
      { lat: 50.3736056, lng: 30.0170517 },
    ],
  },
  {
    name: 'МУЗЫЧИ',
    price: 284,
    points: [
      { lat: 50.3513756, lng: 30.0715542 },
      { lat: 50.3458986, lng: 30.0945568 },
      { lat: 50.3500612, lng: 30.1124096 },
      { lat: 50.3475965, lng: 30.116272 },
      { lat: 50.3483633, lng: 30.1206493 },
      { lat: 50.3424478, lng: 30.1350689 },
      { lat: 50.3463368, lng: 30.1406479 },
      { lat: 50.3494039, lng: 30.1407337 },
      { lat: 50.3497873, lng: 30.1462269 },
      { lat: 50.3560305, lng: 30.1409912 },
      { lat: 50.3586042, lng: 30.1469994 },
      { lat: 50.3617801, lng: 30.1441669 },
      { lat: 50.3612873, lng: 30.136528 },
      { lat: 50.36512, lng: 30.1286316 },
      { lat: 50.363806, lng: 30.1166153 },
      { lat: 50.3678575, lng: 30.1154995 },
      { lat: 50.3682955, lng: 30.1108646 },
      { lat: 50.3737698, lng: 30.1100922 },
      { lat: 50.3737151, lng: 30.103569 },
      { lat: 50.3701021, lng: 30.1033974 },
      { lat: 50.3687334, lng: 30.0977325 },
      { lat: 50.3729487, lng: 30.0915527 },
      { lat: 50.3687882, lng: 30.0851154 },
      { lat: 50.3630942, lng: 30.0900936 },
      { lat: 50.3590423, lng: 30.0821972 },
      { lat: 50.3595899, lng: 30.0742149 },
      { lat: 50.3513756, lng: 30.0715542 },
    ],
  },
  {
    name: 'ЛУКА',
    price: 285,
    points: [
      { lat: 50.3606302, lng: 30.1636505 },
      { lat: 50.3635322, lng: 30.1669979 },
      { lat: 50.3652843, lng: 30.1749802 },
      { lat: 50.3636965, lng: 30.1803875 },
      { lat: 50.3668173, lng: 30.1835632 },
      { lat: 50.3701021, lng: 30.1792717 },
      { lat: 50.3782584, lng: 30.1777267 },
      { lat: 50.3793531, lng: 30.1687145 },
      { lat: 50.3719086, lng: 30.1652813 },
      { lat: 50.3744815, lng: 30.1600456 },
      { lat: 50.3689524, lng: 30.1534367 },
      { lat: 50.3630942, lng: 30.1566124 },
      { lat: 50.3606302, lng: 30.1636505 },
    ],
  },
  {
    name: 'ГОРБОВИЧИ',
    price: 286,
    points: [
      { lat: 50.4087363, lng: 30.174036 },
      { lat: 50.4102132, lng: 30.1613331 },
      { lat: 50.4080799, lng: 30.1544666 },
      { lat: 50.4111978, lng: 30.1445103 },
      { lat: 50.4074235, lng: 30.1406479 },
      { lat: 50.4050166, lng: 30.1500034 },
      { lat: 50.4036489, lng: 30.1490593 },
      { lat: 50.4025001, lng: 30.1530075 },
      { lat: 50.4004211, lng: 30.1514626 },
      { lat: 50.3996552, lng: 30.1530075 },
      { lat: 50.3969743, lng: 30.1511192 },
      { lat: 50.3976856, lng: 30.1467419 },
      { lat: 50.3961536, lng: 30.1451111 },
      { lat: 50.3976856, lng: 30.1393604 },
      { lat: 50.3932536, lng: 30.1352406 },
      { lat: 50.3913931, lng: 30.1442528 },
      { lat: 50.3875078, lng: 30.1438236 },
      { lat: 50.3851546, lng: 30.1650238 },
      { lat: 50.3812687, lng: 30.167942 },
      { lat: 50.3834033, lng: 30.1896572 },
      { lat: 50.3860302, lng: 30.1881981 },
      { lat: 50.3891495, lng: 30.1787567 },
      { lat: 50.3869058, lng: 30.1762676 },
      { lat: 50.3878909, lng: 30.1725769 },
      { lat: 50.3931441, lng: 30.1749802 },
      { lat: 50.3945121, lng: 30.1704311 },
      { lat: 50.3905723, lng: 30.1676846 },
      { lat: 50.391995, lng: 30.1600456 },
      { lat: 50.3965913, lng: 30.159359 },
      { lat: 50.4002023, lng: 30.1618481 },
      { lat: 50.3997646, lng: 30.1686287 },
      { lat: 50.4003664, lng: 30.1724052 },
      { lat: 50.4087363, lng: 30.174036 },
    ],
  },
  {
    name: 'ГНАТОВКА',
    price: 287,
    points: [
      { lat: 50.3996825, lng: 30.2023602 },
      { lat: 50.3859481, lng: 30.2006865 },
      { lat: 50.3871521, lng: 30.2058363 },
      { lat: 50.3851819, lng: 30.2068663 },
      { lat: 50.3889854, lng: 30.217638 },
      { lat: 50.3965913, lng: 30.2203846 },
      { lat: 50.3993543, lng: 30.2140331 },
      { lat: 50.3996825, lng: 30.2023602 },
    ],
  },
  {
    name: 'ШПИТЬКИ',
    price: 288,
    points: [
      { lat: 50.4276589, lng: 30.1153278 },
      { lat: 50.4164485, lng: 30.1060581 },
      { lat: 50.4150265, lng: 30.1104355 },
      { lat: 50.409885, lng: 30.1065731 },
      { lat: 50.408791, lng: 30.1115513 },
      { lat: 50.4060012, lng: 30.1278591 },
      { lat: 50.4089004, lng: 30.1297474 },
      { lat: 50.4083534, lng: 30.1336098 },
      { lat: 50.4124558, lng: 30.1362705 },
      { lat: 50.4136044, lng: 30.1403046 },
      { lat: 50.4130028, lng: 30.1447678 },
      { lat: 50.4128387, lng: 30.1494026 },
      { lat: 50.4172688, lng: 30.1482868 },
      { lat: 50.4177064, lng: 30.1513767 },
      { lat: 50.4181439, lng: 30.1540804 },
      { lat: 50.4183626, lng: 30.1550245 },
      { lat: 50.4238313, lng: 30.1500463 },
      { lat: 50.4247062, lng: 30.1343822 },
      { lat: 50.4274402, lng: 30.1229668 },
      { lat: 50.4260185, lng: 30.1219368 },
      { lat: 50.4276589, lng: 30.1153278 },
    ],
  },
  {
    name: 'ЛЫЧАНКА',
    price: 289,
    points: [
      { lat: 50.4142608, lng: 30.0898361 },
      { lat: 50.4025001, lng: 30.0798798 },
      { lat: 50.3964271, lng: 30.0942993 },
      { lat: 50.4001476, lng: 30.0982475 },
      { lat: 50.3987251, lng: 30.1036549 },
      { lat: 50.4004758, lng: 30.1077747 },
      { lat: 50.4035395, lng: 30.1060581 },
      { lat: 50.4092833, lng: 30.1093197 },
      { lat: 50.409885, lng: 30.1065731 },
      { lat: 50.4142608, lng: 30.0898361 },
    ],
  },
  {
    name: 'НЕГРАШИ',
    price: 290,
    points: [
      { lat: 50.3687882, lng: 30.0851154 },
      { lat: 50.3729487, lng: 30.0915527 },
      { lat: 50.3791889, lng: 30.0835705 },
      { lat: 50.3773826, lng: 30.0813389 },
      { lat: 50.3794078, lng: 30.0783348 },
      { lat: 50.3865775, lng: 30.0819397 },
      { lat: 50.3885476, lng: 30.0755024 },
      { lat: 50.3857018, lng: 30.0730991 },
      { lat: 50.3848262, lng: 30.0691509 },
      { lat: 50.3883287, lng: 30.0623703 },
      { lat: 50.3849357, lng: 30.0578213 },
      { lat: 50.3801741, lng: 30.0662327 },
      { lat: 50.3782584, lng: 30.0631428 },
      { lat: 50.3702663, lng: 30.0730991 },
      { lat: 50.3719086, lng: 30.0771332 },
      { lat: 50.3687882, lng: 30.0851154 },
    ],
  },
  {
    name: 'БЕРЕЗОВКА',
    price: 291,
    points: [
      { lat: 50.4286431, lng: 29.9767971 },
      { lat: 50.4208236, lng: 29.9779987 },
      { lat: 50.4208236, lng: 29.9833202 },
      { lat: 50.4227376, lng: 29.9851227 },
      { lat: 50.4243781, lng: 30.0068378 },
      { lat: 50.4216986, lng: 30.0145626 },
      { lat: 50.4247062, lng: 30.0180817 },
      { lat: 50.4305021, lng: 30.0171375 },
      { lat: 50.4364613, lng: 30.022974 },
      { lat: 50.4351493, lng: 30.0025463 },
      { lat: 50.4332358, lng: 30.0029755 },
      { lat: 50.4319237, lng: 29.9852943 },
      { lat: 50.4294086, lng: 29.9832344 },
      { lat: 50.4286431, lng: 29.9767971 },
    ],
  },
  {
    name: 'БУЗОВАЯ',
    price: 292,
    points: [
      { lat: 50.4282604, lng: 30.0259781 },
      { lat: 50.4256358, lng: 30.0282955 },
      { lat: 50.4209877, lng: 30.0253773 },
      { lat: 50.4200033, lng: 30.029068 },
      { lat: 50.4171595, lng: 30.0269222 },
      { lat: 50.4157922, lng: 30.0309563 },
      { lat: 50.4091192, lng: 30.0321579 },
      { lat: 50.4046883, lng: 30.0306988 },
      { lat: 50.4043054, lng: 30.0347328 },
      { lat: 50.4025001, lng: 30.0347328 },
      { lat: 50.4021718, lng: 30.039196 },
      { lat: 50.4001476, lng: 30.040741 },
      { lat: 50.399874, lng: 30.0456333 },
      { lat: 50.4021718, lng: 30.0455475 },
      { lat: 50.4009682, lng: 30.0515556 },
      { lat: 50.4049071, lng: 30.0540447 },
      { lat: 50.4075329, lng: 30.0473499 },
      { lat: 50.4104867, lng: 30.0496674 },
      { lat: 50.409885, lng: 30.0525856 },
      { lat: 50.4169407, lng: 30.0576496 },
      { lat: 50.4192377, lng: 30.0539589 },
      { lat: 50.4230657, lng: 30.0573921 },
      { lat: 50.4250889, lng: 30.0500107 },
      { lat: 50.4284244, lng: 30.04426 },
      { lat: 50.4304474, lng: 30.0441742 },
      { lat: 50.4305021, lng: 30.0427151 },
      { lat: 50.4325797, lng: 30.0423717 },
      { lat: 50.4316503, lng: 30.0321579 },
      { lat: 50.4327437, lng: 30.0320721 },
      { lat: 50.4332905, lng: 30.0257206 },
      { lat: 50.4301194, lng: 30.0241756 },
      { lat: 50.4282604, lng: 30.0259781 },
    ],
  },
  {
    name: 'ЛЮБИМОВКА',
    price: 293,
    points: [
      { lat: 50.4245968, lng: 30.0517273 },
      { lat: 50.428151, lng: 30.0538731 },
      { lat: 50.4308302, lng: 30.0534439 },
      { lat: 50.4318143, lng: 30.0667477 },
      { lat: 50.4311035, lng: 30.0682926 },
      { lat: 50.4312129, lng: 30.0779915 },
      { lat: 50.4331811, lng: 30.0801373 },
      { lat: 50.4335092, lng: 30.0854588 },
      { lat: 50.4362427, lng: 30.0848579 },
      { lat: 50.436188, lng: 30.081768 },
      { lat: 50.4390854, lng: 30.0771332 },
      { lat: 50.4416546, lng: 30.065546 },
      { lat: 50.436024, lng: 30.062542 },
      { lat: 50.4356413, lng: 30.049324 },
      { lat: 50.4365707, lng: 30.0485516 },
      { lat: 50.436516, lng: 30.0430584 },
      { lat: 50.4325797, lng: 30.0423717 },
      { lat: 50.4305021, lng: 30.0427151 },
      { lat: 50.4304474, lng: 30.0441742 },
      { lat: 50.4284244, lng: 30.04426 },
      { lat: 50.4250889, lng: 30.0500107 },
      { lat: 50.4245968, lng: 30.0517273 },
    ],
  },
  {
    name: 'ГУРОВЩИНА',
    price: 294,
    points: [
      { lat: 50.4335092, lng: 30.0854588 },
      { lat: 50.4308848, lng: 30.0925827 },
      { lat: 50.4289165, lng: 30.0933552 },
      { lat: 50.4268934, lng: 30.0997066 },
      { lat: 50.4374454, lng: 30.1059723 },
      { lat: 50.437172, lng: 30.1029682 },
      { lat: 50.44078, lng: 30.1009941 },
      { lat: 50.440452, lng: 30.0984192 },
      { lat: 50.4383747, lng: 30.0971317 },
      { lat: 50.4376094, lng: 30.0849438 },
      { lat: 50.4362427, lng: 30.0848579 },
      { lat: 50.4335092, lng: 30.0854588 },
    ],
  },
  {
    name: 'ЛЕСНОЕ',
    price: 295,
    points: [
      { lat: 50.4486508, lng: 30.1009083 },
      { lat: 50.4482683, lng: 30.1009083 },
      { lat: 50.443677, lng: 30.1011658 },
      { lat: 50.4420919, lng: 30.1118946 },
      { lat: 50.4434037, lng: 30.1149845 },
      { lat: 50.4423105, lng: 30.1177311 },
      { lat: 50.4442783, lng: 30.1221943 },
      { lat: 50.451766, lng: 30.1127529 },
      { lat: 50.4514381, lng: 30.1011658 },
      { lat: 50.4486508, lng: 30.1009083 },
    ],
  },
  {
    name: 'МРИЯ',
    price: 296,
    points: [
      { lat: 50.4276589, lng: 30.1153278 },
      { lat: 50.4260185, lng: 30.1219368 },
      { lat: 50.4274402, lng: 30.1229668 },
      { lat: 50.4270301, lng: 30.1242542 },
      { lat: 50.4371994, lng: 30.1293612 },
      { lat: 50.438648, lng: 30.1470423 },
      { lat: 50.4405613, lng: 30.1467848 },
      { lat: 50.4403973, lng: 30.1444244 },
      { lat: 50.4427205, lng: 30.1424503 },
      { lat: 50.4425018, lng: 30.1381588 },
      { lat: 50.4398233, lng: 30.1379442 },
      { lat: 50.4380467, lng: 30.11168 },
      { lat: 50.4360787, lng: 30.1122808 },
      { lat: 50.4345206, lng: 30.1201344 },
      { lat: 50.4276589, lng: 30.1153278 },
    ],
  },
  {
    name: 'ДМИТРОВКА',
    price: 297,
    points: [
      { lat: 50.4475304, lng: 30.1704311 },
      { lat: 50.447831, lng: 30.1741648 },
      { lat: 50.4443329, lng: 30.1750231 },
      { lat: 50.4448522, lng: 30.1825333 },
      { lat: 50.4429118, lng: 30.1828766 },
      { lat: 50.4432124, lng: 30.1869965 },
      { lat: 50.4477217, lng: 30.1887131 },
      { lat: 50.4473664, lng: 30.1939917 },
      { lat: 50.4537607, lng: 30.1980257 },
      { lat: 50.4535695, lng: 30.1909447 },
      { lat: 50.4580778, lng: 30.1912451 },
      { lat: 50.4585969, lng: 30.1829195 },
      { lat: 50.4608645, lng: 30.1833916 },
      { lat: 50.4604274, lng: 30.1983261 },
      { lat: 50.4635145, lng: 30.1984549 },
      { lat: 50.4639243, lng: 30.1818037 },
      { lat: 50.4621212, lng: 30.1797867 },
      { lat: 50.4628315, lng: 30.1613331 },
      { lat: 50.4615475, lng: 30.1605606 },
      { lat: 50.4635691, lng: 30.1518917 },
      { lat: 50.460482, lng: 30.149703 },
      { lat: 50.4581051, lng: 30.1498318 },
      { lat: 50.4580505, lng: 30.154767 },
      { lat: 50.4567936, lng: 30.1549816 },
      { lat: 50.4562745, lng: 30.1590157 },
      { lat: 50.454881, lng: 30.1602602 },
      { lat: 50.4549903, lng: 30.1684999 },
      { lat: 50.4475304, lng: 30.1704311 },
    ],
  },
  {
    name: 'БУЧА',
    price: 298,
    points: [
      { lat: 50.4416546, lng: 30.065546 },
      { lat: 50.4390854, lng: 30.0771332 },
      { lat: 50.4437317, lng: 30.0835705 },
      { lat: 50.4440597, lng: 30.0878191 },
      { lat: 50.4476671, lng: 30.0874758 },
      { lat: 50.449498, lng: 30.0936127 },
      { lat: 50.4527224, lng: 30.0928831 },
      { lat: 50.4513835, lng: 30.0855875 },
      { lat: 50.4543346, lng: 30.0837421 },
      { lat: 50.4507003, lng: 30.0701809 },
      { lat: 50.4473391, lng: 30.0693226 },
      { lat: 50.4492247, lng: 30.0600529 },
      { lat: 50.4481316, lng: 30.0587654 },
      { lat: 50.4445243, lng: 30.0606537 },
      { lat: 50.4432398, lng: 30.0652885 },
      { lat: 50.4416546, lng: 30.065546 },
    ],
  },
  {
    name: 'ДМИТРОВКА 2',
    price: 299,
    points: [
      { lat: 50.4788924, lng: 30.1687145 },
      { lat: 50.4725561, lng: 30.1739931 },
      { lat: 50.4730477, lng: 30.1788855 },
      { lat: 50.4697154, lng: 30.1805162 },
      { lat: 50.4715728, lng: 30.1879835 },
      { lat: 50.4702617, lng: 30.1927471 },
      { lat: 50.4729658, lng: 30.1957512 },
      { lat: 50.475697, lng: 30.209012 },
      { lat: 50.4853097, lng: 30.1995277 },
      { lat: 50.4835075, lng: 30.1929188 },
      { lat: 50.4826063, lng: 30.1920176 },
      { lat: 50.4820329, lng: 30.1863527 },
      { lat: 50.4840809, lng: 30.1856661 },
      { lat: 50.4827702, lng: 30.1758385 },
      { lat: 50.4788924, lng: 30.1687145 },
    ],
  },
  {
    name: 'ЗАБУЧЬЕ',
    price: 300,
    points: [
      { lat: 50.4916716, lng: 30.15481 },
      { lat: 50.4845451, lng: 30.1646805 },
      { lat: 50.4904976, lng: 30.1745081 },
      { lat: 50.490989, lng: 30.1791 },
      { lat: 50.4930913, lng: 30.1787996 },
      { lat: 50.4951388, lng: 30.1768255 },
      { lat: 50.4980053, lng: 30.1805162 },
      { lat: 50.4982237, lng: 30.1847219 },
      { lat: 50.4975412, lng: 30.1869965 },
      { lat: 50.4980326, lng: 30.1893997 },
      { lat: 50.5010081, lng: 30.1880693 },
      { lat: 50.503765, lng: 30.1838636 },
      { lat: 50.5005167, lng: 30.1675558 },
      { lat: 50.4916716, lng: 30.15481 },
    ],
  },
  {
    name: 'МИХАЙЛОВКА-РУБЕЖОВКА',
    price: 301,
    points: [
      { lat: 50.5332893, lng: 30.1430511 },
      { lat: 50.5359625, lng: 30.1379013 },
      { lat: 50.5354169, lng: 30.1371288 },
      { lat: 50.5358534, lng: 30.1353264 },
      { lat: 50.5326346, lng: 30.1302624 },
      { lat: 50.5283244, lng: 30.122366 },
      { lat: 50.5293611, lng: 30.1161861 },
      { lat: 50.524996, lng: 30.1118088 },
      { lat: 50.5153913, lng: 30.1182461 },
      { lat: 50.5116799, lng: 30.1276016 },
      { lat: 50.5128807, lng: 30.1383305 },
      { lat: 50.5086779, lng: 30.1409912 },
      { lat: 50.5005986, lng: 30.1338673 },
      { lat: 50.4977596, lng: 30.1402187 },
      { lat: 50.486948, lng: 30.1317215 },
      { lat: 50.4932278, lng: 30.1130962 },
      { lat: 50.4838898, lng: 30.1047707 },
      { lat: 50.4812136, lng: 30.1069164 },
      { lat: 50.476571, lng: 30.0936985 },
      { lat: 50.4629681, lng: 30.1063156 },
      { lat: 50.466137, lng: 30.1197052 },
      { lat: 50.4635691, lng: 30.1227093 },
      { lat: 50.4673389, lng: 30.1300907 },
      { lat: 50.4711085, lng: 30.1279449 },
      { lat: 50.4720918, lng: 30.1257133 },
      { lat: 50.4759155, lng: 30.1245117 },
      { lat: 50.4779911, lng: 30.1328373 },
      { lat: 50.4955211, lng: 30.1490593 },
      { lat: 50.5041471, lng: 30.1559258 },
      { lat: 50.5132628, lng: 30.1482868 },
      { lat: 50.5150093, lng: 30.1510334 },
      { lat: 50.5245049, lng: 30.1523209 },
      { lat: 50.5284881, lng: 30.1463127 },
      { lat: 50.5316526, lng: 30.1422787 },
      { lat: 50.5332893, lng: 30.1430511 },
    ],
  },
  {
    name: 'МИРОЦКОЕ',
    price: 302,
    points: [
      { lat: 50.5737257, lng: 30.0987196 },
      { lat: 50.5739709, lng: 30.1025391 },
      { lat: 50.5781952, lng: 30.1028395 },
      { lat: 50.5781136, lng: 30.1124526 },
      { lat: 50.5767508, lng: 30.1140404 },
      { lat: 50.5734258, lng: 30.1129246 },
      { lat: 50.5701551, lng: 30.136528 },
      { lat: 50.5759877, lng: 30.1403046 },
      { lat: 50.5766963, lng: 30.1366997 },
      { lat: 50.5775684, lng: 30.136528 },
      { lat: 50.5795851, lng: 30.1418495 },
      { lat: 50.5822011, lng: 30.1392746 },
      { lat: 50.5822011, lng: 30.1307774 },
      { lat: 50.585798, lng: 30.1303482 },
      { lat: 50.5883047, lng: 30.1270866 },
      { lat: 50.5888497, lng: 30.119791 },
      { lat: 50.5913562, lng: 30.1190186 },
      { lat: 50.5913562, lng: 30.1082039 },
      { lat: 50.5880868, lng: 30.1080322 },
      { lat: 50.5878688, lng: 30.1110363 },
      { lat: 50.5832911, lng: 30.1108646 },
      { lat: 50.5859615, lng: 30.1051998 },
      { lat: 50.5862884, lng: 30.0998783 },
      { lat: 50.5827733, lng: 30.0991488 },
      { lat: 50.5784678, lng: 30.0991488 },
      { lat: 50.5737257, lng: 30.0987196 },
    ],
  },
  {
    name: 'БАБИНЦЫ',
    price: 303,
    points: [
      { lat: 50.6344376, lng: 29.9949074 },
      { lat: 50.6221327, lng: 30.0094986 },
      { lat: 50.6223505, lng: 30.0165367 },
      { lat: 50.6178851, lng: 30.0216866 },
      { lat: 50.6191921, lng: 30.0266647 },
      { lat: 50.6164691, lng: 30.0316429 },
      { lat: 50.6181029, lng: 30.0371361 },
      { lat: 50.6256176, lng: 30.028553 },
      { lat: 50.6287755, lng: 30.0326729 },
      { lat: 50.6248553, lng: 30.0426292 },
      { lat: 50.6343287, lng: 30.0479507 },
      { lat: 50.637595, lng: 30.0349045 },
      { lat: 50.6428205, lng: 30.0352478 },
      { lat: 50.6470659, lng: 30.0467491 },
      { lat: 50.6459773, lng: 30.0515556 },
      { lat: 50.6500047, lng: 30.0577354 },
      { lat: 50.6565348, lng: 30.0476074 },
      { lat: 50.6464127, lng: 30.0302696 },
      { lat: 50.6485897, lng: 30.0204849 },
      { lat: 50.6549024, lng: 30.0163651 },
      { lat: 50.6636081, lng: 30.0196266 },
      { lat: 50.6693747, lng: 30.0240898 },
      { lat: 50.6703539, lng: 30.0283813 },
      { lat: 50.6741616, lng: 30.0304413 },
      { lat: 50.6740528, lng: 30.0228882 },
      { lat: 50.676011, lng: 30.0209999 },
      { lat: 50.6774251, lng: 30.0167084 },
      { lat: 50.672421, lng: 30.0129318 },
      { lat: 50.6708979, lng: 30.0149918 },
      { lat: 50.6657843, lng: 30.0101852 },
      { lat: 50.6562083, lng: 30.0060654 },
      { lat: 50.6568613, lng: 30.0028038 },
      { lat: 50.6526169, lng: 30.0005722 },
      { lat: 50.649787, lng: 30.0079536 },
      { lat: 50.6446711, lng: 30.0117302 },
      { lat: 50.6418408, lng: 30.0070953 },
      { lat: 50.6424939, lng: 30.0021172 },
      { lat: 50.6344376, lng: 29.9949074 },
    ],
  },
  {
    name: 'БОРОДЯНКА',
    price: 304,
    points: [
      { lat: 50.6260532, lng: 29.9066734 },
      { lat: 50.6346553, lng: 29.9267578 },
      { lat: 50.6496782, lng: 29.9622917 },
      { lat: 50.6636081, lng: 29.9760246 },
      { lat: 50.6705715, lng: 29.9638367 },
      { lat: 50.6603436, lng: 29.9574852 },
      { lat: 50.66252, lng: 29.9480438 },
      { lat: 50.6673075, lng: 29.9483871 },
      { lat: 50.6736177, lng: 29.908905 },
      { lat: 50.6592554, lng: 29.9013519 },
      { lat: 50.6597995, lng: 29.8817825 },
      { lat: 50.6284489, lng: 29.8879623 },
      { lat: 50.6292111, lng: 29.9013519 },
      { lat: 50.6260532, lng: 29.9066734 },
    ],
  },
  {
    name: 'РАКОВКА',
    price: 305,
    points: [
      { lat: 50.6603436, lng: 30.2552319 },
      { lat: 50.6589018, lng: 30.2533436 },
      { lat: 50.659201, lng: 30.250082 },
      { lat: 50.6513924, lng: 30.248065 },
      { lat: 50.6501135, lng: 30.2537298 },
      { lat: 50.6419224, lng: 30.2466488 },
      { lat: 50.6410515, lng: 30.2605534 },
      { lat: 50.6598267, lng: 30.2641582 },
      { lat: 50.6603436, lng: 30.2552319 },
    ],
  },
  {
    name: 'ТРОЕЩИНА 3',
    price: 306,
    points: [
      { lat: 50.5345441, lng: 30.6100559 },
      { lat: 50.5351715, lng: 30.6000996 },
      { lat: 50.5347213, lng: 30.5929756 },
      { lat: 50.5295247, lng: 30.5906582 },
      { lat: 50.5242047, lng: 30.5999708 },
      { lat: 50.520303, lng: 30.5959797 },
      { lat: 50.5164829, lng: 30.6050777 },
      { lat: 50.5197573, lng: 30.6073952 },
      { lat: 50.5219402, lng: 30.6083822 },
      { lat: 50.5345441, lng: 30.6100559 },
    ],
  },
  {
    name: 'БЕРКОВЕЦ',
    price: 307,
    points: [
      { lat: 50.5144635, lng: 30.3626919 },
      { lat: 50.5142998, lng: 30.3612328 },
      { lat: 50.5031099, lng: 30.3627777 },
      { lat: 50.5018543, lng: 30.3492164 },
      { lat: 50.4911936, lng: 30.3509985 },
      { lat: 50.4890505, lng: 30.3646231 },
      { lat: 50.4909276, lng: 30.3652239 },
      { lat: 50.493337, lng: 30.3658676 },
      { lat: 50.4946747, lng: 30.3657389 },
      { lat: 50.4969474, lng: 30.3653955 },
      { lat: 50.4971044, lng: 30.3674984 },
      { lat: 50.4978142, lng: 30.3674555 },
      { lat: 50.4990426, lng: 30.3653097 },
      { lat: 50.500271, lng: 30.3650522 },
      { lat: 50.5030553, lng: 30.3644943 },
      { lat: 50.5032736, lng: 30.3662968 },
      { lat: 50.5054027, lng: 30.3659534 },
      { lat: 50.5052935, lng: 30.364666 },
      { lat: 50.5144635, lng: 30.3626919 },
    ],
  },
  {
    name: 'ЭЛЕКТРОНМАШ',
    price: 308,
    points: [
      { lat: 50.4335365, lng: 30.3527355 },
      { lat: 50.4314384, lng: 30.350976 },
      { lat: 50.4309668, lng: 30.3472853 },
      { lat: 50.4270985, lng: 30.347296 },
      { lat: 50.4268934, lng: 30.3521347 },
      { lat: 50.4279323, lng: 30.3584003 },
      { lat: 50.4295043, lng: 30.3631961 },
      { lat: 50.4322244, lng: 30.3610611 },
      { lat: 50.432033, lng: 30.3581429 },
      { lat: 50.4309532, lng: 30.3542268 },
      { lat: 50.4335365, lng: 30.3527355 },
    ],
  },
  {
    name: 'КОЗИН 1',
    price: 309,
    points: [
      { lat: 50.253242, lng: 30.6237 },
      { lat: 50.2551079, lng: 30.628252 },
      { lat: 50.251925, lng: 30.6331444 },
      { lat: 50.250498, lng: 30.636835 },
      { lat: 50.2508823, lng: 30.6386376 },
      { lat: 50.2534615, lng: 30.639925 },
      { lat: 50.2551628, lng: 30.6520272 },
      { lat: 50.2597174, lng: 30.6590652 },
      { lat: 50.2805215, lng: 30.6428468 },
      { lat: 50.2916544, lng: 30.643276 },
      { lat: 50.2977956, lng: 30.6362379 },
      { lat: 50.3003177, lng: 30.614351 },
      { lat: 50.294451, lng: 30.6096304 },
      { lat: 50.2919834, lng: 30.6024206 },
      { lat: 50.2918189, lng: 30.5968416 },
      { lat: 50.2937381, lng: 30.5921209 },
      { lat: 50.2915448, lng: 30.5892027 },
      { lat: 50.2889674, lng: 30.5942667 },
      { lat: 50.2827154, lng: 30.6032789 },
      { lat: 50.2601684, lng: 30.6196726 },
      { lat: 50.253242, lng: 30.6237 },
    ],
  },
  {
    name: 'Почтовая пл.',
    price: 310,
    points: [
      { lat: 50.4578316, lng: 30.5255085 },
      { lat: 50.455004, lng: 30.5274868 },
      { lat: 50.4553336, lng: 30.5301609 },
      { lat: 50.4561306, lng: 30.5296354 },
      { lat: 50.4570015, lng: 30.5277954 },
      { lat: 50.4577904, lng: 30.5285517 },
      { lat: 50.4591804, lng: 30.5274413 },
      { lat: 50.4606574, lng: 30.5267708 },
      { lat: 50.4605264, lng: 30.5261041 },
      { lat: 50.4594097, lng: 30.5244022 },
      { lat: 50.4583476, lng: 30.5259794 },
      { lat: 50.4579001, lng: 30.5258829 },
      { lat: 50.4578316, lng: 30.5255085 },
    ],
  },
  {
    name: 'Владимирская горка',
    price: 311,
    points: [
      { lat: 50.4556871, lng: 30.5245471 },
      { lat: 50.455004, lng: 30.5274868 },
      { lat: 50.4578316, lng: 30.5255085 },
      { lat: 50.457668, lng: 30.5246115 },
      { lat: 50.4556871, lng: 30.5245471 },
    ],
  },
  {
    name: 'Многоугольник 323',
    price: 312,
    points: [
      { lat: 50.5033428, lng: 30.5340311 },
      { lat: 50.5029377, lng: 30.5505281 },
      { lat: 50.5070451, lng: 30.5480377 },
      { lat: 50.5084098, lng: 30.5391757 },
      { lat: 50.5081232, lng: 30.5332319 },
      { lat: 50.5033428, lng: 30.5340311 },
    ],
  },
  {
    name: 'Итальянский квартал 2',
    price: 313,
    points: [
      { lat: 50.5413834, lng: 30.4970384 },
      { lat: 50.5381173, lng: 30.4968882 },
      { lat: 50.5385647, lng: 30.4973101 },
      { lat: 50.5393011, lng: 30.4974871 },
      { lat: 50.5397409, lng: 30.4979968 },
      { lat: 50.5394716, lng: 30.4985386 },
      { lat: 50.5388306, lng: 30.4981577 },
      { lat: 50.5384487, lng: 30.4976373 },
      { lat: 50.5382749, lng: 30.4999762 },
      { lat: 50.5388817, lng: 30.5040424 },
      { lat: 50.5395909, lng: 30.5043965 },
      { lat: 50.5408728, lng: 30.5039781 },
      { lat: 50.5406503, lng: 30.5084332 },
      { lat: 50.539682, lng: 30.513111 },
      { lat: 50.5363135, lng: 30.51766 },
      { lat: 50.5357134, lng: 30.521501 },
      { lat: 50.5361771, lng: 30.5239901 },
      { lat: 50.5372682, lng: 30.5274018 },
      { lat: 50.5377319, lng: 30.5275949 },
      { lat: 50.539832, lng: 30.5225309 },
      { lat: 50.5430094, lng: 30.5152568 },
      { lat: 50.5444958, lng: 30.5092272 },
      { lat: 50.5444311, lng: 30.5086684 },
      { lat: 50.5428937, lng: 30.5054872 },
      { lat: 50.5416016, lng: 30.5017698 },
      { lat: 50.5413834, lng: 30.4970384 },
    ],
  },
  {
    name: 'Иванковичи',
    price: 314,
    points: [
      { lat: 50.2771505, lng: 30.3943669 },
      { lat: 50.2701289, lng: 30.3967702 },
      { lat: 50.2660691, lng: 30.4072415 },
      { lat: 50.2678247, lng: 30.4175412 },
      { lat: 50.273201, lng: 30.4369389 },
      { lat: 50.2682636, lng: 30.4491269 },
      { lat: 50.2717747, lng: 30.4650914 },
      { lat: 50.2802221, lng: 30.4535901 },
      { lat: 50.2860357, lng: 30.4377973 },
      { lat: 50.2869132, lng: 30.4211461 },
      { lat: 50.2852679, lng: 30.3996884 },
      { lat: 50.2771505, lng: 30.3943669 },
    ],
  },
  {
    name: 'Рославичи',
    price: 315,
    points: [
      { lat: 50.2462038, lng: 30.4213178 },
      { lat: 50.2348246, lng: 30.4211474 },
      { lat: 50.2299931, lng: 30.4463817 },
      { lat: 50.2330677, lng: 30.4532481 },
      { lat: 50.2422905, lng: 30.4577113 },
      { lat: 50.2516213, lng: 30.4628612 },
      { lat: 50.2595236, lng: 30.4721309 },
      { lat: 50.2620477, lng: 30.4764224 },
      { lat: 50.2657787, lng: 30.4757358 },
      { lat: 50.2717747, lng: 30.4650914 },
      { lat: 50.2682636, lng: 30.4491269 },
      { lat: 50.2637647, lng: 30.4304158 },
      { lat: 50.2579484, lng: 30.4134213 },
      { lat: 50.2462038, lng: 30.4213178 },
    ],
  },
  {
    name: 'Хуторец',
    price: 316,
    points: [
      { lat: 50.4706919, lng: 30.5219507 },
      { lat: 50.4696745, lng: 30.5235386 },
      { lat: 50.4691145, lng: 30.5241555 },
      { lat: 50.4678784, lng: 30.5245471 },
      { lat: 50.4675882, lng: 30.5247337 },
      { lat: 50.4683104, lng: 30.5257003 },
      { lat: 50.4698742, lng: 30.5253087 },
      { lat: 50.4712058, lng: 30.5250673 },
      { lat: 50.4720098, lng: 30.5238282 },
      { lat: 50.4706919, lng: 30.5219507 },
    ],
  },
  {
    name: 'Гаванский мост',
    price: 317,
    points: [
      { lat: 30.5285679, lng: 50.4680034 },
      { lat: 30.5284149, lng: 50.4696642 },
      { lat: 30.5278544, lng: 50.4699496 },
      { lat: 30.5273341, lng: 50.4695672 },
      { lat: 30.5257003, lng: 50.4683104 },
      { lat: 30.5247337, lng: 50.4675882 },
      { lat: 30.5253303, lng: 50.4666696 },
      { lat: 30.5256389, lng: 50.4670474 },
      { lat: 30.5285679, lng: 50.4680034 },
    ],
  },
  {
    name: 'Соломенский суд',
    price: 318,
    points: [
      { lat: 50.4570669, lng: 30.4212284 },
      { lat: 50.4545327, lng: 30.4230308 },
      { lat: 50.4531425, lng: 30.430734 },
      { lat: 50.4567663, lng: 30.4321932 },
      { lat: 50.4584876, lng: 30.4217005 },
      { lat: 50.4570669, lng: 30.4212284 },
    ],
  },
  {
    name: 'Лавочка',
    price: 319,
    points: [
      { lat: 50.4498861, lng: 30.3383851 },
      { lat: 50.4457596, lng: 30.3391093 },
      { lat: 50.4463553, lng: 30.3450108 },
      { lat: 50.4502904, lng: 30.3481865 },
      { lat: 50.4498861, lng: 30.3383851 },
    ],
  },
  {
    name: 'Межречье',
    price: 320,
    points: [
      { lat: 50.5818196, lng: 30.5503178 },
      { lat: 50.5769268, lng: 30.5526794 },
      { lat: 50.5756186, lng: 30.5715193 },
      { lat: 50.5788208, lng: 30.5733432 },
      { lat: 50.5815471, lng: 30.5708313 },
      { lat: 50.5830731, lng: 30.5612183 },
      { lat: 50.5835794, lng: 30.5580814 },
      { lat: 50.5843935, lng: 30.5550076 },
      { lat: 50.5844361, lng: 30.5489538 },
      { lat: 50.5842522, lng: 30.54853 },
      { lat: 50.5840938, lng: 30.5479856 },
      { lat: 50.5839541, lng: 30.5469529 },
      { lat: 50.5839269, lng: 30.5462287 },
      { lat: 50.583932, lng: 30.5461402 },
      { lat: 50.5820104, lng: 30.5457687 },
      { lat: 50.5818196, lng: 30.5503178 },
    ],
  },
  {
    name: 'Соби Клаб',
    price: 321,
    points: [
      { lat: 50.5947165, lng: 30.5379704 },
      { lat: 50.5914406, lng: 30.5370149 },
      { lat: 50.5889068, lng: 30.5346224 },
      { lat: 50.5867543, lng: 30.5303523 },
      { lat: 50.5860186, lng: 30.5266186 },
      { lat: 50.5854251, lng: 30.5268481 },
      { lat: 50.5853374, lng: 30.5281369 },
      { lat: 50.5855255, lng: 30.5282164 },
      { lat: 50.5845991, lng: 30.543623 },
      { lat: 50.5845173, lng: 30.5462408 },
      { lat: 50.583932, lng: 30.5461402 },
      { lat: 50.5839269, lng: 30.5462287 },
      { lat: 50.5839541, lng: 30.5469529 },
      { lat: 50.5840938, lng: 30.5479856 },
      { lat: 50.5842522, lng: 30.54853 },
      { lat: 50.5844361, lng: 30.5489538 },
      { lat: 50.5915971, lng: 30.5472401 },
      { lat: 50.5947437, lng: 30.5464247 },
      { lat: 50.5947165, lng: 30.5379704 },
    ],
  },
  {
    name: 'Хотяновка - 1',
    price: 322,
    points: [
      { lat: 50.5947437, lng: 30.5464247 },
      { lat: 50.5844361, lng: 30.5489538 },
      { lat: 50.5843935, lng: 30.5550076 },
      { lat: 50.5835794, lng: 30.5580814 },
      { lat: 50.5830731, lng: 30.5612183 },
      { lat: 50.5855255, lng: 30.5621624 },
      { lat: 50.5990386, lng: 30.5612183 },
      { lat: 50.6022528, lng: 30.5632782 },
      { lat: 50.6039416, lng: 30.5592442 },
      { lat: 50.6027458, lng: 30.5567194 },
      { lat: 50.6033995, lng: 30.5445744 },
      { lat: 50.5989596, lng: 30.5451752 },
      { lat: 50.5947437, lng: 30.5464247 },
    ],
  },
  {
    name: 'Хотяновка - 3',
    price: 323,
    points: [
      { lat: 50.5815471, lng: 30.5708313 },
      { lat: 50.5788208, lng: 30.5733432 },
      { lat: 50.5794139, lng: 30.5737492 },
      { lat: 50.5815463, lng: 30.5730196 },
      { lat: 50.5829292, lng: 30.574189 },
      { lat: 50.5856337, lng: 30.5786201 },
      { lat: 50.5886649, lng: 30.5846389 },
      { lat: 50.5912192, lng: 30.5918916 },
      { lat: 50.5920978, lng: 30.595357 },
      { lat: 50.597949, lng: 30.5970097 },
      { lat: 50.6027431, lng: 30.5853367 },
      { lat: 50.6076517, lng: 30.5914088 },
      { lat: 50.6111785, lng: 30.5773755 },
      { lat: 50.608296, lng: 30.5714659 },
      { lat: 50.6061205, lng: 30.5788136 },
      { lat: 50.6005096, lng: 30.5767536 },
      { lat: 50.5967504, lng: 30.5768394 },
      { lat: 50.5922281, lng: 30.5837917 },
      { lat: 50.5886862, lng: 30.5771828 },
      { lat: 50.5843266, lng: 30.5716896 },
      { lat: 50.5815471, lng: 30.5708313 },
    ],
  },
  {
    name: 'Тарасовка (Обуховский)',
    price: 324,
    points: [
      { lat: 50.1486767, lng: 30.5714508 },
      { lat: 50.1425713, lng: 30.5770298 },
      { lat: 50.1536814, lng: 30.6010624 },
      { lat: 50.1592905, lng: 30.6098172 },
      { lat: 50.1635794, lng: 30.6086155 },
      { lat: 50.1636344, lng: 30.5959126 },
      { lat: 50.156596, lng: 30.5796906 },
      { lat: 50.1486767, lng: 30.5714508 },
    ],
  },
  {
    name: 'Таценки',
    price: 325,
    points: [
      { lat: 50.166691, lng: 30.6662686 },
      { lat: 50.1608274, lng: 30.6681029 },
      { lat: 50.1568683, lng: 30.6734244 },
      { lat: 50.1558784, lng: 30.6694762 },
      { lat: 50.1508189, lng: 30.6723944 },
      { lat: 50.1526888, lng: 30.6816641 },
      { lat: 50.1599476, lng: 30.6796042 },
      { lat: 50.1654264, lng: 30.685323 },
      { lat: 50.1678455, lng: 30.6745084 },
      { lat: 50.166691, lng: 30.6662686 },
    ],
  },
  {
    name: 'Романков',
    price: 326,
    points: [
      { lat: 50.2365008, lng: 30.5570126 },
      { lat: 50.2279962, lng: 30.563089 },
      { lat: 50.2210772, lng: 30.564634 },
      { lat: 50.2203084, lng: 30.573217 },
      { lat: 50.213608, lng: 30.5812851 },
      { lat: 50.2165738, lng: 30.5879799 },
      { lat: 50.2308514, lng: 30.5902115 },
      { lat: 50.2555525, lng: 30.5749336 },
      { lat: 50.25215, lng: 30.5634323 },
      { lat: 50.2451247, lng: 30.5656639 },
      { lat: 50.2436371, lng: 30.5626774 },
      { lat: 50.2365008, lng: 30.5570126 },
    ],
  },
  {
    name: 'Кременище',
    price: 326,
    points: [
      { lat: 50.2813378, lng: 30.4858414 },
      { lat: 50.2885771, lng: 30.4963127 },
      { lat: 50.2934027, lng: 30.4947678 },
      { lat: 50.2982278, lng: 30.4817215 },
      { lat: 50.2986664, lng: 30.4600922 },
      { lat: 50.2954863, lng: 30.4552857 },
      { lat: 50.291648, lng: 30.4570023 },
      { lat: 50.283751, lng: 30.4736534 },
      { lat: 50.2813378, lng: 30.4858414 },
    ],
  },
  {
    name: 'Круглик',
    price: 327,
    points: [
      { lat: 50.2986664, lng: 30.4600922 },
      { lat: 50.2982278, lng: 30.4817215 },
      { lat: 50.306808, lng: 30.4492719 },
      { lat: 50.3088576, lng: 30.4223442 },
      { lat: 50.3020052, lng: 30.4198551 },
      { lat: 50.2954863, lng: 30.4552857 },
      { lat: 50.2986664, lng: 30.4600922 },
    ],
  },
  {
    name: 'Дарницкий мост',
    price: 328,
    points: [
      { lat: 50.4152853, lng: 30.5795267 },
      { lat: 50.4145342, lng: 30.5802727 },
      { lat: 50.4136035, lng: 30.5805996 },
      { lat: 50.4137607, lng: 30.5817905 },
      { lat: 50.4141573, lng: 30.5871979 },
      { lat: 50.4166116, lng: 30.5935279 },
      { lat: 50.4179447, lng: 30.5927661 },
      { lat: 50.4168235, lng: 30.5879811 },
      { lat: 50.4164885, lng: 30.5851379 },
      { lat: 50.417432, lng: 30.5847624 },
      { lat: 50.4176849, lng: 30.5824235 },
      { lat: 50.4159895, lng: 30.5823377 },
      { lat: 50.4152853, lng: 30.5795267 },
    ],
  },
  {
    name: 'Пешеходный мост',
    price: 329,
    points: [
      { lat: 50.4570015, lng: 30.5277954 },
      { lat: 50.4561306, lng: 30.5296354 },
      { lat: 50.4553336, lng: 30.5301609 },
      { lat: 50.4545395, lng: 30.5318535 },
      { lat: 50.4529378, lng: 30.5351177 },
      { lat: 50.4543655, lng: 30.5360404 },
      { lat: 50.4553492, lng: 30.5336157 },
      { lat: 50.4577092, lng: 30.5359599 },
      { lat: 50.4580165, lng: 30.5353269 },
      { lat: 50.4558324, lng: 30.5326176 },
      { lat: 50.457827, lng: 30.5288088 },
      { lat: 50.4577904, lng: 30.5285517 },
      { lat: 50.4570015, lng: 30.5277954 },
    ],
  },
  {
    name: 'Набережное шоссе',
    price: 327,
    points: [
      { lat: 50.4529378, lng: 30.5351177 },
      { lat: 50.4506371, lng: 30.5399252 },
      { lat: 50.4486798, lng: 30.5441255 },
      { lat: 50.447614, lng: 30.5462981 },
      { lat: 50.4447976, lng: 30.5519378 },
      { lat: 50.4419825, lng: 30.5558861 },
      { lat: 50.4426589, lng: 30.5567014 },
      { lat: 50.4432124, lng: 30.5560684 },
      { lat: 50.443964, lng: 30.5573237 },
      { lat: 50.4465107, lng: 30.5534113 },
      { lat: 50.4503911, lng: 30.545708 },
      { lat: 50.4538073, lng: 30.537654 },
      { lat: 50.4543655, lng: 30.5360404 },
      { lat: 50.4529378, lng: 30.5351177 },
    ],
  },
];
