import { useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const RequireAdmin = ({ children }) => {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth.userDate);
  if (user.role === 'Admin') {
    return <Navigate to="/admin" state={{ from: location }} />;
  }
  return children;
};
