import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: ({
        page = 1,
        limit = 10,
        driverNickname = '',
        clientPhoneNumber = '',
      }) => ({
        url: `/orders?page=${page}&limit=${limit}&driverNickname=${encodeURIComponent(
          driverNickname,
        )}&clientPhoneNumber=${encodeURIComponent(clientPhoneNumber)}`,
        method: 'GET',
      }),
      providesTags: () => ['Order'],
    }),
    getOrder: builder.query({
      query: ({ id }) => ({
        url: `/orders/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetOrdersQuery, useGetOrderQuery } = orderApi;
