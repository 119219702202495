import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const cityApi = createApi({
  reducerPath: 'cityApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getCities: builder.query({
      query: () => ({
        url: '/city/get-all',
        method: 'GET',
      }),
      providesTags: () => ['City'],
    }),
    createCity: builder.mutation({
      query: (body) => ({
        url: '/city/create',
        method: 'POST',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['City'],
    }),
    updateCity: builder.mutation({
      query: (body) => ({
        url: '/city/update',
        method: 'PUT',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['City'],
    }),
    deleteCity: builder.mutation({
      query: (body) => ({
        url: '/city/delete',
        method: 'DELETE',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['City'],
    }),
    getCity: builder.query({
      query: (body) => ({
        url: `/city/get-city`,
        method: 'GET',
        params: { ...body },
      }),
    }),
  }),
});

export const {
  useGetCitiesQuery,
  useCreateCityMutation,
  useUpdateCityMutation,
  useDeleteCityMutation,
  useGetCityQuery,
} = cityApi;
