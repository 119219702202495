import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const dispatcherApi = createApi({
  reducerPath: 'dispatcherApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  tagTypes: ['Dispatcher'],
  endpoints: (builder) => ({
    updateVisibleZone: builder.mutation({
      query: (body) => ({
        url: '/dispatcher/zone-visible',
        method: 'PATCH',
        credentials: 'include',
        body,
      }),
    }),
    updateDispatcherCity: builder.mutation({
      query: (body) => ({
        url: '/dispatcher/city',
        method: 'PATCH',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['Dispatcher'],
    }),
    getDispatcherData: builder.query({
      query: (id) => ({
        url: `/dispatcher/${id}`,
        method: 'GET',
      }),
      providesTags: () => ['Dispatcher'],
    }),
  }),
});

export const {
  useUpdateVisibleZoneMutation,
  useUpdateDispatcherCityMutation,
  useGetDispatcherDataQuery,
} = dispatcherApi;
