import React, { useEffect, useState } from 'react';
import { Input } from '../../../components/Input';
import { Button, Modal, Box } from '@mui/material';
import {
  useLazyGetClientQuery,
  useDeleteClientMutation,
  useUpdateClientMutation,
} from '../../../store/api';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';

export const ClientEditPage = () => {
  const [trigger, result] = useLazyGetClientQuery();
  const [deleteClient] = useDeleteClientMutation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [dateRegister, setDateRegister] = useState('');
  const [balance, setBalance] = useState(0);
  const [countTrips, setCountTrips] = useState(0);
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const [inputError, setInputError] = useState({
    name: false,
    phoneNumber: false,
    email: false,
    dateRegister: false,
    password: false,
  });

  const [registerClient] = useUpdateClientMutation();
  const handleSubmit = (event) => {
    event.preventDefault();
    if (name === '') {
      setInputError({ ...inputError, name: true });
    }
    if (phoneNumber === '') {
      setInputError({ ...inputError, phoneNumber: true });
    }
    if (email === '') {
      setInputError({ ...inputError, email: true });
    }
    if (dateRegister === '') {
      setInputError({ ...inputError, dateRegister: true });
    } else if (
      name !== '' &&
      phoneNumber !== '' &&
      email !== '' &&
      dateRegister !== ''
    ) {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('name', name);
      if (phoneNumber !== result?.data?.phoneNumber) {
        formData.append('phoneNumber', phoneNumber);
      }
      if (email !== result?.data?.email) {
        formData.append('email', email);
      }
      formData.append('countTrips', countTrips);
      formData.append('dateRegister', dateRegister);
      formData.append('balance', balance);
      registerClient(formData).then((data) => {
        if (data.error) {
          if (
            data.error.data.message ===
            'User with this phone number has already been created'
          ) {
            setIsErrorVisible(true);
            setErrorMessage('Пользователь с таким номером телефона уже создан');
          }
          if (
            data.error.data.message ===
            'User with this email has already been created'
          ) {
            setIsErrorVisible(true);
            setErrorMessage(
              'Пользователь с таким электронным адресом уже создан',
            );
          }
        }
      });
    }
  };

  const handleVisible = () => {
    setVisible(true);
  };

  const handleDelete = () => {
    deleteClient({ id: id }).then(() => {
      navigate('/admin/client-settings', { replace: false });
    });
  };

  useEffect(() => {
    trigger({ id: id });
  }, []);

  useEffect(() => {
    if (result) {
      setName(result?.data?.name);
      setPhoneNumber(result?.data?.phoneNumber);
      setEmail(result?.data?.email);
      setBalance(result?.data?.balance);
      setDateRegister(result?.data?.dateRegister);
      setCountTrips(result?.data?.countTrips);
    }
  }, [result]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        marginLeft: 5,
      }}
    >
      <Input
        label="Имя"
        helperText={'Это поле обязательное'}
        required
        error={inputError.name}
        value={name}
        onChange={(event) => {
          setInputError({ ...inputError, name: false });
          setName(event.target.value);
        }}
      />
      <Input
        label="Мобильный телефон"
        helperText={'Это поле обязательное'}
        required
        value={phoneNumber}
        error={inputError.phoneNumber}
        onChange={(event) => {
          setInputError({ ...inputError, phoneNumber: false });
          setPhoneNumber(event.target.value);
        }}
      />
      <Input
        label="Почта"
        required
        value={email}
        helperText={'Это поле обязательное'}
        error={inputError.email}
        onChange={(event) => {
          setInputError({ ...inputError, email: false });
          setEmail(event.target.value);
        }}
      />
      <Input
        label="Дата регистрации"
        helperText={'Это поле обязательное'}
        required
        value={dateRegister}
        error={inputError.dateRegister}
        onChange={(event) => {
          setInputError({ ...inputError, dateRegister: false });
          setDateRegister(event.target.value);
        }}
      />
      <Input
        label="Баланс"
        type={'number'}
        helperText={'Это поле обязательное'}
        value={balance}
        onChange={(event) => {
          setBalance(event.target.value);
        }}
      />
      <Input
        label="Количество поездок"
        helperText={'Это поле обязательное'}
        required
        type={'number'}
        value={countTrips}
        onChange={(event) => {
          setCountTrips(event.target.value);
        }}
      />
      <Button
        sx={{ marginTop: 5, marginBottom: 5 }}
        variant="contained"
        onClick={handleSubmit}
      >
        Обновить
      </Button>
      <Button
        sx={{ marginBottom: 5 }}
        variant="contained"
        color={'error'}
        onClick={handleVisible}
      >
        Видалити
      </Button>
      <Modal
        open={visible}
        aria-labelledby=" modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: 1,
            }}
          >
            <Button
              sx={{ width: 150 }}
              variant="contained"
              color="primary"
              onClick={handleDelete}
            >
              Видалити
            </Button>
            <Button
              sx={{ width: 150 }}
              variant="contained"
              color="error"
              onClick={() => {
                setVisible(false);
              }}
            >
              Вийти
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={isErrorVisible}
        aria-labelledby=" modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography sx={{ marginBottom: 3, fontSize: 18, fontWeight: '700' }}>
            {errorMessage}
          </Typography>

          <Button
            sx={{ width: 150 }}
            variant="contained"
            color="error"
            onClick={() => {
              setErrorMessage('');
              setIsErrorVisible(false);
            }}
          >
            Закрыть
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};
