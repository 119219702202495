import React, { useState } from 'react';
import {
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Box,
  FormHelperText,
  Button,
} from '@mui/material';
import { useGetOrderTypesQuery } from '../../../../../store/api';

export const OrderTypesSelect = ({
  onSelectedOrderType,
  onSelectedOrderCategory,
  onSelectedOrderOption,
  onSelectedOrderSubType,
  isRoadCreated,
}) => {
  const { data, isLoading } = useGetOrderTypesQuery();
  const [selectedType, setSelectedType] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedSubType, setSelectedSubType] = useState('');
  const handleChange = (event) => {
    const selectedName = event.target.value;
    const selectedData = data.find((item) => item.name === selectedName);
    setSelectedType(selectedData);
    onSelectedOrderType(selectedData);

    setSelectedCategory('');
    onSelectedOrderCategory('');

    setSelectedOption([]);
    onSelectedOrderOption([]);

    setSelectedSubType('');
    onSelectedOrderSubType('');
  };

  const handleCategoryChange = (event) => {
    const selectedName = event.target.value;

    // If selectedName is empty, we should reset selectedData
    if (selectedName === '') {
      setSelectedCategory('');
      onSelectedOrderCategory('');
      return;
    }

    const selectedData = selectedType.categoryOrder.find(
      (item) => item.name === selectedName,
    );

    setSelectedCategory(selectedData);
    onSelectedOrderCategory(selectedData);
  };

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;

    // If selectedValue is empty, we should reset selectedOption
    if (selectedValue.length === 0) {
      setSelectedOption([]);
      onSelectedOrderOption([]);
      return;
    }

    setSelectedOption(selectedValue);
    onSelectedOrderOption(selectedValue);
  };

  const handleSubTypeChange = (event) => {
    const selectedName = event.target.value;

    // If selectedName is empty, we should reset selectedData
    if (selectedName === '') {
      setSelectedSubType('');
      onSelectedOrderSubType('');
      return;
    }

    const selectedData = selectedType.subTypeOrder.find(
      (item) => item.name === selectedName,
    );

    setSelectedSubType(selectedData);
    onSelectedOrderSubType(selectedData);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl
        sx={{ mb: 5, mt: 5 }}
        error={!isRoadCreated && !selectedType}
      >
        <InputLabel>Тип замовлення</InputLabel>
        <Select
          value={selectedType ? selectedType.name : ''}
          onChange={handleChange}
          label={'Тип замовлення'}
        >
          <MenuItem value="" disabled>
            Тип замовлення
          </MenuItem>
          {data.map((item) => (
            <MenuItem key={item._id} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        {!isRoadCreated && !selectedType && (
          <FormHelperText>Виберіть тип замовлення</FormHelperText>
        )}
      </FormControl>
      {selectedType && selectedType.categoryOrder.length > 0 && (
        <FormControl sx={{ mb: 5 }}>
          <InputLabel>Категория заказа</InputLabel>
          <Select
            value={selectedCategory ? selectedCategory.name : ''}
            onChange={handleCategoryChange}
            label={'Категория заказа'}
          >
            <MenuItem value="" disabled>
              Категория заказа
            </MenuItem>
            {selectedType.categoryOrder.map((category) => (
              <MenuItem key={category._id} value={category.name}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
          {selectedCategory && (
            <Button
              variant="outlined"
              onClick={() => handleCategoryChange({ target: { value: '' } })}
              sx={{ mt: 2 }}
            >
              Видалити категорию замовлення
            </Button>
          )}
        </FormControl>
      )}
      {selectedType && selectedType.optionOrder.length > 0 && (
        <FormControl sx={{ mb: 5 }}>
          <InputLabel>Пожилания к заказу</InputLabel>
          <Select
            multiple
            value={selectedOption ? selectedOption : []}
            onChange={handleOptionChange}
            label={'Пожилания к заказу'}
          >
            <MenuItem value="" disabled>
              Пожилания к заказу
            </MenuItem>
            {selectedType.optionOrder.map((option) => (
              <MenuItem key={option._id} value={option}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          {selectedOption && selectedOption.length > 0 && (
            <Button
              variant="outlined"
              onClick={() => handleOptionChange({ target: { value: [] } })}
              sx={{ mt: 2 }}
            >
              Видалити побажання до замовлення
            </Button>
          )}
        </FormControl>
      )}
      {selectedType && selectedType.subTypeOrder.length > 0 && (
        <FormControl sx={{ mb: 5 }}>
          <InputLabel>Під тип замовлення</InputLabel>
          <Select
            value={selectedSubType ? selectedSubType.name : ''}
            onChange={handleSubTypeChange}
            label={'Під тип замовлення'}
          >
            <MenuItem value="" disabled>
              Під тип замовлення
            </MenuItem>
            {selectedType.subTypeOrder.map((subType) => (
              <MenuItem key={subType._id} value={subType.name}>
                {subType.name}
              </MenuItem>
            ))}
          </Select>
          {selectedSubType && (
            <Button
              variant="outlined"
              onClick={() => handleSubTypeChange({ target: { value: '' } })}
              sx={{ mt: 2 }}
            >
              Видалити під тип замовлення
            </Button>
          )}
        </FormControl>
      )}
    </Box>
  );
};
