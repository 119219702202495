import React from 'react';
import { TextField } from '@mui/material';

export const CustomInput = ({
  name,
  type,
  value,
  onChange,
  label,
  required,
  error,
  helperText,
  sx,
  rows,
  fullWidth,
  multiline,
  autoComplete,
}) => {
  return (
    <TextField
      sx={sx}
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      label={label}
      required={required}
      error={error}
      helperText={error ? helperText : ''}
      rows={rows}
      fullWidth={fullWidth}
      multiline={multiline}
      autoComplete={autoComplete}
    />
  );
};
