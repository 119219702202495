import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

export const FileInput = ({ label, name, error, onChange }) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    onChange(file, name);
    setImagePreviewUrl(URL.createObjectURL(file));
  };

  const handleRemoveImage = () => {
    setImagePreviewUrl('');
    onChange(null, name);
  };

  return (
    <FormControl error={error} fullWidth>
      <Typography>{label}</Typography>
      <Box my={2}>
        <input
          type="file"
          name={name}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id={`file-input-${name}`}
        />
        <label htmlFor={`file-input-${name}`}>
          <Button component="span" variant="contained" color="primary">
            Виберіть фото
          </Button>
        </label>
        {imagePreviewUrl && (
          <Box mt={2} position="relative">
            <img
              src={imagePreviewUrl}
              alt="Preview"
              style={{
                maxHeight: '200px',
                maxWidth: '100%',
                objectFit: 'cover',
                borderRadius: '4px',
              }}
            />
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleRemoveImage}
              aria-label="remove image"
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                borderRadius: '50%',
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        )}
        <Typography variant="caption" display="block" gutterBottom>
          {imagePreviewUrl ? 'Фото' : 'Фото немає'}
        </Typography>
      </Box>
      {error && (
        <FormHelperText>Ошибка:Пожалуйста загрузити файл</FormHelperText>
      )}
    </FormControl>
  );
};
