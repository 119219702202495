import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  ListItem,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useRegisterDriverMutation,
  useGetDriverQuery,
  useUpdateDriverMutation,
  useDeleteDriverMutation,
  useGetOrderTypesQuery,
  useCreateDriverOrdersTypeMutation,
  useGetDriverOrdersTypeQuery,
  useUpdateDriverOrdersTypeMutation,
} from '../../../store/api';

import { inputLabels } from './mock';
import { CustomInput } from '../../../components/CustomInput';
import { ErrorModal } from '../../../components/ErrorModal';
import { FileInput } from '../../../components/FileInput';
import { StatusSelect } from './components/StatusSelect';
import { ImagePreview } from './components/ImagePreview';

export const DriverPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [errors, setErrors] = useState({});
  const [errorModal, setErrorModal] = useState({ open: false, message: '' });
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([]);
  const [values, setValues] = useState({
    carPhoto: '',
    driverLicensePhoto: '',
    carInsurancePhoto: '',
    nickname: '',
    priority: '',
    name: '',
    secondName: '',
    middleName: '',
    phoneNumber: '',
    email: '',
    nameCar: '',
    modelCar: '',
    colorCar: '',
    numberCar: '',
    dateRegister: '',
    status: '',
    balance: '',
    moreInformation: '',
    password: '',
  });
  const [imageUrls, setImageUrls] = useState({
    carPhoto: '',
    driverLicensePhoto: '',
    carInsurancePhoto: '',
  });
  const fileFields = ['carPhoto', 'driverLicensePhoto', 'carInsurancePhoto'];

  const [createDriver] = useRegisterDriverMutation();
  const [createDriverOrders] = useCreateDriverOrdersTypeMutation();
  const [updateDriverOrders] = useUpdateDriverOrdersTypeMutation();
  const [updateDriver] = useUpdateDriverMutation();
  const [deleteDriver] = useDeleteDriverMutation();
  const { data, isLoading } = useGetDriverQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const { data: driverOrdersTypes } = useGetDriverOrdersTypeQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const { data: orderTypes } = useGetOrderTypesQuery({
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const displayValues = { ...values };

  delete displayValues._id;
  delete displayValues.statusOnline;
  delete displayValues.__v;
  const phoneNumberPattern = /^(\+380|0)[1-9]\d{8}$/;

  const handleOrderTypeChange = (event, orderType) => {
    if (!orderType) return;

    if (event.target.checked) {
      const updatedSelectedOrderTypes = [
        ...selectedOrderTypes,
        { orderTypeId: orderType._id, isActive: true },
      ];
      setSelectedOrderTypes(updatedSelectedOrderTypes);
    } else {
      const updatedSelectedOrderTypes = selectedOrderTypes.filter(
        (type) => type.orderTypeId !== orderType._id,
      );
      setSelectedOrderTypes(updatedSelectedOrderTypes);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phoneNumber') {
      if (!value.startsWith('+38') && value.length === 1) {
        const newValue = '+38' + value;
        setValues((prevValues) => ({ ...prevValues, [name]: newValue }));
      } else if (value.length > 1) {
        if (phoneNumberPattern.test(value) || value === '') {
          setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: false }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: true }));
        }
        setValues((prevValues) => ({ ...prevValues, [name]: value }));
      }
    } else {
      setValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
  };
  const handleCloseErrorModal = () => {
    setErrorModal({ open: false, message: '' });
  };

  const handleRemoveImage = (name) => {
    setImageUrls((prevImageUrls) => ({ ...prevImageUrls, [name]: '' }));
  };

  const handleFileChange = (file, name) => {
    setValues((prevValues) => ({ ...prevValues, [name]: file }));
  };

  const handleDelete = async () => {
    await deleteDriver(id).then(() => {
      navigate('/admin/drivers');
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    const optionalFields = [
      'carPhoto',
      'driverLicensePhoto',
      'carInsurancePhoto',
      'middleName',
      'moreInformation',
      'password',
    ];

    if (!id) {
      optionalFields.pop('password');
    }

    for (const key in displayValues) {
      if (key === 'phoneNumber' && errors[key]) {
        newErrors[key] = true;
      } else if (!optionalFields.includes(key) && displayValues[key] === '') {
        newErrors[key] = true;
      }
    }

    if (Object.keys(newErrors).length === 0) {
      try {
        const fd = new FormData();

        for (const key in displayValues) {
          if (
            ['carPhoto', 'driverLicensePhoto', 'carInsurancePhoto'].includes(
              key,
            ) &&
            values[key] instanceof File
          ) {
            fd.append(key, values[key]);
          } else if (displayValues[key] !== undefined) {
            fd.append(key, displayValues[key]);
          }
        }

        if (id) {
          await updateDriver({ id, formData: fd })
            .unwrap()
            .then(async (data) => {
              const dataToSend = {
                driverId: data._id,
                orderTypes: selectedOrderTypes,
              };

              await updateDriverOrders({
                id: driverOrdersTypes._id,
                ...dataToSend,
              })
                .unwrap()
                .then(() => {
                  navigate('/admin/drivers');
                })
                .catch((error) => {
                  setErrorModal({ open: true, message: error.data.message });
                });
            })
            .catch((error) => {
              setErrorModal({ open: true, message: error.data.message });
            });
        } else {
          await createDriver(fd)
            .unwrap()
            .then(async (data) => {
              const dataToSend = {
                driverId: data.data.id,
                orderTypes: selectedOrderTypes,
              };
              await createDriverOrders(dataToSend)
                .unwrap()
                .then(() => {
                  navigate('/admin/drivers');
                })
                .catch((error) => {
                  setErrorModal({ open: true, message: error.data.message });
                });
            })
            .catch((error) => {
              setErrorModal({ open: true, message: error.data.message });
            });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setErrors(newErrors);
    }
  };

  useEffect(() => {
    if (!isLoading && data) {
      setValues({ ...data, password: '' });

      setImageUrls({
        carPhoto: data.carPhoto,
        driverLicensePhoto: data.driverLicensePhoto,
        carInsurancePhoto: data.carInsurancePhoto,
      });
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (driverOrdersTypes && driverOrdersTypes.orderTypes) {
      const initialSelectedOrderTypes = driverOrdersTypes.orderTypes.map(
        (orderType) => ({
          orderTypeId: orderType.orderTypeId._id,
          isActive: orderType.isActive,
        }),
      );
      setSelectedOrderTypes(initialSelectedOrderTypes);
    }
  }, [driverOrdersTypes]);

  return (
    <Grid container spacing={2} sx={{ padding: 5 }}>
      <Grid item xs={12} md={12}>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          color="primary"
          size="large"
        >
          {id ? 'Оновити драйвера' : 'Створити драйвера'}
        </Button>
        {id && (
          <Button
            sx={{ marginLeft: 5 }}
            onClick={handleDelete}
            type="submit"
            variant="contained"
            color="error"
            size="large"
          >
            Видилати драйвера
          </Button>
        )}
      </Grid>
      <Grid item xs={7} md={7}>
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}
        >
          {fileFields.map((fileKey) => (
            <React.Fragment key={fileKey}>
              <FileInput
                name={fileKey}
                label={inputLabels[fileKey]}
                onChange={handleFileChange}
                error={errors[fileKey]}
              />
              <ImagePreview
                values={values}
                url={imageUrls[fileKey]}
                name={fileKey}
                onRemove={handleRemoveImage}
              />
            </React.Fragment>
          ))}

          {Object.entries(displayValues).map(([key, value]) => {
            const isFileInput = [
              'carPhoto',
              'driverLicensePhoto',
              'carInsurancePhoto',
            ].includes(key);
            const isOptionalField = [
              'middleName',
              'moreInformation',
              'password',
            ].includes(key);
            const isNumberField = ['balance', 'priority'].includes(key);

            if (key === 'status') {
              return (
                <StatusSelect
                  key={key}
                  name={key}
                  label={inputLabels[key]}
                  value={value}
                  onChange={handleChange}
                  error={errors[key]}
                />
              );
            } else if (!isFileInput) {
              const isPhoneNumber = key === 'phoneNumber';
              return (
                <CustomInput
                  key={key}
                  sx={{ marginTop: 3 }}
                  name={key}
                  label={inputLabels[key]}
                  value={value}
                  onChange={handleChange}
                  error={errors[key]}
                  required={!id && key === 'password' ? true : !isOptionalField}
                  type={isNumberField ? 'number' : 'text'}
                  helperText={
                    isOptionalField && key !== 'password'
                      ? ''
                      : isPhoneNumber && errors[key]
                        ? 'Введите корректный номер телефона'
                        : !id && key === 'password' && !value
                          ? 'Это поле обязательное'
                          : !isOptionalField && !value
                            ? 'Это поле обязательное'
                            : ''
                  }
                />
              );
            }
          })}
        </Box>
      </Grid>
      <Grid item xs={5} md={5}>
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}
        >
          {orderTypes && (
            <Typography>Виберіть доступні типи замовлення для водія</Typography>
          )}
          {orderTypes &&
            orderTypes.map((orderType) => {
              const isSelected = selectedOrderTypes.some(
                (selectedType) => selectedType.orderTypeId === orderType._id,
              );

              return (
                <ListItem key={orderType._id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSelected}
                        onChange={(event) =>
                          handleOrderTypeChange(event, orderType)
                        }
                      />
                    }
                    label={orderType.name}
                  />
                </ListItem>
              );
            })}
        </Box>
      </Grid>
      <ErrorModal
        open={errorModal.open}
        message={errorModal.message}
        onClose={handleCloseErrorModal}
      />
    </Grid>
  );
};
