import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  orderSlice,
  authSlice,
  zoneSlice,
  dispatcherSlice,
  citySlice,
  rateSlice,
  driverSlice,
} from './slices';

import {
  authApi,
  cityApi,
  clientApi,
  dispatcherApi,
  driverApi,
  driverOrdersTypesApi,
  orderApi,
  orderTypesApi,
  rateApi,
  zoneApi,
} from './api';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [zoneApi.reducerPath]: zoneApi.reducer,
    [dispatcherApi.reducerPath]: dispatcherApi.reducer,
    [cityApi.reducerPath]: cityApi.reducer,
    [rateApi.reducerPath]: rateApi.reducer,
    [driverApi.reducerPath]: driverApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [orderTypesApi.reducerPath]: orderTypesApi.reducer,
    [driverOrdersTypesApi.reducerPath]: driverOrdersTypesApi.reducer,
    order: orderSlice,
    auth: authSlice,
    zone: zoneSlice,
    dispatcher: dispatcherSlice,
    city: citySlice,
    rate: rateSlice,
    driver: driverSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      orderApi.middleware,
      zoneApi.middleware,
      dispatcherApi.middleware,
      cityApi.middleware,
      rateApi.middleware,
      driverApi.middleware,
      clientApi.middleware,
      orderTypesApi.middleware,
      driverOrdersTypesApi.middleware,
    ),
});

setupListeners(store.dispatch);
