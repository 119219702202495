import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const PrivateRoute = () => {
  const { user } = useSelector((state) => state.auth.userDate);
  const isLogin = useSelector((state) => state.auth.isLogin);
  if (!isLogin) {
    return <Navigate to={'/login'} />;
  }
  if (user?.role === 'Admin') {
    return <Navigate to={isLogin ? '/admin' : '/login'} />;
  }
  if (user?.role === 'Dispatcher') {
    return <Navigate to={isLogin ? '/dispatcher' : '/login'} />;
  }
};
