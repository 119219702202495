import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { Reorder } from 'framer-motion';
import Button from '@mui/material/Button';

import { setRoutePoints } from '../../../../../../store/slices/orderSlice';
import { Points } from '../index';

export const ItemPoints = ({ isLoaded }) => {
  const dispatch = useDispatch();

  const [components, setComponents] = useState([
    { id: 'start_point', description: '', location: {} },
  ]);

  const onSelect = useCallback(
    (location, description, id) => {
      setComponents((current) =>
        current.map((obj) => {
          if (obj.id === id) {
            return { ...obj, description: description, location: location };
          }
          return obj;
        }),
      );
    },
    [components],
  );

  const addInput = useCallback(() => {
    setComponents([
      ...components,
      { id: uuidv4(), description: '', location: {} },
    ]);
  }, [components]);

  useEffect(() => {
    dispatch(setRoutePoints(components));
  }, [components]);

  return (
    <div>
      <Reorder.Group axis="y" values={components} onReorder={setComponents}>
        {components.map((component, index) => (
          <Points
            key={component.id}
            component={component}
            index={index}
            id={component.id}
            onSelect={onSelect}
            setComponents={setComponents}
            isLoaded={isLoaded}
            placeholder={
              index === 0
                ? 'Початкова точка'
                : index + 1 === components.length
                  ? 'Кінечна точка'
                  : 'Проміжкова точка'
            }
          />
        ))}
      </Reorder.Group>
      <Button sx={{ mt: 1 }} variant="contained" fullWidth onClick={addInput}>
        Додати точку
      </Button>
    </div>
  );
};
