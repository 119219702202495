import { Box, Button } from '@mui/material';

export const ImagePreview = ({ url, name, onRemove, values }) => {
  const fileSelected = values[name] instanceof File;

  if (fileSelected) {
    return null;
  }

  return (
    <Box sx={{ width: '100%', textAlign: 'center', position: 'relative' }}>
      {url ? (
        <>
          <img
            src={`https://ds-admin.com.ua/${url}`}
            alt="Preview"
            style={{ maxWidth: '100%', maxHeight: '200px' }}
          />
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={() => onRemove(name)}
            style={{ position: 'absolute', top: 0, right: 0 }}
          >
            Видалити
          </Button>
        </>
      ) : (
        <Box sx={{ minHeight: '200px', border: '1px dashed grey' }}>
          <p style={{ textAlign: 'center', marginTop: '50%' }}>
            Немає зображення
          </p>
        </Box>
      )}
    </Box>
  );
};
