import React from 'react';
import { CircularProgress, Switch, Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { useUpdateVisibleZoneMutation } from '../../../../../store/api';

export const ChangeZoneVisible = () => {
  const { zoneVisible, id } = useSelector(
    (state) => state.dispatcher.dispatcherData,
  );

  const handleVisibleZone = () => {
    switchVisible({ zoneVisible: !zoneVisible, id });
  };

  const [switchVisible, { isLoading }] = useUpdateVisibleZoneMutation();

  return isLoading ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size={40} />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography>Показувати зони - </Typography>
      <Switch
        checked={zoneVisible}
        value={zoneVisible}
        onClick={handleVisibleZone}
      />
    </Box>
  );
};
