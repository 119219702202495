export const inputLabels = {
  carPhoto: 'Фото автомобіля',
  driverLicensePhoto: 'Фото прав драйвера',
  carInsurancePhoto: 'Фото страховки',
  nickname: 'Позивний',
  priority: 'Проирітет',
  name: "Ім'я",
  secondName: 'Прізвище',
  middleName: 'Побатькові',
  phoneNumber: 'Мобільний телефон',
  email: 'Email',
  nameCar: 'Марка автомобіля',
  modelCar: 'Модель автомобіля',
  colorCar: 'Колір автомобіля',
  numberCar: 'Номер автомобіля',
  dateRegister: 'Дата реєстрації',
  status: 'Статус',
  balance: 'Баланс',
  moreInformation: 'Додаткова інформація',
  password: 'Пароль',
};
