import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useUpdatePriceZoneMutation } from '../../../../store/api';

export const ZoneModal = ({ visible, setVisible, data }) => {
  const [name, setName] = useState(data?.name);
  const [coefficient, setCoefficient] = useState(
    data?.coefficient === undefined ? 0 : data?.coefficient,
  );
  const [price, setPrice] = useState(data?.price);
  const handleEditData = () => {
    editZone({
      name: name,
      coefficient: coefficient,
      price: price,
      id: data?._id,
    }).then(() => setVisible());
  };

  const [editZone] = useUpdatePriceZoneMutation();

  const handleClose = () => setVisible();

  const disabled = useMemo(() => {
    let value = true;

    if (
      name !== data?.name ||
      coefficient !== data?.coefficient ||
      price !== data?.price
    ) {
      value = false;
    }
    return value;
  }, [name, coefficient, price]);

  return (
    <Modal
      open={visible}
      aria-labelledby=" modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
          <Typography>Вікно зміни зони</Typography>
        </Box>
        <Typography sx={{ fontSize: 14 }}>Назва</Typography>
        <TextField
          placeholder={'Назва'}
          style={{ marginBottom: 10 }}
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <Typography sx={{ fontSize: 14 }}>Коефіцієнт</Typography>
        <TextField
          placeholder={'Коефіцієнт'}
          style={{ marginBottom: 10 }}
          value={coefficient}
          onChange={(event) => {
            setCoefficient(event.target.value);
          }}
        />
        <Typography sx={{ fontSize: 14 }}>Ціна</Typography>
        <TextField
          placeholder={'Ціна'}
          style={{ marginBottom: 10 }}
          value={price}
          onChange={(event) => {
            setPrice(event.target.value);
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginTop: 1,
          }}
        >
          <Button
            disabled={disabled}
            sx={{ width: 150 }}
            variant="contained"
            onClick={handleEditData}
            color="primary"
          >
            Змінити
          </Button>
          <Button
            sx={{ width: 150 }}
            variant="contained"
            color="error"
            onClick={handleClose}
          >
            Вийти
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
