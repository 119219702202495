import { createSlice } from '@reduxjs/toolkit';
import { rateApi } from '../api';

const initialState = {
  rate: {},
  rates: [],
};

export const rateSlice = createSlice({
  name: 'rate',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      rateApi.endpoints.getRates.matchFulfilled,
      (state, { payload }) => {
        state.rates = payload;
      },
    );
    builder.addMatcher(
      rateApi.endpoints.getRate.matchFulfilled,
      (state, { payload }) => {
        state.rate = payload;
      },
    );
  },
});

export default rateSlice.reducer;
