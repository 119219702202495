import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const driverOrdersTypesApi = createApi({
  reducerPath: 'driverOrdersTypesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  tagTypes: ['DriverOrdersType'],
  endpoints: (builder) => ({
    getDriverOrdersTypes: builder.query({
      query: () => ({
        url: `/driver-orders-types`,
        method: 'GET',
      }),
      providesTags: () => ['DriverOrdersType'],
    }),
    getDriverOrdersType: builder.query({
      query: (id) => ({
        url: `/driver-orders-types/${id}`,
        method: 'GET',
      }),
      providesTags: () => ['DriverOrdersType'],
    }),
    createDriverOrdersType: builder.mutation({
      query: (body) => ({
        url: `driver-orders-types`,
        method: 'POST',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['DriverOrdersType'],
    }),
    updateDriverOrdersType: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/driver-orders-types/${id}`,
        method: 'PUT',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['DriverOrdersType'],
    }),
    updateDriverOrdersTypeStatus: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/driver-orders-types/update-is-active`,
        method: 'PUT',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['DriverOrdersType'],
    }),
    deleteDriverOrdersType: builder.mutation({
      query: (id) => ({
        url: `/driver-orders-types/${id}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['DriverOrdersType'],
    }),
  }),
});

export const {
  useGetDriverOrdersTypesQuery,
  useGetDriverOrdersTypeQuery,
  useCreateDriverOrdersTypeMutation,
  useUpdateDriverOrdersTypeMutation,
  useUpdateDriverOrdersTypeStatusMutation,
  useDeleteDriverOrdersTypeMutation,
} = driverOrdersTypesApi;
