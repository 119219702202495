import React from 'react';
import { Switch, Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { setIsAllCities } from '../../../../../store/slices/dispatcherSlice';

export const ChangeCityZone = () => {
  const dispatch = useDispatch();

  const isAllCities = useSelector((state) => state.dispatcher.isAllCities);

  const handleVisibleZone = () => {
    dispatch(setIsAllCities(!isAllCities));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography>Міжгород - </Typography>
      <Switch
        checked={isAllCities}
        value={!isAllCities}
        onClick={handleVisibleZone}
      />
    </Box>
  );
};
