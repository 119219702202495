import { createSlice } from '@reduxjs/toolkit';
import { driverApi } from '../api';

const initialState = {
  driver: {},
  drivers: [],
};

export const driverSlice = createSlice({
  name: 'driver',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      driverApi.endpoints.getDrivers.matchFulfilled,
      (state, { payload }) => {
        state.drivers = payload;
      },
    );
    builder.addMatcher(
      driverApi.endpoints.getDriver.matchFulfilled,
      (state, { payload }) => {
        state.driver = payload;
      },
    );
  },
});

export default driverSlice.reducer;
