import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const clientApi = createApi({
  reducerPath: 'clientApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getClients: builder.query({
      query: () => ({
        url: '/client/get-all',
        method: 'GET',
      }),
      providesTags: () => ['Clients'],
    }),
    getClient: builder.query({
      query: (body) => ({
        url: `/client/get-client`,
        method: 'GET',
        params: { ...body },
        lazy: true,
        providesTags: () => ['Client'],
      }),
    }),
    registerClient: builder.mutation({
      query: (body) => ({
        url: '/client/register',
        method: 'POST',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['Clients'],
    }),
    updateClient: builder.mutation({
      query: (body) => ({
        url: '/client/update',
        method: 'PATCH',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['Clients', 'Client'],
    }),
    deleteClient: builder.mutation({
      query: (body) => ({
        url: '/client/delete',
        method: 'DELETE',
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['Clients'],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useLazyGetClientQuery,
  useRegisterClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
} = clientApi;
