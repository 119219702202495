import { useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const RequireAuth = ({ children }) => {
  const location = useLocation();
  const isLogin = useSelector((state) => state.auth.isLogin);
  if (!isLogin) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};
