import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Input } from '../../../components/Input';
import { Button, Modal } from '@mui/material';
import { useRegisterClientMutation } from '../../../store/api';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

export const ClientCreatePage = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [dateRegister, setDateRegister] = useState('');
  const [balance, setBalance] = useState(0);
  const [password, setPassword] = useState('');
  const [countTrips, setCountTrips] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const [inputError, setInputError] = useState({
    name: false,
    phoneNumber: false,
    email: false,
    dateRegister: false,
    password: false,
  });

  const [registerDriver] = useRegisterClientMutation();
  const handleSubmit = (event) => {
    event.preventDefault();

    if (name === '') {
      setInputError({ ...inputError, name: true });
    }
    if (phoneNumber === '') {
      setInputError({ ...inputError, phoneNumber: true });
    }
    if (email === '') {
      setInputError({ ...inputError, email: true });
    }
    if (dateRegister === '') {
      setInputError({ ...inputError, dateRegister: true });
    }
    if (password === '') {
      setInputError({ ...inputError, password: true });
    } else if (
      name !== '' &&
      phoneNumber !== '' &&
      email !== '' &&
      dateRegister !== '' &&
      password !== ''
    ) {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('phoneNumber', phoneNumber);
      formData.append('email', email);
      formData.append('countTrips', countTrips);
      formData.append('dateRegister', dateRegister);
      formData.append('balance', balance);
      formData.append('password', password);
      registerDriver(formData).then((data) => {
        if (data.error) {
          if (
            data.error.data.message ===
            'User with this phone number has already been created'
          ) {
            setIsErrorVisible(true);
            setErrorMessage('Пользователь с таким номером телефона уже создан');
          }
          if (
            data.error.data.message ===
            'User with this email has already been created'
          ) {
            setIsErrorVisible(true);
            setErrorMessage(
              'Пользователь с таким электронным адресом уже создан',
            );
          }
        } else {
          navigate('/admin/client-settings', { replace: false });
        }
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        marginLeft: 5,
      }}
    >
      <Input
        label="Имя"
        helperText={'Это поле обязательное'}
        required
        error={inputError.name}
        value={name}
        onChange={(event) => {
          setInputError({ ...inputError, name: false });
          setName(event.target.value);
        }}
      />
      <Input
        label="Мобильный телефон"
        helperText={'Это поле обязательное'}
        required
        value={phoneNumber}
        error={inputError.phoneNumber}
        onChange={(event) => {
          setInputError({ ...inputError, phoneNumber: false });
          setPhoneNumber(event.target.value);
        }}
      />
      <Input
        label="Почта"
        required
        value={email}
        helperText={'Это поле обязательное'}
        error={inputError.email}
        onChange={(event) => {
          setInputError({ ...inputError, email: false });
          setEmail(event.target.value);
        }}
      />
      <Input
        label="Дата регистрации"
        helperText={'Это поле обязательное'}
        required
        value={dateRegister}
        error={inputError.dateRegister}
        onChange={(event) => {
          setInputError({ ...inputError, dateRegister: false });
          setDateRegister(event.target.value);
        }}
      />
      <Input
        label="Баланс"
        type={'number'}
        helperText={'Это поле обязательное'}
        value={balance}
        onChange={(event) => {
          setBalance(event.target.value);
        }}
      />
      <Input
        label="Количество поездок"
        helperText={'Это поле обязательное'}
        required
        type={'number'}
        value={countTrips}
        onChange={(event) => {
          setCountTrips(event.target.value);
        }}
      />
      <Input
        label="Пароль"
        helperText={'Это поле обязательное'}
        required
        value={password}
        error={inputError.password}
        onChange={(event) => {
          setInputError({ ...inputError, password: false });
          setPassword(event.target.value);
        }}
      />
      <Button
        sx={{ marginTop: 5, marginBottom: 5 }}
        variant={'contained'}
        onClick={handleSubmit}
      >
        Создать
      </Button>
      <Modal
        open={isErrorVisible}
        aria-labelledby=" modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography sx={{ marginBottom: 3, fontSize: 18, fontWeight: '700' }}>
            {errorMessage}
          </Typography>

          <Button
            sx={{ width: 150 }}
            variant="contained"
            color="error"
            onClick={() => {
              setErrorMessage('');
              setIsErrorVisible(false);
            }}
          >
            Закрыть
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};
