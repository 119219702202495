import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  useCreateRateMutation,
  useUpdateRateMutation,
} from '../../../../store/api';

export const RateModal = ({ visible, setVisible, data, typeModal }) => {
  const [name, setName] = useState(data?.name);
  const [rate, setRate] = useState(data?.rate);

  const handleEditData = () => {
    if (typeModal === 'add') {
      addRate({
        name: name,
        rate: rate,
      }).then(() => setVisible());
    } else if (typeModal === 'edit') {
      editRate({
        name: name,
        rate: rate,
        id: data?.id,
      }).then(() => setVisible());
    }
  };

  const [addRate] = useCreateRateMutation();
  const [editRate] = useUpdateRateMutation();

  const handleClose = () => setVisible();

  const disabled = useMemo(() => {
    let value = true;
    if (typeModal === 'add') {
      if (name !== data?.name || rate !== data?.rate) {
        value = false;
      }
    } else if (typeModal === 'edit') {
      if (name !== data?.name || rate !== data?.rate) {
        value = false;
      }
    }
    return value;
  }, [name, rate]);

  return (
    <Modal
      open={visible}
      aria-labelledby=" modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        {typeModal === 'delete' ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: 1,
            }}
          >
            <Button
              sx={{ width: 150 }}
              variant="contained"
              onClick={handleEditData}
              color="primary"
            >
              Видалити
            </Button>
            <Button
              sx={{ width: 150 }}
              variant="contained"
              color="error"
              onClick={handleClose}
            >
              Вийти
            </Button>
          </Box>
        ) : (
          <>
            <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
              <Typography>
                {typeModal === 'add'
                  ? 'Вікно додавання глобальної надбавки'
                  : 'Вікно зміни глобальної надбавки'}
              </Typography>
            </Box>
            <Typography sx={{ fontSize: 14 }}>Назва</Typography>
            <TextField
              style={{ marginBottom: 10 }}
              placeholder={'Назва'}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <Typography sx={{ fontSize: 14 }}>Коефіцієнт</Typography>
            <TextField
              placeholder={'Коефіцієнт'}
              style={{ marginBottom: 10 }}
              value={rate}
              onChange={(event) => {
                setRate(event.target.value);
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginTop: 1,
              }}
            >
              <Button
                disabled={disabled}
                sx={{ width: 150 }}
                variant="contained"
                onClick={handleEditData}
                color="primary"
              >
                {typeModal === 'add' ? 'Додати' : 'Змінити'}
              </Button>
              <Button
                sx={{ width: 150 }}
                variant="contained"
                color="error"
                onClick={handleClose}
              >
                Вийти
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};
