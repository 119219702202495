import React from 'react';
import { IconButton, Box, Button } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

import { CustomInput } from '../CustomInput';

export const ArrayInput = ({ items, label, onChange }) => {
  const handleAdd = () => {
    const newItem = { name: '', price: 0 };
    onChange([...items, newItem]);
  };

  const handleRemove = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onChange(newItems);
  };

  const handleChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index] = { ...newItems[index], [field]: value };
    onChange(newItems);
  };

  return (
    <Box>
      <h3>{label}</h3>
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 3,
          }}
        >
          <CustomInput
            name={'name'}
            type={'text'}
            value={item.name}
            onChange={(e) => handleChange(index, 'name', e.target.value)}
            label={'Назва'}
            helperText="Це поле обов'язкове"
          />
          <CustomInput
            name={'price'}
            type={'number'}
            value={item.price}
            onChange={(e) => handleChange(index, 'price', e.target.value)}
            label={'Ціна'}
            helperText="Это поле обязательное"
          />
          <IconButton color="error" onClick={() => handleRemove(index)}>
            <Delete />
          </IconButton>
        </Box>
      ))}
      <Button variant="contained" endIcon={<Add />} onClick={handleAdd}>
        Додати
      </Button>
    </Box>
  );
};
