import React, { createContext, useContext, useEffect, useRef } from 'react';
import socketIOClient from 'socket.io-client';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const socketRef = useRef(null);

  useEffect(() => {
    socketRef.current = socketIOClient(`${process.env.REACT_APP_SOCKET_URL}`);

    socketRef.current.on('connect', () => {
      console.log('Connected to the server');
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={socketRef}>
      {children}
    </SocketContext.Provider>
  );
};
