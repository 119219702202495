import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { AddBox } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { useGetDriversQuery } from '../../../store/api';

const columns = [
  { id: 'nickname', label: 'Позивний' },
  { id: 'name', label: 'Імя' },
  { id: 'secondName', label: 'Прізвище' },
  { id: 'balance', label: 'Баланс' },
  { id: 'priority', label: 'Приорітет' },
  { id: 'status', label: 'Статус' },
  { id: 'statusOnline', label: 'Статус мережі' },
  { id: 'lastDateStatusOnline', label: 'Останній вхід' },
];

export const DriverListPage = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data, isLoading } = useGetDriversQuery();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (id) => {
    navigate(`/admin/driver-create/${id}`);
  };

  return (
    <Paper>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography sx={{ margin: 2 }}>Налаштування драйверів</Typography>
            <Button
              sx={{ margin: 2 }}
              color="primary"
              onClick={() => {
                navigate('/admin/driver-create');
              }}
              variant="contained"
              endIcon={<AddBox />}
            >
              Додати драйвера
            </Button>
          </Box>
          {columns.length === 0 ? (
            <Typography variant="h6" align="center" sx={{ margin: 2 }}>
              Список порожній
            </Typography>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row, index) => (
                        <TableRow
                          key={index}
                          onClick={() => handleRowClick(row._id)}
                          style={{ cursor: 'pointer' }}
                        >
                          {columns.map((column) => (
                            <TableCell key={column.id}>
                              {column.id === 'statusOnline'
                                ? row[column.id]
                                  ? 'Онлайн'
                                  : 'Оффлайн'
                                : column.id === 'balance'
                                  ? parseFloat(row[column.id]).toFixed(2)
                                  : row[column.id]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <TablePagination
            component="div"
            count={data?.length || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
  );
};
