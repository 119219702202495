import React, { useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setCenter } from '../../../../../store/slices/orderSlice';
import { useGetCityQuery } from '../../../../../store/api';

export const CustomAutocomplete = ({
  placeholder,
  id,
  onSelect,
  isLoaded,
  value,
}) => {
  const [searchResult, setSearchResult] = useState('Result: none');
  const dispatcher = useDispatch();
  const { city } = useSelector((state) => state.dispatcher.dispatcherData);
  const isAllCities = useSelector((state) => state.dispatcher.isAllCities);
  const params = {
    name: city,
  };

  const { data } = useGetCityQuery(params);

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      let lat = place.geometry.location.lat();
      let lng = place.geometry.location.lng();
      console.log('place', place);
      console.log(
        "place.formatted_address.replace(/,\\s*(Ukraine|Україна|Украина).*/, ''),",
        place.formatted_address.replace(/,\s*(Ukraine|Україна|Украина).*/, ''),
      );
      dispatcher(setCenter({ lat, lng }));
      onSelect(
        { lat, lng },
        place.formatted_address.replace(/,\s*(Ukraine|Україна|Украина).*/, ''),
        id,
      );
    } else {
      alert('Please enter text');
    }
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <div id="searchColumn">
        <Autocomplete
          onPlaceChanged={onPlaceChanged}
          onLoad={onLoad}
          restrictions={{ country: 'ua' }}
          bounds={{
            north: data?.points?.north !== undefined ? data?.points?.north : 0,
            east: data?.points?.east !== undefined ? data?.points?.east : 0,
            south: data?.points?.south !== undefined ? data?.points?.south : 0,
            west: data?.points?.west !== undefined ? data?.points?.west : 0,
          }}
          options={{ strictBounds: !isAllCities }}
        >
          {value ? (
            <TextField
              margin="dense"
              required
              fullWidth
              name={placeholder}
              label={placeholder}
              value={value}
              placeholder={placeholder}
              type="text"
              id={placeholder}
              autoComplete={placeholder}
            />
          ) : (
            <TextField
              margin="dense"
              required
              fullWidth
              name={placeholder}
              label={placeholder}
              placeholder={placeholder}
              type="text"
              id={placeholder}
              autoComplete={placeholder}
            />
          )}
        </Autocomplete>
      </div>
    </div>
  );
};
