import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  useCreateCityMutation,
  useDeleteCityMutation,
  useUpdateCityMutation,
} from '../../../store/api';

export const CityModal = ({ visible, setVisible, data, typeModal }) => {
  const [city, setCity] = useState(data?.name);
  const [north, setNorth] = useState(data?.points?.north);
  const [south, setSouth] = useState(data?.points?.south);
  const [east, setEast] = useState(data?.points?.east);
  const [west, setWest] = useState(data?.points?.west);

  const handleEditData = () => {
    if (typeModal === 'add') {
      addCity({
        name: city,
        points: {
          north: Number(north),
          south: Number(south),
          east: Number(east),
          west: Number(west),
        },
      }).then(() => setVisible());
    } else if (typeModal === 'edit') {
      editCity({
        name: city,
        points: {
          north: Number(north),
          south: Number(south),
          east: Number(east),
          west: Number(west),
        },
        id: data?.id,
      }).then(() => setVisible());
    } else if (typeModal === 'delete') {
      deleteCity({ id: data?.id }).then(() => setVisible());
    }
  };

  const [addCity] = useCreateCityMutation();
  const [editCity] = useUpdateCityMutation();
  const [deleteCity] = useDeleteCityMutation();

  const handleClose = () => setVisible();

  const disabled = useMemo(() => {
    let value = true;
    if (typeModal === 'add') {
      if (
        city !== data?.name ||
        north !== data?.points?.north ||
        south !== data?.points?.south ||
        east !== data?.points?.east ||
        west !== data?.points?.west
      ) {
        value = false;
      }
    } else if (typeModal === 'edit') {
      if (
        city !== data?.name ||
        north !== data?.points?.north ||
        south !== data?.points?.south ||
        east !== data?.points?.east ||
        west !== data?.points?.west
      ) {
        value = false;
      }
    }
    return value;
  }, [city, north, south, east, west]);

  return (
    <Modal
      open={visible}
      aria-labelledby=" modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        {typeModal === 'delete' ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: 1,
            }}
          >
            <Button
              sx={{ width: 150 }}
              variant="contained"
              onClick={handleEditData}
              color="primary"
            >
              Видалити
            </Button>
            <Button
              sx={{ width: 150 }}
              variant="contained"
              color="error"
              onClick={handleClose}
            >
              Вийти
            </Button>
          </Box>
        ) : (
          <>
            <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
              <Typography>
                {typeModal === 'add'
                  ? 'Вікно додавання міста'
                  : 'Вікно редагування міста'}
              </Typography>
            </Box>
            <Typography sx={{ fontSize: 14 }}>Назва</Typography>
            <TextField
              style={{ marginBottom: 10 }}
              placeholder={'Назва'}
              value={city}
              onChange={(event) => {
                setCity(event.target.value);
              }}
            />
            <Typography sx={{ fontSize: 14 }}>Північ-Широта</Typography>
            <TextField
              placeholder={'Північ-Широта'}
              style={{ marginBottom: 10 }}
              value={north}
              onChange={(event) => {
                setNorth(event.target.value);
              }}
            />
            <Typography sx={{ fontSize: 14 }}>Схід-Довгота</Typography>
            <TextField
              placeholder={'Схід-Довгота'}
              style={{ marginBottom: 10 }}
              value={east}
              onChange={(event) => {
                setEast(event.target.value);
              }}
            />
            <Typography sx={{ fontSize: 14 }}>Південь-Широта</Typography>
            <TextField
              placeholder={'Південь-Широта'}
              style={{ marginBottom: 10 }}
              value={south}
              onChange={(event) => {
                setSouth(event.target.value);
              }}
            />
            <Typography sx={{ fontSize: 14 }}>Захід-Довгота</Typography>
            <TextField
              placeholder={'Захід-Довгота'}
              style={{ marginBottom: 10 }}
              value={west}
              onChange={(event) => {
                setWest(event.target.value);
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginTop: 1,
              }}
            >
              <Button
                disabled={disabled}
                sx={{ width: 150 }}
                variant="contained"
                onClick={handleEditData}
                color="primary"
              >
                {typeModal === 'add' ? 'Додати' : 'Змінити'}
              </Button>
              <Button
                sx={{ width: 150 }}
                variant="contained"
                color="error"
                onClick={handleClose}
              >
                Вийти
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};
