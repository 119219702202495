import React, { useState } from 'react';
import { useGetCitiesQuery } from '../../../store/api';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableContainer from '@mui/material/TableContainer';
import { CityModal } from './CityModal';

export const CitySettingsPage = () => {
  const cities = useSelector((state) => state.city.cities);

  const [visibleModal, setVisibleModal] = useState(false);
  const [typeModal, setTypeModal] = useState('');
  const [city, setCity] = useState();

  const openModal = (data) => {
    setCity(data);
    setVisibleModal(true);
  };

  const closeModal = () => {
    setVisibleModal(false);
    setTypeModal('');
  };

  useGetCitiesQuery();

  return (
    <Box>
      <TableContainer>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <Typography sx={{ margin: 2 }}>Настройки городов</Typography>
          <Button
            sx={{ margin: 2 }}
            color="primary"
            onClick={() => {
              setTypeModal('add');
              openModal({
                name: '',
                points: {
                  north: '',
                  east: '',
                  south: '',
                  west: '',
                },
              });
            }}
            variant="contained"
            endIcon={<AddBoxIcon />}
          >
            Додати місто
          </Button>
        </Box>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width={300}>Назва</TableCell>
              <TableCell width={300} align="center">
                Північ-Широта
              </TableCell>
              <TableCell width={300} align="center">
                Схід-Довгота
              </TableCell>
              <TableCell width={300} align="center">
                Південь-Широта
              </TableCell>
              <TableCell width={300} align="center">
                Захід-Довгота
              </TableCell>
              <TableCell align="center">Дії</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cities?.map((row) => (
              <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.points.north}</TableCell>
                <TableCell align="center">{row.points.east}</TableCell>
                <TableCell align="center">{row.points.south}</TableCell>
                <TableCell align="center">{row.points.west}</TableCell>
                <TableCell align="center">
                  <Tooltip title="Редагувати">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setTypeModal('edit');
                        openModal({
                          name: row?.name,
                          points: row?.points,
                          id: row?._id,
                        });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Видалити">
                    <IconButton
                      color="error"
                      onClick={() => {
                        setTypeModal('delete');
                        openModal({ id: row?._id });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {visibleModal && (
        <CityModal
          visible={visibleModal}
          setVisible={closeModal}
          data={city}
          typeModal={typeModal}
        />
      )}
    </Box>
  );
};
