export const formula = (
  minTariff,
  submissionKilometers,
  priceInCity,
  priceOutCity,
  price,
  submissionMinutes,
  distanceInCity,
  distanceOutCity,
  time,
  distance,
  result,
  sumOfPrices,
) => {
  if (
    distanceInCity > distanceOutCity &&
    submissionKilometers < distanceInCity &&
    distance > submissionKilometers
  ) {
    return (
      sumOfPrices +
      (minTariff +
        (distanceInCity === 0
          ? 0
          : priceInCity * (distanceInCity - submissionKilometers)) +
        (distanceOutCity === 0 ? 0 : priceOutCity * distanceOutCity) +
        price * (time - submissionMinutes)) *
        result
    );
  }
  if (
    distanceInCity < distanceOutCity &&
    submissionKilometers < distanceOutCity &&
    distance > submissionKilometers
  ) {
    return (
      sumOfPrices +
      (minTariff +
        (distanceInCity === 0 ? 0 : priceInCity * distanceInCity) +
        (distanceOutCity === 0
          ? 0
          : priceOutCity * (distanceOutCity - submissionKilometers)) +
        price * (time - submissionMinutes)) *
        result
    );
  }
  if (
    distanceInCity > distanceOutCity &&
    submissionKilometers > distanceInCity &&
    distance > submissionKilometers
  ) {
    return (
      sumOfPrices +
      (minTariff +
        (distanceInCity === 0
          ? 0
          : priceInCity * (submissionKilometers - distanceInCity)) +
        (distanceOutCity === 0
          ? 0
          : priceOutCity *
            (distanceOutCity - (submissionKilometers - distanceInCity))) +
        price * (time - submissionMinutes)) *
        result
    );
  }
  if (
    distanceInCity < distanceOutCity &&
    submissionKilometers > distanceOutCity &&
    distance > submissionKilometers
  ) {
    return (
      sumOfPrices +
      (minTariff +
        (distanceInCity === 0
          ? 0
          : priceInCity *
            (distanceInCity - (submissionKilometers - distanceOutCity))) +
        (distanceOutCity === 0
          ? 0
          : priceOutCity * (submissionKilometers - distanceOutCity)) +
        price * (time - submissionMinutes)) *
        result
    );
  }
  if (distance <= submissionKilometers) {
    return minTariff;
  }
};
