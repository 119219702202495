import { createSlice } from '@reduxjs/toolkit';
import { zoneApi } from '../api';

const initialState = {
  zones: [],
};

export const zoneSlice = createSlice({
  name: 'zone',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      zoneApi.endpoints.getPriceZone.matchFulfilled,
      (state, { payload }) => {
        state.zones = payload;
      },
    );
  },
});

export default zoneSlice.reducer;
