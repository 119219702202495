import React, { useCallback } from 'react';
import { Reorder, useDragControls } from 'framer-motion';
import { DeleteForever, Place } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CustomAutocomplete } from '../Autocomplete';
import { removePoint } from '../../../../../store/slices/orderSlice';

export const Points = ({
  component,
  isLoaded,
  onSelect,
  index,
  placeholder,
  value,
  id,
}) => {
  const controls = useDragControls();
  const dispatch = useDispatch();

  const onRemove = useCallback((id) => {
    dispatch(removePoint(id));
  }, []);

  return (
    <Reorder.Item
      value={component}
      id={component}
      dragListener={false}
      dragControls={controls}
    >
      <span
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <IconButton
          className="reorder-handle"
          onPointerDown={(event) => controls.start(event)}
        >
          <Place color="primary" />
        </IconButton>
        <Box sx={{ width: '100%' }}>
          <CustomAutocomplete
            id={id}
            value={value}
            isLoaded={isLoaded}
            onSelect={onSelect}
            placeholder={placeholder}
          />
        </Box>
        <IconButton onClick={() => onRemove(id)} disabled={index === 0}>
          <DeleteForever color={index === 0 ? 'disabled' : 'warning'} />
        </IconButton>
      </span>
    </Reorder.Item>
  );
};
